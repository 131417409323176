import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { accountHasAccountRoleFlag, AccountSecurityRole, SecurityRoles, userHasSecurityFlag } from "@app/account/account.models";
import { IAddToCartModel, ICartItemModel, IOrderLifecycleEvent, ISavedCart } from "@app/checkout/checkout.models";
import { IDiscountProgramBenefit } from "@app/cms/cms.models";
import { SuggestedProductsPopupComponent } from "@app/popups/suggested-products-popup/suggested-products-popup.component";
import { IDimensionCustomizations, IFinishCustomizations, IGrillCustomizations, IPartCustomizationsModel, IPartFinishOption, IPartsCustomizationModel, IProductLightModel, IProductView } from "@app/products/products.models";
import { IPromotionDisplayModel } from "@app/promotions/promotion.models";
import { CartService } from "@app/services/cart.service";
import { PopupService } from "@app/services/popup.service";
import { ProductCustomizationService } from "@app/services/product-customization.service";
import { ProductService } from "@app/services/product.service";
import { PromoService } from "@app/services/promo.service";
import { IAppState } from "@app/store";
import { getCurrentCurrencySymbol } from "@app/store/account/account.selectors";
import { selectIsMsrp } from "@app/store/auth";
import { AddCartItemAttempt, EditCartItemAttempt } from "@app/store/cart";
import { LoadLightProductBySku, selectProductLight } from "@app/store/product-light";
import { WarningToast } from "@app/store/toast/toast.actions";
import { getCurrentUserWithAccount, selectCurrentUser } from "@app/store/user";
import { select, Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { switchMap, takeUntil, withLatestFrom } from "rxjs/operators";

@Component({
	selector: "c4-add-to-cart-widget",
	templateUrl: "./add-to-cart-widget.component.html",
	styleUrls: ["./add-to-cart-widget.component.scss"],
})
export class AddToCartWidgetComponent implements OnInit, OnDestroy {
	@Input() product: IProductView;
	@Input() editedCartItem: ICartItemModel;
	@Input() isCustomizing = false;

	private _customizedSku: string;
	@Input() set customizedSku(val: string) {
		this._customizedSku = val;
		this.store.dispatch(new LoadLightProductBySku(this.customizedSku));
		this.customizedSkuChange.next(val);
	}
	get customizedSku() {
		return this._customizedSku;
	}
	@Output() customizedSkuChange = new EventEmitter<string>();

	quantity = 1;
	disableChanges: boolean;
	canPurchase = false;
	canViewPricing = false;
	canViewMsrpPricing = false;
	currency$: Observable<string>;
	suggestedProducts$: Observable<IProductLightModel[]>;
	productDiscountBenefits: IDiscountProgramBenefit[];
	promos: IPromotionDisplayModel[];
	savedCarts: ISavedCart[];
	addingToSaveCart: boolean;
	currencyCode: string;

	variantChange = new EventEmitter<IPartCustomizationsModel>();

	@Input() customOptions: IPartCustomizationsModel;

	customFinish = <IFinishCustomizations>{};
	customWidth = <IDimensionCustomizations>{};
	customHeight = <IDimensionCustomizations>{};
	customGrill = <IGrillCustomizations>{};
	mount: string;
	isMsrp$: Observable<boolean>;

	resettingCustomizations = false;
	showWarehouses: boolean;

	private destroyed$ = new Subject<{}>();

	constructor(
		readonly store: Store<IAppState>,
		readonly productService: ProductService,
		readonly promoService: PromoService,
		readonly popupService: PopupService,
		readonly cartService: CartService,
		readonly customizationService: ProductCustomizationService,
	) { }

	ngOnInit() {
		this.isMsrp$ = this.store.pipe(select(selectIsMsrp));
		if (this.editedCartItem) {
			this.populateEditedCartItemInfo();
		}
		this.customOptions = this.product.customizations;
		this.currency$ = this.store.pipe(select(getCurrentCurrencySymbol));
		this.store.pipe(
			select(getCurrentUserWithAccount),
			takeUntil(this.destroyed$),
		).subscribe(user => {
			this.canPurchase = userHasSecurityFlag(user, SecurityRoles.PurchasingUser);
			this.canViewPricing = userHasSecurityFlag(user, SecurityRoles.PriceViewing | SecurityRoles.PurchasingUser);
			this.canViewMsrpPricing = accountHasAccountRoleFlag(user.account, AccountSecurityRole.MsrpViewing);
			this.currencyCode = user.account.currency.code;
			this.resetCustomizations();
		});

		this.customizedSkuChange.pipe(
			withLatestFrom(this.store.pipe(select(selectCurrentUser))),
			switchMap(([sku, _]) => this.store.pipe(select(selectProductLight(sku)))),
			takeUntil(this.destroyed$),
		).subscribe(product => {
			if (product) {
				this.populate(product);
			} else {
				this.store.dispatch(new LoadLightProductBySku(this.customizedSku));
			}
		});
		this.customizedSkuChange.emit(this.customizedSku);
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}

	populate(result: IProductLightModel) {
		this.product.name = result.name;
		this.product.dealerPrice = result.dealerPrice;
		this.product.msrp = result.msrp;
		this.product.hasPromo = result.hasPromo;
		this.product.snapPrice = result.snapPrice;
		this.product.displayPrice = result.displayPrice;

		if (this.product.hasPromo) {
			this.promos = null;
			this.promoService.getActiveDisplayPromotions(this.customizedSku).subscribe(promoResult => {
				this.promos = promoResult;
			});
		}

		if (result.productDiscountId > 0 && result.currencyHasDiscount) {
			this.product.productDiscount = result.productDiscount;
			this.product.productDiscountPercent = result.productDiscountPercent;
			this.product.productDiscountName = result.productDiscountName;
			this.product.productDiscountId = result.productDiscountId;

			this.productService.getProductDiscountBenefits(this.product.productDiscountId).subscribe(benefitResult => {
				this.productDiscountBenefits = benefitResult;
			});
		} else {
			this.product.productDiscount = 0;
			this.product.productDiscountPercent = undefined;
			this.product.productDiscountName = undefined;
			this.product.productDiscountId = undefined;
			this.productDiscountBenefits = undefined;
		}

	}

	populateEditedCartItemInfo() {
		this.isCustomizing = true;
		this.quantity = this.editedCartItem.quantity;
		this.mount = this.editedCartItem.mountType;
		this.customWidth = <IDimensionCustomizations>{ customDimension: this.editedCartItem.measurement, isCustomized: !!this.editedCartItem.measurement };
		this.customHeight = <IDimensionCustomizations>{ customDimension: this.editedCartItem.measurement, isCustomized: !!this.editedCartItem.measurement };
		// tslint:disable-next-line:max-line-length
		this.customGrill = <IGrillCustomizations>{ grillPaintCode: this.editedCartItem.grillPaintCode, grillType: this.editedCartItem.grillType, color: this.editedCartItem.grillColor, grillPaintManufacturer: this.editedCartItem.grillPaintManufacturer, quantity: this.quantity };
		this.customFinish = <IFinishCustomizations>{ finish: <IPartFinishOption>{ value: this.editedCartItem.veneerFinish }, paintCode: this.editedCartItem.paintCode, paintManufacturer: this.editedCartItem.paintManufacturer };
	}

	onCustomizeDimensionOrFinishChange() {
		let sku = this.product.sku;
		if (this.customWidth.isCustomized && this.customWidth.isValid) {
			sku = this.customWidth.option.finalPartNumber;
		}
		if (this.customHeight.isCustomized && this.customHeight.isValid) {
			sku = this.customHeight.option.finalPartNumber;
		}
		if (this.customFinish.isCustomized && this.customFinish.isValid) {
			if (this.customFinish.finish) {
				if (this.customFinish.finish.type === "paint") {
					const matchingColor = this.customOptions.customColorOptions.find(option => option.partNumber === this.customizedSku);
					if (matchingColor) {
						sku = matchingColor.finalPartNumber;
					}
				} else if (this.customFinish.finish.type === "veneer") {
					sku = this.customOptions.customVeneerOptions.find(x => x.partNumber === sku).finalPartNumber;
				}
			}
		}
		if (this.customFinish.cabinetColor) {
			sku = this.customFinish.cabinetColor.finalPartNumber;
		}
		this.customizedSku = sku;
	}

	enableAddToCart() {
		return this.canPurchase && this.quantity > 0 && !this.product.isDisplayOnly && this.product.dealerPrice !== 0 &&
			!this.disableChanges && (!this.isCustomizing || this.customizationsSelected());
	}

	isShowEstimatedProfit() {
		// Don't show estimated profit for the following currency codes because they include VAT in the pricelist.
		// GBP, NZD, AUD, EUR, and CHF
		if (this.currencyCode === "GBP" || this.currencyCode === "NZD" || this.currencyCode === "AUD" || this.currencyCode === "EUR" || this.currencyCode === "CHF") {
			return false;
		}

		return true;
	}

	addToCart = (): EventEmitter<boolean> => {
		const completion = new EventEmitter<boolean>();
		const item = {
			quantity: this.quantity <= 0 ? 1 : this.quantity,
			sku: this.product.sku,
		} as IAddToCartModel;
		const lifecycle = {
			action: "AddToCart",
			actionSource: "ProductDetails",
			itemQuantity: item.quantity,
			itemSku: item.sku,
			page: window.location.pathname,
		} as IOrderLifecycleEvent;

		if (this.customOptions.canCustomize) {
			item.sku = this.customizedSku;
			item.originalPartNumber = this.product.sku;

			if (this.customOptions.canCustomizePaint) {
				if (this.customFinish.paintManufacturer || this.customFinish.paintCode) {
					item.paintManufacturer = this.customFinish.paintManufacturer;
					item.paintCode = this.customFinish.paintCode;
				} else if (this.customFinish.finish && this.customFinish.finish.type === "paint") {
					item.paintCode = this.customFinish.finish.value;
				}
			}

			if (this.customOptions.canCustomizeVeneer && this.customFinish.finish && this.customFinish.finish.type === "veneer") {
				item.veneerFinish = this.customFinish.finish.value;
			}

			if (this.customOptions.canCustomizeGrillColor) {
				item.grillColor = this.customGrill.color;
				item.grillPaintManufacturer = this.customGrill.grillPaintManufacturer;
				item.grillPaintCode = this.customGrill.grillPaintCode;
			}

			if (this.customOptions.canCustomizeGrillShape) {
				item.grillType = this.customGrill.grillType;
			}

			if (this.customOptions.canCustomizeWidth) {
				item.measurement = this.customWidth.customDimension;
				item.dimensionType = "Width";
			} else if (this.customOptions.canCustomizeHeight) {
				item.measurement = this.customHeight.customDimension;
				item.dimensionType = "Height";
			}

			if (this.customOptions.canCustomizeMount) {
				item.mountType = this.mount;
			}
		}

		this.disableChanges = true;
		this.addingToSaveCart = false;

		if (this.customOptions.canCustomizeGrill && this.customGrill.addOnItem) {
			this.customGrill.addOnItem.quantity *= this.quantity;
			this.customGrill.addOnItem.grillColor = item.grillColor;
		}

		if (this.editedCartItem) {
			this.store.dispatch(new EditCartItemAttempt({
				item, lifecycle, originalOrderDetailId: this.editedCartItem.orderDetailId, originalItemAddOn: this.customGrill.editedItemAddOn, completionCallback: success => {
					completion.next(success);
					if (success) {
						if (this.customOptions.canCustomizeGrill && this.customGrill.addOnItem) {
							lifecycle.actionSource = "ProductDetailsAddon";
							this.store.dispatch(new AddCartItemAttempt({ item: this.customGrill.addOnItem, lifecycle }));
						}
						this.popupService.clear();
					} else {
						this.disableChanges = false;
						this.store.dispatch(new WarningToast({ message: "There was a problem saving this cart item." }));
					}
				},
			}));
		} else {
			this.store.dispatch(new AddCartItemAttempt({
				item, lifecycle, completionCallback: success => {
					completion.next(success);
					if (success) {
						if (this.customOptions.canCustomizeGrill && this.customGrill.addOnItem) {
							lifecycle.actionSource = "ProductDetailsAddon";
							this.store.dispatch(new AddCartItemAttempt({ item: this.customGrill.addOnItem, lifecycle }));
						}
						this.itemAddedToCart();
					} else {
						this.disableChanges = false;
						this.store.dispatch(new WarningToast({ message: "There was a problem adding the item to your cart." }));
					}
				},
			}));
		}

		return completion;
	}

	itemAddedToCart() {
		this.quantity = 1;
		this.getSuggestedProducts();
		this.disableChanges = false;
	}

	getSuggestedProducts() {
		this.productService.getSuggestedProductSkus(this.product.sku).subscribe(result => {
			if (result && result.length) {
				this.popupService.create(SuggestedProductsPopupComponent, { skus: result });
			}
		});
	}

	customizationsSelected(): boolean {
		if (!this.customOptions || !this.customOptions.canCustomize || !this.isCustomizing) {
			return true;
		}

		const measurementCustomized = !(this.customHeight.isCustomized && this.customWidth.isCustomized) || (this.customWidth.isValid || this.customHeight.isValid);
		const finishCustomized = !this.customFinish.isCustomized || this.customFinish.isValid;
		const grillCustomized = !this.customGrill.isCustomized || this.customGrill.isValid;
		const mountCustomized = !this.customOptions.canCustomizeMount || !!this.mount;

		return !!(measurementCustomized && finishCustomized && grillCustomized && mountCustomized);
	}

	onGrillChange() {
		const grillOption = this.customGrill.grillOption;
		if (this.customGrill.color && this.customOptions.customColorOptions.length > 0 && this.customGrill.color !== "White") {
			this.customizedSku = this.customOptions.customColorOptions.find((option: IPartsCustomizationModel) => option.partNumber === this.product.sku).finalPartNumber;
		} else if (grillOption && grillOption.grillShape.toLowerCase() === "square") {
			this.customizedSku = grillOption.finalPartNumber;
		} else {
			this.customizedSku = this.product.sku;
		}
	}

	toggleCustomization(): void {
		this.isCustomizing = !this.isCustomizing;

		if (!this.isCustomizing) {
			// Load original product name / pricing
			this.customizedSku = this.product.sku;
			this.resetCustomizations();
		}
	}

	resetCustomizations() {
		this.resettingCustomizations = true;

		setTimeout(() => {
			this.resettingCustomizations = false;
		}, 0);
	}

	allowsPrimaryCustomizations(): boolean {
		return this.product && (
			this.customOptions.canCustomizeWidth ||
			this.customOptions.canCustomizeHeight ||
			this.customOptions.canCustomizePaint ||
			this.customOptions.canCustomizeVeneer ||
			this.customOptions.customColorOptions.length > 0 ||
			this.customOptions.canCustomizeGrillShape ||
			this.customOptions.canCustomizeGrillColor ||
			this.customOptions.canCustomizeMount);
	}

	discountTierQtyRange(benefit: IDiscountProgramBenefit, index: number): string {
		const minQty = benefit.itemQuantity;
		const maxQty = index < (this.productDiscountBenefits.length - 1) ? (this.productDiscountBenefits[(index + 1)].itemQuantity - 1) : 0;
		const rangeText = index === (this.productDiscountBenefits.length - 1)
			? `${minQty}+`
			: minQty !== maxQty ? `${minQty}-${maxQty}` : `${minQty}`;

		return rangeText;
	}

	discountTierEffectivePrice(benefit: IDiscountProgramBenefit): number | string {
		const price = benefit.discountType === "percentage"
			? (this.product.dealerPrice - this.product.dealerPrice * benefit.discountAmount / 100).toFixed(2)
			: (this.product.dealerPrice - benefit.discountAmount);

		return price;
	}

	discountTierPercentage(benefit: IDiscountProgramBenefit): number | string {
		const discountPercentage = benefit.discountType === "percentage"
			? benefit.discountAmount
			: ((benefit.discountAmount / this.product.dealerPrice) * 100).toFixed(2);

		return discountPercentage;
	}

	toggleSaveForLater() {
		this.addingToSaveCart = !this.addingToSaveCart;

		if (this.addingToSaveCart && !this.savedCarts) {
			this.cartService.getSavedCarts().subscribe(result => {
				this.savedCarts = result;
			});
		}
	}
}
