import { Account } from "@app/account/account.models";
import { IAddress, IError } from "@app/shared/shared.models";
import { Action } from "@ngrx/store";

export class LoadAccountAttempt implements Action {
	readonly type = AccountActionTypes.LoadAccountAttempt;
	constructor(public accountId: string) { }
}

export class LoadAccountSuccess implements Action {
	readonly type = AccountActionTypes.LoadAccountSuccess;
	constructor(public payload: AccountResult) { }
}

export class LoadAccountError implements Action {
	readonly type = AccountActionTypes.LoadAccountError;
	constructor(public payload: IError) { }
}

export interface AccountResult extends Account {
	shippingAddress: IAddress;
	billingAddress: IAddress;
}

export type AccountActions =
	LoadAccountAttempt
	| LoadAccountSuccess
	| LoadAccountError;
export enum AccountActionTypes {
	LoadAccountAttempt = "[Account Load] Attempt",
	LoadAccountSuccess = "[Account Load] Success",
	LoadAccountError = "[Account Load] Error",
}
