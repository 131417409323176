import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { EnumService } from "@app/services/enum.service";
import { IEnumItem } from "@app/shared-elements/shared-elements.models";

@Component({
	selector: "c4-enum-checkbox",
	templateUrl: "./enum-checkbox.component.html",
	styleUrls: ["./enum-checkbox.component.scss"]
})
export class EnumCheckboxComponent<T extends number> implements OnInit {
	@Input() areImages = false;

	private _labelMap: Map<T, string>;
	@Input() get labelMap() {
		return this._labelMap;
	}
	set labelMap(val: Map<T, string>) {
		this._labelMap = val;
		this.populate();
	}

	@Input() columnClass: string;
	items: IEnumItem<T>[] = [];
	selected: T[];

	private _value: any;
	@Input() get value() {
		return this._value;
	}

	set value(val: any) {
		if (val !== this._value) {
			this.selected = this.enumService.getFlagValues(this.labelMap, val);
		}

		this._value = val;
		this.valueChange.emit(this._value);
	}
	@Output() valueChange = new EventEmitter();

	constructor(private enumService: EnumService) { }

	ngOnInit() { }

	populate() {
		const items: IEnumItem<T>[] = [];
		this.labelMap.forEach((label, value) => items.push({ name: label, value: value }));
		this.items = items;
		this.selected = this.enumService.getFlagValues(this.labelMap, this.value);
	}

	toggle(value: T) {
		const idx = this.selected.indexOf(value);

		if (idx > -1) {
			this.selected.splice(idx, 1);
			this.value = this.value & ~value;
		} else {
			this.selected.push(value);
			this.value = this.value | value;
		}
	}
}