import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ProductService } from "@app/services/product.service";
import { ILoadCompatibilityPart } from "@app/shared/shared.models";

@Component({
	selector: "c4-print-lighting-compatibility-report",
	templateUrl: "./print-lighting-compatibility-report.component.html",
	styleUrls: ["./print-lighting-compatibility-report.component.scss"]
})
export class PrintLightingCompatibilityReportComponent implements OnInit {
	sku: string;
	lightId: number;
	partLoad: ILoadCompatibilityPart;

	constructor(private readonly productService: ProductService, private route: ActivatedRoute) { }

	ngOnInit() {
		this.route.params.subscribe(params => {
			this.sku = params["sku"];
			this.lightId = params["lightId"];
			this.populate();
		});
	}

	private populate() {
		this.productService.getLightingCompatibilityPart(this.sku, this.lightId).subscribe((result) => {
			this.partLoad = result;
		});
	}
}
