import { Component, Input, OnInit } from "@angular/core";
import { ICatalogsCategory } from "@app/products/products.models";
import { CategoryService } from "@app/services/category.service";
import { CloudinaryService } from "@app/services/cloudinary.service";
import { IMedia } from "@app/shared/shared.models";

@Component({
	selector: "c4-category-card",
	templateUrl: "./category-card.component.html",
	styleUrls: ["./category-card.component.scss"]
})
export class CategoryCardComponent implements OnInit {
	@Input() category: ICatalogsCategory;
	@Input() section: string;
	categoryImage: IMedia;
	url: string;

	constructor(readonly categoryService: CategoryService, readonly cloudinaryService: CloudinaryService) { }

	ngOnInit() {
		this.categoryImage = this.category.categoryImage;
		this.url = this.categoryService.getCategoryUrl(this.category);
	}
}
