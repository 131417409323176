import { AccountOrderHistory } from "@app/account/account.models";
import { Action } from "@ngrx/store";

export class LoadAccountOrderHistoryAttempt implements Action {
	readonly type = OrderHistoryActionTypes.LoadAccountOrderHistoryAttempt;
	constructor() { }
}

export class LoadAccountOrderHistorySuccess implements Action {
	readonly type = OrderHistoryActionTypes.LoadAccountOrderHistorySuccess;
	constructor(public payload: AccountOrderHistory) { }
}

export class LoadAccountOrderHistoryError implements Action {
	readonly type = OrderHistoryActionTypes.LoadAccountOrderHistoryError;
	constructor(public payload: string) { }
}

export class ToggleSelectOrderHistoryItem implements Action {
	readonly type = OrderHistoryActionTypes.ToggleSelectOrderHistory;
	constructor(public webOrderId: number) { }
}

export type OrderHistoryActions =
	LoadAccountOrderHistoryAttempt
	| LoadAccountOrderHistorySuccess
	| LoadAccountOrderHistoryError
	| ToggleSelectOrderHistoryItem;
export enum OrderHistoryActionTypes {
	LoadAccountOrderHistoryAttempt = "[Account Order History Load] Attempt",
	LoadAccountOrderHistorySuccess = "[Account Order History Load] Success",
	LoadAccountOrderHistoryError = "[Account Order History Load] Failure",
	ToggleSelectOrderHistory = "[Order History Item] Toggle Select",
}
