export class PagingInfo {
	static update(existing: PagingInfo, update: Partial<PagingInfo>) {
		return PagingInfo.hasUpdates(existing, update) ? Object.assign({}, existing, update) : existing;
	}

	private static hasUpdates(existing: PagingInfo, other: Partial<PagingInfo>) {
		return (other.pageSize != null && existing.pageSize !== other.pageSize)
			|| (other.total != null && existing.total !== other.total)
			|| (other.current != null && existing.current !== other.current);
	}

	constructor(public readonly current: number, public readonly total: number, public readonly pageSize = 10) { }

	pageCount() { return this.total > 0 && this.pageSize > 0 ? Math.ceil(this.total / this.pageSize) : 0; }
}

export interface IEnumItem<T> {
	name: string;
	value: T;
}

export interface ICloudinaryUploadOverview {
	data: any;
	event: string;
	info: ICloudinaryUploadResult;
	uw_event: boolean;
}

//#region  Ugly cloudinary result
export interface ICloudinaryUploadResult {
	bytes: number;
	created_at: string;
	etag: string;
	existing: boolean;
	format: string;
	height: number;
	original_filename: string;
	pages: number;
	path: string;
	placeholder: boolean;
	public_id: string;
	resource_type: string;
	secure_url: string;
	signature: string;
	tags: string[];
	thumbnail_url: string;
	type: string;
	url: string;
	version: number;
	width: number;

	// Profile image upload extras
	moderation: ICloudinaryModeration[];
	info: ICloudinaryInfo;
	faces: any[];

	pendingDelete: boolean;
}

interface ICloudinaryModeration {
	status: string; // approved, rejected
	kind: string;
	updated_at: string;
}

interface ICloudinaryInfo {
	categorization: ICloudinaryCategorization;
	detection: ICloudinaryDetection;
}

export interface ICloudinaryCategorization {
	aws_rek_tagging: ICloudinaryAwsTagging;
}

interface ICloudinaryDetection {
	aws_rek_face: ICloudinaryAwsFaceRecognition;
}

interface ICloudinaryAwsTagging {
	status: string; // complete
	data: ICloudinaryAwsTaggingData[];
}

interface ICloudinaryAwsFaceRecognition {
	status: string; // complete
	data: ICloudinaryAwsFaceRecognitionData;
}

interface ICloudinaryAwsTaggingData {
	tag: string;
	confidence: number;
}

interface ICloudinaryAwsFaceRecognitionData {
	celebrity_faces: any[];
	unrecognized_faces: any[];
}
// #endregion

export interface IGoal {
	minimum: number;
	minimumPercent?: number;
	name?: string;
	reward: number;
	benefit: string;

	active?: boolean;
}

export interface IAccelerationGoalModel extends IGoal {
	buyingGroup: string;
}