import { AfterViewChecked, AfterViewInit, Component, EventEmitter, forwardRef, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { KeyEventId } from "@app/cms/cms.models";
import { KeyboardService } from "@app/services/keyboard.service";
import { objectUpdate } from "@app/shared/helpers";
import { CkEditorService } from "../ckeditor.service";

declare var CKEDITOR: any;

/**
 * Refactored version of ng2-ckeditor
 * Usage :
 *  <ckeditor [(ngModel)]="data" [config]="{...}" debounce="500"></ckeditor>
 */
@Component({
	selector: "c4-ckeditor",
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CKEditorComponent),
			multi: true,
		},
	],
	template: `<textarea #host></textarea>`,
})
export class CKEditorComponent implements OnInit, OnChanges, AfterViewInit, AfterViewChecked, OnDestroy {
	@Input() config: any;
	@Input() readonly: boolean;
	@Input() debounce: string;
	@Input() keyEventId: KeyEventId;

	@Output() change = new EventEmitter();
	@Output() editorChange = new EventEmitter();
	@Output() ready = new EventEmitter();
	@Output() blur = new EventEmitter();
	@Output() focus = new EventEmitter();
	@Output() contentDom = new EventEmitter();
	@Output() paste = new EventEmitter();
	@Output() drop = new EventEmitter();

	@ViewChild("host", { static: false }) host: any;

	_value = "";
	instance: any;
	debounceTimeout: any;

	constructor(private zone: NgZone, private service: CkEditorService, readonly keyboardService: KeyboardService) {
	}

	get value() {
		return this._value;
	}
	@Input()
	set value(v) {
		if (v !== this._value) {
			this._value = v;
			this.onChange(v);
		}
	}

	ngOnInit() { }

	ngOnChanges(changes: SimpleChanges) {
		if (changes.readonly && this.instance) {
			this.instance.setReadOnly(changes.readonly.currentValue);
		}
	}

	ngOnDestroy() {
		if (this.instance) {
			setTimeout(() => {
				this.instance.removeAllListeners();
				CKEDITOR.instances[this.instance.name].destroy();
				this.instance.destroy();
				this.instance = null;
			});
		}
	}

	ngAfterViewInit() {
		this.ckeditorInit(this.config || {});
	}

	ngAfterViewChecked() {
		this.ckeditorInit(this.config || {});
	}

	updateValue(value: any) {
		this.zone.run(() => {
			this.value = value;

			this.onChange(value);

			this.onTouched();
			this.change.emit(value);
		});
	}

	ckeditorInit(config: any) {
		if (typeof CKEDITOR === "undefined") {
			this.service.loadCkEditor();
			this.service.ckeditorLoaded.subscribe(loaded => {
				if (loaded) {
					this.ckeditorInit(this.config || {});
				}
			});
		} else {
			// Check textarea exists
			if (this.instance || !this.documentContains(this.host.nativeElement)) {
				return;
			}

			if (this.readonly) {
				config.readOnly = this.readonly;
			}
			// CKEditor replace textarea
			config = objectUpdate(this.service.defaultConfig, config);
			this.instance = CKEDITOR.replace(this.host.nativeElement, config);

			// Set initial value
			this.instance.setData(this.value);

			// listen for instanceReady event
			this.instance.on("instanceReady", (evt: any) => {
				// if value has changed while instance loading
				// update instance with current component value
				if (this.instance.getData() !== this.value) {
					this.instance.setData(this.value);
				}

				evt.editor.widgets.registered.uploadimage.onUploaded = function (upload: any) {
					this.replaceWith(`<img src="${upload.url}" width="${upload.width}" height="${upload.height}">`);
				};

				// send the evt to the EventEmitter
				this.ready.emit(evt);
			});

			this.instance.on("key", (event: any) => {
				const evt = event.data.domEvent.$ as KeyboardEvent;
				if (evt && this.keyEventId) {
					this.keyboardService.keydown.next({ event: evt, id: this.keyEventId });
				}
			});

			// CKEditor change event
			this.instance.on("change", (evt: any) => {
				this.onTouched();
				const value = this.instance.getData();

				if (this.value !== value) {
					// Debounce update
					if (this.debounce) {
						if (this.debounceTimeout) { clearTimeout(this.debounceTimeout); }
						this.debounceTimeout = setTimeout(() => {
							this.updateValue(value);
							this.debounceTimeout = null;
						}, parseInt(this.debounce, 10));

						// Live update
					} else {
						this.updateValue(value);
					}
				}

				// Original ckeditor event dispatch
				this.editorChange.emit(evt);
			});

			// CKEditor blur event
			this.instance.on("blur", (evt: any) => {
				this.blur.emit(evt);
			});

			// CKEditor focus event
			this.instance.on("focus", (evt: any) => {
				this.focus.emit(evt);
			});

			// CKEditor contentDom event
			this.instance.on("contentDom", (evt: any) => {
				this.contentDom.emit(evt);
			});

			// CKEditor fileUploadRequest event
			this.instance.on("fileUploadRequest", (evt: any) => {
				const fileLoader = evt.data.fileLoader,
					formData = new FormData(),
					xhr = fileLoader.xhr;
				xhr.open("POST", "https://api.cloudinary.com/v1_1/control4/image/upload/", true);
				formData.append("file", fileLoader.file);
				formData.append("folder", "ckeditor-images");
				formData.append("upload_preset", "nayrzjcb");
				fileLoader.xhr.send(formData);
				evt.cancel();
			}, null, null, 4);

			// CKEditor fileUploadResponse event
			this.instance.on("fileUploadResponse", (evt: any) => {
				const data = evt.data.fileLoader,
					xhr = evt.data.fileLoader.xhr,
					response = JSON.parse(xhr.responseText);
				data.message = 1;
				data.url = response.secure_url.replace("upload/", "upload/q_auto/");
				data.fileName = response.original_filename;
				data.width = response.width;
				data.height = response.height;
				evt.stop();
			}, null, null, 4);

			// CKEditor paste event
			this.instance.on("paste", (evt: any) => {
				this.paste.emit(evt);
			});

			// CKEditor drop event
			this.instance.on("drop", (evt: any) => {
				this.drop.emit(evt);
			});
		}
	}

	writeValue(value: any) {
		this._value = value;
		if (this.instance) { this.instance.setData(value); }
	}
	onChange(_: any) { }
	onTouched() { }
	registerOnChange(fn: any) {
		this.onChange = fn;
	}
	registerOnTouched(fn: any) {
		this.onTouched = fn;
	}

	private documentContains(node: Node) {
		return document.contains ? document.contains(node) : document.body.contains(node);
	}
}