import { Component, OnDestroy, OnInit } from "@angular/core";
import { IUser } from "@app/account/account.models";
import { DocumentationService } from "@app/services/documentation.service";
import { IDocumentationDoc } from "@app/shared/shared.models";
import { IAppState } from "@app/store";
import { selectActiveUser } from "@app/store/user";
import { select, Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: "c4-latest-documentation",
	templateUrl: "./latest-documentation.component.html",
	styleUrls: ["./latest-documentation.component.scss"]
})
export class LatestDocumentationComponent implements OnInit, OnDestroy {
	documents$: Observable<IDocumentationDoc[]>;
	activeUser: IUser;

	private destroyed$ = new Subject<{}>();

	constructor(readonly store: Store<IAppState>, readonly docService: DocumentationService) { }

	ngOnInit() {
		this.store.pipe(select(selectActiveUser), takeUntil(this.destroyed$)).subscribe((activeUser) => { this.activeUser = activeUser; });

		this.documents$ = this.docService.getMostRecentDocumentation(7);
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

	viewDocument(url: string) {
		this.docService.viewDocument(url, this.activeUser.token);
	}
}
