import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FeatureFlags } from "@app/account/account.models";
import { ICustomerSummary } from "@app/dashboard/dashboard.models";
import { IAppState } from "@app/store";
import { isFeatureFlagEnabled } from "@app/store/account";
import { AddOrderLifecycleEventAttempt, getCurrentCart, getCurrentCartCustomer, getCurrentCartItemTotal, getCurrentCartLoading, getCurrentCartName } from "@app/store/cart";
import { currentCanPurchase } from "@app/store/user";
import { select, Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";

@Component({
	selector: "c4-nav-cart",
	templateUrl: "./nav-cart.component.html",
	styleUrls: ["./nav-cart.component.scss"],
})
export class NavCartComponent implements OnInit {
	cartQuantity$: Observable<number>;
	canPurchase$: Observable<boolean>;
	cartName$: Observable<string>;
	canSwitchCart$: Observable<boolean>;
	isSavedCart$: Observable<boolean>;
	loading$: Observable<boolean>;
	currentCustomer$: Observable<ICustomerSummary>;

	@Input() showSavedCarts: boolean;
	@Output() showSavedCartsChange = new EventEmitter<boolean>();

	destroyed$ = new Subject<{}>();

	constructor(readonly store: Store<IAppState>) { }

	ngOnInit() {
		this.cartQuantity$ = this.store.pipe(select(getCurrentCartItemTotal));
		this.canPurchase$ = this.store.pipe(select(currentCanPurchase));
		this.cartName$ = this.store.pipe(select(getCurrentCartName), map(name => name || "Cart"));
		this.loading$ = this.store.pipe(select(getCurrentCartLoading));
		this.canSwitchCart$ = this.store.pipe(select(isFeatureFlagEnabled(FeatureFlags.SwitchCart)));
		this.isSavedCart$ = this.store.pipe(select(getCurrentCart), map(cart => cart && cart.cartType !== "C"));
		this.currentCustomer$ = this.store.pipe(select(getCurrentCartCustomer));
	}

	toggleSavedCarts() {
		this.showSavedCartsChange.emit(!this.showSavedCarts);
	}

	trackLifecycleEvent() {
		this.store.dispatch(new AddOrderLifecycleEventAttempt({ action: "GoToCart", actionSource: "HeaderCartWidget" }));
	}
}