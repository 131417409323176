import { Injectable } from "@angular/core";
import { ProductService } from "@app/services/product.service";
import { getNgStoreCacheItem } from "@app/shared/helpers";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action, select, Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, filter, mergeMap, withLatestFrom } from "rxjs/operators";
import { IAppState } from "..";
import { Redirect } from "../router";
import { LoadProductDetailsAttempt, LoadProductDetailsError, LoadProductDetailsSuccess, ProductDetailsActionType } from "./product-details.actions";
import { detailsSelectEntities } from "./product-details.selectors";

@Injectable()
export class ProductDetailsEffects {
	constructor(
		private actions$: Actions,
		private store: Store<IAppState>,
		private productService: ProductService
	) { }

	@Effect()
	loadProductDetails$: Observable<Action> = this.actions$.pipe(
		ofType<LoadProductDetailsAttempt>(ProductDetailsActionType.LoadProductDetailsAttempt),
		withLatestFrom(this.store.pipe(select(detailsSelectEntities))),
		filter(([action, entities]) => !getNgStoreCacheItem(entities[action.payload.sku])),
		mergeMap(([action, _]) => {
			return this.productService
				.getProductDetails(action.payload.sku)
				.pipe(
					mergeMap((info) => {
						if (info.sku !== action.payload.sku) {
							return [new LoadProductDetailsSuccess(info), new Redirect({ url: `/product/${info.sku}` })];
						}
						return [new LoadProductDetailsSuccess(info)];
					}),
					catchError((err) => of(new LoadProductDetailsError({ error: err.message || err.statusText })))
				);
		})
	);

}
