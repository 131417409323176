import { getNgStoreCacheItem } from "@app/shared/helpers";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { categoryAdapter } from "./product-category.adapter";
import { ICategoryState } from "./product-category.reducer";

export const getCategoriesState = createFeatureSelector<ICategoryState>("productCategories");

export const {
	selectIds: categorySelectIds,
	selectEntities: categorySelectEntities,
	selectAll: categorySelectAll,
	selectTotal: categorySelectTotal,
} = categoryAdapter.getSelectors(getCategoriesState);

export const getProductsInListView = createSelector(
	getCategoriesState, state => state.listView,
);

export const getSelectedCategoryId = createSelector(
	getCategoriesState, state => state.selectedId,
);

export const getSelectedCategory = createSelector(
	categorySelectEntities,
	getSelectedCategoryId,
	(entities, id) => getNgStoreCacheItem(entities[id]),
);

export const getCategory = (id: number) => createSelector(
	categorySelectEntities, entities => entities ? getNgStoreCacheItem(entities[id]) : undefined,
);

export const getSelectedCategoryFilters = createSelector(
	getSelectedCategory, category => category ? category.filters : undefined,
);

export const getSelectedCategorySkus = createSelector(
	getSelectedCategory, category => category && !category.description ? category.skus : undefined,
);
