import { IShippingMethod } from "@app/checkout/checkout.models";
import { ngStoreCacheItem } from "@app/shared/helpers";
import { ShippingMethodActionTypes } from "@app/store/shipping-method";
import { shippingMethodAdapter } from "@app/store/shipping-method/shipping-method.adapter";
import { EntityState } from "@ngrx/entity";
import { ShippingMethodActions } from "./shipping-method.actions";

export interface IShippingMethodState extends EntityState<IShippingMethod> { }

export const initialState: IShippingMethodState = shippingMethodAdapter.getInitialState();

export function shippingMethodReducer(
	state = initialState,
	action: ShippingMethodActions
): IShippingMethodState {
	switch (action.type) {
		case ShippingMethodActionTypes.LoadShippingMethodSuccess:
			return shippingMethodAdapter.upsertOne(ngStoreCacheItem(action.payload, 2, "hours"), state);
		default: {
			return state;
		}
	}
}