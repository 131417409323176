import { Pipe, PipeTransform } from "@angular/core";
import { ChartSegment } from "@app/dashboard/dashboard.models";

@Pipe({
	name: "chartMap"
})
export class ChartMapPipe implements PipeTransform {

	transform(ids: number[], segments: ChartSegment[], seperator = ", "): any {
		if (!ids || !segments) {
			return "";
		}
		return ids.map(x => {
			const item = segments.find(s => s.id === x);
			return item ? item.name : `Unknown (${x})`;
		}).join(seperator);
	}

}
