import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { Account, IUser } from "@app/account/account.models";
import { IRmaHistoryItem } from "@app/rma/rma.models";
import { KbService } from "@app/services/kb.service";
import { RmaService } from "@app/services/rma.service";
import { UserService } from "@app/services/user.service";
import { Portals } from "@app/shared/shared.models";
import { IAppState } from "@app/store";
import { getCurrentPortal } from "@app/store/app-info/app-info.selectors";
import { getSalesforceContactId, LoadUserSalesforceInfoAttempt } from "@app/store/sf-contact";
import { SupportActionType } from "@app/store/support/support.actions";
import { WarningToast } from "@app/store/toast/toast.actions";
import { getCurrentUserWithAccount } from "@app/store/user";
import { IKbArticleSearchModel } from "@app/support/support.models";
import { ActionsSubject, select, Store } from "@ngrx/store";
import { combineLatest, Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";

declare var liveagent: any;

@Component({
	selector: "c4-live-chat-window",
	templateUrl: "./live-chat-window.component.html",
	styleUrls: ["./live-chat-window.component.scss"],
})
export class LiveChatWindowComponent implements OnInit, OnDestroy {

	currentUser: IUser;
	currentAccount: Account;
	show = false;
	casesLoaded = false;
	articlesLoaded = false;
	currentPane = 1;

	issueText: string;
	projectName: string;
	selectedCase: IRmaHistoryItem;

	cases: IRmaHistoryItem[];
	kbArticles: IKbArticleSearchModel[];

	isExisting: boolean;
	isExistingString: string;

	liveAgentId: string;
	liveAgentInitId: string;
	contactId: string;

	chatRunning: boolean;
	private chatWindow: any;
	private scriptTag: HTMLScriptElement;

	selectablePortals: Portals[];
	selectedPortal: Portals;
	hasComposer: boolean;

	subsc: Subscription;

	private destroyed$ = new Subject<{}>();

	constructor(private store: Store<IAppState>, private route: ActivatedRoute,
		private kbService: KbService, private userService: UserService,
		private rmaService: RmaService, private actionsSubj: ActionsSubject) { }

	ngOnInit() {
		this.route.queryParams.subscribe((params: Params) => {
			this.hasComposer = params["origin"] === "composer";
			this.show = this.hasComposer;
		});

		combineLatest([
			this.store.pipe(select(getCurrentUserWithAccount)),
			this.store.pipe(select(getSalesforceContactId)),
			this.store.pipe(select(getCurrentPortal)),
		]).pipe(
			takeUntil(this.destroyed$),
		).subscribe(([user, contactId, portal]) => {
			this.currentUser = user;
			this.currentAccount = user ? user.account : undefined;
			this.contactId = contactId;
			this.selectedPortal = portal;
			this.store.dispatch(new LoadUserSalesforceInfoAttempt());
			if (this.currentUser && this.currentAccount) {
				this.populate();
			}
		});

		this.actionsSubj.pipe(takeUntil(this.destroyed$)).subscribe(data => {
			if (data.type === SupportActionType.LiveChatToggle) {
				this.toggleShow();
			}
		});
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}

	toggleShow() {
		this.show = !this.show;
	}

	populate() {
		this.selectablePortals = this.getSelectablePortals(this.selectedPortal);
		this.liveAgentId = this.getLiveAgentId();
		this.liveAgentInitId = this.getLiveAgentInitId();
		this.currentPane = 1;
		this.issueText = null;
		this.projectName = null;
		this.selectedCase = null;
		this.cases = null;
		this.kbArticles = null;
		this.isExisting = false;
		this.casesLoaded = false;
		this.articlesLoaded = false;
		// this.loadLiveAgentScript();
	}

	getPrettyName(portal: Portals) {
		switch (portal) {
			case Portals.Pakedge:
				return "Pakedge";
			case Portals.Triad:
				return "Triad";
			default:
				return "Control4";
		}
	}

	private getSelectablePortals(portal: Portals): Portals[] {
		switch (portal) {
			case Portals.DealerEmea:
				return [Portals.DealerEmea, Portals.Pakedge, Portals.Triad];
			case Portals.Pakedge:
			case Portals.Triad:
				return [Portals.Pakedge, Portals.Triad];
			default:
				return [Portals.DealerUs, Portals.Pakedge, Portals.Triad];
		}
	}

	goToSecondPane() {
		this.currentPane = 2;
		this.loadSecondPane();
	}

	startLiveAgentChat() {
		if (this.chatRunning) { return; }
		if (!this.canLoadChat()) {
			this.store.dispatch(new WarningToast({ message: "A case or project name must be provided.", ttl: 3000 }));

			return;
		}

		this.chatWindow = window.open("about:blank", "livechat", "width=500,height=500");
		this.loadLiveAgentScript(this.addChatDetails);
	}

	canLoadChat() {
		return !this.chatRunning && ((this.isExisting && this.selectedCase) || (!this.isExisting && this.projectName));
	}

	private getLiveAgentId(): string {
		if (this.hasComposer) {
			return "57338000000Gn1J";
		}

		switch (this.selectedPortal) {
			case Portals.DealerEmea:
				return "573380000004CmN";
			case Portals.Pakedge:
				return "573380000004CmI";
			case Portals.Triad:
				return "573380000004CmX";
			default:
				return "57350000000PBKI";
		}
	}

	private getLiveAgentInitId(): string {
		if (this.hasComposer) {
			return "57238000000KzJv";
		}
		switch (this.selectedPortal) {
			case Portals.Pakedge:
				return "572380000004ChR";
			case Portals.Triad:
				return "572380000004ChC";
			default:
				return "57238000000KzJv";
		}
	}

	private loadSecondPane() {
		this.loadKbArticles();
		this.loadExistingCases();
	}

	private loadKbArticles() {
		this.kbService.searchKbArticles(this.issueText, 5).subscribe((result: IKbArticleSearchModel[]) => {
			this.kbArticles = result || [];
			this.articlesLoaded = true;
		});
	}

	private loadExistingCases() {
		this.rmaService.getRecentSalesforceCases().subscribe((result: IRmaHistoryItem[]) => {
			this.cases = result;
			this.casesLoaded = true;
		});
	}

	private loadLiveAgentScript(runChat: Function = null) {
		this.chatRunning = false;
		if (this.scriptTag) {
			this.scriptTag.remove();
		}

		this.scriptTag = document.createElement("script");
		this.scriptTag.src = "https://c.la1-c1-dfw.salesforceliveagent.com/content/g/js/38.0/deployment.js";
		this.scriptTag.onload = () => {
			this.liveAgentInitId = this.getLiveAgentInitId();
			liveagent.init("https://d.la1-c1-dfw.salesforceliveagent.com/chat", this.liveAgentInitId, "00D5000000078mu");
			if (!(window as any)._laq) { (window as any)._laq = []; }
			(window as any)._laq.push(() => {
				this.liveAgentId = this.getLiveAgentId();
				liveagent.showWhenOnline(this.liveAgentId, document.getElementById(`liveagent_button_online_${this.liveAgentId}`));
				liveagent.showWhenOffline(this.liveAgentId, document.getElementById(`liveagent_button_offline_${this.liveAgentId}`));
			});
			if (runChat) {
				(window as any)._laq.push(runChat);
			}
		};

		document.getElementsByTagName("head")[0].appendChild(this.scriptTag);
	}

	private addChatDetails = () => {
		if (!this.chatRunning) {
			liveagent.setChatWindowWidth(500);
			liveagent.setChatWindowHeight(500);

			const fullName = `${this.currentUser.firstName} ${this.currentUser.lastName}`;
			liveagent.addCustomDetail("First Name", this.currentUser.firstName);
			liveagent.addCustomDetail("Last Name", this.currentUser.lastName);
			liveagent.addCustomDetail("Full Name", fullName);
			const phone = this.currentUser.dayPhone; // || this.currentUser.mobilePhone;
			if (phone) {
				liveagent.addCustomDetail("Phone Number", phone);
			}
			if (this.contactId) {
				liveagent.addCustomDetail("Contact Id", this.contactId);
			}
			liveagent.addCustomDetail("Email Address", this.currentUser.email);
			liveagent.addCustomDetail("Dealership", this.currentAccount.accountName);
			liveagent.addCustomDetail("ChatRequestPage", location.href);
			liveagent.addCustomDetail("Case Subject", this.issueText).saveToTranscript("Subject__c").doKnowledgeSearch();
			liveagent.setName(fullName);

			if (!this.isExisting) {
				if (!this.contactId) {
					liveagent.findOrCreate("Contact")
						.map("Email", "Email Address", true, true, false)
						.map("Name", "Full Name", true, true, false)
						.map("Account", "Dealership", true, true, false);
				} else {
					liveagent.findOrCreate("Contact")
						.map("Id", "Contact Id", true, true, false);
				}
				liveagent.findOrCreate("Contact").showOnCreate().linkToEntity("Case", "ContactId");
				liveagent.findOrCreate("Contact").saveToTranscript("Contact");
				liveagent.addCustomDetail("Case Origin", "LiveChat");
				liveagent.addCustomDetail("Case Record Type", this.getRecordType());
				liveagent.addCustomDetail("Project Name", this.projectName);
				liveagent.findOrCreate("Case")
					.map("Project_Name__c", "Project Name", false, false, true)
					.map("Subject", "Case Subject", false, false, true)
					.map("RecordTypeId", "Case Record Type", false, false, true)
					.map("Origin", "Case Origin", false, false, true).showOnCreate();
			} else {
				liveagent.addCustomDetail("Recent Case", this.selectedCase.caseNumber);
				liveagent.addCustomDetail("Contact Id", this.selectedCase.contactId).saveToTranscript("Contact");
				liveagent.findOrCreate("Case").map("CaseNumber", "Recent Case", true, true, true).showOnCreate();
			}

			liveagent.findOrCreate("Case").saveToTranscript("Case");

			liveagent.addButtonEventHandler(this.getLiveAgentId(), (e: any) => {
				if (e === liveagent.BUTTON_EVENT.BUTTON_AVAILABLE && !this.chatRunning) {
					this.chatRunning = true;
					this.openChatWindow();

					if (this.hasComposer) {
						// this.$timeout(() => { this.$window.location.replace(this.$window.location.href.replace(/origin=composer\&?/, "")); }, 100);
					}
				}
			});
		}
	}

	private openChatWindow() {
		if (this.chatWindow) {
			liveagent.startChatWithWindow(this.getLiveAgentId(), this.chatWindow.name);
		}
	}

	private getRecordType(): string {
		switch (this.selectedPortal) {
			case Portals.Pakedge:
				return "01238000000UVLH";
			case Portals.Triad:
				return "01238000000E9iL";
			default:
				return "01238000000E8v4";
		}
	}
}