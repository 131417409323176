import { getNgStoreCacheItem } from "@app/shared/helpers";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { getCurrentAccount } from "../account";
import { statusProgressAdapter } from "./status-progress.adapter";
import { IStatusProgressState } from "./status-progress.reducer";

export const getStatusProgressState = createFeatureSelector<IStatusProgressState>("statusProgress");

export const {
	selectIds: statusProgressSelectIds,
	selectEntities: statusProgressSelectEntities,
	selectAll: statusProgressSelectAll,
	selectTotal: statusProgressSelectTotal
} = statusProgressAdapter.getSelectors(getStatusProgressState);

export const getCurrentStatusProgress = createSelector(
	getCurrentAccount,
	statusProgressSelectEntities,
	(account, entities) => account ? getNgStoreCacheItem(entities[account.id]) : undefined
);