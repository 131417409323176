import { IProductView } from "@app/products/products.models";
import { ngStoreCacheItem, objectUpdate } from "@app/shared/helpers";
import { EntityState } from "@ngrx/entity";
import { AuthActions, AuthActionType } from "../auth";
import { CartActions, CartActionType } from "../cart";
import { ProductDetailsActions, ProductDetailsActionType } from "./product-details.actions";
import { detailsAdapter } from "./product-details.adapter";

export interface IProductDetailsState extends EntityState<IProductView> {
	customizedSku: string;
	selectedSku: string;
}

export const initialState: IProductDetailsState = detailsAdapter.getInitialState({
	customizedSku: undefined,
	selectedSku: undefined,
});

export function productDetailsReducer(
	state = initialState,
	action: ProductDetailsActions | AuthActions | CartActions,
): IProductDetailsState {
	switch (action.type) {
		case AuthActionType.ImpersonateSuccess:
		case AuthActionType.UnimpersonateSuccess:
		case CartActionType.ChangeActiveCartSuccess:
		case CartActionType.CheckoutPageLoadedSuccess:
			return detailsAdapter.removeAll(state);
		case ProductDetailsActionType.UpdateSelectedSku: {
			const sku = action.payload.sku ? action.payload.sku.toUpperCase() : undefined;
			return objectUpdate(state, { customizedSku: sku, selectedSku: sku });
		}
		case ProductDetailsActionType.UpdateCustomizedSku: {
			const sku = action.payload.sku ? action.payload.sku.toUpperCase() : undefined;
			return objectUpdate(state, { customizedSku: sku });
		}
		case ProductDetailsActionType.LoadProductDetailsSuccess:
			return detailsAdapter.upsertOne(ngStoreCacheItem(action.payload, 20, "minutes"), state);
		case ProductDetailsActionType.UpdateCmsProductDetailsSuccess:
			return detailsAdapter.removeOne(action.payload.sku.toUpperCase(), state);
		case ProductDetailsActionType.UpdateCmsBulkProductDetailsSuccess:
			return detailsAdapter.removeMany(action.payload.skus.map(sku => sku.toUpperCase()), state);
		default:
			return state;
	}
}