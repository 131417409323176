import { IError, ISalesContactsModel } from "@app/shared/shared.models";
import { Action } from "@ngrx/store";

export class LoadSalesContactsAttempt implements Action {
	readonly type = SalesContactActionTypes.LoadSalesContactsAttempt;
	constructor() { }
}

export class LoadSalesContactsSuccess implements Action {
	readonly type = SalesContactActionTypes.LoadSalesContactsSuccess;
	constructor(public payload: ISalesContactsModel) { }
}

export class LoadSalesContactsError implements Action {
	readonly type = SalesContactActionTypes.LoadSalesContactsError;
	constructor(public payload: IError) { }
}

export type SalesContactsActions =
	LoadSalesContactsAttempt
	| LoadSalesContactsSuccess
	| LoadSalesContactsError;
export enum SalesContactActionTypes {
	LoadSalesContactsAttempt = "[Sales Contacts Load] Attempt",
	LoadSalesContactsSuccess = "[Sales Contacts Load] Success",
	LoadSalesContactsError = "[Sales Contacts Load] Error",
}
