import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IAuthenticatedLinkModel, IAuthResponse, ILogin } from "@app/auth/auth.models";
import { AuthService } from "@app/services/auth.service";
import { UserService } from "@app/services/user.service";
import { PasswordErrors, Portals } from "@app/shared/shared.models";
import { IAppState } from "@app/store";
import { getCurrentPortal, getUsernameRegex } from "@app/store/app-info/app-info.selectors";
import { LoginSuccess } from "@app/store/auth";
import { ExternalRedirect, Redirect } from "@app/store/router";
import { ErrorToast } from "@app/store/toast/toast.actions";
import { selectActiveUser } from "@app/store/user";
import { select, Store } from "@ngrx/store";
import { combineLatest, Observable, Subject } from "rxjs";
import { filter, map, switchMap, takeUntil } from "rxjs/operators";

@Component({
	selector: "c4-login-home",
	templateUrl: "./login-home.component.html",
	styleUrls: ["./login-home.component.scss"],
})
export class LoginHomeComponent implements OnInit, OnDestroy {
	model: ILogin;
	currentPortal: Portals;
	error: string;
	infoMessage: string;
	loggingIn: boolean;
	showForgot = false;
	showReset = false;
	showCreateInitialPassword = false;
	usernameChangeRequired = false;
	newUsername: string;
	usernameRegex: string;
	redirectParam: string;

	resetInfo: IAuthenticatedLinkModel;

	private destroyed$ = new Subject<{}>();
	authenticated$: Observable<boolean>;

	constructor(private store: Store<IAppState>, readonly authService: AuthService, readonly userService: UserService, readonly route: ActivatedRoute) { }

	ngOnInit() {
		this.authenticated$ = this.store.pipe(select(selectActiveUser), map(user => !!user));
		this.model = {} as ILogin;

		combineLatest([
			this.store.pipe(select(getCurrentPortal)),
			this.store.pipe(select(getUsernameRegex)),
			this.route.queryParams,
		]).pipe(
			takeUntil(this.destroyed$),
		).subscribe(([portal, regex, params]) => {
			this.currentPortal = portal;
			this.usernameRegex = regex;
			this.redirectParam = params["redirect"] as string;

			const authToken = params["auth"] as string;
			if (authToken) {
				this.loginWithToken(authToken);
			}
		});

		this.route.params.pipe(
			map(params => params.firstlogin),
			filter(firstlogin => !!firstlogin),
			takeUntil(this.destroyed$),
			switchMap(firstlogin => this.authService.getFirstTimeUserData(firstlogin)),
			takeUntil(this.destroyed$),
		).subscribe(result => {
			this.showCreateInitialPassword = result.linkValid;
			if (this.showCreateInitialPassword) {
				this.model.loginWithNewPasswordToken = result.id;
				this.model.username = result.user.loginName;
			}
		});

		this.route.params.pipe(
			map(params => params.guid),
			filter(guid => !!guid),
			takeUntil(this.destroyed$),
			switchMap(guid => this.authService.getResetData(guid)),
			takeUntil(this.destroyed$),
		).subscribe(result => {
			this.resetInfo = result;

			this.showReset = this.resetInfo.id !== null;

			if (!this.resetInfo.linkValid) {
				this.error = "This password reset link is no longer valid. Please request a new password reset.";
			}
		});
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}

	login() {
		this.error = null;
		this.model.currentPortal = this.currentPortal;
		if (!this.model.username) {
			this.error = "Must provide a username";
		} else if (!this.model.password) {
			this.error = "Must provide a password";
		} else {
			this.loggingIn = true;
			this.authService.login(this.model).subscribe(result => {
				if (result.authError) {
					this.displayMessage(result.authError, true);
				} else if (result.isSecondaryAttempt && result.destinationPortalUrl && result.authToken) {
					this.authService.setIsExternal();
					const externalUrl = `${result.destinationPortalUrl}/login?auth=${encodeURIComponent(result.authToken)}&redirect=${encodeURIComponent((this.redirectParam || ""))}`;
					this.store.dispatch(new ExternalRedirect(externalUrl));
				} else {
					this.handleSuccess(result);
				}
			}, err => {
				this.displayMessage(err.statusText, true);
			});
		}
	}

	newUserPasswordIsValid() {
		if (this.model.loginWithNewPasswordToken) {
			const isNewUserPasswordValid = !(this.model.password.search(this.authService.passwordRegex) < 0);
			this.error = isNewUserPasswordValid ? null : PasswordErrors.InvalidPassword;
		}
	}

	loginWithNewPassword(newLogin: ILogin) {
		this.model.username = newLogin.username;
		this.model.password = newLogin.password;
		this.login();
	}

	loginWithToken(authToken: string) {
		this.loggingIn = true;
		this.model.authToken = authToken;
		this.displayMessage("Please wait while you are signed in...");
		this.authService.loginWithToken(this.model).subscribe(result => {
			if (result.authError) {
				this.displayMessage(result.authError, true);
			} else {
				this.handleSuccess(result);
			}
		}, err => {
			this.displayMessage(err.statusText, true);
		});
	}

	handleSuccess(result: IAuthResponse) {
		this.model.username = "";
		this.model.password = "";
		this.authService.saveActiveJwt(result.jwt);
		this.authService.saveCurrentJwt(result.jwt);

		if (!result.user.loginName.match(this.usernameRegex) && result.enforceUsernames) {
			this.loggingIn = false;
			this.usernameChangeRequired = true;

			return;
		}

		this.store.dispatch(new LoginSuccess(result));
		this.store.dispatch(new Redirect({ url: this.route.snapshot.queryParamMap.get("redirect") || "" }));
	}

	displayMessage(msg: string, isError: boolean = false) {
		if (isError) {
			this.error = msg;
			this.infoMessage = null;
		} else {
			this.infoMessage = msg;
			this.error = null;
		}
		this.model.password = "";
		this.loggingIn = false;
	}

	closePasswordReset() {
		this.showReset = false;
		this.loggingIn = false;
		this.error = null;
	}

	changeUsername() {
		this.loggingIn = true;
		this.userService.changeUsername(this.newUsername).subscribe(result => {
			if (result.success) {
				this.displayMessage(`Your username has been updated to ${this.newUsername}. Please log in with your new username.`);
				this.usernameChangeRequired = false;
			} else {
				this.loggingIn = false;
				this.store.dispatch(new ErrorToast({ message: result.warningMessage || "There was a problem updating your username." }));
			}
		});
	}
}