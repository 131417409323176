import { Component, OnDestroy, OnInit } from "@angular/core";
import { IAppState } from "@app/store";
import { MsrpImpersonateAttempt, selectIsMsrp, UnimpersonateAttempt } from "@app/store/auth";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: "c4-msrp-impersonation",
	templateUrl: "./msrp-impersonation.component.html",
	styleUrls: ["./msrp-impersonation.component.scss"]
})

export class MsrpImpersonationComponent implements OnInit, OnDestroy {
	isMsrp: boolean;

	private destroyed$ = new Subject<{}>();

	constructor(private store: Store<IAppState>) { }

	ngOnInit() {
		this.store.pipe(
			select(selectIsMsrp),
			takeUntil(this.destroyed$)
		).subscribe(msrp => this.isMsrp = msrp);
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}

	selectMsrpUser() {
		this.store.dispatch(this.isMsrp ? new UnimpersonateAttempt() : new MsrpImpersonateAttempt());
	}
}