import { Pipe, PipeTransform } from "@angular/core";
import { ICertificationTraineeUserModel } from "@app/training/training.models";
import { addDays, differenceInCalendarDays, isAfter } from "date-fns";

@Pipe({ name: "rebateDays" })
export class RebateDaysPipe implements PipeTransform {
	transform(trainee: ICertificationTraineeUserModel): number {
		if (!trainee || !trainee.rebateStartDate) { return -1; }
		const registrationExpiry = addDays(trainee.rebateStartDate, 60); // If you change this, you must update the UserService.RegisterPcnaTrainees
		return isAfter(registrationExpiry, new Date()) ? differenceInCalendarDays(registrationExpiry, new Date()) : -1;
	}
}
