import { Component, OnInit } from "@angular/core";
import { FeatureFlags } from "@app/account/account.models";
import { IAppState } from "@app/store";
import { isFeatureFlagEnabled } from "@app/store/account";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";

@Component({
	selector: "c4-internal-error",
	templateUrl: "./internal-error.component.html",
	styleUrls: ["./internal-error.component.scss"],
})
export class InternalErrorComponent implements OnInit {

	useWebhelpEmail$: Observable<boolean>;

	constructor(readonly store: Store<IAppState>) { }

	ngOnInit() {
		this.useWebhelpEmail$ = this.store.pipe(select(isFeatureFlagEnabled(FeatureFlags.UseWebhelpEmail)));
	}

}
