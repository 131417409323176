import { AccountRole, AccountSecurityRole, FeatureFlags, SapInstanceType } from "@app/account/account.models";
import { checkDisplayToggle, equalsIgnoreCase, isDisplayToggleInternal } from "@app/shared/helpers";
import { accountAdapter } from "@app/store/account/account.adapter";
import { AccountState } from "@app/store/account/account.reducer";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { getSiteFeatureFlag } from "../app-info/app-info.selectors";

export const getAccountState = createFeatureSelector<AccountState>("accounts");

export const {
	selectIds: accountsSelectIds,
	selectEntities: accountsSelectEntities,
	selectAll: accountsSelectAll,
	selectTotal: accountsSelectTotal,
} = accountAdapter.getSelectors(getAccountState);

export const getActiveAccountId = createSelector(
	getAccountState, state => state.active,
);

export const getActiveUserAccount = createSelector(
	accountsSelectEntities,
	getActiveAccountId,
	(entities, id) => entities[id],
);

export const getImpersonatedAccountId = createSelector(
	getAccountState, state => state.impersonated,
);

export const getImpersonatedAccount = createSelector(
	accountsSelectEntities,
	getImpersonatedAccountId,
	(entities, id) => id && entities[id],
);

export const getCurrentAccountId = createSelector(
	getAccountState, state => state.current,
);

export const getCurrentAccount = createSelector(
	accountsSelectEntities,
	getCurrentAccountId,
	(entities, id) => entities[id],
);

export const getCurrentPremierStatus = createSelector(
	getCurrentAccount, account => {
		if (account) {
			const status = (account.premierStatus || "authorized").toLowerCase();

			return status === "none" ? "authorized" : status;
		}

		return undefined;
	},
);

export const getIsAuthorizedDealer = createSelector(
	getCurrentPremierStatus, status => status === "authorized",
);

export const getIsCurrentUserPremier = createSelector(
	getCurrentPremierStatus, status => {
		if (!status) {
			return false;
		}
		switch (status.toLowerCase()) {
			case "gold":
			case "diamond":
			case "platinum":
			case "pinnacle":
			case "pegold":
			case "pesilver":
			case "peplatinum:":
			case "triad gold":
			case "triad silver":
			case "triad platinum":
			case "triad level 1":
			case "triad level 2":
			case "triad level 3":
			case "triad elite":
				return true;
			default:
				return false;
		}
	},
);

export const getCurrentCurrencyInfo = createSelector(
	getCurrentAccount, account => account ? account.currency : undefined,
);

export const getCurrentCurrencySymbol = createSelector(
	getCurrentCurrencyInfo, currency => currency ? currency.symbol : undefined,
);

export const getCurrentAltCurrencySymbol = createSelector(
	getCurrentCurrencyInfo, currency => currency ? currency.altSymbol : undefined,
);

export const getCurrentCurrencyCode = createSelector(
	getCurrentCurrencyInfo, currency => currency ? currency.code : undefined,
);

export const getCurrentBuyingGroup = createSelector(
	getCurrentAccount, account => account ? account.buyingGroup : undefined,
);

export const getCurrentIsEmea = createSelector(
	getCurrentAccount, account => account && account.sapInstance === SapInstanceType.EMEA,
);

export const getCurrentIsAustralia = createSelector(
	getCurrentAccount, account => account && account.sapInstance === SapInstanceType.Australia,
);

export const getCurrentIsUS = createSelector(
	getCurrentAccount, account => account && account.sapInstance === SapInstanceType.US,
);

export const getIsNonEmeaInternational = createSelector(
	getCurrentAccount,
	getCurrentIsEmea,
	(account, emea) => account && account.isInternational && !emea,
);

export const getAccountLocaleInfo = createSelector(
	getCurrentIsEmea,
	getCurrentIsAustralia,
	getCurrentCurrencyCode,
	getCurrentCurrencySymbol,
	(isEmea, isAustralia, currencyCode, currencySymbol) => ({ isEmea, isAustralia, currencyCode, currencySymbol }),
);

export const getCurrentRebateGoalFilter = createSelector(
	getCurrentCurrencyCode,
	getCurrentBuyingGroup,
	(currencyCode, buyingGroup) => ({ currencyCode, buyingGroup }),
);

export const getCurrentSubDealers = createSelector(
	getCurrentAccount, account => account && account.isDistributor ? account.subDealers : undefined,
);

export const getCurrentSubDealersMap = createSelector(
	getCurrentSubDealers, subdealers => {
		if (subdealers) {
			const dict = new Map<string, string>();
			subdealers.forEach(subDealer => dict.set(subDealer, subDealer));

			return dict;
		}

		return undefined;
	},
);

export const getInternalAccountIds = createSelector(
	getAccountState, state => state.internalAccountIds,
);

export const isAccountInternal = (accountId: string) => createSelector(
	() => (accountId || "").toUpperCase(),
	accountsSelectEntities,
	(id, entities) => id && entities[id] && (entities[id].accountRoles & AccountRole.Internal) > 0,
);

export const isActiveAccountInternal = createSelector(
	getActiveUserAccount, active => active && (active.accountRoles & AccountRole.Internal) > 0,
);

export const isCurrentAccountBuilder = createSelector(
	getCurrentAccount, active => active && (active.accountRoles & AccountRole.Builder) > 0,
);

export const isCurrentAccountInternal = createSelector(
	getCurrentAccount, current => current && (current.accountRoles & AccountRole.Internal) > 0,
);

export const showCurrentAccountPropaganda = createSelector(
	getCurrentAccount, current => current && (current.accountSecurityRoles & AccountSecurityRole.Advertising) > 0,
);

export const getCurrentSnapRewardsTier = createSelector(
	getCurrentAccount, account => account && account.snapRewardsTier && !equalsIgnoreCase(account.snapRewardsTier, "none") ? account.snapRewardsTier : undefined,
);

export const isFeatureFlagEnabled = (toggle: string, checkInternal = true) => createSelector(
	isActiveAccountInternal,
	getSiteFeatureFlag(toggle),
	(isInternal, flag) => checkDisplayToggle(flag, checkInternal && isInternal),
);

export const isFeatureFlagToggleVisible = (toggle: string) => createSelector(
	isActiveAccountInternal,
	getSiteFeatureFlag(toggle),
	(internal, flag) => internal && isDisplayToggleInternal(flag),
);

export const areInvoicesPayable = createSelector(
	getCurrentIsEmea,
	getCurrentIsAustralia,
	isActiveAccountInternal,
	getSiteFeatureFlag("emeaInvoices"),
	getSiteFeatureFlag("ausInvoices"),
	(isEmea, isAustralia, isInternal, emeaInvoiceFlag, ausInvoiceFlag) => (!isEmea || checkDisplayToggle(emeaInvoiceFlag, isInternal)) && (!isAustralia || checkDisplayToggle(ausInvoiceFlag, isInternal)),
);

export const isRebateVisible = createSelector(
	isFeatureFlagEnabled(FeatureFlags.TriadRebate),
	isFeatureFlagEnabled(FeatureFlags.DealerRebate),
	(triad, dealer) => triad || dealer,
);

export const getTriadLoyaltyFaqLink = createSelector(
	getCurrentCurrencySymbol, symbol => {
		switch (symbol) {
			case "$":
				return "https://www.control4.com/docs/product/triad-loyalty-program/all-dealer-triad-faq/english/revision/E";
			case "AUD $":
				return "https://www.control4.com/docs/product/triad-loyalty-program/all-dealer-triad-faq/english/revision/A";
			case "CAD $":
				return "https://www.control4.com/docs/product/triad-loyalty-program/all-dealer-triad-faq/english/revision/D";
			case "EUR":
				return "https://www.control4.com/docs/product/triad-loyalty-program/all-dealer-triad-faq/english/revision/B";
			case "GBP":
				return "https://www.control4.com/docs/product/triad-loyalty-program/all-dealer-triad-faq/english/revision/C";
		}
	},
);