import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Portals } from "@app/shared/shared.models";
import { IContentItem, IContentItemComment, IContentItemRelatedContent, IKbArticleSearchModel, IPhoneSupportWaitTimes, ISubscriptionSummary } from "@app/support/support.models";

@Injectable({
	providedIn: "root",
})
export class KbService {

	constructor(private http: HttpClient) { }

	searchKbArticles(query: string, limit: number) {
		return this.http.get<IKbArticleSearchModel[]>("api/Knowledgebase/SearchContentItem", { params: { query, limit: limit.toString(), v: new Date().toISOString() } });
	}

	getPhoneSupportWaitTimes() {
		return this.http.get<IPhoneSupportWaitTimes>("api/account/GetPhoneSupportWaitTimes");
	}

	getFavoriteContentItems(lowestLimit: number, upperLimit: number, forceRefresh = false) {
		let params = new HttpParams().append("lowestLimit", lowestLimit.toString());
		params = params.append("upperLimit", upperLimit.toString());
		if (forceRefresh) {
			params = params.append("date", new Date().toISOString());
		}

		return this.http.get<IContentItem[]>("api/Knowledgebase/GetFavoriteContentItems", { params });
	}

	getAllFavoriteContentItems(forceRefresh = false) {
		let params = new HttpParams();
		if (forceRefresh) {
			params = params.append("date", new Date().toISOString());
		}

		return this.http.get<IContentItem[]>("api/Knowledgebase/GetAllFavoriteContentItems", { params });
	}

	getFavoriteContentItemCount(forceRefresh?: boolean) {
		let params = new HttpParams();
		if (forceRefresh) {
			params = params.append("v", new Date().toISOString());
		}

		return this.http.get<number>("api/Knowledgebase/GetFavoriteContentItemCount", { params });
	}

	getRecentContentItems(upperLimit: number, forceRefresh?: boolean) {
		let params = new HttpParams().append("upperLimit", upperLimit.toString());
		if (forceRefresh) {
			params = params.append("v", new Date().toISOString());
		}

		return this.http.get<IContentItem[]>("api/Knowledgebase/GetRecentContentItems", { params });
	}

	getPopularContentItems(upperLimit: number, portal?: Portals) {
		let params = new HttpParams().append("upperLimit", upperLimit.toString());
		if (portal) {
			params = params.append("portal", portal.toString());
		}

		return this.http.get<IContentItem[]>("api/Knowledgebase/GetPopularContentItems", { params });
	}

	bookmarkContentItem(contentItemId: number) {
		return this.http.put<boolean>("api/Knowledgebase/BookmarkContentItem", contentItemId);
	}

	unBookmarkContentItem(contentItemId: number) {
		return this.http.post<boolean>("api/Knowledgebase/UnbookmarkContentItem", contentItemId);
	}

	getSubscriptionsCount() {
		return this.http.get<number>("api/Knowledgebase/GetContentItemSubscriptionCount");
	}

	getSubscriptions(forceRefresh = false) {
		let params = new HttpParams();
		if (forceRefresh) {
			params = params.append("date", new Date().toISOString());
		}

		return this.http.get<ISubscriptionSummary[]>("api/Knowledgebase/GetAllContentItemSubscriptions", { params });
	}

	unscubribeFromArticle(contentItemId: number) {
		return this.http.post<boolean>("api/Knowledgebase/UnsubscribeFromContentItem", contentItemId);
	}

	subscribeToAllArticles() {
		return this.http.put("api/Knowledgebase/SubscribeToAllContentItems", null);
	}

	unSubscribeToAllArticles() {
		return this.http.post("api/Knowledgebase/UnsubscribeFromAllContentItems", null);
	}

	getContentItemView(contentItemId: number, forceRefresh?: boolean) {
		return this.http.get<IContentItem>("api/Knowledgebase/GetContentItemView", { params: { contentItemId: contentItemId.toString(), v: forceRefresh ? new Date().getTime().toString() : "" } });
	}

	commentOnArticle(articleId: number, commentText: string) {
		return this.http.put<IContentItemComment>("api/Knowledgebase/CommentOnContentItem", { contentItemId: articleId, content: commentText });
	}

	subscribeToArticle(contentItemId: number) {
		return this.http.put<boolean>("api/Knowledgebase/SubscribeToContentItem", contentItemId);
	}

	unsubscribeFromArticle(contentItemId: number) {
		return this.http.post<boolean>("api/Knowledgebase/UnsubscribeFromContentItem", contentItemId);
	}

	getRelatedContent(contentItemId: number, forceRefresh?: boolean) {
		let params = new HttpParams().append("contentItemId", contentItemId.toString());
		if (forceRefresh) {
			params = params.append("v", new Date().toISOString());
		}

		return this.http.get<IContentItemRelatedContent[]>("api/Knowledgebase/GetContentItemRelatedContent", { params });
	}

	hideComment(commentId: number) {
		return this.http.post<boolean>("api/Knowledgebase/HideComment", commentId);
	}

	replyToComment(commentId: number, articleId: number, commextText: string) {
		return this.http.put<IContentItemComment>("api/Knowledgebase/CommentOnContentItem", { replyToContentItemCommentId: commentId, contentItemId: articleId, content: commextText });
	}

	upVoteComment(commentId: number) {
		return this.http.post<boolean>("api/Knowledgebase/UpDownVoteComment", { contentItemCommentId: commentId, isUpVote: true });
	}

	downVoteComment(commentId: number) {
		return this.http.post<boolean>("api/Knowledgebase/UpDownVoteComment", { contentItemCommentId: commentId, isUpVote: false });
	}

	getContentItemComments(contentItemId: number, forceRefresh?: boolean) {
		return this.http.get<IContentItemComment[]>("api/Knowledgebase/GetContentItemComments", { params: { contentItemId: contentItemId.toString(), v: forceRefresh ? new Date().getTime().toString() : "" } });
	}

}
