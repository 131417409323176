import { Injectable } from "@angular/core";
import { IUser, SecurityRoles } from "@app/account/account.models";
import { ICartModel } from "@app/checkout/checkout.models";
import { IProductLightModel, IProductView } from "@app/products/products.models";
import { environment } from "@env/environment";
import { Angulartics2 } from "angulartics2";
import { Angulartics2GoogleAnalytics } from "angulartics2/ga";
import { Angulartics2GoogleAnalyticsEnhancedEcommerce } from "angulartics2/ga-enhanced-ecom";

@Injectable({
	providedIn: "root",
})
export class TrackService {

	constructor(
		private enhancedEcom: Angulartics2GoogleAnalyticsEnhancedEcommerce,
		private tracking: Angulartics2,
		private ga: Angulartics2GoogleAnalytics,
	) { }

	initialize() {
		this.ga.startTracking();
	}

	updateUserTracking(actUser: IUser) {
		this.ga.setUsername(actUser ? actUser.technicianId : undefined);
		window.ga("set", "dimension6", actUser && actUser.accountId);
		window.ga("set", "dimension9", actUser && actUser.technicianId);
		const canPurchase = actUser && (actUser.securityRoles & (SecurityRoles.PurchasingUser | SecurityRoles.InternalSuperUser)) > 0;
		window.ga("set", "dimension11", canPurchase ? "true" : "false");
		window.ga("send", "pageview");
	}

	updateImpersonatedUserTracking(curUser: IUser) {
		window.ga("set", "dimension7", curUser && curUser.accountId);
		window.ga("set", "dimension10", curUser && curUser.technicianId);
		window.ga("send", "pageview");
	}

	trackImpression(product: IProductView | IProductLightModel, list: string, category?: number, index?: number) {
		if (!product) { return; }
		if (environment.consoleLogAnalytics) {
			console.log("[Track Impression] SKU:", product.sku, ", PRICE: ", product.dealerPrice, ", LIST:", list, ", CATEGORY:", category, ", POSITION:", index);
		}
		this.enhancedEcom.ecAddImpression({
			id: product.sku,
			price: product.dealerPrice,
			list,
			position: index,
			category: category ? category.toString() : undefined,
		});
	}

	trackAddProduct(product: IProductView | IProductLightModel, quantity: number) {
		if (!product) { return; }
		if (environment.consoleLogAnalytics) {
			console.log("[Track Add]", "SKU:", product.sku, "REMOVE QUANTITY", quantity);
		}
		this.trackProduct(product, undefined, quantity);
		this.enhancedEcom.ecSetAction("add", {});
	}

	trackRemoveProduct(product: IProductView | IProductLightModel, quantity: number) {
		if (!product) { return; }
		if (environment.consoleLogAnalytics) {
			console.log("[Track Remove]", "SKU:", product.sku, "REMOVE QUANTITY", quantity);
		}
		this.trackProduct(product, undefined, quantity);
		this.enhancedEcom.ecSetAction("remove", {});
	}

	private trackProduct(product: IProductView | IProductLightModel, category?: number, quantity?: number) {
		if (!product) { return; }
		if (environment.consoleLogAnalytics) {
			console.log("[Track Product] SKU:", product.sku, ", PRICE:", product.dealerPrice, ", CATEGORY: ", category);
		}
		this.enhancedEcom.ecAddProduct({
			id: product.sku,
			category: category ? category.toString() : undefined,
			price: product.dealerPrice,
			quantity,
		});
	}

	trackEvent(action: string, category: string, label: string) {
		if (environment.consoleLogAnalytics) {
			console.log("[Track Event] ACTION:", action, ", CATEGORY: ", category, ", LABEL:", label);
		}
		this.tracking.eventTrack.next({ action, properties: { label, category } });
	}

	trackOrder(order: ICartModel, benefits: string[]) {
		if (!order) { return; }
		const benefitList = benefits ? benefits.join(",") : undefined;
		if (environment.consoleLogAnalytics) {
			console.log("[Track Order] ID:", order.id, ", TOTAL: ", order.grandTotal, ", TAX:", order.taxTotal, ", SHIP:", order.shippingTotal, ", COUPON:", benefitList);
		}
		this.enhancedEcom.ecSetAction("purchase", {
			id: order.id.toString(),
			affiliation: "Dealer Portal",
			revenue: order.grandTotal,
			tax: order.taxTotal,
			coupon: benefitList,
			shipping: order.shippingTotal,
		});
	}
}
