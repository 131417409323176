import { Component, ComponentRef, Input, OnInit } from "@angular/core";
import { accountHasAccountRoleFlag, AccountSecurityRole } from "@app/account/account.models";
import { QuickViewPopupComponent } from "@app/popups/quick-view-popup/quick-view-popup.component";
import { SuggestedProductsPopupComponent } from "@app/popups/suggested-products-popup/suggested-products-popup.component";
import { IProductLightModel } from "@app/products/products.models";
import { PopupService } from "@app/services/popup.service";
import { IAppState } from "@app/store";
import { getCurrentAccount, getCurrentCurrencySymbol } from "@app/store/account";
import { selectIsMsrp } from "@app/store/auth";
import { getCurrentCartId } from "@app/store/cart";
import { currentCanPurchase, currentCanViewPricing } from "@app/store/user";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { SearchResultItem } from "../search.models";

@Component({
	selector: "c4-search-product-item",
	templateUrl: "./search-product-item.component.html",
	styleUrls: ["./search-product-item.component.scss"],
})
export class SearchProductItemComponent implements OnInit {
	@Input() item: SearchResultItem<IProductLightModel>;

	product: IProductLightModel;

	canPurchase$: Observable<boolean>;
	canViewPricing$: Observable<boolean>;
	canViewMsrpPricing = false;
	currency$: Observable<string>;
	isMsrp$: Observable<boolean>;
	orderId$: Observable<number>;

	displayOnlyMessage: string;

	@Input() popupRef?: ComponentRef<SuggestedProductsPopupComponent>;

	constructor(private store: Store<IAppState>, private popupService: PopupService) { }

	ngOnInit() {
		this.product = this.item.data;
		this.product.quantity = 1;

		this.canPurchase$ = this.store.pipe(select(currentCanPurchase));
		this.canViewPricing$ = this.store.pipe(select(currentCanViewPricing));
		this.currency$ = this.store.pipe(select(getCurrentCurrencySymbol));
		this.isMsrp$ = this.store.pipe(select(selectIsMsrp));
		this.orderId$ = this.store.pipe(select(getCurrentCartId));

		this.store.pipe(
			select(getCurrentAccount),
		).subscribe(account => {
			this.canViewMsrpPricing = accountHasAccountRoleFlag(account, AccountSecurityRole.MsrpViewing);
		});
	}

	closePopup() {
		if (this.popupRef) {
			this.popupRef.destroy();
		}
	}

	openQuickView() {
		this.popupService.create(QuickViewPopupComponent, { sku: this.product.sku });
	}
}
