import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "c4-card",
	templateUrl: "./card.component.html",
	styleUrls: ["./card.component.scss"]
})
export class CardComponent implements OnInit {

	@Input() overlay = false;

	constructor() { }

	ngOnInit() {
	}

}
