import { Action } from "@ngrx/store";

export class LiveChatToggle implements Action {
	readonly type = SupportActionType.LiveChatToggle;
}

export type SupportActions = LiveChatToggle;
export enum SupportActionType {
	LiveChatToggle = "Toggle Live Chat",
}
