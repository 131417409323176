import { SecurityRoles } from "@app/account/account.models";
import { getNgStoreCacheItem } from "@app/shared/helpers";
import { salesforceContactAdapter } from "@app/store/sf-contact/sf-contact.adapter";
import { ISalesforceContactState } from "@app/store/sf-contact/sf-contact.reducer";
import { currentHasRole, selectCurrentUser } from "@app/store/user";
import { createFeatureSelector, createSelector } from "@ngrx/store";

export const getSalesforceContactState = createFeatureSelector<ISalesforceContactState>("sfContacts");

export const {
	selectIds: salesforceContactSelectIds,
	selectEntities: salesforceContactSelectEntities,
	selectAll: salesforceContactSelectAll,
	selectTotal: salesforceContactSelectTotal
} = salesforceContactAdapter.getSelectors(getSalesforceContactState);

export const getSalesforceInfo = createSelector(
	salesforceContactSelectEntities,
	selectCurrentUser,
	(entities, user) => entities && user ? getNgStoreCacheItem(entities[user.userId]) : undefined
);

export const getSalesforceContactId = createSelector(
	getSalesforceInfo,
	(info) => info && info.contact ? info.contact.id : undefined
);

export const getCurrentAccountCanSubmitRma = createSelector(
	getSalesforceInfo,
	(info) => info && info.account ? info.account.allowOnlineRMA : undefined
);

export const getCurrentUserCanSubmitRma = createSelector(
	getCurrentAccountCanSubmitRma,
	currentHasRole(SecurityRoles.OnlineRma),
	(checkAccount, checkUser) => checkAccount && checkUser
);