import { Component, ComponentRef, HostListener, OnInit } from "@angular/core";
import { Filter, FilterGroup } from "@app/cms/cms.models";
import { CmsProductService } from "@app/services/cms-product.service";
import { KeyboardService } from "@app/services/keyboard.service";
import { IAppState } from "@app/store";
import { SaveProductFilterGroupSuccess } from "@app/store/product-filters/product-filters.actions";
import { ErrorToast } from "@app/store/toast/toast.actions";
import { Store } from "@ngrx/store";
import { IPopupComponent } from "../popups.models";

@Component({
	selector: "c4-add-filter-group-popup",
	templateUrl: "./add-filter-group-popup.component.html",
	styleUrls: ["./add-filter-group-popup.component.scss"],
})
export class AddFilterGroupPopupComponent implements OnInit, IPopupComponent {
	close: () => void;
	self: ComponentRef<AddFilterGroupPopupComponent>;

	group = { filters: [] } as FilterGroup;

	constructor(private store: Store<IAppState>, readonly cmsProductService: CmsProductService, readonly keyboardService: KeyboardService) { }

	ngOnInit() {
	}

	@HostListener("keydown", ["$event"])
	onKeyDownHandler(event: KeyboardEvent) {
		if (this.keyboardService.isSaveKeybind(event)) {
			this.save();
		}
	}

	save() {
		if (this.validate()) {
			this.cmsProductService.saveFilterGroup(this.group).subscribe(result => {
				if (result.success) {
					this.store.dispatch(new SaveProductFilterGroupSuccess(result.result));
					this.close();
				} else {
					this.store.dispatch(new ErrorToast({ message: `Failed to save filter group. ${result.errorMessage}` }));
				}
			});
		}
	}

	private validate() {
		if (!this.group.description) {
			this.store.dispatch(new ErrorToast({ message: "A filter group name must be provided" }));
			return false;
		} else if (this.group.filters.length > this.cmsProductService.MaxFilterFlags) {
			this.store.dispatch(new ErrorToast({ message: `No more than ${this.cmsProductService.MaxFilterFlags} flags per filter group` }));
			return false;
		}
		return true;
	}

	addFilter() {
		this.group.filters.push({} as Filter);
	}

	removeFilter(filter: Filter) {
		this.group.filters = this.group.filters.filter(x => x !== filter);
	}
}
