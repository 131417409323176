import { Component, OnDestroy, OnInit } from "@angular/core";
import { RewardsService } from "@app/services/rewards.service";
import { IAppState } from "@app/store";
import { getCurrentCurrencySymbol } from "@app/store/account";
import { LoadRebateProgressAttempt } from "@app/store/rebate-progress/rebate-progress.actions";
import { getCurrentRebateTotal } from "@app/store/rebate-progress/rebate-progress.selectors";
import { select, Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: "c4-kpi-widget-rebate",
	templateUrl: "./kpi-widget-rebate.component.html",
	styleUrls: ["./kpi-widget-rebate.component.scss"]
})
export class KpiWidgetRebateComponent implements OnInit, OnDestroy {
	loading = true;
	error: boolean;

	currency$: Observable<string>;
	earned: number;

	private destroyed$ = new Subject<{}>();

	constructor(readonly store: Store<IAppState>, readonly rewardsService: RewardsService) { }

	ngOnInit() {
		this.currency$ = this.store.pipe(select(getCurrentCurrencySymbol));
		this.store.pipe(select(getCurrentRebateTotal), takeUntil(this.destroyed$)).subscribe((earned) => {
			this.earned = earned;
			this.loading = earned == null;
			this.error = isNaN(earned);
			if (this.loading) {
				this.store.dispatch(new LoadRebateProgressAttempt());
			}
		});
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}
}
