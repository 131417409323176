import { IPresetConsumerFilter } from "@app/dashboard/dashboard.models";
import { Portals, Schedule, ScheduleType } from "@app/shared/shared.models";

export enum StaticPageType {
	Promotions = 3,
	Miscellaneous = 4,
	Training = 5,
	Support = 6,
	Marketing = 7,
	Products = 11,
	New = 12,
	Home = 13,
	DesignServices = 14,
	Account = 15,
	Programs = 16,
	SmartHomeOs = 17,
	OvrC = 18,
}

export const StaticPageTypeMap = new Map(
	[
		[StaticPageType.Account, "Account"],
		[StaticPageType.DesignServices, "Design Services"],
		[StaticPageType.Home, "Home"],
		[StaticPageType.Training, "Training"],
		[StaticPageType.Marketing, "Marketing"],
		[StaticPageType.New, "New"],
		[StaticPageType.Miscellaneous, "Partial Content"],
		[StaticPageType.Products, "Products"],
		[StaticPageType.Programs, "Programs"],
		[StaticPageType.Promotions, "Promotions"],
		[StaticPageType.Support, "Support"],
		[StaticPageType.SmartHomeOs, "Smart Home OS"],
		[StaticPageType.OvrC, "OvrC"],
	],
);

export interface IStaticContent {
	id: number;
	userId: string;
	pageCode: string;
	title: string;
	pageType: StaticPageType;
	pageSourceCode: string;
	releaseDate: string | Date;
	expireDate: string | Date;
	dateModified: string;
	communityVisible: boolean;
	approved: boolean;
	views: number;
	friendlyUrl: string;
	visibleInPortals: Portals;
	portalDisplay: string;
	scheduleType: ScheduleType;
	sortOrder: number;

	// Front-end only
	isStaticPage: boolean;
}

export interface IDiscountProgram {
	id: number;
	name: string;
	restrictionType: string;
	startDate: string | Date;
	endDate: string | Date;
	visibleInPortals: Portals;
	scheduleType: ScheduleType;
	sapDiscountProgramCode: string;
	cartType: string;

	benefits: IDiscountProgramBenefit[];
	categories: IDiscountProgramCategory[];
	dealers: IDiscountProgramDealer[];
	parts: IDiscountProgramPart[];
}

export interface IDiscountProgramBenefit {
	id: number;
	discountProgramId: number;
	discountType: string;
	itemQuantity: number;
	currencyCode: string;
	discountAmount: number;
}

export interface IDiscountProgramCategory {
	id: number;
	discountProgramId: number;
	categoryId: number;
}

export interface IDiscountProgramDealer {
	id: number;
	discountProgramId: number;
	accountId: string;
}

export interface IDiscountProgramPart {
	id: number;
	discountProgramId: number;
	partNo: string;
}

export enum SelectType {
	multi = 0,
	single = 1,
}

export interface CategoryProduct {
	sku: string;
	name: string;
	isDisplayOnly: boolean;
	isPrimary: boolean;
	categoryId: number;
	available: boolean;
}

export interface ILightCmsCategoryModel {
	id: number;
	parentId: number;
	name: string;
	activeSkuCount: number;
	inactiveSkuCount: number;
	isVisible: boolean;
	sortCode: number;

	// Front-end only
	parent: ILightCmsCategoryModel;
	subcategories: ILightCmsCategoryModel[];
	products: CategoryProduct[];
	url: string;
	open: boolean;
}

export enum SortDirection {
	Up,
	Down,
	UpAll,
	DownAll,
}

export interface ISortData {
	categoryId: number;
	sortCode: number;
}

export interface CategorySortEvent {
	category: ILightCmsCategoryModel;
	direction: SortDirection;
}

export interface SchedulerInfo extends Schedule {
	valid: boolean;
	error?: string;
}

export interface ICmsPresetConsumerFilter extends IPresetConsumerFilter {
	id: number;
	schedule: Schedule;
	scheduleId: number;
	sortCode: number;
	visible: boolean;
}

export interface ISortProduct {
	sku: string;
	position: number;
}

export interface FilterGroup {
	id: number;
	description: string;
	displayName: string;
	filters: Filter[];
}

export interface Filter {
	id: number;
	filterGroupId: number;
	filterFlag: number;
	filterDisplay: string;
	sortOrder: number;
	partFilters: PartFilter[];
}

export interface SelectFilter {
	id: number;
	display: string;
}

export interface PartFilter {
	id: number;
	sku: string;
	filterId: number;
}

export enum KeyEventId {
	product = "product",
	productDescription = "product-description",
	productFeatures = "product-features",
	productSupport = "product-support",
	productFirmware = "product-firmware",
	bulkProductDescription = "bulk-product-description",
	bulkProductSupport = "bulk-product-support",
	bulkProductFeatures = "bulk-product-features",
}