import { DealerLeadIds, Lead } from "@app/dashboard/dashboard.models";
import { deepcopy, ngStoreCacheItem, objectUpdate } from "@app/shared/helpers";
import { LeadsActions, LeadsActionTypes } from "@app/store/leads";
import { leadsAdapter } from "@app/store/leads/leads.adapter";
import { Dictionary, EntityState } from "@ngrx/entity";

export interface LeadsState extends EntityState<Lead> {
	idsByDealer: Dictionary<DealerLeadIds>;
}

export const initialState: LeadsState = leadsAdapter.getInitialState({
	idsByDealer: {},
});

export function leadsReducer(
	state = initialState,
	action: LeadsActions,
): LeadsState {
	switch (action.type) {
		case LeadsActionTypes.LoadLeadsSuccess:
			const leadIds = action.payload.leads.map(x => x.opportunityId);
			const idsByDealer = deepcopy(state.idsByDealer);
			idsByDealer[action.payload.accountId.toLowerCase()] = ngStoreCacheItem(<DealerLeadIds>{ ids: leadIds }, 6, "hours");
			const updatedState = objectUpdate(state, { idsByDealer });
			return leadsAdapter.upsertMany(action.payload.leads, updatedState);
		case LeadsActionTypes.CloseLeadSuccess:
			return leadsAdapter.removeOne(action.payload.opportunityId, state);
		case LeadsActionTypes.AcceptLeadSuccess:
		case LeadsActionTypes.UpdateLeadStatusSuccess:
			return leadsAdapter.updateOne({ id: action.payload.opportunityId, changes: action.payload }, state);
		default: {
			return state;
		}
	}
}