import { AfterViewInit, Component, ContentChildren, ElementRef, Input, OnDestroy, QueryList } from "@angular/core";

@Component({
	selector: "c4-carousel",
	templateUrl: "./carousel.component.html",
	styleUrls: ["./carousel.component.scss"]
})
export class CarouselComponent implements OnDestroy, AfterViewInit {
	@Input() interval = 5000;
	@Input() height = 300;
	@Input() load = true;
	@Input() showDots = true;
	@Input() showArrows: boolean;

	selected: ElementRef<any>;
	hover = false;
	private index: number;
	private timer: any;

	@ContentChildren("c4Slide") slides: QueryList<ElementRef>;

	ngOnDestroy() {
		this.stopTimer();
	}

	ngAfterViewInit() {
		this.load = true;
		this.changePosition(0);
		this.slides.changes.subscribe(() => {
			this.changePosition(0);
		});
	}

	next() {
		const index = this.index < this.slides.length - 1 ? this.index + 1 : 0;
		this.changePosition(index);
	}

	previous() {
		const index = this.index > 0 ? this.index - 1 : this.slides.length - 1;
		this.changePosition(index);
	}

	changePosition(index: number, reset = false) {
		if (reset) {
			this.stopTimer();
		}
		if (this.selected) {
			this.selected.nativeElement.classList.remove("show");
		}
		setTimeout(() => {
			this.selected = this.slides.find((item, idx) => idx === index);
			if (this.selected) {
				this.selected.nativeElement.classList.add("show");
				this.index = index;
				if (!this.hover) {
					this.load = false;
					if (reset || !this.timer) {
						this.startTimer();
					}
				}
			}
		}, 0);
	}

	onHover() {
		this.hover = true;
		this.stopTimer();
	}

	leaveHover() {
		this.hover = false;
		this.startTimer();
	}

	private startTimer() {
		if (this.interval > 0 && !this.hover) {
			this.timer = setInterval(() => {
				this.next();
			}, this.interval);
		}
	}

	private stopTimer() {
		clearInterval(this.timer);
	}
}
