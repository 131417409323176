import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "linkTransform"
})
export class LinkTransformPipe implements PipeTransform {

	transform(url: string, ): any {
		if (url) {
			if (url.indexOf("http") !== 0 && url.indexOf("/") !== 0) {
				url = ("/" + url);
			}
			url = url.replace("/#transforms#", "");
		}
		return url;
	}
}
