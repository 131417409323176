import { Injectable } from "@angular/core";
import { OrderService } from "@app/services/order.service";
import { LoadAccountOrderHistoryError, LoadAccountOrderHistorySuccess, OrderHistoryActionTypes } from "@app/store/order-history/order-history.actions";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";

@Injectable()
export class OrderHistoryEffects {
	constructor(
		private actions$: Actions,
		private orderService: OrderService,
	) { }

	@Effect()
	loadOrderHistory$: Observable<Action> = this.actions$.pipe(
		ofType(OrderHistoryActionTypes.LoadAccountOrderHistoryAttempt),
		mergeMap(() => {
			return this.orderService
				.getAccountOrderHistory()
				.pipe(
					map(response => new LoadAccountOrderHistorySuccess(response)),
					catchError(err => of(new LoadAccountOrderHistoryError(err.error))),
				);
		}),
	);
}
