import { IDiscountProgram } from "@app/cms/cms.models";
import { ICustomerSummary } from "@app/dashboard/dashboard.models";
import { IPaymentInformation } from "@app/print/print.models";
import { IStockStatusItem } from "@app/products/products.models";
import { ICheckPaymentModel, ICreditCardType, ICreditPaymentModel } from "@app/shared/payment.models";
import { IAccountBalanceLineItem, ICacheable, IErrorInfo, IMedia } from "@app/shared/shared.models";

export interface ICartModel {
	id: number;
	name: string;
	cartType: string;
	subtotal: number;
	msrpSubtotal: number;
	grandTotal: number;
	shippingTotal: number;
	discountTotal: number;
	discountedSubtotal: number;
	taxTotal: number;
	quantityTotal: number;
	customerReference: string;
	poReference: string;
	community: string;
	project: string;
	currency: string;
	isBuilderOrder: boolean;
}

export interface IBuilderPromo {
	discountId: number;
	discountCode: string;
	description: string;
}

export interface IPartnerContract {
	id: number;
	manufacturerCode: number;
	manufacturerName: string;
	terms: string;
	accepted: boolean;
}

export interface ISavedCart {
	id: number;
	name: string;
	cartType: CartType;
	quantityTotal: number;
	subtotal: number;
	discountedSubtotal: number;
	createdDate: string;
	items: ICartItemModel[];
	showMore: boolean;
}

// #region Checkout
export interface ICheckoutModel {
	order: ICartModel;
	orderTotals: IOrderTotalsModel;
	items: ICartItemModel[];
	removedItems: ICartItemModel[];
	benefits: string[];
	discountPrograms: IDiscountProgram[];
	hasPartnerProducts: boolean;
	builderPromos: IBuilderPromo[];
	partnerContracts: IPartnerContract[];
	partnerShippingTotals: IPartnerShippingModel[];
	hasUpgradeController: boolean;
	customer: ICustomerSummary;
}

export class CheckoutShippingInfo {
	selectedShippingAddressId: number;
	selectedFinalDestinationId: number;
	selectedShippingMethod: IShippingMethod;
	selectedShippingCarrier: IAccountCarrier;
	shippingAccountRequired = false;
}

export interface CheckoutBillingInfo {
	selectedBillingAddressId: number;
	selectedPaymentMethod: string;
	billingOptions: IBillingOption;
	paymentErrors: IErrorInfo[];
	hasTerms: boolean;
}

export interface IPaymentModel {
	orderId: number;
	billingAddressId: number;
	paymentType: string;
	creditCardData: ICreditPaymentModel;
	eftData: ICheckPaymentModel;
	selectedInvoices?: IAccountBalanceLineItem[];
}

export interface IProcessOrderResult {
	orderId: number;
	newOrderId: number;
	success: boolean;
	goBackToCart: boolean;
	error: string;
}

export interface IOrderConfirmation {
	shippingMethod: string;
	shippingAddress: string;
	billingAddress: string;
	finalDestinationAddress: string;
	order: ICartModel;
	items: ICartItemModel[];
	benefits: string[];
	payment: IPaymentInformation;
	partnerShipping: IPartnerShippingModel[];
}
// #endregion

// #region Items
export interface ICartItemModel extends IStockStatusItem {
	orderId: number;
	orderDetailId: number;
	sku: string;
	parentSku: string;
	name: string;
	quantity: number;
	discountPer: number;
	dealerPrice: number;
	manufacturerCode: number;
	media: IMedia[];
	paintManufacturer: string;
	paintCode: string;
	veneerFinish: string;
	grillType: string;
	grillColor: string;
	grillPaintManufacturer: string;
	grillPaintCode: string;
	mountType: string;
	measurement: number;
	dimensionType: string;
	originalPartNumber: string;
	isVariant: boolean;
	url: string;
	extendedPrice: number;
	location: string;
	displayPrice: number;
	msrp: number;
	productDiscount: number;
	productDiscountPercent: number;
	productDiscountName: string;
	productDiscountId: number;
	productDiscountBenefitId: number;
	currencyHasDiscount: boolean;
	lineFreight: number;
	_origQty: number;
	canCustomize: boolean;
	hasCustomizations: boolean;
}

export interface IAddToCartModel {
	orderId?: number; // Will default to current cart id if not provided
	sku: string;
	quantity: number;
	name?: string;
	parentSku?: string;
	paintManufacturer?: string;
	paintCode?: string;
	veneerFinish?: string;
	grillType?: string;
	grillColor?: string;
	grillPaintManufacturer?: string;
	grillPaintCode?: string;
	mountType?: string;
	measurement?: number;
	dimensionType?: "Width" | "Height";
	originalPartNumber?: string;
	purchaseForUserId?: string;
	keycapDetails?: IKeycapDetails;
}

export interface IKeycapDetails {
	line1: string;
	line2: string;
	alignment: string;
	size: string;
	language: string;
	keypad: IKeypadModel;
	buttonType: string;
}

export interface IKeypadModel {
	id: number;
	keypadOrderId: number;
	location: string;
	name: string;
	color: string;
	keycaps: IKeycapDetails[];
}
// #endregion

// #region Shipping
export interface IPartnerShippingModel {
	distanceToGoal: number;
	flatShipping: number;
	freeShippingMinimum: number;
	partnerName: string;
}

export interface IShippingMethod extends ICacheable {
	id: number;
	name: string;
	qualifiesFree: boolean;
	displayValue: string;
}

export interface IAccountCarrier {
	usersAccountCarrierId: number;
	accountId: string;
	carrierName: string;
	carrierAccountNumber: string;
	active: boolean;
	displayValue: string;

	selectedCarrier: string;
}

export interface IShippingModel {
	selectedShippingAddressId: number;
	selectedShipMethod: number;
	shippingMethods: IShippingMethod[];
	localPickupAvailable: boolean;
	finalShippingAddressId: number;
	customShippingCarrier: IAccountCarrier;
	customShippingAccount: string;
	customCarriers: IAccountCarrier[];
	estimatedTotals: IOrderTotalsModel;
}
// #endregion

// #region Totals
export interface IOrderTotalsModel {
	orderId: number;
	cartName: string;
	cartType: CartType;
	subtotal: number;
	msrpSubtotal: number;
	discountTotal: number;
	discountedSubtotal: number;
	discountedSnapSubtotal: number;
	shippingTotal: number;
	taxTotal: number;
	grandTotal: number;
	lineItemCount: number;
	lineItemSum: number;
	partnerShippingTotals: IPartnerShippingModel[];
	oversizedItems: ICartItemModel[];
	shippingAlertItems: ICartItemModel[];
	customer?: ICustomerSummary;
	billing?: CheckoutBillingInfo;
	shipping?: CheckoutShippingInfo;
	modifying: boolean;
}

export enum CartType {
	Default = "C",
	Keycap = "K",
	Order = "O",
	Saved = "N",
	DesignService = "D",
	Upgrade = "U",
	Pcna = "S",
}

export const cartTypeLabels = new Map<CartType, string>([
	[CartType.Default, ""],
	[CartType.Keycap, "Keycap"],
	[CartType.Order, "Order"],
	[CartType.Saved, "Saved"],
	[CartType.DesignService, "Design"],
	[CartType.Upgrade, "Upgrade"],
	[CartType.Pcna, "PCNA"],
]);
// #endregion

// #region Billling
export interface IBillingOption {
	cardType: string;
	cardNumber: string;
	nameOnCard: string;
	expirationMonth: string;
	expirationYear: string;
	securityCode: string;
	bankAccountHolder: string;
	bankRoutingNumber: number;
	bankAccountNumber: string;
}

export interface IBillingModel {
	creditCardTypes: ICreditCardType[];
	paymentMethod: number;
	hasTerms: boolean;
	paymentInformation: IBillingOption;
	selectedBillingAddressId: number;
}
// #endregion

// #region Lifecycle
export interface IOrderLifecycleEvent {
	page?: string;
	action?: string;
	actionSource?: string;
	itemSku?: string;
	itemQuantity?: number;
}
// #endregion

// #region Sales Quote
export interface IExportFilter {
	includePartnerProducts: boolean;
	includeControl4Products: boolean;
	includeDealerPricing: boolean;
	includeMsrpPricing: boolean;
	includeControl4Logo: boolean;
}

export interface ISalesQuoteExport {
	savedCartId: number;
	name: string;
	exportFilter: IExportFilter;
}

export interface DiscountMessageInfo {
	totalQuantity: number;
	toAddQuantity: number;
	percent: number;
	savings: number;
}

// #endregion
