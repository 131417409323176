import { Component, OnDestroy, OnInit } from "@angular/core";
import { IUser } from "@app/account/account.models";
import { KbService } from "@app/services/kb.service";
import { IAppState } from "@app/store";
import { selectCurrentUser } from "@app/store/user";
import { IPhoneSupportWaitTimes } from "@app/support/support.models";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: "c4-support-phone",
	templateUrl: "./support-phone.component.html",
	styleUrls: ["./support-phone.component.scss"]
})
export class SupportPhoneComponent implements OnInit, OnDestroy {
	currentUser: IUser;
	supportWaitTimes: IPhoneSupportWaitTimes;
	showSupportPhone = false;
	isMobile = false;

	private destroyed$ = new Subject<{}>();

	constructor(private store: Store<IAppState>, private kbService: KbService) { }

	ngOnInit() {
		this.store.pipe(
			select(selectCurrentUser),
			takeUntil(this.destroyed$)
		).subscribe((user) => {
			this.currentUser = user;
			if (user) {
				this.populate();
			}
		});
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}

	private populate() {
		this.kbService.getPhoneSupportWaitTimes()
			.subscribe((result: IPhoneSupportWaitTimes) => {
				this.supportWaitTimes = result;
			});
	}

	supportPhoneUrl(phone: number): string {
		const phoneStr = phone.toString();

		return this.isMobile ? `tel:${phoneStr}` : `callto:${phoneStr}`;
	}

	getTimeDisplay(): string {
		if (!this.supportWaitTimes.phoneAvailable) {
			return "Unavailable";
		}
		let ret = "";
		if (this.supportWaitTimes.phoneHours) {
			ret = `${this.supportWaitTimes.phoneHours} Hour `;
		}
		ret += `${this.supportWaitTimes.phoneMinutes} Minute Wait`;
		return ret;
	}
}