import { Component, OnDestroy, OnInit } from "@angular/core";
import { RewardsService } from "@app/services/rewards.service";
import { IAppState } from "@app/store";
import { getCurrentCurrencySymbol } from "@app/store/account";
import { getCurrentTriadDiscount, LoadTriadDiscountAttempt } from "@app/store/triad-discount";
import { select, Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: "c4-kpi-widget-discount",
	templateUrl: "./kpi-widget-discount.component.html",
	styleUrls: ["./kpi-widget-discount.component.scss"]
})
export class KpiWidgetDiscountComponent implements OnInit, OnDestroy {
	loading: boolean;

	currency$: Observable<string>;
	currentPercentOff: number;
	amountNeeded: number;

	private destroyed$ = new Subject<{}>();

	constructor(readonly store: Store<IAppState>, readonly rewardsService: RewardsService) { }

	ngOnInit(): void {
		this.currency$ = this.store.pipe(select(getCurrentCurrencySymbol));

		this.store.pipe(
			select(getCurrentTriadDiscount),
			takeUntil(this.destroyed$)
		).subscribe((data) => {
			this.loading = !data;
			if (!this.loading) {
				this.amountNeeded = data.distanceToNextGoal;
				this.currentPercentOff = data.currentReward;
			} else {
				this.store.dispatch(new LoadTriadDiscountAttempt());
			}
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
