import { IInvoicePaymentModel } from "@app/shared/payment.models";
import { IAccountBalanceResult, IError, IGenericErrorListResponse, SubmitStateType } from "@app/shared/shared.models";
import { Action } from "@ngrx/store";

export class LoadAccountBalanceAttempt implements Action {
	readonly type = AccountBalanceActionTypes.LoadAccountBalanceAttempt;
	constructor() { }
}

export class LoadAccountBalanceSuccess implements Action {
	readonly type = AccountBalanceActionTypes.LoadAccountBalanceSuccess;
	constructor(public payload: IAccountBalanceResult) { }
}

export class LoadAccountBalanceError implements Action {
	readonly type = AccountBalanceActionTypes.LoadAccountBalanceError;
	constructor(public payload: IError) { }
}

export class PayInvoiceAttempt implements Action {
	readonly type = AccountBalanceActionTypes.PayInvoiceAttempt;
	constructor(public payload: IInvoicePaymentModel) { }
}

export class PayInvoiceSuccess implements Action {
	readonly type = AccountBalanceActionTypes.PayInvoiceSuccess;
	constructor(public payload: IInvoicePaymentModel) { }
}

export class PayInvoiceError implements Action {
	readonly type = AccountBalanceActionTypes.PayInvoiceError;
	constructor(public payload: IGenericErrorListResponse) { }
}

export class UpdateAccountBalancePaymentSubmitState implements Action {
	readonly type = AccountBalanceActionTypes.UpdateSubmitState;
	constructor(public payload: SubmitStateType) { }
}

export type AccountBalanceActions =
	LoadAccountBalanceAttempt
	| LoadAccountBalanceSuccess
	| LoadAccountBalanceError
	| PayInvoiceAttempt
	| PayInvoiceSuccess
	| PayInvoiceError
	| UpdateAccountBalancePaymentSubmitState;
export enum AccountBalanceActionTypes {
	LoadAccountBalanceAttempt = "[Account Balance Load] Attempt",
	LoadAccountBalanceSuccess = "[Account Balance Load] Success",
	LoadAccountBalanceError = "[Account Balance Load] Error",
	PayInvoiceAttempt = "[Invoice Pay] Attempt",
	PayInvoiceSuccess = "[Invoice Pay] Success",
	PayInvoiceError = "[Invoice Pay] Error",
	UpdateSubmitState = "[Submit State] Update",
}
