import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "progressPercent",
	pure: false
})
export class ProgressPercentPipe implements PipeTransform {

	transform(value: number, total: number): any {
		if (!total) {
			return 0;
		}
		return Math.round(value / total * 10000) / 100;
	}
}
