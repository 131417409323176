import { Pipe, PipeTransform } from "@angular/core";
import { Portals } from "@app/shared/shared.models";

@Pipe({
	name: "portalMatch"
})
export class PortalMatchPipe implements PipeTransform {

	transform(portal: Portals, ...matches: Portals[]): boolean {
		if (!matches) {
			return false;
		}
		return !!matches.find(x => (x & portal) !== 0);
	}

}
