import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "enumDescription"
})
export class EnumDescriptionPipe implements PipeTransform {

	transform<T extends number>(value: T, map: Map<T, string>, separator = ", "): any {
		const values: string[] = [];
		map.forEach((label, val) => {
			if ((value & val) > 0) {
				values.push(label);
			}
		});
		return values.sort().join(separator);
	}

}
