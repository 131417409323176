import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IBrochureGroupModel, IMagazineModel } from "@app/marketing/marketing.models";
import { IDocumentationDoc, IDocumentationPage } from "@app/shared/shared.models";
import { AuthService } from "./auth.service";

@Injectable({
	providedIn: "root"
})
export class DocumentationService {

	constructor(readonly http: HttpClient, private authService: AuthService) { }

	getDocumentationByGroup(group: string) {
		return this.http.get<IDocumentationDoc[]>("api/documentation/GetDocumentationByGroup", { params: { "group": group } });
	}

	getMostRecentDocumentation(limit: number, group: string = null) {
		let params = new HttpParams().append("limit", limit.toString());
		if (group) {
			params = params.append("group", group);
		}

		return this.http.get<IDocumentationDoc[]>("api/documentation/GetMostRecentDocumentation", { params });
	}

	getDocumentation() {
		return this.http.get<IDocumentationPage>("api/content/GetDocumentation");
	}

	getDocumentationByRelease(release: string) {
		return this.http.get<IDocumentationPage>("api/content/GetDocumentationByRelease", { params: { release } });
	}

	getBrochures() {
		return this.http.get<IBrochureGroupModel>("api/content/GetBrochures");
	}

	getMagazines() {
		return this.http.get<IMagazineModel[]>("api/content/GetMagazines");
	}

	viewDocument(url: string, token: string) {
		const newTab = window.open("about:blank", "_blank");
		this.authService.getUpdatedToken(token).subscribe((result) => {
			if (result) {
				newTab.location.href = `${url}?token=${result.token}`;
			} else {
				newTab.location.href = url;
			}
		});
	}
}
