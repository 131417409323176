import { IError } from "@app/shared/shared.models";
import { Action } from "@ngrx/store";
import { ICountryCode } from "../address/address.models";

export class LoadCountryCodesAttempt implements Action {
	readonly type = CountryCodeActionTypes.LoadCountryCodesAttempt;
	constructor() { }
}

export class LoadCountryCodesSuccess implements Action {
	readonly type = CountryCodeActionTypes.LoadCountryCodesSuccess;
	constructor(public payload: ICountryCode[]) { }
}

export class LoadCountryCodesError implements Action {
	readonly type = CountryCodeActionTypes.LoadCountryCodesError;
	constructor(public payload: IError) { }
}

export type CountryCodeActions =
	LoadCountryCodesAttempt
	| LoadCountryCodesSuccess
	| LoadCountryCodesError;
export enum CountryCodeActionTypes {
	LoadCountryCodesAttempt = "[Country Code] Load All Attempt",
	LoadCountryCodesSuccess = "[Country Code] Load All Success",
	LoadCountryCodesError = "[Country Code] Load All Error",
}
