import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { RouterModule } from "@angular/router";
import { PipesModule } from "@app/pipes/pipes.module";
import { CardComponent } from "@app/shared-elements/card/card.component";
import { CarouselComponent } from "@app/shared-elements/carousel/carousel.component";
import { EnumCheckboxComponent } from "@app/shared-elements/enum-checkbox/enum-checkbox.component";
import { EnumRadioButtonComponent } from "@app/shared-elements/enum-radio-button/enum-radio-button.component";
import { FocusDirective } from "@app/shared-elements/focus.directive";
import { GoalProgressComponent } from "@app/shared-elements/goal-progress/goal-progress.component";
import { LoadingComponent } from "@app/shared-elements/loading/loading.component";
import { MediaImageComponent } from "@app/shared-elements/media-image/media-image.component";
import { PagerComponent } from "@app/shared-elements/pager/pager.component";
import { TypeaheadComponent } from "@app/shared-elements/typeahead/typeahead.component";
import { WidgetComponent } from "@app/shared-elements/widget/widget.component";
import { ProctorUComponent } from "@app/training/proctor-u/proctor-u.component";
import { BrandContentComponent } from "./brand-content/brand-content.component";
import { ChartColorSelectorComponent } from "./chart-color-selector/chart-color-selector.component";
import { ClickToggleDirective } from "./click-toggle.directive";
import { CloudinaryComponent } from "./cloudinary/cloudinary.component";
import { DateTimeComponent } from "./date-time/date-time.component";
import { ExternalLinkDirective } from "./external-link.directive";
import { FeatureToggleComponent } from "./feature-toggle/feature-toggle.component";
import { FileUploadComponent } from "./file-upload/file-upload.component";
import { HoverToggleDirective } from "./hover-toggle.directive";
import { ImageWiperComponent } from "./image-wiper/image-wiper.component";
import { KpiWidgetBalanceComponent } from "./kpi-widget-balance/kpi-widget-balance.component";
import { KpiWidgetDiscountComponent } from "./kpi-widget-discount/kpi-widget-discount.component";
import { KpiWidgetLeadsComponent } from "./kpi-widget-leads/kpi-widget-leads.component";
import { KpiWidgetRebateComponent } from "./kpi-widget-rebate/kpi-widget-rebate.component";
import { KpiWidgetRevenueComponent } from "./kpi-widget-revenue/kpi-widget-revenue.component";
import { KpiWidgetStatusComponent } from "./kpi-widget-status/kpi-widget-status.component";
import { KpiWidgetComponent } from "./kpi-widget/kpi-widget.component";
import { NoBrComponent } from "./no-br/no-br.component";
import { PieChartComponent } from "./pie-chart/pie-chart.component";
import { PortalContactUsFormComponent } from "./portal-contact-us-form/portal-contact-us-form.component";
import { ProductVideoComponent } from "./product-video/product-video.component";
import { RunScriptsDirective } from "./run-scripts.directive";
import { SchedulerComponent } from "./scheduler/scheduler.component";
import { StaticReviewMessageComponent } from "./static-review-message/static-review-message.component";
import { StatusBadgeComponent } from "./status-badge/status-badge.component";
import { SubdealerSelectComponent } from "./subdealer-select/subdealer-select.component";
import { ToggleDirective } from "./toggle.directive";
import { TooltipComponent } from "./tooltip/tooltip.component";
import { TrackDirective } from "./track.directive";
import { TypeaheadResultComponent } from "./typeahead-result/typeahead-result.component";
import { WistiaIframeComponent } from "./wistia-iframe/wistia-iframe.component";
import { WistiaPlayerComponent } from "./wistia-player/wistia-player.component";
import { WistiaThumbnailPlayerComponent } from "./wistia-thumbnail-player/wistia-thumbnail-player.component";
import { ZoomDirective } from "./zoom.directive";

@NgModule({
	imports: [
		CommonModule,
		PipesModule,
		FormsModule,
		RouterModule.forChild([]),
		MatInputModule,
		MatFormFieldModule,
		MatAutocompleteModule,
	],
	declarations: [
		CarouselComponent,
		GoalProgressComponent,
		LoadingComponent,
		WidgetComponent,
		TypeaheadComponent,
		CardComponent,
		PagerComponent,
		EnumCheckboxComponent,
		EnumRadioButtonComponent,
		MediaImageComponent,
		TooltipComponent,
		FocusDirective,
		TrackDirective,
		PortalContactUsFormComponent,
		FileUploadComponent,
		DateTimeComponent,
		StatusBadgeComponent,
		SubdealerSelectComponent,
		CloudinaryComponent,
		NoBrComponent,
		BrandContentComponent,
		WistiaPlayerComponent,
		WistiaIframeComponent,
		ToggleDirective,
		ProductVideoComponent,
		ZoomDirective,
		HoverToggleDirective,
		ClickToggleDirective,
		WistiaThumbnailPlayerComponent,
		RunScriptsDirective,
		KpiWidgetComponent,
		KpiWidgetLeadsComponent,
		KpiWidgetBalanceComponent,
		KpiWidgetStatusComponent,
		KpiWidgetRebateComponent,
		KpiWidgetDiscountComponent,
		KpiWidgetRevenueComponent,
		ImageWiperComponent,
		ExternalLinkDirective,
		PieChartComponent,
		ChartColorSelectorComponent,
		SchedulerComponent,
		FeatureToggleComponent,
		TypeaheadResultComponent,
		StaticReviewMessageComponent,
		ProctorUComponent,
	],
	exports: [
		CarouselComponent,
		GoalProgressComponent,
		LoadingComponent,
		WidgetComponent,
		TypeaheadComponent,
		CardComponent,
		PagerComponent,
		EnumCheckboxComponent,
		EnumRadioButtonComponent,
		MediaImageComponent,
		TooltipComponent,
		FocusDirective,
		TrackDirective,
		PortalContactUsFormComponent,
		FileUploadComponent,
		DateTimeComponent,
		StatusBadgeComponent,
		SubdealerSelectComponent,
		NoBrComponent,
		CloudinaryComponent,
		BrandContentComponent,
		WistiaPlayerComponent,
		WistiaThumbnailPlayerComponent,
		WistiaIframeComponent,
		ToggleDirective,
		ProductVideoComponent,
		HoverToggleDirective,
		ClickToggleDirective,
		RunScriptsDirective,
		KpiWidgetComponent,
		KpiWidgetLeadsComponent,
		KpiWidgetBalanceComponent,
		KpiWidgetStatusComponent,
		KpiWidgetRebateComponent,
		KpiWidgetDiscountComponent,
		KpiWidgetRevenueComponent,
		ImageWiperComponent,
		ExternalLinkDirective,
		PieChartComponent,
		ChartColorSelectorComponent,
		SchedulerComponent,
		FeatureToggleComponent,
		StaticReviewMessageComponent,
		ProctorUComponent,
	],
})
export class SharedElementsModule { }
