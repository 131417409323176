import { getNgStoreCacheItem } from "@app/shared/helpers";
import { getCurrentAccountId, getCurrentSubDealersMap } from "@app/store/account";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ICustomerSummariesState as ICustomerSummaryState } from ".";
import { selectCurrentUser } from "../user";
import { customerSummariesAdapter } from "./customer-summary.adapter";

export const getCustomerSummaryState = createFeatureSelector<ICustomerSummaryState>("customerSummaries");

export const {
	selectIds: customerSummarySelectIds,
	selectEntities: customerSummarySelectEntities,
	selectAll: customerSummarySelectAll,
	selectTotal: customerSummarySelectTotal
} = customerSummariesAdapter.getSelectors(getCustomerSummaryState);

export const getCustomerSummarySubdealerFilter = createSelector(
	getCustomerSummaryState,
	getCurrentSubDealersMap,
	(state, subdealers) => subdealers && state.subdealerFilter && subdealers.get(state.subdealerFilter) ? state.subdealerFilter : undefined
);

export const getCurrentCustomerSummaryInfo = createSelector(
	getCurrentAccountId,
	selectCurrentUser,
	customerSummarySelectEntities,
	(accountId, user, entities) => accountId && user && entities ? getNgStoreCacheItem(entities[user.subDealerId || accountId]) : undefined
);

export const getCurrentCustomerSummaries = createSelector(
	getCurrentCustomerSummaryInfo,
	getCustomerSummarySubdealerFilter,
	(info, filter) => {
		if (!(info && info.consumers)) {
			return undefined;
		}
		if (filter) {
			return info.consumers.filter(x => x.dealerId === filter);
		}
		return info.consumers;
	}
);

export const getCurrentCustomerSummariesCount = createSelector(
	getCurrentCustomerSummaries,
	(controllers) => controllers ? controllers.length : 0
);

export const getCurrentCustomerSummariesNeededUpgrading = createSelector(
	getCurrentCustomerSummaries,
	(controllers) => controllers ? controllers.filter(x => !x.upToDateController) : undefined
);

export const getCurrentCustomerSummariesNeededUpgradingCount = createSelector(
	getCurrentCustomerSummariesNeededUpgrading,
	(controllers) => controllers ? controllers.length : 0
);

export const getCurrentCustomerSummariesUpToDate = createSelector(
	getCurrentCustomerSummaries,
	(controllers) => controllers ? controllers.filter(x => x.upToDateController) : undefined
);

export const getCurrentCustomerSummariesUpToDateCount = createSelector(
	getCurrentCustomerSummariesUpToDate,
	(controllers) => controllers ? controllers.length : 0
);


export const getCurrentCustomerSummariesUpgradedPercent = createSelector(
	getCurrentCustomerSummariesCount,
	getCurrentCustomerSummariesUpToDateCount,
	(total, num) => !!total ? Math.round(num / total * 100) : 0
);