import { Injectable } from "@angular/core";
import { ProductService } from "@app/services/product.service";
import { getNgStoreCacheItem } from "@app/shared/helpers";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action, select, Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, filter, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { IAppState } from "../app.reducer";
import { LoadProductStockStatusAttempt, LoadProductStockStatusError, LoadProductStockStatusSuccess, StockStatusActionTypes } from "./product-stock-status.actions";
import { productStockStatusSelectEntities } from "./product-stock-status.selectors";

@Injectable()
export class ProductStockStatusEffects {
	constructor(
		private actions$: Actions,
		private store: Store<IAppState>,
		private productService: ProductService,
	) { }

	@Effect()
	loadStockStatus$: Observable<Action> = this.actions$.pipe(
		ofType<LoadProductStockStatusAttempt>(StockStatusActionTypes.LoadProductStockStatusAttempt),
		withLatestFrom(this.store.pipe(select(productStockStatusSelectEntities))),
		filter(([action, entities]) => !getNgStoreCacheItem(entities[action.payload.sku])),
		mergeMap(([action, _]) => {
			return this.productService
				.getStockStatus(action.payload.sku)
				.pipe(
					map(response => new LoadProductStockStatusSuccess(response)),
					catchError(err => of(new LoadProductStockStatusError(err.error))),
				);
		}),
	);
}
