import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "camelize"
})
export class CamelizePipe implements PipeTransform {
	transform(text: string): string {
		return String(text || "").replace(/\w\S*/g, (txt: string) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
	}
}
