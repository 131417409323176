import { SecurityRoles, userHasSecurityFlag } from "@app/account/account.models";
import { convertToMap, getNgStoreCacheItem } from "@app/shared/helpers";
import { Portals } from "@app/shared/shared.models";
import { securityRolesAdapter } from "@app/store/security-roles/secuirity-roles.adapter";
import { ISecurityRolesState } from "@app/store/security-roles/security-roles.reducer";
import { selectActiveUser, selectCurrentUser } from "@app/store/user";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { getCurrentPortal } from "../app-info/app-info.selectors";
import { getCurrentAccountCanSubmitRma } from "../sf-contact";

export const getSecurityRolesState = createFeatureSelector<ISecurityRolesState>("securityRoles");

export const {
	selectIds: securityRolesSelectIds,
	selectEntities: securityRolesSelectEntities,
	selectAll: securityRolesSelectAll,
	selectTotal: securityRolesSelectTotal,
} = securityRolesAdapter.getSelectors(getSecurityRolesState);

export const getEditableSecurityRoleDetails = createSelector(
	securityRolesSelectAll,
	selectCurrentUser,
	selectActiveUser,
	getCurrentPortal,
	getCurrentAccountCanSubmitRma,
	(items, current, active, portal, allowOnlineRma) => {
		// Ignore if no items, user, or the cache is stale
		if (!items || !current || items.find(x => !getNgStoreCacheItem(x))) {
			return [];
		}

		const internalRoles = SecurityRoles.InternalSuperUser
			| SecurityRoles.InternalSalesImpersonation
			| SecurityRoles.InternalMarketingAdmin
			| SecurityRoles.InternalSupportAdmin
			| SecurityRoles.InternalProductAdmin
			| SecurityRoles.InternalSalesAdmin
			| SecurityRoles.InternalEducationAdmin
			| SecurityRoles.InternalFirmwareAdmin
			| SecurityRoles.InternalPlaceDealerOrders;

		let roles = SecurityRoles.All & ~internalRoles;

		const canSeeImpersonation = userHasSecurityFlag(current, SecurityRoles.InternalSalesImpersonation);
		const canEditUsers = userHasSecurityFlag(current, SecurityRoles.AcctUserAdmin);
		const canPurchase = userHasSecurityFlag(current, SecurityRoles.PurchasingUser);
		const canAdminMarketing = userHasSecurityFlag(current, SecurityRoles.InternalMarketingAdmin);
		const canAdminSupport = userHasSecurityFlag(current, SecurityRoles.InternalSupportAdmin);
		const hasMarketingToolkit = userHasSecurityFlag(current, SecurityRoles.MarketingToolKit);
		const hasFirmwareManagement = userHasSecurityFlag(current, SecurityRoles.InternalFirmwareAdmin);
		const canOrderForDealers = userHasSecurityFlag(current, SecurityRoles.InternalPlaceDealerOrders);
		const superUser = userHasSecurityFlag(active, SecurityRoles.InternalSuperUser);
		const subdealerId = current.subDealerId;

		if (canEditUsers || superUser || canSeeImpersonation) {
			if (!superUser || active.userId !== current.userId) {
				if ((subdealerId && current.userId !== current.accountId) || (current.parentAccountId && current.parentAccountId !== current.accountId)) {
					roles = SecurityRoles.Dashboard | SecurityRoles.AcctUserAdmin;
				}
			}

			if (portal === Portals.Pakedge || portal === Portals.Triad) {
				roles = SecurityRoles.ViewCompInfo | SecurityRoles.PriceViewing | SecurityRoles.PurchasingUser | SecurityRoles.ViewOrderInfo | SecurityRoles.AcctUserAdmin;
			}

			if (!allowOnlineRma) {
				roles &= ~SecurityRoles.OnlineRma;
			}

			if (!canPurchase && !superUser && !canSeeImpersonation) {
				roles &= ~SecurityRoles.PurchasingUser;
			}

			if (!hasMarketingToolkit && !superUser && !canSeeImpersonation) {
				roles &= ~SecurityRoles.MarketingToolKit;
			}

			if (!canAdminMarketing && !superUser) {
				roles &= ~SecurityRoles.InternalContentReviewer;
			}

			if (!canAdminSupport && !superUser) {
				roles &= ~SecurityRoles.InternalSupportModerator;
			}

			if (!hasFirmwareManagement && !superUser) {
				roles &= ~SecurityRoles.InternalFirmwareAdmin;
			}

			if (!canOrderForDealers && !superUser) {
				roles &= ~SecurityRoles.InternalPlaceDealerOrders;
			}
		}
		if (superUser && active.userId === current.userId) {
			roles |= internalRoles | SecurityRoles.InternalContentReviewer | SecurityRoles.InternalSupportModerator;
		}

		return items.filter(item => item.operator && (item.operator & roles) > 0).sort((a, b) => a.roleName.localeCompare(b.roleName));
	},
);

export const getAllEditableSecurityRoleDetailsMap = createSelector(
	getEditableSecurityRoleDetails, details => convertToMap(details.filter(detail => !detail.isInternal), "operator", "roleName"),
);

export const getEditableExternalSecurityRoleDetailsMap = createSelector(
	getEditableSecurityRoleDetails, details => convertToMap(details.filter(detail => !detail.isInternal), "operator", "roleName"),
);

export const getEditableInternalSecurityRoleDetailsMap = createSelector(
	getEditableSecurityRoleDetails, details => convertToMap(details.filter(detail => detail.isInternal), "operator", "roleName"),
);

export const getCurrentUserSecurityRoleDetails = createSelector(
	securityRolesSelectAll,
	selectCurrentUser,
	(items, current) => items && current ? items.filter(item => item.operator && (item.operator & current.securityRoles) > 0) : undefined,
);

export const getCurrentUserSecurityRoleDetailsMap = createSelector(
	getCurrentUserSecurityRoleDetails, details => convertToMap(details, "operator", "roleName"),
);

export const getCurrentUserSecurityRoleDetailNames = createSelector(
	getCurrentUserSecurityRoleDetails, details => details.map(detail => detail.roleName),
);
