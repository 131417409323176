import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "@app/services/auth.service";
import { equalsIgnoreCase, startsWithIgnoreCase } from "@app/shared/helpers";
import { IAppState } from "@app/store";
import { LogoutAttempt } from "@app/store/auth";
import { environment } from "@env/environment";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
	constructor(private service: AuthService, private store: Store<IAppState>) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (req.url.toLowerCase().startsWith("api")) {
			const isKbService = req.url.toLowerCase().startsWith("api/knowledgebase");
			const isKbAnnouncement = req.params && req.params.has("pageCode") ? req.params.get("pageCode") === "kb-announcements" : false;
			const url = (isKbAnnouncement || isKbService) && environment.isEmea ? environment.secondaryApiUrl : environment.apiUrl;
			const currentJwt = this.service.getCurrentJwt();
			const activeJwt = this.service.getActiveJwt();

			req = req.clone({
				url: url + req.url,
				setHeaders: {
					Authorization: `Bearer ${currentJwt ? currentJwt.token : ""}`,
					ActiveUser: `${activeJwt ? activeJwt.token : ""}`,
				},
			});
		}
		return next.handle(req).pipe(catchError((err: HttpErrorResponse, caught) => {
			const path = !startsWithIgnoreCase(window.location.pathname, "/iframe") ? window.location.pathname + (window.location.hash ? window.location.hash : "") : null;
			if (err.status === 401 && !equalsIgnoreCase(path, "/login") && !equalsIgnoreCase(path, "/logout")) {
				this.store.dispatch(new LogoutAttempt({ loginRedirect: path }));
			}

			throw err;
		}));
	}
}
