import { Component, Input } from "@angular/core";

@Component({
	selector: "c4-status-badge",
	templateUrl: "./status-badge.component.html",
	styleUrls: ["./status-badge.component.scss"]
})
export class StatusBadgeComponent {

	@Input() type: string;

	constructor() { }
}
