import { Component, OnDestroy, OnInit } from "@angular/core";
import { IUser } from "@app/account/account.models";
import { IAppState } from "@app/store";
import { selectCurrentUser } from "@app/store/user";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: "c4-rma-button",
	templateUrl: "./rma-button.component.html",
	styleUrls: ["./rma-button.component.scss"]
})
export class RmaButtonComponent implements OnInit, OnDestroy {

	constructor(private readonly store: Store<IAppState>) { }

	user: IUser;
	private destroyed$ = new Subject<{}>();

	ngOnInit() {
		this.store.pipe(select(selectCurrentUser), takeUntil(this.destroyed$)).subscribe((userResult) => {
			this.user = userResult;
		});
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}

}
