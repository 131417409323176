import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CKEditorComponent } from "./ckeditor/ckeditor.component";

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [CKEditorComponent],
	exports: [CKEditorComponent]
})
export class CKEditorModule { }
