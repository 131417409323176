import { Params } from "@angular/router";
import { accountBalanceReducer, IAccountBalanceState } from "@app/store/account-balance/account-balance.reducer";
import { accountReducer, AccountState } from "@app/store/account/account.reducer";
import { addressReducer, IAddressState } from "@app/store/address/address.reducer";
import { AuthActionType } from "@app/store/auth";
import { authReducer, IAuthState } from "@app/store/auth/auth.reducer";
import { bakpakCodesReducer, IBakpakCodesState } from "@app/store/bakpak-codes/bakpak-codes.reducer";
import { c4ContactsReducer, IC4ContactsState } from "@app/store/c4-contacts/c4-contacts.reducer";
import { cartReducer, ICartState } from "@app/store/cart/cart.reducer";
import { contractReducer, IContractState } from "@app/store/contract/contract.reducer";
import { creditCardTypeReducer, ICreditCardTypeState } from "@app/store/credit-card-type/credit-card-type.reducer";
import { customerSummaryReducer, ICustomerSummariesState } from "@app/store/customer-summary/customer-summary.reducer";
import { dealerLocatorReducer, IDealerLocatorState } from "@app/store/dealer-locator/dealer-locator.reducer";
import { ILeadPreferencesState, leadPreferencesReducer } from "@app/store/lead-preferences/lead-preferences.reducer";
import { leadsReducer, LeadsState } from "@app/store/leads/leads.reducer";
import { navReducer, NavState } from "@app/store/nav/nav.reducer";
import { IOrderHistoryDetailsState, orderHistoryDetailsReducer } from "@app/store/order-history-details/order-history-details.reducer";
import { orderHistoryReducer, OrderHistoryState } from "@app/store/order-history/order-history.reducer";
import { ISalesContactsState, salesContactsReducer } from "@app/store/sales-contacts/sales-contacts.reducer";
import { ISecurityRolesState, securityRolesReducer } from "@app/store/security-roles/security-roles.reducer";
import { ISalesforceContactState, salesforceContactReducer } from "@app/store/sf-contact/sf-contact.reducer";
import { IShippingMethodState, shippingMethodReducer } from "@app/store/shipping-method/shipping-method.reducer";
import { IUpgradeRequestFileState } from "@app/store/upgrade-request-file";
import { upgradeRequestFileReducer } from "@app/store/upgrade-request-file/upgrade-request-file.reducer";
import { IUserCertificationsState, userCertificationsReducer } from "@app/store/user-certifications/user-certifications.reducer";
import { userReducer, UserState } from "@app/store/user/user.reducer";
import { environment } from "@env/environment";
import { routerReducer, RouterReducerState } from "@ngrx/router-store";
import { Action, ActionReducer, ActionReducerMap, MetaReducer } from "@ngrx/store";
import { localStorageSync } from "ngrx-store-localstorage";
import { appInfoReducer, IAppInfoState } from "./app-info/app-info.reducer";
import { countryCodesReducer, ICountryCodeState } from "./country-code/country-code.reducer";
import { customerLicenseSummariesReducer, ICustomerLicenseSummariesState } from "./customer-license-summary/customer-license-summary.reducer";
import { ICategoryState } from "./product-category";
import { categoryReducer } from "./product-category/product-category.reducer";
import { IProductDetailsState } from "./product-details";
import { productDetailsReducer } from "./product-details/product-details.reducer";
import { IProductLightState } from "./product-light";
import { productLightReducer } from "./product-light/product-light.reducer";
import { IProductStockStatusState, productStockStatusReducer } from "./product-stock-status/product-stock-status.reducer";
import { IRebateProgressState, rebateProgressReducer } from "./rebate-progress/rebate-progress.reducer";
import { ISalesInfoState } from "./sales-info";
import { salesInfoReducer } from "./sales-info/sales-info.reducer";
import { IStatusProgressState } from "./status-progress";
import { statusProgressReducer } from "./status-progress/status-progress.reducer";
import { ITriadDiscountState, triadDiscountReducer } from "./triad-discount/triad-discount.reducer";

export interface IRouterStateUrl {
	url: string;
	params: Params;
	queryParams: Params;
	title?: string;
}

export interface IAppState {
	auth: IAuthState;
	appInfo: IAppInfoState;
	nav: NavState;
	router: RouterReducerState<IRouterStateUrl>;
	users: UserState;
	productDetails: IProductDetailsState;
	productsLight: IProductLightState;
	customerSummaries: ICustomerSummariesState;
	customerLicenseSummaries: ICustomerLicenseSummariesState;
	contracts: IContractState;
	carts: ICartState;
	leadPreferences: ILeadPreferencesState;
	c4Contacts: IC4ContactsState;
	salesContacts: ISalesContactsState;
	leads: LeadsState;
	addresses: IAddressState;
	shippingMethods: IShippingMethodState;
	bakpakCodes: IBakpakCodesState;
	userCertifications: IUserCertificationsState;
	sfContacts: ISalesforceContactState;
	accounts: AccountState;
	securityRoles: ISecurityRolesState;
	orderHistory: OrderHistoryState;
	orderHistoryDetails: IOrderHistoryDetailsState;
	accountBalances: IAccountBalanceState;
	creditCardTypes: ICreditCardTypeState;
	dealerLocators: IDealerLocatorState;
	upgradeRequestFiles: IUpgradeRequestFileState;
	countryCodes: ICountryCodeState;
	productCategories: ICategoryState;
	triadDiscount: ITriadDiscountState;
	salesInfo: ISalesInfoState;
	rebateProgress: IRebateProgressState;
	statusProgress: IStatusProgressState;
	productStockStatus: IProductStockStatusState;
}

export const reducers: ActionReducerMap<IAppState> = {
	auth: authReducer,
	nav: navReducer,
	appInfo: appInfoReducer,
	router: routerReducer,
	users: userReducer,
	productDetails: productDetailsReducer,
	productsLight: productLightReducer,
	customerLicenseSummaries: customerLicenseSummariesReducer,
	customerSummaries: customerSummaryReducer,
	contracts: contractReducer,
	carts: cartReducer,
	leadPreferences: leadPreferencesReducer,
	c4Contacts: c4ContactsReducer,
	salesContacts: salesContactsReducer,
	leads: leadsReducer,
	addresses: addressReducer,
	shippingMethods: shippingMethodReducer,
	bakpakCodes: bakpakCodesReducer,
	userCertifications: userCertificationsReducer,
	sfContacts: salesforceContactReducer,
	accounts: accountReducer,
	securityRoles: securityRolesReducer,
	orderHistory: orderHistoryReducer,
	orderHistoryDetails: orderHistoryDetailsReducer,
	accountBalances: accountBalanceReducer,
	creditCardTypes: creditCardTypeReducer,
	dealerLocators: dealerLocatorReducer,
	upgradeRequestFiles: upgradeRequestFileReducer,
	countryCodes: countryCodesReducer,
	productCategories: categoryReducer,
	triadDiscount: triadDiscountReducer,
	salesInfo: salesInfoReducer,
	rebateProgress: rebateProgressReducer,
	statusProgress: statusProgressReducer,
	productStockStatus: productStockStatusReducer,
};

export function logger(
	reducer: ActionReducer<IAppState>,
): ActionReducer<IAppState> {
	return (state: IAppState, action: Action): IAppState => {
		console.log("action", action, "state", state);

		return reducer(state, action);
	};
}

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
	return localStorageSync({
		keys: [
			"auth",
			"nav",
			"users",
			"productsLight",
			"productDetails",
			"productCategories",
			"contracts",
			"c4Contacts",
			"salesContacts",
			"addresses",
			"shippingMethods",
			"bakpakCodes",
			"userCertifications",
			"sfContacts",
			"accounts",
			"securityRoles",
			"orderHistory",
			"orderHistoryDetails",
			"accountBalance",
			"creditCardTypes",
			"countryCodes",
			"carts",
			"triadDiscount",
			"rebateProgress",
			"statusProgress",
		],
		rehydrate: true,
		removeOnUndefined: true,
		restoreDates: true,
		storageKeySerializer: key => `c4-${key}`,
	})(reducer);
}

export function clearState(
	reducer: ActionReducer<IAppState>,
): ActionReducer<IAppState> {
	return (state: IAppState, action: Action): IAppState => {
		if (action.type === AuthActionType.LogoutSuccess) {
			state = { appInfo: state.appInfo } as IAppState;
		}

		return reducer(state, action);
	};
}

export const allEnvironmentReducers = [clearState, localStorageSyncReducer];

export const metaReducers: MetaReducer<IAppState>[] = !environment.production
	? [...allEnvironmentReducers, logger]
	: [...allEnvironmentReducers];