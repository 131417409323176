import { Injectable } from "@angular/core";
import { startsWithIgnoreCase } from "@app/shared/helpers";

@Injectable({
	providedIn: "root",
})
export class IFrameService {

	constructor() { }

	postHeightForParent(height = 0) {
		if (startsWithIgnoreCase(window.location.pathname, "/iframe")) {
			const heightForParent = height ? height : document.documentElement.scrollHeight;
			parent.postMessage("{\"height\":" + heightForParent + "}", "https://qa.snapav.com");
		}
	}
}
