import { IControl4ContactsModel, IError } from "@app/shared/shared.models";
import { Action } from "@ngrx/store";

export class LoadC4ContactsAttempt implements Action {
	readonly type = C4ContactActionTypes.LoadC4ContactsAttempt;
	constructor() { }
}

export class LoadC4ContactsSuccess implements Action {
	readonly type = C4ContactActionTypes.LoadC4ContactsSuccess;
	constructor(public payload: IControl4ContactsModel) { }
}

export class LoadC4ContactsError implements Action {
	readonly type = C4ContactActionTypes.LoadC4ContactsError;
	constructor(public payload: IError) { }
}

export type C4ContactsActions =
	LoadC4ContactsAttempt
	| LoadC4ContactsSuccess
	| LoadC4ContactsError;
export enum C4ContactActionTypes {
	LoadC4ContactsAttempt = "[C4 Contacts Load] Attempt",
	LoadC4ContactsSuccess = "[C4 Contacts Load] Success",
	LoadC4ContactsError = "[C4 Contacts Load] Error",
}
