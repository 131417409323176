import { getNgStoreCacheItem } from "@app/shared/helpers";
import { userCertificationsAdapter } from "@app/store/user-certifications/user-certifications.adapter";
import { IUserCertificationsState } from "@app/store/user-certifications/user-certifications.reducer";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { selectCurrentUserId } from "../user/user.selectors";

export const getUserCertificationsState = createFeatureSelector<IUserCertificationsState>("userCertifications");

export const {
	selectIds: userCertificationsSelectIds,
	selectEntities: userCertificationsSelectEntities,
	selectAll: userCertificationsSelectAll,
	selectTotal: userCertificationsSelectTotal
} = userCertificationsAdapter.getSelectors(getUserCertificationsState);

export const getCurrentUserCertifications = createSelector(
	userCertificationsSelectEntities,
	selectCurrentUserId,
	(entities, userId) => userId && getNgStoreCacheItem(entities[userId]) ? entities[userId].certifications : undefined
);