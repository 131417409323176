import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { getCurrentContract, IContractState, LoadContractAttempt } from "@app/store/contract";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { IContractModel } from "../shared.models";

@Component({
	selector: "c4-contract",
	templateUrl: "./contract.component.html",
	styleUrls: ["./contract.component.scss"],
})
export class ContractComponent implements OnInit {
	@Input() contractType: number;

	contract$: Observable<IContractModel>;

	constructor(readonly route: ActivatedRoute, readonly store: Store<IContractState>) { }

	ngOnInit() {
		this.contractType = this.contractType || this.route.snapshot.data["contractType"];
		this.store.dispatch(new LoadContractAttempt(this.contractType));
		this.contract$ = this.store.pipe(select(getCurrentContract(this.contractType)));
	}
}
