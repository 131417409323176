import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "removeEncoding"
})
export class RemoveEncodingPipe implements PipeTransform {
	transform(text: string): string {
		const lastIdx = text.lastIndexOf(">");
		return lastIdx > 0 ? text.substring(lastIdx + 1, text.length) : text;
	}
}
