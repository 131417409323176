import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { IAppState } from "@app/store";
import { AuthActionType } from "@app/store/auth";
import { Redirect } from "@app/store/router";
import { Actions, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: "c4-not-found",
	templateUrl: "./not-found.component.html",
	styleUrls: ["./not-found.component.scss"],
})
export class NotFoundComponent implements OnInit, OnDestroy {

	searchQuery: string;

	destroyed$ = new Subject<{}>();

	constructor(private store: Store<IAppState>, private actions$: Actions, private router: Router) { }

	ngOnInit() {
		this.actions$.pipe(
			ofType(AuthActionType.ImpersonateSuccess, AuthActionType.UnimpersonateSuccess),
			takeUntil(this.destroyed$),
		).subscribe(() => this.router.navigateByUrl(location.pathname + location.search));
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}

	search() {
		this.store.dispatch(new Redirect({ path: ["search"], query: { q: this.searchQuery } }));
		this.searchQuery = undefined;
	}

}
