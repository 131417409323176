import { Injectable } from "@angular/core";
import { KeyEventId } from "@app/cms/cms.models";
import { Subject } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class KeyboardService {

	constructor() { }

	keydown = new Subject<{ event: KeyboardEvent, id: KeyEventId }>();

	isSaveKeybind(event: KeyboardEvent) {
		return this.getCtrlKey(event) && !event.shiftKey && this.checkKey(event, "s");
	}

	isSaveAllKeybind(event: KeyboardEvent) {
		return this.getCtrlKey(event) && event.shiftKey && this.checkKey(event, "s");
	}

	isEscKeybind(event: KeyboardEvent) {
		return this.checkKey(event, "escape");
	}

	checkKey(event: KeyboardEvent, key: string) {
		const charCode = event.key && event.key.toLowerCase();
		const result = charCode === key;
		if (result) {
			event.preventDefault();
		}

		return result;
	}

	getCtrlKey(event: KeyboardEvent) {
		return navigator.platform.match("Mac") ? event.metaKey : event.ctrlKey;
	}
}
