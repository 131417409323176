import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ICmsPresetConsumerFilter } from "@app/cms/cms.models";
import { ControllerOs, ControllerOsGroup, ControllerType, ControllerTypeGroup } from "@app/dashboard/dashboard.models";
import { ErrorList } from "@app/shared/shared.models";

@Injectable({
	providedIn: "root"
})
export class CmsCustomerService {

	constructor(private http: HttpClient) { }

	getCmsPresetFilters() {
		return this.http.get<ICmsPresetConsumerFilter[]>("api/admin/customer/getPresetFilters");
	}

	savePresetFilter(preset: ICmsPresetConsumerFilter) {
		return this.http.post<ICmsPresetConsumerFilter | ErrorList>("api/admin/customer/savePresetFilter", preset);
	}

	removePresetFilter(id: number) {
		return this.http.delete<ICmsPresetConsumerFilter>("api/admin/customer/removePresetFilter", { params: { id: id.toString() } });
	}

	getControllerOsSegments() {
		return this.http.get<ControllerOsGroup[]>("api/admin/customer/getControllerOsSegments");
	}

	searchControllerOs(text: string) {
		return this.http.get<string[]>("api/admin/customer/searchControllerOs", { params: { text } });
	}

	saveControllerOsGroup(group: ControllerOsGroup) {
		return this.http.post<ControllerOsGroup>("api/admin/customer/saveControllerOsGroup", group);
	}

	saveControllerOs(os: ControllerOs) {
		return this.http.post<ControllerOs>("api/admin/customer/saveControllerOs", os);
	}

	removeControllerOsGroup(id: number) {
		return this.http.delete<boolean>("api/admin/customer/removeControllerOsGroup", { params: { id: id.toString() } });
	}

	removeControllerOs(id: number) {
		return this.http.delete<boolean>("api/admin/customer/removeControllerOs", { params: { id: id.toString() } });
	}

	getControllerTypeSegments() {
		return this.http.get<ControllerTypeGroup[]>("api/admin/customer/getControllerTypeSegments");
	}

	searchControllerTypes(text: string) {
		return this.http.get<string[]>("api/admin/customer/searchControllerTypes", { params: { text } });
	}

	saveControllerTypeGroup(group: ControllerTypeGroup) {
		return this.http.post<ControllerTypeGroup>("api/admin/customer/saveControllerTypeGroup", group);
	}

	saveControllerType(type: ControllerType) {
		return this.http.post<ControllerType>("api/admin/customer/saveControllerType", type);
	}

	removeControllerTypeGroup(id: number) {
		return this.http.delete<boolean>("api/admin/customer/removeControllerTypeGroup", { params: { id: id.toString() } });
	}

	removeControllerType(id: number) {
		return this.http.delete<boolean>("api/admin/customer/removeControllerType", { params: { id: id.toString() } });
	}
}
