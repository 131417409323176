import { IStatusProgress } from "@app/shared-widgets/shared-widgets.models";
import { IError } from "@app/shared/shared.models";
import { Action } from "@ngrx/store";

export class LoadStatusProgressAttempt implements Action {
	readonly type = StatusProgressActionTypes.LoadStatusProgressAttempt;
	constructor() { }
}

export class LoadStatusProgressSuccess implements Action {
	readonly type = StatusProgressActionTypes.LoadStatusProgressSuccess;
	constructor(public payload: IStatusProgress) { }
}

export class LoadStatusProgressError implements Action {
	readonly type = StatusProgressActionTypes.LoadStatusProgressError;
	constructor(public payload: IError) { }
}

export type StatusProgressActions =
	LoadStatusProgressAttempt
	| LoadStatusProgressSuccess
	| LoadStatusProgressError;

export enum StatusProgressActionTypes {
	LoadStatusProgressAttempt = "[Status Progress Load] Attempt",
	LoadStatusProgressSuccess = "[Status Progress Load] Success",
	LoadStatusProgressError = "[Status Progress Load] Error",
}
