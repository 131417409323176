import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree } from "@angular/router";
import { AuthService } from "@app/services/auth.service";
import { IAppState } from "@app/store";
import { IframeLoginSuccess, isAuthenticated } from "@app/store/auth";
import { activeHasAccountRole, activeHasRole, currentHasAccountRole, currentHasRole, LoadUserAttempt, selectCurrentUser } from "@app/store/user";
import { select, Store } from "@ngrx/store";
import { combineLatest, Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { IAuthResponse, IJwtResponse } from "./auth.models";

/**
 * Prevent unauthorized activating and loading of routes
 * @class IframeAuthGuard
 */
@Injectable()
export class IframeAuthGuard implements CanActivate, CanActivateChild {
	constructor(private store: Store<IAppState>, private authService: AuthService) { }

	canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
		return this.isAuthenticated(route, state);
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
		return this.isAuthenticated(route, state);
	}

	private isAuthenticated(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		// get observable
		const checkForActiveUser = !!route.data.checkForActiveUser;

		const requiredRoleFlag = route.data.requiredRole;
		const requiresRole = !!requiredRoleFlag;

		const requiredAccountRoleFlag = route.data.requiredAccountRole;
		const requiresAccountRole = !!requiredAccountRoleFlag;

		const userHasRole = checkForActiveUser ? activeHasRole(requiredRoleFlag) : currentHasRole(requiredRoleFlag);
		const accountHasRole = checkForActiveUser ? activeHasAccountRole(requiredAccountRoleFlag) : currentHasAccountRole(requiredAccountRoleFlag);

		const params = route.queryParams;
		const userId = params["user"] as string;
		const accountId = params["account"] as string;
		const token = params["token"] as string;
		const jwt = { token, expires: 86400 } as IJwtResponse;

		if (userId && accountId && jwt) {
			return this.store.pipe(select(isAuthenticated),
				map(authenticated => {
					this.authService.saveActiveJwt(jwt);
					this.authService.saveCurrentJwt(jwt);
					this.store.dispatch(new IframeLoginSuccess({ userId, accountId, jwt } as IAuthResponse));
					this.store.dispatch(new LoadUserAttempt({ userId }));
					return authenticated;
				}),
				switchMap(auth => {
					if (auth) {
						return combineLatest([
							this.store.pipe(select(selectCurrentUser)),
							this.store.pipe(select(requiresRole ? userHasRole : isAuthenticated)),
							this.store.pipe(select(requiresAccountRole ? accountHasRole : isAuthenticated)),
						]).pipe(map(([actUser, user, account]) => !actUser || (user && account)));
					}

					return of(true);
				}));
		}
	}
}
