import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "@app/services/auth.service";
import { IGenericResponse, PasswordErrors } from "@app/shared/shared.models";
import { IAppState } from "@app/store";
import { Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class PasswordService {
	authenticated$: Observable<boolean>;
	errors: string[] = [];

	destroyed$ = new Subject<{}>();

	constructor(readonly store: Store<IAppState>, readonly authService: AuthService, readonly route: ActivatedRoute) { }

	validate(password: string, confirm: string): boolean {
		this.errors = [];
		if (password && password.search(this.authService.passwordRegex) < 0) {
			this.errors.push(PasswordErrors.InvalidPassword);
		}

		if (password && confirm && password !== confirm) {
			this.errors.push(PasswordErrors.MismatchedPassword);
		}

		return this.errors.length > 0;
	}

	reset(loginName: string, currentPassword: string, password: string, resetId?: string, verificationCode?: string): Observable<IGenericResponse> {
		this.errors = [];

		const prr = { currentPassword, password, resetId, loginName, verificationCode };

		return this.authService.changePassword(prr);
	}
}
