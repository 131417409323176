import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { InternalErrorComponent } from "./internal-error/internal-error.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { UnauthorizedComponent } from "./unauthorized/unauthorized.component";

@NgModule({
	declarations: [NotFoundComponent, UnauthorizedComponent, InternalErrorComponent],
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
	],
	exports: [NotFoundComponent, UnauthorizedComponent]
})
export class ErrorModule { }
