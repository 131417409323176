import { IError } from "@app/shared/shared.models";
import { ISalesforceInfo } from "@app/support/support.models";
import { Action } from "@ngrx/store";

export class LoadUserSalesforceInfoAttempt implements Action {
	readonly type = UserSalesforceContactActionTypes.LoadUserSalesforceInfoAttempt;
	constructor() { }
}

export class LoadUserSalesforceInfoSuccess implements Action {
	readonly type = UserSalesforceContactActionTypes.LoadUserSalesforceInfoSuccess;
	constructor(public payload: ISalesforceInfo) { }
}

export class LoadUserSalesforceInfoError implements Action {
	readonly type = UserSalesforceContactActionTypes.LoadUserSalesforceInfoError;
	constructor(public payload: IError) { }
}

export class UpdateUserSalesforceInfoSuccess implements Action {
	readonly type = UserSalesforceContactActionTypes.UpdateUserSalesforceInfoSuccess;
	constructor(public c4UserId: string) { }
}

export type UserSalesforceContactActions =
	LoadUserSalesforceInfoAttempt
	| LoadUserSalesforceInfoSuccess
	| LoadUserSalesforceInfoError
	| UpdateUserSalesforceInfoSuccess;
export enum UserSalesforceContactActionTypes {
	LoadUserSalesforceInfoAttempt = "[User Salesforce Info] Attempt",
	LoadUserSalesforceInfoSuccess = "[User Salesforce Info] Success",
	LoadUserSalesforceInfoError = "[User Salesforce Info] Error",
	UpdateUserSalesforceInfoSuccess = "[User Salesforce Info] Update Success"
}
