import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'statusBadgeImageUrl'
})
export class StatusBadgeImageUrlPipe implements PipeTransform {

	transform(badgeType: string): string {
		return `//www.control4.com/docs/thumbnail/${badgeType}/internal-logo/latest`;
	}

}
