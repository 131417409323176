import { INavMenuItem } from "@app/nav/nav.models";
import { objectUpdate } from "@app/shared/helpers";
import { NavActions, NavActionTypes } from "@app/store/nav";
import { navAdapter } from "@app/store/nav/nav.adapter";
import { EntityState } from "@ngrx/entity";
import { Dictionary } from "@ngrx/entity/src/models";
import { RouterNavigationAction, ROUTER_NAVIGATION } from "@ngrx/router-store";
import { chain } from "underscore";

export interface NavState extends EntityState<INavMenuItem> {
	currentMenuId: number;
	productCategoryId: number;
	topMenuId: number;
	parentId: number;
}

export const initialState: NavState = navAdapter.getInitialState({
	currentMenuId: undefined,
	productCategoryId: null,
	topMenuId: null,
	parentId: null,
});

export function navReducer(
	state = initialState,
	action: NavActions | RouterNavigationAction,
): NavState {
	switch (action.type) {
		case NavActionTypes.LoadSuccess: {
			action.payload.forEach(
				item => {
					item.hasChildren = !!action.payload.find(x => x.parentId === item.id);
					item.categoryId = item.id > 200000 ? item.id - 200000 : undefined;
				},
			);
			const newState = navAdapter.addAll(action.payload, state);
			const partialState = getUpdatedState(newState.entities, window.location.pathname.toLowerCase());

			return objectUpdate(newState, partialState);
		}
		case ROUTER_NAVIGATION: {
			let url = action.payload.event.url.toLowerCase();
			if (url.startsWith("/login")) {
				return state;
			}
			// TODO: Hacky fix for CMS edit pages, def needs some TLC
			if (url.startsWith("/cms") || url.startsWith("/rma")) {
				url = window.location.pathname.toLowerCase();
			}
			const partialState = getUpdatedState(state.entities, url);

			return objectUpdate(state, partialState);
		}
		case NavActionTypes.Forward: {
			return objectUpdate(state, {
				currentMenuId: action.menuId,
				topMenuId: state.entities[action.menuId].topMenuId,
				parentId: state.entities[action.menuId].parentId,
			});
		}
		case NavActionTypes.Back: {
			const current = state.entities[state.currentMenuId];

			return objectUpdate(state, {
				currentMenuId: current ? current.parentId : 0,
				parentId: state.entities[current.parentId].parentId,
			});
		}
		case NavActionTypes.BackToTopMenu: {
			return state.topMenuId
				? objectUpdate(state, { currentMenuId: state.topMenuId })
				: state;
		}
		default:
			return state;
	}
}

function getUpdatedState(entities: Dictionary<INavMenuItem>, url?: string): Partial<NavState> {
	const hasNav = chain(entities).any();
	const productCategoryId = getProductCategoryId(url);
	const currentMenuId = hasNav ? getCurrentNavIdByUrl(entities, url.toLowerCase(), productCategoryId) : undefined;
	const topMenuId = currentMenuId ? entities[currentMenuId].topMenuId : undefined;
	const parentId = currentMenuId ? entities[currentMenuId].parentId : undefined;

	return {
		productCategoryId,
		currentMenuId,
		topMenuId,
		parentId,
	};
}

function getProductCategoryId(url: string) {
	if (url.indexOf("/products/") === 0) {
		const parts = url.split("/");
		if (parts.length > 2) {
			return parseInt(parts[2], 10);
		}
	}

	return 0;
}

function getCurrentNavIdByUrl(
	navigation: Dictionary<INavMenuItem>,
	url: string,
	categoryId: number,
): number {
	const menu = chain(navigation).map(item => item);
	if (categoryId) {
		const productMenu = menu.find((x: INavMenuItem) => x && x.categoryId === categoryId).value();
		if (productMenu) {
			return productMenu.hasLandingPage ? productMenu.id : productMenu.parentId;
		}
	}
	// Manual override for KB articles to load the Support menu
	if (url.indexOf("/knowledgebase/article") === 0) {
		url = "/support";
	}
	const found = menu
		.reverse()
		.filter(item => !!item.url)
		.find((item: INavMenuItem) => removeTrailingSlashes(item.url) === url.split("?")[0].split("#")[0])
		.value();
	if (!found) {
		return 0;
	}
	const id = found.hasChildren ? found.id : found.parentId;

	return id > 0 ? id : 0;
}

function removeTrailingSlashes(url: string) {
	return !!url ? url.replace(/\/$/, "") || "/" : url;
}
