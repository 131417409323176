import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "isHtml"
})
export class IsHtmlPipe implements PipeTransform {
	transform(value: string): boolean {
		return value ? /<[a-z][\s\S]*>/i.test(value) : false;
	}
}
