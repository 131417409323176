import { Component, EventEmitter, Input, Output } from "@angular/core";
import { format as dateFnsFormat, isAfter as dateFnsIsAfter, parse as dateFnsParse } from "date-fns";

@Component({
	selector: "c4-date-time",
	templateUrl: "./date-time.component.html",
	styleUrls: ["./date-time.component.scss"],
})
export class DateTimeComponent {
	date: string;
	time: string;

	@Input() hasTime: boolean;
	@Input() hasReset: boolean;
	@Input() placeholder: string;
	@Input() disabled: boolean;

	private _date: Date;
	@Input() get value() {
		return this._date;
	}
	set value(val) {
		this._date = val;
		if (val) {
			this.date = dateFnsFormat(val, "YYYY-MM-DD");
			this.time = dateFnsFormat(val, "HH:mm");
		}
	}

	@Output() valueChange = new EventEmitter<Date>();

	update() {
		const date = dateFnsParse(`${this.date || ""} ${this.time || ""}`);
		if (dateFnsIsAfter(date, new Date(1900, 1, 1))) {
			this.valueChange.emit(date);
		}
	}

	reset() {
		this.date = undefined;
		this.time = undefined;
		this.valueChange.emit(undefined);
	}
}
