import { Pipe, PipeTransform } from "@angular/core";
import { ChartColor } from "@app/dashboard/dashboard.models";

@Pipe({
	name: "chartColor"
})
export class ChartColorPipe implements PipeTransform {

	transform(color: ChartColor): any {
		switch (color) {
			case ChartColor.Success:
				return "chart-success";
			case ChartColor.Error:
				return "chart-danger";
			case ChartColor.Warn:
				return "chart-warn";
			default:
				return "chart-unknown";
		}
	}

}
