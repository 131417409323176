import { Component, Input, OnChanges } from "@angular/core";
import { IDimensionCustomizations, IFinishCustomizations, IGrillCustomizations } from "@app/products/products.models";
import { ProductCustomizationService } from "@app/services/product-customization.service";

@Component({
	selector: "c4-product-customization-agreements",
	templateUrl: "./customization-agreements.component.html",
	styleUrls: ["./customization-agreements.component.scss"]
})
export class CustomizationAgreementsComponent implements OnChanges {
	@Input() finish: IFinishCustomizations;
	@Input() width: IDimensionCustomizations;
	@Input() height: IDimensionCustomizations;
	@Input() grill: IGrillCustomizations;

	widthCustomized: boolean;
	heightCustomized: boolean;
	showFinishMatch: boolean;
	showCustomPaint: boolean;
	showCustomVeneer: boolean;

	constructor(private customizationService: ProductCustomizationService) { }

	ngOnChanges() {
		this.showFinishMatch = this.customizationService.customVeneerMatch(this.finish.finish)
			|| this.customizationService.customColorMatch(this.finish.paintManufacturer)
			|| this.customizationService.customColorMatch(this.grill.color);
		this.showCustomVeneer = this.finish.finish && this.finish.finish.type === "veneer";
		this.showCustomPaint = this.customizationService.finishIsCustomColor(this.finish.finish) || this.grill.isCustomColor;
		this.widthCustomized = this.width && this.width.option && this.width.option.max > 0;
		this.heightCustomized = this.height && this.height.option && this.height.option.max > 0;
	}
}
