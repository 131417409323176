import { getNgStoreCacheItem } from "@app/shared/helpers";
import { salesContactsAdapter } from "@app/store/sales-contacts/sales-contacts.adapter";
import { ISalesContactsState } from "@app/store/sales-contacts/sales-contacts.reducer";
import { selectCurrentUser } from "@app/store/user";
import { createFeatureSelector, createSelector } from "@ngrx/store";

export const getSalesContactsState = createFeatureSelector<ISalesContactsState>("salesContacts");

export const {
	selectIds: salesContactsSelectIds,
	selectEntities: salesContactsSelectEntities,
	selectAll: salesContactsSelectAll,
	selectTotal: salesContactsSelectTotal,
} = salesContactsAdapter.getSelectors(getSalesContactsState);

export const getCurrentSalesContacts = createSelector(
	salesContactsSelectEntities,
	selectCurrentUser,
	(entities, user) => user ? getNgStoreCacheItem(entities[user.accountId]) : undefined,
);