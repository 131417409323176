import { Component, ComponentRef, OnDestroy, OnInit } from "@angular/core";
import { UserService } from "@app/services/user.service";
import { IAppState } from "@app/store";
import { currentHasMigrationScheduled } from "@app/store/user";
import { select, Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { IPopupComponent } from "../popups.models";

@Component({
	selector: "c4-migration-scheduled-popup",
	templateUrl: "./migration-scheduled-popup.component.html",
	styleUrls: ["./migration-scheduled-popup.component.scss"],
})
export class MigrationScheduledPopupComponent implements OnInit, OnDestroy, IPopupComponent {
	close: () => void;
	self: ComponentRef<MigrationScheduledPopupComponent>;
	currentHasMigrationScheduled$: Observable<boolean>;

	private destroyed$ = new Subject<{}>();

	constructor(readonly store: Store<IAppState>, readonly userService: UserService) { }
	ngOnInit(): void {
		this.currentHasMigrationScheduled$ = this.store.pipe(select(currentHasMigrationScheduled()));
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}

}
