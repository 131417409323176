import { Component } from "@angular/core";

@Component({
	selector: "c4-loading",
	templateUrl: "./loading.component.html",
	styleUrls: ["./loading.component.scss"]
})
export class LoadingComponent {

	constructor() { }
}
