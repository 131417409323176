import { Component, OnDestroy, OnInit } from "@angular/core";
import { StaticPageFormsService } from "@app/services/static-page-forms.service";
import { IControl4ContactsModel, ISalesContactsModel } from "@app/shared/shared.models";
import { IAppState } from "@app/store";
import { getCurrentIsEmea } from "@app/store/account";
import { getCurrentSalesContacts, LoadSalesContactsAttempt } from "@app/store/sales-contacts";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: "c4-feedback",
	templateUrl: "./feedback.component.html",
	styleUrls: ["./feedback.component.scss"],
})
export class FeedbackComponent implements OnInit, OnDestroy {

	private destroyed$ = new Subject<{}>();
	contacts: IControl4ContactsModel;
	salesContacts: ISalesContactsModel;
	isEmea: boolean;

	constructor(private store: Store<IAppState>, readonly staticPageFormsService: StaticPageFormsService) { }

	ngOnInit() {
		this.store.pipe(select(getCurrentIsEmea)).subscribe(isEmea => { this.isEmea = isEmea; });

		this.store
			.pipe(
				select(getCurrentSalesContacts),
				takeUntil(this.destroyed$),
			).subscribe(salesContacts => {
				if (salesContacts) {
					this.salesContacts = salesContacts;
				} else {
					this.store.dispatch(new LoadSalesContactsAttempt());
				}
			});
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}

	close() {
		this.staticPageFormsService.commonFeedbackDisplay.emit(false);
	}
}