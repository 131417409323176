import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SearchResultItem } from "@app/search/search.models";
import { Portals } from "@app/shared/shared.models";

@Injectable({ providedIn: "root" })
export class SearchService {
	constructor(private http: HttpClient) { }
	globalSearch(query: string, currentPortal: Portals, limit: number = 0) {
		let params = new HttpParams().append("query", query);
		params = params.append("portal", JSON.stringify(currentPortal));
		params = params.append("limit", limit.toString());

		return this.http.get<SearchResultItem[]>("api/search/GlobalSearch", { params });
	}
}