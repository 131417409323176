import { ngStoreCacheItem } from "@app/shared/helpers";
import { UserSalesforceContactActions, UserSalesforceContactActionTypes } from "@app/store/sf-contact/sf-contact.actions";
import { salesforceContactAdapter } from "@app/store/sf-contact/sf-contact.adapter";
import { ISalesforceInfo } from "@app/support/support.models";
import { EntityState } from "@ngrx/entity";
import { UserActions, UserActionTypes } from "../user";

export interface ISalesforceContactState extends EntityState<ISalesforceInfo> { }

export const initialState: ISalesforceContactState = salesforceContactAdapter.getInitialState();

export function salesforceContactReducer(
	state = initialState,
	action: UserSalesforceContactActions | UserActions
): ISalesforceContactState {
	switch (action.type) {
		case UserSalesforceContactActionTypes.LoadUserSalesforceInfoSuccess:
			return salesforceContactAdapter.upsertOne(ngStoreCacheItem(action.payload, 2, "hours"), state);
		case UserSalesforceContactActionTypes.UpdateUserSalesforceInfoSuccess:
			return salesforceContactAdapter.removeOne(action.c4UserId, state);
		case UserActionTypes.SaveUserProfileSuccess:
			return salesforceContactAdapter.upsertOne(ngStoreCacheItem(action.sfInfo, 2, "hours"), state);
		default: {
			return state;
		}
	}
}