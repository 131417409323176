import { Component, ComponentRef, Input, OnInit } from "@angular/core";
import { IPopupComponent, IPopupInfo } from "@app/popups/popups.models";
import { Subject } from "rxjs";

@Component({
	selector: "c4-confirm-popup",
	templateUrl: "./confirm-popup.component.html",
	styleUrls: ["./confirm-popup.component.scss"]
})
export class ConfirmPopupComponent implements OnInit, IPopupComponent {
	data?: IPopupInfo;
	self: ComponentRef<ConfirmPopupComponent>;

	@Input() title: string;
	@Input() body: string;
	@Input() confirmText: string;
	@Input() headerColor: string;

	answer = new Subject<boolean>();

	constructor() { }

	ngOnInit() {
		this.title = this.title || "Confirm";
		this.body = this.body || "Are you sure you wish to continue?";
		this.confirmText = this.confirmText || "Confirm";
	}

	confirm() {
		this.answer.next(true);
		this.answer.complete();
		this.self.destroy();
	}

	close() {
		this.answer.next(false);
		this.answer.complete();
		this.self.destroy();
	}
}
