import { ngStoreCacheItem } from "@app/shared/helpers";
import { ICreditCardType } from "@app/shared/payment.models";
import { CreditCardTypeActions, CreditCardTypeActionTypes } from "@app/store/credit-card-type/credit-card-type.actions";
import { creditCardTypeAdapter } from "@app/store/credit-card-type/credit-card-type.adapter";
import { EntityState } from "@ngrx/entity";

export interface ICreditCardTypeState extends EntityState<ICreditCardType> { }

export const initialState: ICreditCardTypeState = creditCardTypeAdapter.getInitialState();

export function creditCardTypeReducer(
	state = initialState,
	action: CreditCardTypeActions
): ICreditCardTypeState {
	switch (action.type) {
		case CreditCardTypeActionTypes.LoadAllSuccess:
			return creditCardTypeAdapter.upsertMany(action.payload.map(x => ngStoreCacheItem(x, 12, "hours")), state);
		default: {
			return state;
		}
	}
}