import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "keyValueSelected"
})
export class KeyValueSelectedPipe implements PipeTransform {

	transform(values: string[] | Map<string, any>, key: string): any {
		if (Array.isArray(values)) {
			return values.includes(key);
		} else {
			return values.has(key);
		}
	}

}
