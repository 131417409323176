import { IBakpakCodes } from "@app/account/account.models";
import { ngStoreCacheItem } from "@app/shared/helpers";
import { BakpakCodesActions, BakpakCodesActionTypes } from "@app/store/bakpak-codes";
import { bakpakCodesAdapter } from "@app/store/bakpak-codes/bakpak-codes.adapter";
import { EntityState } from "@ngrx/entity";

export interface IBakpakCodesState extends EntityState<IBakpakCodes> { }

export const initialState: IBakpakCodesState = bakpakCodesAdapter.getInitialState();

export function bakpakCodesReducer(
	state = initialState,
	action: BakpakCodesActions
): IBakpakCodesState {
	switch (action.type) {
		case BakpakCodesActionTypes.LoadBakpakCodesSuccess:
			return bakpakCodesAdapter.upsertOne(ngStoreCacheItem(action.payload, 6, "hours"), state);
		default:
			return state;
	}
}