import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "toPlainText"
})
export class ToPlainTextPipe implements PipeTransform {
	transform(text: string): string {
		return String(text).replace(/<style.*>[\w\W]{1,}(.*?)[\w\W]{1,}<\/style>/gm, "").replace(/<(?:.|\n)*?>/gm, "").replace(/&[a-z]{2,4};/gm, "");
	}
}
