import { objectUpdate } from "@app/shared/helpers";
import { AuthActions, AuthActionType } from "@app/store/auth";

export const initialState: IAuthState = {
	authenticated: false,
	isImpersonating: false,
	isMsrp: false,
};

export interface IAuthState {
	authenticated: boolean;
	isImpersonating: boolean;
	isMsrp: boolean;
}

export function authReducer(
	state = initialState,
	action: AuthActions,
): IAuthState {
	switch (action.type) {
		case AuthActionType.IframeLoginSuccess:
		case AuthActionType.LoginSuccess:
			return objectUpdate(state, { authenticated: true });
		case AuthActionType.LogoutSuccess:
			return objectUpdate(state, initialState);
		case AuthActionType.ImpersonateSuccess:
			return objectUpdate(state, {
				isImpersonating: true,
				isMsrp: false,
			});
		case AuthActionType.MsrpImpersonateSuccess:
			return objectUpdate(state, {
				isImpersonating: false,
				isMsrp: true,
			});
		case AuthActionType.UnimpersonateSuccess:
			return objectUpdate(state, {
				isImpersonating: false,
				isMsrp: false,
			});
		default:
			return state;
	}
}
