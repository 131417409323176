import { Component, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { OrderService } from "@app/services/order.service";
import { Portals } from "@app/shared/shared.models";
import { IAppState } from "@app/store";
import { getCurrentCurrencySymbol, getCurrentIsEmea } from "@app/store/account";
import { getCurrentPortal } from "@app/store/app-info/app-info.selectors";
import { select, Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { IInvoiceInfo } from "../print.models";

@Component({
	selector: "c4-print-invoice",
	templateUrl: "./print-invoice.component.html",
	styleUrls: ["./print-invoice.component.scss"]
})
export class PrintInvoiceComponent implements OnInit, OnDestroy {
	docType: string;
	info: IInvoiceInfo;

	currency$: Observable<string>;
	isEmea$: Observable<boolean>;
	logo$: Observable<string>;

	private destroyed$ = new Subject<{}>();

	constructor(readonly orderService: OrderService, readonly store: Store<IAppState>, readonly route: ActivatedRoute, readonly title: Title) { }

	ngOnInit() {
		this.isEmea$ = this.store.pipe(select(getCurrentIsEmea));
		this.logo$ = this.store.pipe(select(getCurrentPortal), map((portal) => this.getLogoUrl(portal)));
		this.currency$ = this.store.pipe(select(getCurrentCurrencySymbol));

		const id = this.route.snapshot.paramMap.get("id");

		this.orderService.getInvoiceInfo(id).subscribe((result) => {
			this.docType = result.order.cartType === "I" ? "Invoice" : "Order";
			this.title.setTitle(`${this.docType} #${id}`);
			this.info = result;
		});
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}

	getLogoUrl(portal: Portals) {
		switch (portal) {
			case Portals.Pakedge:
				return "https://res.cloudinary.com/control4/image/upload/c_scale,w_200/PakedgeLogo.png";
			case Portals.Triad:
				return "https://res.cloudinary.com/control4/image/upload/c_mpad,g_west,h_85,w_200/Logos/1/TriadLogo.png";
			default:
				return "https://res.cloudinary.com/control4/image/upload/c_scale,w_200/Logos/Control4-logonotag-highres-RGB.png";
		}
	}
}
