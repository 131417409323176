import { objectSort } from "@app/shared/helpers";
import { INavState } from "@app/store/nav";
import { navAdapter } from "@app/store/nav/nav.adapter";
import { createFeatureSelector, createSelector } from "@ngrx/store";

export const getNavState = createFeatureSelector<INavState>("nav");

export const {
	selectIds: selectIds,
	selectEntities: selectEntities,
	selectAll: selectAll,
	selectTotal: selectTotal,
} = navAdapter.getSelectors(getNavState);

export const selectCurrentNavId = createSelector(
	getNavState, state => state.currentMenuId,
);

export const selectCurrentNav = createSelector(
	getNavState,
	selectCurrentNavId,
	(state, id) => state.entities[id],
);

export const selectTopMenu = createSelector(
	getNavState, state => state.topMenuId ? state.entities[state.topMenuId] : null,
);

export const selectParentLabel = createSelector(
	getNavState, state => state.parentId ? state.entities[state.parentId].label : null,
);

export const selectCurrentProductCategoryId = createSelector(
	getNavState, state => state.productCategoryId,
);

export const selectCurrentChildren = createSelector(
	selectAll,
	selectCurrentNavId,
	(items, id) => {
		return items.length
			? items
				.filter(item => item.parentId === id)
				.sort((x, y) => objectSort(x, y, ["sortOrder", "label"]))
			: null;
	},
);
