import { Injectable } from "@angular/core";
import { ProductService } from "@app/services/product.service";
import { equalsIgnoreCase } from "@app/shared/helpers";
import { ProductLightActionType } from "@app/store/product-light";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action, select, Store } from "@ngrx/store";
import { merge, Observable, of } from "rxjs";
import { bufferTime, catchError, filter, map, mergeMap, switchMap, tap } from "rxjs/operators";
import { chunk, flatten } from "underscore";
import { AuthActionType } from "../auth";
import { BulkLoadProductsLightAttempt, BulkLoadProductsLightError, BulkLoadProductsLightSuccess, LoadLightProductBySku } from "./product-light.actions";
import { IProductLightState } from "./product-light.reducer";
import { selectUnloadedSkus } from "./product-light.selectors";

@Injectable()
export class ProductLightEffects {
	constructor(
		private actions$: Actions,
		private productService: ProductService,
		private store: Store<IProductLightState>,
	) { }

	loadingSkus: string[] = [];

	@Effect()
	loadLightProductBySku$: Observable<Action> = this.actions$.pipe(
		ofType<LoadLightProductBySku>(ProductLightActionType.LoadLightProductBySku),
		bufferTime(250),
		map(actions => [...new Set(actions.map(x => x.payload))]),
		switchMap(skus => this.store.pipe(select(selectUnloadedSkus(skus)))),
		filter(unloaded => unloaded.length > 0),
		mergeMap(unloaded => {
			return this.productService
				.getBulkCustomizedLightProducts(unloaded)
				.pipe(
					map(products => new BulkLoadProductsLightSuccess({
						products,
						failedSkus: unloaded.filter(x => !products.find(y => equalsIgnoreCase(y.sku, x))),
					})),
					catchError(err => of(new BulkLoadProductsLightError({ error: err.message || err.statusText }))),
				);
		}),
	);

	@Effect()
	bulkLoadProductsLight$: Observable<Action> = this.actions$.pipe(
		ofType<BulkLoadProductsLightAttempt>(ProductLightActionType.BulkLoadProductsLightAttempt),
		bufferTime(250),
		map(actions => [...new Set(flatten(actions.map(x => x.payload)))].filter(s => !this.loadingSkus.includes(s))),
		switchMap(x => this.store.pipe(select(selectUnloadedSkus(x)))),
		filter(x => x.length > 0),
		mergeMap(unloaded => {
			this.loadingSkus = [...this.loadingSkus, ...unloaded];
			const bulkLoadActions: Observable<Action>[] = [];
			chunk(unloaded, 100).forEach((skus: string[]) => {
				bulkLoadActions.push(this.productService
					.getBulkLightProducts(skus)
					.pipe(
						map(products => {
							this.loadingSkus = this.loadingSkus.filter(x => !skus.includes(x));
							return new BulkLoadProductsLightSuccess({
								products,
								failedSkus: unloaded.filter(x => !products.find(s => equalsIgnoreCase(s.sku, x))),
							});
						}),
						catchError(err => {
							this.loadingSkus = this.loadingSkus.filter(x => !skus.includes(x));
							return of(new BulkLoadProductsLightError({ error: err.message || err.statusText }));
						}),
					));
			});
			return merge(...bulkLoadActions);
		}),
	);

	@Effect({ dispatch: false })
	clearLoadingSkus$: Observable<Action> = this.actions$.pipe(
		ofType(AuthActionType.LoginSuccess, AuthActionType.ImpersonateSuccess, AuthActionType.MsrpImpersonateSuccess),
		tap(() => this.loadingSkus = []),
	);
}
