import { IUserCertificationsInfo } from "@app/account/account.models";
import { IError } from "@app/shared/shared.models";
import { Action } from "@ngrx/store";

export class LoadUserCertificationsAttempt implements Action {
	readonly type = UserCertificationsActionTypes.LoadUserCertificationsAttempt;
	constructor(public userId: string) { }
}

export class LoadUserCertificationsSuccess implements Action {
	readonly type = UserCertificationsActionTypes.LoadUserCertificationsSuccess;
	constructor(public payload: IUserCertificationsInfo) { }
}

export class LoadUserCertificationsError implements Action {
	readonly type = UserCertificationsActionTypes.LoadUserCertificationsError;
	constructor(public payload: IError) { }
}

export type UserCertificationsActions =
	LoadUserCertificationsAttempt
	| LoadUserCertificationsSuccess
	| LoadUserCertificationsError;
export enum UserCertificationsActionTypes {
	LoadUserCertificationsAttempt = "[User Certifications Load] Attempt",
	LoadUserCertificationsSuccess = "[User Certifications Load] Success",
	LoadUserCertificationsError = "[User Certifications Load] Error",
}
