import { Pipe, PipeTransform } from "@angular/core";
import { IAppState } from "@app/store";
import { Store } from "@ngrx/store";

@Pipe({
	name: "phone"
})
export class PhonePipe implements PipeTransform {

	isEmea: boolean;
	constructor(store: Store<IAppState>) {
	}

	transform(value: string): any {
		if (this.isEmea || !value) { return value; }
		const stripped = value.replace(/\D/g, "");
		return `(${stripped.substr(0, 3)}) ${stripped.substr(3, 3)}-${stripped.substr(6, 4)}`;
	}

}
