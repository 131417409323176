import { Pipe, PipeTransform } from "@angular/core";
import { Portals } from "@app/shared/shared.models";

@Pipe({
	name: "control4Visible"
})
export class Control4VisiblePipe implements PipeTransform {

	transform(value: Portals) {
		return (value & (Portals.DealerUs | Portals.DealerUs)) !== 0;
	}

}
