import { Component, OnInit } from "@angular/core";
import { IAppState } from "@app/store";
import { AddOrderLifecycleEventAttempt, getCurrentCartItemTotal } from "@app/store/cart";
import { currentCanPurchase } from "@app/store/user";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";

@Component({
	selector: "c4-header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {
	cartQuantity$: Observable<number>;
	canPurchase$: Observable<boolean>;

	constructor(readonly store: Store<IAppState>) { }

	ngOnInit() {
		this.cartQuantity$ = this.store.pipe(select(getCurrentCartItemTotal));
		this.canPurchase$ = this.store.pipe(select(currentCanPurchase));
	}

	trackLifecycleEvent() {
		this.store.dispatch(new AddOrderLifecycleEventAttempt({ action: "GoToCart", actionSource: "HeaderCartWidget" }));
	}

	showMenu() {
		const exists = document.body.classList.contains("showmenu");
		if (exists) {
			document.body.classList.remove("showmenu");
		} else {
			document.body.classList.add("showmenu");
		}
	}
}
