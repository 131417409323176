import { Component, Input, OnInit } from "@angular/core";
import { IProductLightModel } from "@app/products/products.models";

@Component({
	selector: "c4-typeahead-result",
	templateUrl: "./typeahead-result.component.html",
	styleUrls: ["./typeahead-result.component.scss"],
})
export class TypeaheadResultComponent implements OnInit {

	@Input() item: any | IProductLightModel;
	@Input() itemID: string = null;
	@Input() field = "id";
	isProduct = false;

	constructor() { }

	ngOnInit() {
		this.isProduct = this.itemIsProduct(this.item);
	}

	itemIsProduct(item: any): item is IProductLightModel {
		return typeof (item) === "object" && "sku" in item;
	}

	displayValue(item: any | IProductLightModel) {
		if (typeof (item) === "string") {
			return item;
		} else {
			return (this.itemID ? `(${item[this.itemID]}) ` : "") + item[this.field];
		}
	}

}
