import { Pipe, PipeTransform } from "@angular/core";

const shortCode = ["", "K", "M", "B"];
@Pipe({
	name: "shortCurrency"
})
export class ShortCurrencyPipe implements PipeTransform {
	transform = shortCurrencyPipeTransform;
}

export function shortCurrencyPipeTransform(value: number, currency = "", precision = 0): any {
	let val = value;

	let idx = 0;
	while (value > 1000) {
		value = Math.floor(value / 1000);
		idx++;
	}

	const pow = Math.pow(10, idx * 3);
	const precPow = Math.pow(10, precision);
	if (pow > 1) {
		val = Math.floor(val / (pow / precPow)) / precPow;
	}
	let res = (val).toFixed(precision);
	if (precision > 0) { // If we have a decimal, remove any excess 0s (and decimal place if needed)
		res = res.replace(/\.?0+$/, "");
	}
	return `${currency}${res}${shortCode[idx]}`;
}
