import { IUser } from "@app/account/account.models";
import { ngStoreCacheItem, objectUpdate } from "@app/shared/helpers";
import { AuthActions, AuthActionType } from "@app/store/auth";
import { UserActions, UserActionTypes } from "@app/store/user/user.actions";
import { userAdapter } from "@app/store/user/user.adapter";
import { EntityState } from "@ngrx/entity";
import { CartActions, CartActionType } from "../cart";
import { UserSalesforceContactActions, UserSalesforceContactActionTypes } from "../sf-contact";

export interface UserState extends EntityState<IUser> {
	currentId: string;
	activeId: string;
	impersonatedId?: string;
}

export const initialState: UserState = userAdapter.getInitialState({
	currentId: null,
	activeId: null,
	impersonatedId: null,
});

export function userReducer(
	state = initialState,
	action: UserActions | AuthActions | UserSalesforceContactActions | CartActions,
): UserState {
	switch (action.type) {
		case UserActionTypes.LoadSuccess:
			return userAdapter.upsertOne(ngStoreCacheItem(action.payload, 2, "hours"), state);
		case UserActionTypes.SaveUserProfileSuccess:
			const user = action.user;

			return userAdapter.updateOne({
				id: user.userId, changes: {
					firstName: user.firstName,
					lastName: user.lastName,
					email: user.email,
					workEmailVerified: user.workEmailVerified,
				},
			}, state);
		case UserActionTypes.SaveUserSuccess:
			return userAdapter.upsertOne(ngStoreCacheItem(action.payload, 2, "hours"), state);
		case UserActionTypes.RemoveSuccess:
			return userAdapter.updateOne({ id: action.payload, changes: { isActive: false, enabled: false } }, state);
		case UserActionTypes.SaveBusinessRoleSuccess:
			return userAdapter.updateOne({
				id: action.payload.userId, changes: {
					primaryRole: action.payload.primaryRole,
				},
			}, state);
		case AuthActionType.IframeLoginSuccess:
		case AuthActionType.LoginSuccess:
			const newState = objectUpdate(state, {
				currentId: action.payload.userId,
				activeId: action.payload.userId,
			});

			return action.payload.user ? userAdapter.upsertOne(ngStoreCacheItem(action.payload.user, 2, "hours"), newState) : newState;
		case AuthActionType.ImpersonateSuccess:
			const impersonateInfo = objectUpdate(state, {
				currentId: action.payload.userId,
				impersonatedId: action.payload.userId,
			});

			return userAdapter.upsertOne(ngStoreCacheItem(action.payload.user, 2, "hours"), impersonateInfo);
		case AuthActionType.MsrpImpersonateSuccess:
			return objectUpdate(state, {
				currentId: action.payload.userId,
				impersonatedId: action.payload.userId,
			});
		case AuthActionType.UnimpersonateSuccess:
			return objectUpdate(state, {
				currentId: state.activeId,
				impersonatedId: undefined,
			});
		case UserSalesforceContactActionTypes.LoadUserSalesforceInfoSuccess:
			return action.payload.contact
				? userAdapter.updateOne({
					id: action.payload.c4UserId,
					changes: { technicianId: action.payload.contact.technicianId },
				}, state)
				: state;
		case CartActionType.ChangeActiveCartSuccess:
		case CartActionType.LoadCurrentCartSuccess:
			if (state.entities[state.currentId] && state.entities[state.currentId].activeCartId === action.payload.orderId) {
				return state;
			}

			return userAdapter.updateOne({ id: state.currentId, changes: { activeCartId: action.payload.orderId } }, state);
		case CartActionType.OrderPlacedSuccess:
			return userAdapter.updateOne({ id: state.currentId, changes: { activeCartId: 0 } }, state);
		default: {
			return state;
		}
	}
}
