import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FeatureFlags } from "@app/account/account.models";
import { AuthService } from "@app/services/auth.service";
import { PasswordService } from "@app/services/password.service";
import { IAppState } from "@app/store";
import { isFeatureFlagEnabled } from "@app/store/account";
import { selectActiveUser } from "@app/store/user";
import { select, Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { map, tap } from "rxjs/operators";

@Component({
	selector: "c4-change-password",
	templateUrl: "./change-password.component.html",
	styleUrls: ["./change-password.component.scss"],
})

export class ChangePasswordComponent implements OnInit, OnDestroy {
	@Output() display = new EventEmitter<boolean>();
	loginName: string;
	authenticated$: Observable<boolean>;
	isCognitoChangePasswordOn$: Observable<boolean>;

	current: string;

	private _password: string;
	get password() {
		return this._password;
	}
	set password(val: string) {
		this._password = val;
		this.passwordService.validate(this.password, this.confirm);
	}

	private _confirm: string;
	get confirm() {
		return this._confirm;
	}
	set confirm(val: string) {
		this._confirm = val;
		this.passwordService.validate(this.password, this.confirm);
	}

	successMessage: string;

	destroyed$ = new Subject<{}>();

	constructor(readonly store: Store<IAppState>, readonly authService: AuthService, readonly route: ActivatedRoute, readonly passwordService: PasswordService) { }

	ngOnInit() {
		this.authenticated$ = this.store.pipe(select(selectActiveUser), tap(user => this.loginName = user.loginName), map(user => !!user));
		this.isCognitoChangePasswordOn$ = this.store.pipe(select(isFeatureFlagEnabled(FeatureFlags.PersistPasswordChange, false)));
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}

	reset(): void {
		if (this.passwordService.validate(this.password, this.confirm)) {
			return;
		}

		this.passwordService.reset(this.loginName, this.current, this.password).subscribe(result => {
			this.password = null;
			this.confirm = null;

			if (result.success) {
				this.successMessage = "Your password has been reset successfully.";

				setTimeout(() => this.display.emit(false), 3000);
			} else {
				this.passwordService.errors.push("There was a problem resetting your password.");
			}
		});
	}
	cancel() {
		this.display.emit(false);
	}
}
