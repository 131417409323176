import { Injectable } from "@angular/core";
import { CategoryService } from "@app/services/category.service";
import { getNgStoreCacheItem } from "@app/shared/helpers";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action, select, Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, filter, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { IAppState } from "../app.reducer";
import { CategoryActionType, LoadCategoryAttempt, LoadCategoryError, LoadCategorySuccess } from "./product-category.actions";
import { categorySelectEntities } from "./product-category.selectors";

@Injectable()
export class CategoryEffects {
	constructor(
		private actions$: Actions,
		private store: Store<IAppState>,
		private categoryService: CategoryService
	) { }

	@Effect()
	loadCategory$: Observable<Action> = this.actions$.pipe(
		ofType<LoadCategoryAttempt>(CategoryActionType.LoadCategoryAttempt),
		withLatestFrom(this.store.pipe(select(categorySelectEntities))),
		filter(([action, entities]) => !getNgStoreCacheItem(entities[action.payload])),
		mergeMap(([action, _]) => {
			return this.categoryService
				.getCategory(action.payload)
				.pipe(
					map((info) => new LoadCategorySuccess(info)),
					catchError((err) => of(new LoadCategoryError({ error: err.message || err.statusText })))
				);
		})
	);
}
