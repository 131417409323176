import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UserService } from "@app/services/user.service";
import { IAppState } from "@app/store";
import { selectCurrentUserId } from "@app/store/user";
import { getCurrentUserCertifications, LoadUserCertificationsAttempt } from "@app/store/user-certifications";
import { CertificationType } from "@app/training/training.models";
import { select, Store } from "@ngrx/store";
import { combineLatest, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: "c4-static-training-content",
	templateUrl: "./training-content.component.html",
	styleUrls: ["./training-content.component.scss"],
})
export class TrainingContentComponent implements OnInit, OnDestroy {
	@Input() certificationType: CertificationType;
	@Input() showWhenCertified: boolean;
	@Input() hideWhenCertified: boolean;
	@Input() showWhenEnrolled: boolean;
	@Input() hideWhenEnrolled: boolean;
	@Input() loadCerts: boolean;

	show: boolean;
	userId: string;
	destroyed$ = new Subject<{}>();

	constructor(readonly store: Store<IAppState>, readonly userService: UserService) { }

	ngOnInit() {
		combineLatest([
			this.store.pipe(select(selectCurrentUserId)),
			this.store.pipe(select(getCurrentUserCertifications)),
		]).pipe(
			takeUntil(this.destroyed$),
		).subscribe(([userId, certs]) => {
			this.userId = userId;

			if (certs) {
				const cert = this.userService.getCertificate(certs, this.certificationType);
				this.show = true;
				if (this.showWhenCertified) {
					this.show = !!(this.show && cert && cert.completionDate);
				} else if (this.hideWhenCertified) {
					this.show = this.show && !cert || (cert && !cert.completionDate);
				}

				if (this.showWhenEnrolled) {
					this.show = !!(this.show && cert && cert.enrollmentId > 0);
				} else if (this.hideWhenEnrolled) {
					this.show = this.show && !cert || (cert && !cert.enrollmentId || cert.enrollmentId <= 0);
				}
			} else if (this.loadCerts && this.userId) {
				this.store.dispatch(new LoadUserCertificationsAttempt(this.userId));
			}
		});
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}
}
