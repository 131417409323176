import { ngStoreCacheItem } from "@app/shared/helpers";
import { IContractModel } from "@app/shared/shared.models";
import { ContractActions, ContractActionType } from "@app/store/contract";
import { contractAdapter } from "@app/store/contract/contract.adapter";
import { EntityState } from "@ngrx/entity";
import { AuthActions, AuthActionType } from "../auth";


export interface IContractState extends EntityState<IContractModel> { }

export const initialState: IContractState = contractAdapter.getInitialState();

export function contractReducer(
	state = initialState,
	action: ContractActions | AuthActions
): IContractState {
	switch (action.type) {
		case AuthActionType.ImpersonateSuccess:
		case AuthActionType.UnimpersonateSuccess:
			return contractAdapter.removeAll(state);
		case ContractActionType.LoadSuccess:
			return contractAdapter.upsertOne(ngStoreCacheItem(action.payload, 6, "hours"), state);
		case ContractActionType.ContractSigned:
			return contractAdapter.updateOne({ id: action.id, changes: { isSigned: true } }, state);
		default: {
			return state;
		}
	}
}