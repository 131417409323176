import { ngStoreCacheItem } from "@app/shared/helpers";
import { EntityState } from "@ngrx/entity";
import { ICountryCode } from "../address/address.models";
import { CountryCodeActions, CountryCodeActionTypes } from "./country-code.actions";
import { countryCodeAdapter } from "./country-code.adapter";

export interface ICountryCodeState extends EntityState<ICountryCode> { }

export const initialState: ICountryCodeState = countryCodeAdapter.getInitialState();

export function countryCodesReducer(
	state = initialState,
	action: CountryCodeActions
): ICountryCodeState {
	switch (action.type) {
		case CountryCodeActionTypes.LoadCountryCodesSuccess:
			return countryCodeAdapter.upsertMany(action.payload.map(code => ngStoreCacheItem(code, 12, "hours")), state);
		default: {
			return state;
		}
	}
}