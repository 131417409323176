import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IMedia, MediaType, Portals } from "@app/shared/shared.models";

@Injectable({
	providedIn: "root"
})
export class MediaService {

	constructor(private http: HttpClient) { }

	private notFoundUrl = "https://res.cloudinary.com/control4/#transforms#/C4-ImageNotAvailable-DarkerLogo";

	saveMedia(media: IMedia) {
		return this.http.post<IMedia>("api/media/SaveMedia", media);
	}

	saveBulkMedia(media: IMedia[]) {
		return this.http.post<IMedia[]>("api/media/SaveBulkMedia", media);
	}

	deleteMedia(id: number) {
		const params = new HttpParams().append("id", id.toString());
		return this.http.delete<boolean>("api/media/deleteMedia", { params });
	}

	getStatuses() {
		return this.http.get<IMedia[]>("api/media/getCompanyStatusImages");
	}

	getMediaByQuery(primaryId: string, mediaType?: MediaType, portal?: Portals) {
		let params = new HttpParams().append("primaryId", primaryId);
		if (mediaType) {
			params = params.append("mediaType", JSON.stringify(mediaType));
		}
		if (portal) {
			params = params.append("portal", JSON.stringify(portal));
		}
		return this.http.get<IMedia[]>("api/media/getMediaByQuery", { params });
	}

	transformUrl(media: IMedia, transformations?: string, isThumbnail = false) {
		let url: string;
		if (media) {
			url = (isThumbnail ? media.thumbnailUrl : media.url) || media.url || this.notFoundUrl;
		} else {
			url = this.notFoundUrl;
		}

		let transforms = !media || this.isImage(media.mediaTypeId) && media.primaryId !== "Logos" ? "e_trim:1/" : "";

		if (transformations) {
			transforms += transformations.split(",").map((item) => item.replace("=", "_").trim()).join(",");
		}

		url = url.replace("#transforms#", transforms);

		return url;
	}

	sortMedia(media: IMedia[]) {
		return this.http.post<boolean>("api/media/SortMedia", media);
	}

	private isImage(type: MediaType): boolean {
		switch (type) {
			case MediaType.Attachment:
			case MediaType.Downloads:
			case MediaType.Documents:
			case MediaType.Firmware:
			case MediaType.Video:
				return false;
			case MediaType.Image:
			case MediaType.SpecSheet:
			default:
				return true;
		}
	}

}