import { Component, ComponentRef, Input, OnDestroy, OnInit } from "@angular/core";
import { ICartItemModel } from "@app/checkout/checkout.models";
import { IProductView } from "@app/products/products.models";
import { MediaService } from "@app/services/media.service";
import { ProductService } from "@app/services/product.service";
import { IMedia } from "@app/shared/shared.models";
import { IAppState } from "@app/store";
import { getCurrentCurrencySymbol } from "@app/store/account";
import { getCurrentBrandName, getIsTabletOrSmaller } from "@app/store/app-info/app-info.selectors";
import { selectIsMsrp } from "@app/store/auth";
import { getProductDetailsBySku, LoadProductDetailsAttempt } from "@app/store/product-details";
import { select, Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { IPopupComponent } from "../popups.models";

@Component({
	selector: "c4-quick-view-popup",
	templateUrl: "./quick-view-popup.component.html",
	styleUrls: ["./quick-view-popup.component.scss"],
})
export class QuickViewPopupComponent implements OnInit, OnDestroy, IPopupComponent {
	close: () => void;
	self: ComponentRef<any>;

	sku: string;
	displaySku: string;
	product: IProductView;

	currency$: Observable<string>;
	brandName$: Observable<string>;
	isMobile$: Observable<boolean>;

	sliderImages: IMedia[];
	reloadImages: boolean;

	isMsrp$: Observable<boolean>;

	private _customizedSku: string;
	@Input() editedCartItem: ICartItemModel;
	@Input() category: number;

	@Input() get customizedSku() {
		return this._customizedSku;
	}
	set customizedSku(val) {
		if (val && val !== this._customizedSku) {
			this._customizedSku = val;
			this.displaySku = val;

			this.mediaService.getMediaByQuery(val).subscribe(result => {
				this.setupProductImages(result);
			});
		}
	}

	private destroyed$ = new Subject<{}>();

	constructor(readonly store: Store<IAppState>, readonly mediaService: MediaService, readonly productService: ProductService) { }

	ngOnInit() {
		this.displaySku = this.sku;

		this.currency$ = this.store.pipe(select(getCurrentCurrencySymbol));
		this.brandName$ = this.store.pipe(select(getCurrentBrandName));
		this.isMobile$ = this.store.pipe(select(getIsTabletOrSmaller));
		this.isMsrp$ = this.store.pipe(select(selectIsMsrp));

		this.store.pipe(
			select(getProductDetailsBySku(this.sku)),
			takeUntil(this.destroyed$),
		).subscribe(product => {
			if (!product) {
				this.store.dispatch(new LoadProductDetailsAttempt({ sku: this.sku }));

				return;
			}
			this.product = product;
			this.customizedSku = this.sku;
		});
	}

	private setupProductImages(media: IMedia[]): void {
		const images = this.productService.getImages(media);

		if (images && images.length) {
			this.sliderImages = images;
			this.reloadImages = true;
		}
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}

}
