import { IBakpakCodes } from "@app/account/account.models";
import { IError } from "@app/shared/shared.models";
import { Action } from "@ngrx/store";

export class LoadBakpakCodesAttempt implements Action {
	readonly type = BakpakCodesActionTypes.LoadBakpakCodesAttempt;
	constructor() { }
}

export class LoadBakpakCodesSuccess implements Action {
	readonly type = BakpakCodesActionTypes.LoadBakpakCodesSuccess;
	constructor(public payload: IBakpakCodes) { }
}

export class LoadBakpakCodesError implements Action {
	readonly type = BakpakCodesActionTypes.LoadBakpakCodesError;
	constructor(public payload: IError) { }
}

export type BakpakCodesActions =
	LoadBakpakCodesAttempt
	| LoadBakpakCodesSuccess
	| LoadBakpakCodesError;

export enum BakpakCodesActionTypes {
	LoadBakpakCodesAttempt = "[Bakpak Codes Load] Attempt",
	LoadBakpakCodesSuccess = "[Bakpak Codes Load] Success",
	LoadBakpakCodesError = "[Bakpak Codes Load] Error",
}
