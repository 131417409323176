import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AccountService } from "@app/services/account.service";
import { ISalesContactsModel } from "@app/shared/shared.models";
import { getCurrentSalesContacts, LoadSalesContactsError, LoadSalesContactsSuccess, SalesContactActionTypes } from "@app/store/sales-contacts";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action, select, Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, filter, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { IAppState } from "../app.reducer";

@Injectable()
export class SalesContactsEffects {
	constructor(
		private actions$: Actions,
		private accountService: AccountService,
		private store: Store<IAppState>,
	) { }

	@Effect()
	loadSalesContacts$: Observable<Action> = this.actions$.pipe(
		ofType(SalesContactActionTypes.LoadSalesContactsAttempt),
		withLatestFrom(this.store.pipe(select(getCurrentSalesContacts))),
		filter(([action, contacts]) => !contacts),
		mergeMap(() => {
			return this.accountService
				.getSalesContacts()
				.pipe(
					map((info: ISalesContactsModel) => new LoadSalesContactsSuccess(info)),
					catchError((err: HttpErrorResponse) => of(new LoadSalesContactsError({ error: err.message || err.statusText }))),
				);
		}),
	);
}
