import { Component, Input, OnInit } from "@angular/core";
import { SearchResultItem, SearchResultType } from "../search.models";

@Component({
	selector: "c4-search-item",
	templateUrl: "./search-item.component.html",
	styleUrls: ["./search-item.component.scss"],
})
export class SearchItemComponent implements OnInit {

	@Input() item: SearchResultItem;
	resultType = SearchResultType;

	constructor() { }

	ngOnInit() {
	}

}
