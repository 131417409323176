export interface SearchResultItem<T = any> {
	title: string;
	url: string;
	summary: string;
	category: string;
	resultType: SearchResultType;
	data: T;
}

export enum SearchResultType {
	None,
	Product,
	Video,
	Page,
	Media,
	Download,
}

export interface SearchCategory {
	icon: string;
	value: string;
	label: string;
}

export const searchCategories: SearchCategory[] = [
	{ label: "Documents", value: "Documents", icon: "fal fa-file" },
	{ label: "Design Services", value: "DesignServices", icon: "fal fa-pencil-ruler" },
	{ label: "Downloads", value: "Downloads", icon: "fal fa-arrow-to-bottom" },
	{ label: "Knowledgebase", value: "Knowledgebase", icon: "fal fa-info-square" },
	{ label: "Products", value: "Products", icon: "fal fa-shopping-cart" },
	{ label: "Marketing", value: "Marketing", icon: "fal fa-rocket" },
	{ label: "Support", value: "Support", icon: "fal fa-question-circle" },
	{ label: "Education", value: "Training", icon: "fal fa-graduation-cap" },
	{ label: "What's New", value: "New", icon: "fal fa-rss" },
	{ label: "Videos", value: "Videos", icon: "fal fa-play-circle" },
];