import { Component, Inject, OnInit, ViewContainerRef } from "@angular/core";
import { PopupService } from "@app/services/popup.service";
import { select, Store } from "@ngrx/store";
import { combineLatest, fromEvent, Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, map, startWith } from "rxjs/operators";
import { FeatureFlags } from "./account/account.models";
import { EditToolingService } from "./services/edit-tooling.service";
import { IFrameService } from "./services/iframe.service";
import { SettingsService } from "./services/settings.service";
import { TrackService } from "./services/track.service";
import { startsWithIgnoreCase } from "./shared/helpers";
import { Portals } from "./shared/shared.models";
import { IAppState } from "./store";
import { isFeatureFlagEnabled } from "./store/account";
import { LoadFeatureFlagsAttempt, LoadSettingsSuccess, SetScreenWidth } from "./store/app-info/app-info.actions";
import { getCurrentPortal } from "./store/app-info/app-info.selectors";
import { selectActiveUser, selectActiveUserId, selectImpersonatedUser } from "./store/user";

@Component({
	selector: "c4-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
	isFullStoryOn$: Observable<boolean>;
	fullStoryScript = `<script>
window['_fs_debug'] = false;
window['_fs_host'] = 'fullstory.com';
window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
window['_fs_org'] = '1AqY';
window['_fs_namespace'] = 'FS';
(function(m,n,e,t,l,o,g,y){
    if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
    g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
    o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script;
    y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
    g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
    g.anonymize=function(){g.identify(!!0)};
    g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
    g.log = function(a,b){g("log",[a,b])};
    g.consent=function(a){g("consent",!arguments.length||a)};
    g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
    g.clearUserCookie=function(){};
    g.setVars=function(n, p){g('setVars',[n,p]);};
    g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y];
    if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)};
    g._v="1.3.0";
})(window,document,window['_fs_namespace'],'script','user');
</script>`;
	fullStoryUserScript: string = null;

	constructor(
		@Inject(ViewContainerRef) private viewContainerRef: ViewContainerRef,
		readonly store: Store<IAppState>,
		private popupService: PopupService,
		private editToolingService: EditToolingService,
		private settingsService: SettingsService,
		private tracking: TrackService,
		private readonly iframeService: IFrameService,
	) { }

	ngOnInit() {
		this.tracking.initialize();
		this.popupService.setDefaultViewContainer(this.viewContainerRef);
		this.editToolingService.setDefaultViewContainer(this.viewContainerRef);
		this.store.pipe(select(getCurrentPortal)).subscribe(portal => {
			switch (portal) {
				case Portals.Pakedge:
					require("style-loader!../shared-assets/scss/layout-dealer.changes-pakedge.scss");
					break;
				case Portals.Triad:
					require("style-loader!../shared-assets/scss/layout-dealer.changes-triad.scss");
					break;
			}
		});

		// Making the store available for Cypress testing
		const w: any = window;
		if (w.Cypress) {
			w.store = this.store;
		}

		this.settingsService.load().subscribe(settings => this.store.dispatch(new LoadSettingsSuccess(settings)));

		fromEvent(window, "resize")
			.pipe(
				debounceTime(200),
				map(() => window.innerWidth),
				distinctUntilChanged(),
				startWith(window.innerWidth),
			).subscribe(width => {
				this.store.dispatch(new SetScreenWidth(width));
			},
			);

		this.isFullStoryOn$ = this.store.pipe(select(isFeatureFlagEnabled(FeatureFlags.FullStoryEnabled, false)));

		combineLatest([
			this.store.pipe(select(selectImpersonatedUser)),
			this.store.pipe(select(selectActiveUser)),
		]).subscribe(([impersonatedUser, activeUser]) => {
			if (activeUser) {
				if (activeUser.technicianId || (impersonatedUser && impersonatedUser.technicianId)) {
					if (!impersonatedUser) {
						this.fullStoryUserScript = `<script>FS.identify('${activeUser.technicianId}', { displayName: 'Technician Id: ${activeUser.technicianId}', accountId_str: '${activeUser.accountId}' });</script>`;
					} else {
						this.fullStoryUserScript = `<script>FS.identify('${activeUser.technicianId}', { displayName: 'Technician Id: ${activeUser.technicianId} as ${impersonatedUser.technicianId}', accountId_str: '${impersonatedUser.accountId}' });</script>`;
					}
				} else {
					this.fullStoryUserScript = `<script>FS.setUserVars({\"accountId_str\": ${activeUser.accountId}});</script>`;
				}
			}
			this.store.dispatch(new LoadFeatureFlagsAttempt());
		});

		this.store.pipe(
			select(selectActiveUserId),
		).subscribe(id => {
			if (!id && startsWithIgnoreCase(window.location.pathname, "/iframe")) {
				parent.postMessage("{\"expired\":\"" + "true" + "\"}", "https://qa.snapav.com");
			}
		});

		fromEvent(window, "click").subscribe(event => {
			setTimeout(this.iframeService.postHeightForParent, 200);
		});

	}

}
