import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedElementsModule } from "@app/shared-elements/shared-elements.module";
import { PremierContentComponent } from "./premier-content/premier-content.component";
import { StaticContentComponent } from "./static-content/static-content.component";
import { TrainingContentComponent } from "./training-content/training-content.component";

@NgModule({
	imports: [
		CommonModule,
		SharedElementsModule
	],
	declarations: [
		StaticContentComponent,
		PremierContentComponent,
		TrainingContentComponent
	],
	exports: [
		StaticContentComponent,
		PremierContentComponent,
		TrainingContentComponent
	]
})
export class StaticContentModule { }
