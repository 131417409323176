import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
	selector: "c4-wistia-iframe",
	templateUrl: "./wistia-iframe.component.html",
	styleUrls: ["./wistia-iframe.component.scss"]
})
export class WistiaIframeComponent implements OnInit {

	@Input() wistiaId: string;

	wistiaClasses = ["wistia_embed"];
	videoSource: SafeResourceUrl;

	constructor(private sanitizer: DomSanitizer) { }

	ngOnInit() {
		const src = `https://fast.wistia.net/embed/iframe/${this.wistiaId}?autoPlay=1&videoFoam=true`;
		this.videoSource = this.sanitizer.bypassSecurityTrustResourceUrl(src);
	}
}
