import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { RouterModule } from "@angular/router";
import { MarketingImagesComponent } from "@app/marketing/marketing-images/marketing-images.component";
import { PipesModule } from "@app/pipes/pipes.module";
import { SharedElementsModule } from "@app/shared-elements/shared-elements.module";
import { ContractComponent } from "@app/shared/contract/contract.component";
import { ControlColorComponent } from "@app/shared/control-color/control-color.component";
import { FeedbackComponent } from "@app/shared/feedback/feedback.component";
import { LatestDocumentationComponent } from "@app/shared/latest-documentation/latest-documentation.component";
import { LatestDriversComponent } from "@app/shared/latest-drivers/latest-drivers.component";
import { PropagandaComponent } from "@app/shared/propaganda/propaganda.component";
import { CommentComponent } from "@app/support/comment/comment.component";
import { KbSearchRedirectComponent } from "@app/support/kb/kb-search-redirect/kb-search-redirect.component";
import { LiveChatButtonComponent } from "@app/support/live-chat-button/live-chat-button.component";
import { LiveChatWindowComponent } from "@app/support/live-chat-window/live-chat-window.component";
import { RmaButtonComponent } from "@app/support/rma-button/rma-button.component";
import { SupportEmailComponent } from "@app/support/support-email/support-email.component";
import { SupportPhoneComponent } from "@app/support/support-phone/support-phone.component";
import { SupportQuestionComponent } from "@app/support/support-question/support-question.component";
import { CertificationBadgeComponent } from "./certification-badge/certification-badge.component";
import { LatestFirmwareComponent } from "./latest-firmware/latest-firmware.component";
import { LoadCompatibilityComponent } from "./load-compatibility/load-compatibility.component";
import { MyBakpakComponent } from "./my-bakpak/my-bakpak.component";
import { UserCertificationsComponent } from "./user-certifications/user-certifications.component";

@NgModule({
	imports: [CommonModule, PipesModule, FormsModule, RouterModule, SharedElementsModule, MatFormFieldModule, MatInputModule],
	declarations: [
		FeedbackComponent,
		ContractComponent,
		PropagandaComponent,
		LatestDocumentationComponent,
		LatestDriversComponent,
		LoadCompatibilityComponent,
		MarketingImagesComponent,
		LiveChatButtonComponent,
		LiveChatWindowComponent,
		SupportPhoneComponent,
		SupportEmailComponent,
		SupportQuestionComponent,
		CommentComponent,
		RmaButtonComponent,
		MyBakpakComponent,
		ControlColorComponent,
		LatestFirmwareComponent,
		KbSearchRedirectComponent,
		CertificationBadgeComponent,
		UserCertificationsComponent
	],
	exports: [
		FeedbackComponent,
		ContractComponent,
		PropagandaComponent,
		LatestDocumentationComponent,
		LatestDriversComponent,
		LoadCompatibilityComponent,
		MarketingImagesComponent,
		LiveChatButtonComponent,
		LiveChatWindowComponent,
		SupportPhoneComponent,
		SupportEmailComponent,
		SupportQuestionComponent,
		CommentComponent,
		RmaButtonComponent,
		MyBakpakComponent,
		ControlColorComponent,
		LatestFirmwareComponent,
		KbSearchRedirectComponent,
		CertificationBadgeComponent,
		UserCertificationsComponent
	]
})
export class SharedModule { }