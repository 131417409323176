import { objectUpdate } from "@app/shared/helpers";
import { Portals, Settings, SiteFeatureFlag } from "@app/shared/shared.models";
import { environment } from "@env/environment";
import { AppInfoActions, AppInfoActionTypes } from "./app-info.actions";

function getCurrentPortal(): Portals {
	console.log(document.referrer, window.location.href);
	// This will take the URL that was used before redirecting to the current page, unless if the previous page was the login page.
	// This is because the login page could have outdated styling, and we want to keep the styling updated.
	const url = document.referrer && document.referrer.indexOf("/login?") < 0 ? document.referrer : window.location.href;

	if (url.indexOf("pakedge.") >= 0) {
		return Portals.Pakedge;
	}
	if (url.indexOf("triadspeakers.") >= 0) {
		return Portals.Triad;
	}
	if (environment.isEmea) {
		return Portals.DealerEmea;
	}
	return Portals.DealerUs;
}

export interface IAppInfoState {
	currentPortal: Portals;
	screenWidth: number;
	featureFlags: SiteFeatureFlag[];
	settings: Settings;
}

export const initialState: IAppInfoState = {
	currentPortal: getCurrentPortal(),
	screenWidth: window.screen.width,
	featureFlags: [],
	settings: undefined,
};

export function appInfoReducer(
	state = initialState,
	action: AppInfoActions,
): IAppInfoState {
	switch (action.type) {
		case AppInfoActionTypes.SetScreenWidth:
			return objectUpdate(state, { screenWidth: action.width });
		case AppInfoActionTypes.LoadFeatureFlagsSuccess:
			return objectUpdate(state, { featureFlags: action.payload });
		case AppInfoActionTypes.LoadSettingsSuccess:
			return objectUpdate(state, { settings: action.payload });
		default: {
			return state;
		}
	}
}
