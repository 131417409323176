import { Component, ComponentRef, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ICatalogsCategory, IManufacturer, IProductLightModel } from "@app/products/products.models";
import { IPromoConditionModel } from "@app/promotions/promotion.models";
import { CmsProductService } from "@app/services/cms-product.service";
import { PromoService } from "@app/services/promo.service";
import { deepcopy } from "@app/shared/helpers";
import { IAppState } from "@app/store";
import { SuccessToast, WarningToast } from "@app/store/toast/toast.actions";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { IPopupComponent } from "../popups.models";

@Component({
	selector: "c4-promotion-condition-edit-popup",
	templateUrl: "./promotion-condition-edit-popup.component.html",
	styleUrls: ["./promotion-condition-edit-popup.component.scss"],
})
export class PromotionConditionEditPopupComponent implements IPopupComponent, OnInit, OnDestroy {
	close: () => void;

	self: ComponentRef<PromotionConditionEditPopupComponent>;

	@Input() condition: IPromoConditionModel;
	@Output() conditionRemoved = new EventEmitter<number>();
	@Output() conditionSaved = new EventEmitter<IPromoConditionModel>();
	@Input() valueMatchesList = false;

	private destroyed$ = new Subject<{}>();

	constructor(readonly promoService: PromoService, readonly store: Store<IAppState>, readonly cmsProductService: CmsProductService) { }

	ngOnInit() {
	}

	cancelEditCondition() {
		this.condition = null;
		this.self.destroy();
	}

	removeCondition() {
		if (confirm("Are you sure you would like to remove this condition?")) {
			this.promoService.removePromoCondition(this.condition.id).subscribe(result => {
				this.conditionRemoved.emit(this.condition.id);
				this.store.dispatch(new SuccessToast({ message: "Promo condition removed" }));
				this.cancelEditCondition();
			}, () => {
				this.store.dispatch(new WarningToast({ message: "There was a problem removing the promo condition." }));
			});
		}
	}

	saveCondition() {
		this.condition.id = this.condition.id ? this.condition.id : 0;
		const updatedCondition = deepcopy(this.condition) as IPromoConditionModel;

		if (this.condition.discountId > 0) {
			this.promoService.savePromoCondition(this.condition).subscribe(result => {
				if (result > 0) {
					updatedCondition.id = result;
					this.conditionSaved.emit(updatedCondition);
					this.store.dispatch(new SuccessToast({ message: "Promo condition saved successfully." }));
					this.cancelEditCondition();
				} else {
					this.store.dispatch(new WarningToast({ message: "There was a problem saving the promo condition." }));
				}
			}, (err: any) => {
				this.store.dispatch(new WarningToast({ message: `There was a problem saving the promo condition. ${err}` }));
			});
		} else {
			this.conditionSaved.emit(updatedCondition);
			this.cancelEditCondition();
		}
	}

	selectCategory = (item: ICatalogsCategory) => {
		this.condition.conditionValue = item.categoryId;
	}

	selectManufacturer = (item: IManufacturer) => {
		this.condition.conditionValue = item.id;
	}

	selectProduct = (item: IProductLightModel) => {
		this.condition.conditionValue = item.sku;
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}

}
