import { IProductView } from "@app/products/products.models";
import { IError } from "@app/shared/shared.models";
import { Action } from "@ngrx/store";

export class LoadProductDetailsAttempt implements Action {
	readonly type = ProductDetailsActionType.LoadProductDetailsAttempt;
	constructor(public payload: { sku: string }) { }
}

export class LoadProductDetailsSuccess implements Action {
	readonly type = ProductDetailsActionType.LoadProductDetailsSuccess;
	constructor(public payload: IProductView) { }
}

export class LoadProductDetailsError implements Action {
	readonly type = ProductDetailsActionType.LoadProductDetailsError;
	constructor(public payload: IError) { }
}

export class UpdateSelectedSku implements Action {
	readonly type = ProductDetailsActionType.UpdateSelectedSku;
	constructor(public payload: { sku: string }) { }
}

export class UpdateCustomizedSku implements Action {
	readonly type = ProductDetailsActionType.UpdateCustomizedSku;
	constructor(public payload: { sku: string }) { }
}

export class UpdateCmsProductDetailsSuccess implements Action {
	readonly type = ProductDetailsActionType.UpdateCmsProductDetailsSuccess;
	constructor(public payload: { sku: string }) { }
}

export class UpdateCmsBulkProductDetailsSuccess implements Action {
	readonly type = ProductDetailsActionType.UpdateCmsBulkProductDetailsSuccess;
	constructor(public payload: { skus: string[] }) { }
}

export type ProductDetailsActions =
	LoadProductDetailsAttempt
	| LoadProductDetailsSuccess
	| LoadProductDetailsError
	| UpdateSelectedSku
	| UpdateCustomizedSku
	| UpdateCmsProductDetailsSuccess
	| UpdateCmsBulkProductDetailsSuccess;

export enum ProductDetailsActionType {
	LoadProductDetailsAttempt = "[Product Details] Load Attempt",
	LoadProductDetailsSuccess = "[Product Details] Load Success",
	LoadProductDetailsError = "[Product Details] Load Error",
	UpdateSelectedSku = "[Product Details] Selected Sku Update",
	UpdateCustomizedSku = "[Product Details] Customized Sku Update",
	UpdateCmsProductDetailsSuccess = "[Product Details] CMS Product Details Update",
	UpdateCmsBulkProductDetailsSuccess = "[Product Details] CMS Bulk Product Details Update",
}