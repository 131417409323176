import { Component, OnDestroy, OnInit } from "@angular/core";
import { TrackService } from "@app/services/track.service";
import { getNgStoreCacheItem } from "@app/shared/helpers";
import { IAppState } from "@app/store";
import { getActiveUserAccount, getCurrentAccount, LoadAccountAttempt } from "@app/store/account";
import { LoadUserAttempt, selectActiveUser, selectCurrentUser } from "@app/store/user";
import { select, Store } from "@ngrx/store";
import { combineLatest, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: "c4-iframe-layout",
	templateUrl: "./iframe-layout.component.html",
	styleUrls: ["./iframe-layout.component.scss"],
})
export class IframeLayoutComponent implements OnInit, OnDestroy {

	isAuthenticated: boolean;

	private destroyed$ = new Subject<{}>();

	constructor(
		private store: Store<IAppState>,
		private tracking: TrackService,
	) {
		combineLatest([
			this.store.pipe(select(selectActiveUser)),
			this.store.pipe(select(getActiveUserAccount)),
		]).pipe(
			takeUntil(this.destroyed$),
		).subscribe(([actUser, actAccount]) => {
			if (actUser && !getNgStoreCacheItem(actUser)) {
				this.store.dispatch(new LoadUserAttempt({ userId: actUser.userId }));
			}
			if (actAccount && !getNgStoreCacheItem(actAccount)) {
				this.store.dispatch(new LoadAccountAttempt(actAccount.id));
			}
			this.tracking.updateUserTracking(actUser);
		});

		// Active and current must be kept separate to prevent conflicts with closing the dealer role popup
		combineLatest([
			this.store.pipe(select(selectCurrentUser)),
			this.store.pipe(select(getCurrentAccount)),
		]).pipe(
			takeUntil(this.destroyed$),
		).subscribe(([curUser, curAccount]) => {
			if (curUser && !getNgStoreCacheItem(curUser)) {
				this.store.dispatch(new LoadUserAttempt({ userId: curUser.userId }));
			}
			if (curAccount && !getNgStoreCacheItem(curAccount)) {
				this.store.dispatch(new LoadAccountAttempt(curAccount.id));
			}
			this.tracking.updateImpersonatedUserTracking(curUser);
		});
	}

	ngOnInit() {
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}
}
