import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IUser } from "@app/account/account.models";
import { AuthService } from "@app/services/auth.service";
import { PasswordService } from "@app/services/password.service";
import { IAppState } from "@app/store";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { IAuthenticatedLinkModel, ILogin } from "../auth.models";

@Component({
	selector: "c4-reset-password",
	templateUrl: "./reset-password.component.html",
	styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
	@Input() temporaryPassword: string;
	@Input() resetInfo: IAuthenticatedLinkModel;
	@Output() resetPassword = new EventEmitter<ILogin>();

	private _password: string;
	get password() {
		return this._password;
	}
	set password(val: string) {
		this._password = val;
		this.passwordService.validate(this.password, this.confirm);
	}

	private _confirm: string;
	get confirm() {
		return this._confirm;
	}
	set confirm(val: string) {
		this._confirm = val;
		this.passwordService.validate(this.password, this.confirm);
	}

	loginName: string;
	successMessage: string;
	verificationCode: string;

	destroyed$ = new Subject<{}>();

	constructor(readonly store: Store<IAppState>, readonly authService: AuthService, readonly route: ActivatedRoute, readonly passwordService: PasswordService) { }

	ngOnInit() {
		this.loginName = this.resetInfo && this.resetInfo.user ? this.resetInfo.user.loginName : null;
		this.passwordService.validate(this.password, this.confirm);
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}

	reset(): void {
		const resetId = this.resetInfo ? this.resetInfo.id : null;
		const resetUser: IUser = this.resetInfo ? this.resetInfo.user : null;

		this.passwordService.reset(this.loginName || resetUser.loginName, this.temporaryPassword, this.password, resetId, this.verificationCode).subscribe(result => {
			if (result.success) {
				this.successMessage = "Your password has been reset successfully.";

				const username = this.loginName ? this.loginName : resetUser.loginName;
				const loginModel = <ILogin>{ username, password: this.password };
				this.password = null;
				this.confirm = null;
				setTimeout(() => this.resetPassword.emit(loginModel), 3000);
			} else {
				this.passwordService.errors.push("There was a problem resetting your password.");
			}
		});
	}
}
