import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { IUser } from "@app/account/account.models";
import { IAppState } from "@app/store";
import { selectActiveUser } from "@app/store/user";
import { environment } from "@env/environment";
import { select, Store } from "@ngrx/store";

@Injectable({
	providedIn: "root"
})
export class StaticPageFormsService {
	private activeUser: IUser;
	private securityRoles: string;
	commonFeedbackDisplay = new EventEmitter<boolean>();

	constructor(readonly http: HttpClient, store: Store<IAppState>) {
		store.pipe(select(selectActiveUser)).subscribe((user) => this.activeUser = user);
	}

	/**
	* @param data The following variables are pre-populated
	* * firstName (active user)
	* * lastName (active user)
	* * email (active user)
	* * dealerID (active user)
	* * portalRoles (active user)
	* * pageURL (window.location.href)
	*/

	postForward(data: IContactUsData | IShowroomData) {
		const val = Object.assign({
			firstName: this.activeUser.firstName,
			lastName: this.activeUser.lastName,
			email: this.activeUser.email,
			phone: this.activeUser.dayPhone,
			dealerID: this.activeUser.accountId,
			portalRoles: this.securityRoles,
			pageURL: window.location.href
		}, data);
		return this.http.post(environment.postForwarderUrl, val, { responseType: "text", headers: { Authorization: `Basic cG9zdGZvcndhcmRlcjo4SU1Wc3ZnTlBLV1g=` } });
	}
}

export interface IContactUsData {
	postUrl: string;
	comments?: string;
	subject?: string;
	firstName?: string;
	email?: string;
	phone?: string;
	dealerID?: string;
	portalRoles?: string;
	pageURL?: string;
}

export interface IShowroomData {
	businessName?: string;
	showroomContactName?: string;
	phoneNumber?: string;
	dealerId?: string;
	email?: string;
	showroomComments?: string;
	postUrl: string;
}