import { Component, ComponentRef, EventEmitter, Input, OnInit } from "@angular/core";
import { TrainingService } from "@app/services/training.service";
import { IAppState } from "@app/store";
import { WarningToast } from "@app/store/toast/toast.actions";
import { IProctorUStudent, IProctorUTimeZone } from "@app/training/training.models";
import { Store } from "@ngrx/store";
import { IPopupComponent } from "../popups.models";

@Component({
	selector: "c4-proctoru-profile-popup",
	templateUrl: "./proctoru-profile-popup.component.html",
	styleUrls: ["./proctoru-profile-popup.component.scss"]
})
export class ProctoruProfilePopupComponent implements IPopupComponent, OnInit {
	close: () => void;
	self: ComponentRef<ProctoruProfilePopupComponent>;

	@Input() student: IProctorUStudent;
	@Input() profileConfirmed: EventEmitter<IProctorUStudent>;

	timeZones: IProctorUTimeZone[];
	selectedTimeZone: IProctorUTimeZone;

	constructor(private readonly store: Store<IAppState>, private readonly trainingService: TrainingService) { }

	ngOnInit() {
		this.trainingService.getTimeZones().subscribe((result) => {
			this.timeZones = result;
		});
	}

	createStudent() {
		const newTab = window.open("about:blank", "_blank");

		this.student.timeZoneId = this.selectedTimeZone.id;
		this.trainingService.createStudentProfile(this.student).subscribe((result) => {
			if (result) {
				this.student.hasStudentProfile = true;
				this.profileConfirmed.emit(this.student);
				this.trainingService.initiateProctorSso(newTab);
			} else {
				this.store.dispatch(new WarningToast({ message: "There was a problem creating your ProctorU student profile. Please try again later or contact training@control4.com." }));
			}
		});
	}
}
