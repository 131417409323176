import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'statusBadgeDownloadUrl'
})

export class StatusBadgeDownloadUrlPipe implements PipeTransform {

	transform(badgeType: string): string {
		return `//www.control4.com/docs/product/${badgeType}/internal-logo/english/latest`;
	}

}
