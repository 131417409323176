import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Filter, FilterGroup, PartFilter, SelectFilter } from "@app/cms/cms.models";
import { IVideoModel } from "@app/marketing/marketing.models";
import { IBulkEditProduct, ICatalogsCategory, IConnectedProductLightModel, IManufacturer, IMarketingDescription, IPartCustomizationsModel, IPartsMarketingRecommendedProduct, IPartsMarketingSuggestedProduct, IProductLightModel, IProductModel, IRecommendedCategoryModel, ISupportInfo } from "@app/products/products.models";
import { IGenericResponse, IMedia, Portals } from "@app/shared/shared.models";

@Injectable({
	providedIn: "root",
})
export class CmsProductService {
	readonly MaxFilterFlags = 32;

	constructor(private http: HttpClient) { }

	getProduct(sku: string) {
		return this.http.get<IProductModel>("api/productAdmin/GetProduct", { params: { sku } });
	}

	searchProducts = (sku: string, limit = 10, portalFilter?: Portals) => {
		return this.http.get<IProductModel[]>("api/ProductAdmin/SearchProducts", { params: { sku, limit: limit.toString(), portal: portalFilter ? portalFilter.toString() : "" } });
	}

	searchSkus = (sku: string, limit = 10, portalFilter?: Portals) => {
		return this.http.get<IProductModel[]>("api/ProductAdmin/SearchSkus", { params: { sku, limit: limit.toString(), portal: portalFilter ? portalFilter.toString() : "" } });
	}

	searchFilters = (search: string, limit = 10) => {
		return this.http.get<SelectFilter[]>("api/productAdmin/searchFilters", { params: { search, limit: limit.toString() } });
	}

	getFilterGroups() {
		return this.http.get<FilterGroup[]>("api/productAdmin/getFilterGroups");
	}

	saveFilterGroup(group: FilterGroup) {
		return this.http.post<IGenericResponse<FilterGroup>>("api/productAdmin/saveFilterGroup", group);
	}

	removeFilterGroup(id: number) {
		return this.http.delete<boolean>("api/productAdmin/removeFilterGroup", { params: { id: id.toString() } });
	}

	saveFilter(filter: Filter) {
		return this.http.post<Filter>("api/productAdmin/saveFilter", filter);
	}

	removeFilter(id: number) {
		return this.http.delete<boolean>("api/productAdmin/removeFilter", { params: { id: id.toString() } });
	}

	addPartFilter(filterId: number, sku: string) {
		return this.http.post<PartFilter>("api/productAdmin/addPartFilter", { filterId, sku });
	}

	removePartFilter(filterId: number, sku: string) {
		return this.http.delete<boolean>("api/productAdmin/removePartFilter", { params: { filterId: filterId.toString(), sku } });
	}

	getCategoriesByPartialName = (search: string, includeImage = false) => {
		let params = new HttpParams().append("search", search);
		params = params.append("includeImage", includeImage.toString());

		return this.http.get<ICatalogsCategory[]>("api/ProductAdmin/GetCategoriesByPartialName", { params });
	}

	saveSupportInfo(support: ISupportInfo[]) {
		return this.http.post<ISupportInfo[]>("api/productAdmin/SaveSupportInfo", support);
	}

	updateReleaseNotes(product: IProductModel) {
		return this.http.post<boolean>("api/ProductAdmin/UpdateReleaseNotes", product);
	}

	updateMarketingDescription(marketingDescription: IMarketingDescription) {
		return this.http.post("api/ProductAdmin/updateMarketingDescription", marketingDescription);
	}

	updateDescription(product: IProductModel) {
		return this.http.post("api/ProductAdmin/UpdateDescription", product);
	}

	saveProduct(product: IProductModel) {
		return this.http.post<boolean>("api/productAdmin/SaveProduct", product);
	}

	saveVideo(video: IVideoModel) {
		return this.http.post<IVideoModel>("api/productAdmin/SaveVideo", video);
	}

	deleteVideo(id: number) {
		const params = new HttpParams().append("id", id.toString());

		return this.http.delete<boolean>("api/productAdmin/DeleteVideo", { params });
	}

	saveRecommendedCategory(category: IRecommendedCategoryModel) {
		return this.http.post<IRecommendedCategoryModel>("api/productAdmin/SaveRecommendedCategory", category);
	}

	deleteRecommendedCategory(id: number) {
		const params = new HttpParams().append("id", id.toString());

		return this.http.delete("api/productAdmin/DeleteRecommendedCategory", { params });
	}

	sortRecommendedCategories(data: IRecommendedCategoryModel[]) {
		return this.http.patch("api/productAdmin/SortRecommendedCategories", data);
	}

	getRecommendedCategories(sku: string) {
		return this.http.get<IRecommendedCategoryModel[]>("api/productAdmin/GetRecommendedCategories", { params: { sku } });
	}

	getRecommendedProducts(sku: string) {
		return this.http.get<IConnectedProductLightModel[]>("api/productAdmin/GetRecommendedProducts", { params: { sku } });
	}

	saveRecommendedProduct(product: IPartsMarketingRecommendedProduct) {
		return this.http.post<IConnectedProductLightModel>("api/productAdmin/SaveRecommendedProduct", product);
	}

	deleteRecommendedProduct(id: number) {
		return this.http.post<boolean>("api/productAdmin/DeleteRecommendedProduct", id);
	}

	sortRecommendedProducts(products: IPartsMarketingRecommendedProduct[]) {
		return this.http.post<boolean>("api/productAdmin/SortRecommendedProducts", products);
	}

	getLightProducts(skus: string[]) {
		return this.http.get<IConnectedProductLightModel[]>("api/productAdmin/GetLightProducts", { params: { skus } });
	}

	getSuggestedProducts(sku: string) {
		return this.http.get<IConnectedProductLightModel[]>("api/productAdmin/GetSuggestedProducts", { params: { sku } });
	}

	saveSuggestedProduct(product: IPartsMarketingSuggestedProduct) {
		return this.http.post<IConnectedProductLightModel>("api/productAdmin/SaveSuggestedProduct", product);
	}

	deleteSuggestedProduct(id: number) {
		return this.http.post<boolean>("api/productAdmin/DeleteSuggestedProduct", id);
	}

	sortSuggestedProducts(products: IPartsMarketingSuggestedProduct[]) {
		return this.http.post<boolean>("api/productAdmin/SortSuggestedProducts", products);
	}

	getManufacturersByName = (name: string) => {
		return this.http.get<IManufacturer[]>("api/productAdmin/GetManufacturersByName", { params: { name } });
	}

	getProducts(skus: string[]) {
		return this.http.get<IProductModel[]>("api/ProductAdmin/GetProducts", { params: { skus } });
	}

	getProductsByManufacturer(manufacturerId: string) {
		return this.http.get<IProductModel[]>("api/productAdmin/GetProductsByManufacturer", { params: { manufacturerId } });
	}

	getProductsByCategory(categoryId: number) {
		const params = new HttpParams().append("categoryId", categoryId.toString());

		return this.http.get<IProductModel[]>("api/productAdmin/GetProductsByCategory", { params });
	}

	updateBulkShippingEstimateDescription(bulkEditProduct: IBulkEditProduct) {
		return this.http.post<boolean>("api/ProductAdmin/UpdateBulkShippingEstimateDescription", bulkEditProduct);
	}

	updateBulkVisibleInPortals(bulkEditProduct: IBulkEditProduct) {
		return this.http.post<boolean>("api/ProductAdmin/UpdateBulkVisibleInPortals", bulkEditProduct);
	}

	updateBulkFlags(bulkEditProduct: IBulkEditProduct) {
		return this.http.post<boolean>("api/ProductAdmin/UpdateBulkFlags", bulkEditProduct);
	}

	updateBulkCustomizations(bulkEditProduct: IBulkEditProduct) {
		return this.http.post<boolean>("api/ProductAdmin/UpdateBulkCustomizations", bulkEditProduct);
	}

	addBulkRecommendedProducts(bulkEditProduct: IBulkEditProduct) {
		return this.http.post<boolean>("api/productAdmin/AddBulkRecommendedProducts", bulkEditProduct);
	}

	updateBulkCategories(bulkEditProduct: IBulkEditProduct) {
		return this.http.post<boolean>("api/ProductAdmin/UpdateBulkCategories", bulkEditProduct);
	}

	updateBulkFilters(bulkEditProduct: IBulkEditProduct) {
		return this.http.post<boolean>("api/productAdmin/updateBulkFilters", bulkEditProduct);
	}

	bulkAddVideos(skus: string[], videos: any[]) {
		return this.http.post<boolean>("api/productAdmin/BulkAddVideos", { skus, videos });
	}

	updateBulkFeatures(bulkEditProduct: IBulkEditProduct) {
		return this.http.post<boolean>("api/ProductAdmin/UpdateBulkFeatures", bulkEditProduct);
	}

	addBulkRecommendedCategories(skus: string[], recommendedCategories: IRecommendedCategoryModel[]) {
		return this.http.post<boolean>("api/productAdmin/AddBulkRecommendedCategories", { skus, recommendedCategories });
	}

	updateBulkSupportInfo(bulkEditProduct: IBulkEditProduct) {
		return this.http.post<boolean>("api/ProductAdmin/UpdateBulkSupportInfo", bulkEditProduct);
	}

	updateBulkDescription(bulkEditProduct: IBulkEditProduct) {
		return this.http.post("api/ProductAdmin/UpdateBulkDescription", bulkEditProduct);
	}

	addBulkMedia(skus: string[], media: IMedia[]) {
		return this.http.post("api/productAdmin/AddBulkMediaToProducts", { skus, media });
	}

	syncEmeaPortalProducts(bulkEditProduct: IBulkEditProduct) {
		return this.http.post<boolean>("api/productAdmin/SyncEmeaPortalProducts", bulkEditProduct);
	}

	getFeaturedProducts(portal: Portals) {
		const params = new HttpParams().append("portal", JSON.stringify(portal));

		return this.http.get<IProductLightModel[]>("api/productAdmin/GetFeaturedProducts", { params });
	}

	saveFeaturedProduct(sku: string, portal: Portals) {
		return this.http.put<IProductLightModel>("api/productAdmin/AddFeaturedSkuByPortal", { sku, portal });
	}

	deleteFeaturedProduct(sku: string, portal: Portals) {
		let params = new HttpParams().append("portal", portal.toString());
		params = params.append("sku", sku);

		return this.http.delete<boolean | string>("api/productAdmin/DeleteFeaturedSkuByPortal", { params });
	}

	updateProductCustomizations(customizations: IPartCustomizationsModel) {
		return this.http.post("api/ProductAdmin/UpdateCustomizationOptions", customizations);
	}

	importSnapAvData(skus: string[]) {
		return this.http.post("api/productAdmin/ImportSnapProductMetadata", skus);
	}

	validateMedia(skus: string[], safeMode: boolean) {
		return this.http.post<IMedia[]>("api/productAdmin/media/validate", { skus, safeMode });
	}

	exportBasicProductInfo() {
		return this.http.get("api/productAdmin/export/basic-info", { responseType: "blob" });
	}

	exportProductWarranties() {
		return this.http.get("api/productAdmin/export/warranties", { responseType: "blob" });
	}

	exportProductSpecSheets() {
		return this.http.get("api/productAdmin/export/specs", { responseType: "blob" });
	}

	exportProductFilters() {
		return this.http.get("api/productAdmin/export/filters", { responseType: "blob" });
	}

	exportProductCategories() {
		return this.http.get("api/productAdmin/export/categories", { responseType: "blob" });
	}

	exportProductImages() {
		return this.http.get("api/productAdmin/export/images", { responseType: "blob" });
	}

	exportProductDownloads() {
		return this.http.get("api/productAdmin/export/downloads", { responseType: "blob" });
	}

	exportProductFirmware() {
		return this.http.get("api/productAdmin/export/firmware", { responseType: "blob" });
	}

	exportProductCustomizations() {
		return this.http.get("api/productAdmin/export/customizations", { responseType: "blob" });
	}

	exportProductCustomizationPairings() {
		return this.http.get("api/productAdmin/export/customizationPairings", { responseType: "blob" });
	}

	exportProductVideos() {
		return this.http.get("api/productAdmin/export/videos", { responseType: "blob" });
	}

	exportThirdPartyShipping() {
		return this.http.get("api/productAdmin/export/thirdPartyShipping", { responseType: "blob" });
	}

	exportUserProfileInfo() {
		return this.http.get("api/productAdmin/export/userProfileInfo", { responseType: "blob" });
	}
}