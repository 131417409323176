import { getNgStoreCacheItem } from "@app/shared/helpers";
import { getCurrentAccount } from "@app/store/account";
import { upgradeRequestFileAdapter } from "@app/store/upgrade-request-file/upgrade-request-file.adapter";
import { IUpgradeRequestFileState } from "@app/store/upgrade-request-file/upgrade-request-file.reducer";
import { createFeatureSelector, createSelector } from "@ngrx/store";

export const getUpgradeRequestFileState = createFeatureSelector<IUpgradeRequestFileState>("upgradeRequestFiles");

export const {
	selectIds: upgradeRequestFilesSelectIds,
	selectEntities: upgradeRequestFilesSelectEntities,
	selectAll: upgradeRequestFilesSelectAll,
	selectTotal: upgradeRequestFilesSelectTotal
} = upgradeRequestFileAdapter.getSelectors(getUpgradeRequestFileState);

const getAccountCache = createSelector(
	getUpgradeRequestFileState,
	(state) => state.loadedAccounts
);

const getCurrentAccountLoaded = createSelector(
	getCurrentAccount,
	getAccountCache,
	(account, cache) => account && cache && !!cache.find((item) => item.item === account.id && !!getNgStoreCacheItem(item))
);

export const getCurrentAccountUpgradeRequestFiles = createSelector(
	getCurrentAccount,
	getCurrentAccountLoaded,
	upgradeRequestFilesSelectAll,
	(account, loaded, items) => loaded && items && account ? items.filter(x => x.accountId === account.id) : undefined
);

export const getUpgradeRequestSubmitState = createSelector(
	getUpgradeRequestFileState,
	(state) => state.submitState
);