import { Pipe, PipeTransform } from "@angular/core";
import { IProductLightModel, IProductView } from "@app/products/products.models";

@Pipe({
	name: "productUrl"
})
export class ProductUrlPipe implements PipeTransform {
	transform(product: IProductView | IProductLightModel, categoryId?: number) {
		return product ? `/products/${categoryId || product.categoryId}/product/${product.sku}` : "";
	}
}
