import { Injectable } from "@angular/core";
import { IPartCustomColorOptionModel, IPartFinishOption } from "@app/products/products.models";
import { contains, startsWithIgnoreCase } from "@app/shared/helpers";

@Injectable({
	providedIn: "root"
})
export class ProductCustomizationService {

	paintFinishes: IPartFinishOption[] = [
		{ value: "Black", image: "black", type: "paint" },
		{ value: "White", image: "", type: "paint" },
		{ value: "Custom Color†", image: "", type: "paint" }
	];

	veneerOptions: IPartFinishOption[] = [
		{ value: "Anigre Fig QC", image: "anigre", type: "veneer" },
		{ value: "Ash White FC", image: "ashwhite", type: "veneer" },
		{ value: "Bamboo Narrow", image: "bamboo", type: "veneer" },
		{ value: "Birch White FC", image: "birchwhite", type: "veneer" },
		{ value: "Cherry American FC", image: "cherryamerican", type: "veneer" },
		{ value: "Douglas Fir", image: "douglasfir", type: "veneer" },
		{ value: "Ebony QC", image: "ebony", type: "veneer" },
		{ value: "Maple FC", image: "maple", type: "veneer" },
		{ value: "Maple Birds Eye", image: "maplebirdseye", type: "veneer" },
		{ value: "Mexican Rosewood", image: "mexicanrosewood", type: "veneer" },
		{ value: "Oak Red FC", image: "oakred", type: "veneer" },
		{ value: "Oak Red Rift", image: "oakredrift", type: "veneer" },
		{ value: "Oak White FC", image: "oakwhite", type: "veneer" },
		{ value: "Oak White Rift", image: "oakwhiterift", type: "veneer" },
		{ value: "Paldao Fig QC", image: "paldaofig", type: "veneer" },
		{ value: "Sapele FC", image: "sapelefc", type: "veneer" },
		{ value: "Sapele QC", image: "sapeleqc", type: "veneer" },
		{ value: "Teak FC", image: "teak", type: "veneer" },
		{ value: "Walnut FC", image: "walnut", type: "veneer" },
		{ value: "Zebrawood", image: "zebrawood", type: "veneer" },
		{ value: "Veneer Matching Service††", image: "", type: "veneer" }
	];

	constructor() { }

	finishIsCustomColor(finish: IPartFinishOption) {
		return finish && this.isCustomColor(finish.value);
	}

	cabinetColorIsCustom(color: IPartCustomColorOptionModel) {
		return color && this.isCustomColor(color.displayValue);
	}

	customVeneerMatch(finish: IPartFinishOption) {
		return finish && contains(finish.value, "Matching");
	}

	customColorMatch(manufacturer: string) {
		return manufacturer && contains(manufacturer, "Matching");
	}

	isCustomColor(color: string) {
		return color === "Custom Color†" || startsWithIgnoreCase(color, "custom:");
	}
}
