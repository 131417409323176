import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { IProductLightModel } from "@app/products/products.models";
import { Observable, of, Subject } from "rxjs";
import { debounceTime, switchMap, takeUntil } from "rxjs/operators";

@Component({
	selector: "c4-typeahead",
	templateUrl: "./typeahead.component.html",
	styleUrls: ["./typeahead.component.scss"],
})
export class TypeaheadComponent implements OnInit, OnDestroy {

	@Input() field = "id";
	@Input() placeholder: string;
	@Input() focus: boolean;
	@Input() getItems: (query: string) => Observable<any[]>;
	@Input() disable = false;
	@Input() required = false;
	@Input() autoActiveFirstOption = true;
	@Input() itemID: string = null;
	@Input() autoclear = true;

	@Output() searching = new EventEmitter<boolean>();
	@Output() selected = new EventEmitter<any>();
	@Output() cleared = new EventEmitter();
	@Output() inputChange = new EventEmitter<string>();
	@Output() inputMatchesList = new EventEmitter<boolean>();

	items: any[];
	@Input() input = "";
	inputStream = new Subject<string>();
	loading = false;

	private destroyed$ = new Subject<{}>();

	ngOnInit() {
		this.inputStream.pipe(
			debounceTime(300),
			switchMap((input: string) => input ? this.getItems(input) : of([])),
			takeUntil(this.destroyed$),
		).subscribe(items => {
			if (items) {
				this.items = items && items.length ? items : null;
				let isMatching = false;
				if (this.items) {
					isMatching = this.items.includes(this.input);
					this.inputMatchesList.emit(isMatching);
				}
				this.loading = false;
			}
			this.searching.emit(items.length > 0);
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

	isProduct(item: any | IProductLightModel): item is IProductLightModel {
		return true;
	}

	onChange() {
		this.loading = true;
		this.inputChange.emit(this.input);
		this.inputStream.next(this.input);
	}

	clearInput() {
		this.input = "";
		this.onChange();
		this.cleared.emit();
	}

	onSelected(item: any) {
		this.selected.emit(item);
		this.inputMatchesList.emit(true);
		this.items = [];
		if (this.autoclear) {
			this.input = "";
		}
	}
}