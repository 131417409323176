import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatOptionModule } from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { RouterModule } from "@angular/router";
import { CKEditorModule } from "@app/ckeditor/ckeditor.module";
import { PipesModule } from "@app/pipes/pipes.module";
import { ContractPopupComponent } from "@app/popups/contract-popup/contract-popup.component";
import { FirmwarePopupComponent } from "@app/popups/firmware-popup/firmware-popup.component";
import { ImpersonationPopupComponent } from "@app/popups/impersonation-popup/impersonation-popup.component";
import { PopupComponent } from "@app/popups/popup/popup.component";
import { ProductUpgradePopupComponent } from "@app/popups/product-upgrade-popup/product-upgrade-popup.component";
import { ProductsSharedModule } from "@app/products-shared/products-shared.module";
import { SharedElementsModule } from "@app/shared-elements/shared-elements.module";
import { StaticContentModule } from "@app/static-content/static-content.module";
import { AddFilterGroupPopupComponent } from "./add-filter-group-popup/add-filter-group-popup.component";
import { ConfirmPopupComponent } from "./confirm-popup/confirm-popup.component";
import { ControllerOsGroupEditPopupComponent } from "./controller-os-group-edit-popup/controller-os-group-edit-popup.component";
import { ControllerPresetFilterEditPopupComponent } from "./controller-preset-filter-edit-popup/controller-preset-filter-edit-popup.component";
import { ControllerTypeGroupEditPopupComponent } from "./controller-type-group-edit-popup/controller-type-group-edit-popup.component";
import { DealerRolePopupComponent } from "./dealer-role-popup/dealer-role-popup.component";
import { EngravingReviewPopupComponent } from "./engraving-review-popup/engraving-review-popup.component";
import { FirmwareVersionEditPopupComponent } from "./firmware-version-edit-popup/firmware-version-edit-popup.component";
import { MigrationScheduledPopupComponent } from "./migration-scheduled-popup/migration-scheduled-popup.component";
import { MobilePhoneUsagePopupComponent } from "./mobile-phone-usage-popup/mobile-phone-usage-popup.component";
import { ProctoruProfilePopupComponent } from "./proctoru-profile-popup/proctoru-profile-popup.component";
import { PromotionBenefitEditPopupComponent } from "./promotion-benefit-edit-popup/promotion-benefit-edit-popup.component";
import { PromotionConditionEditPopupComponent } from "./promotion-condition-edit-popup/promotion-condition-edit-popup.component";
import { QuickViewPopupComponent } from "./quick-view-popup/quick-view-popup.component";
import { SalesQuoteExportPopupComponent } from "./sales-quote-export-popup/sales-quote-export-popup.component";
import { SuggestedProductsPopupComponent } from "./suggested-products-popup/suggested-products-popup.component";
import { UpgradeReminderPopupComponent } from "./upgrade-reminder-popup/upgrade-reminder-popup.component";

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		PipesModule,
		FormsModule,
		SharedElementsModule,
		ProductsSharedModule,
		MatFormFieldModule,
		MatInputModule,
		MatAutocompleteModule,
		MatSelectModule,
		MatOptionModule,
		CKEditorModule,
		StaticContentModule
	],
	// Other than PopupComponent, these also need to be added to app.module entryComponents to allow dynamic generation
	declarations: [
		PopupComponent,
		MigrationScheduledPopupComponent,
		ImpersonationPopupComponent,
		FirmwarePopupComponent,
		ContractPopupComponent,
		ProductUpgradePopupComponent,
		MobilePhoneUsagePopupComponent,
		ConfirmPopupComponent,
		EngravingReviewPopupComponent,
		SuggestedProductsPopupComponent,
		QuickViewPopupComponent,
		ProctoruProfilePopupComponent,
		SalesQuoteExportPopupComponent,
		PromotionConditionEditPopupComponent,
		PromotionBenefitEditPopupComponent,
		AddFilterGroupPopupComponent,
		ControllerOsGroupEditPopupComponent,
		ControllerTypeGroupEditPopupComponent,
		ControllerPresetFilterEditPopupComponent,
		UpgradeReminderPopupComponent,
		DealerRolePopupComponent,
		FirmwareVersionEditPopupComponent,
	],
	exports: [
		ImpersonationPopupComponent,
		FirmwarePopupComponent,
		ContractPopupComponent,
		DealerRolePopupComponent,
		ProductUpgradePopupComponent,
		ConfirmPopupComponent,
		PromotionConditionEditPopupComponent,
		PromotionBenefitEditPopupComponent,
		FirmwareVersionEditPopupComponent,
	],
})
export class PopupsModule { }
