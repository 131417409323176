import { Injectable } from "@angular/core";
import { AccountService } from "@app/services/account.service";
import { DealerLocatorActionTypes, LoadDealerLocatorsError, LoadDealerLocatorsSuccess, SaveDealerLocatorAttempt, SaveDealerLocatorError, SaveDealerLocatorSuccess } from "@app/store/dealer-locator/dealer-locator.actions";
import { SuccessToast } from "@app/store/toast/toast.actions";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";

@Injectable()
export class DealerLocatorEffects {
	constructor(
		private actions$: Actions,
		private accountService: AccountService
	) { }

	@Effect()
	loadSubDealerLocators$: Observable<Action> = this.actions$.pipe(
		ofType(DealerLocatorActionTypes.LoadDealerLocatorsAttempt),
		mergeMap(() => {
			return this.accountService
				.getSubDealerLocators()
				.pipe(
					map((info) => new LoadDealerLocatorsSuccess(info)),
					catchError((err) => of(new LoadDealerLocatorsError({ error: err.message || err.statusText })))
				);
		})
	);

	@Effect()
	saveDealerLocator$: Observable<Action> = this.actions$.pipe(
		ofType<SaveDealerLocatorAttempt>(DealerLocatorActionTypes.SaveDealerLocatorAttempt),
		mergeMap((action) => {
			if (!action.payload.latitude) {
				action.payload.latitude = 0;
			}
			if (!action.payload.longitude) {
				action.payload.longitude = 0;
			}
			return this.accountService
				.modifySubDealerLocator(action.payload)
				.pipe(
					mergeMap((success): Action[] => success
						? [new SaveDealerLocatorSuccess(action.payload), new SuccessToast({ message: "Your changes have been saved." })]
						: [new SaveDealerLocatorError({ error: "Dealer locator save failed" })]),
					catchError((err) => of(new SaveDealerLocatorError({ error: err.message || err.statusText })))
				);
		})
	);
}
