import { Component } from "@angular/core";
import { IAppState } from "@app/store/app.reducer";
import { LiveChatToggle } from "@app/store/support";
import { Store } from "@ngrx/store";

@Component({
	selector: "c4-live-chat-button",
	templateUrl: "./live-chat-button.component.html",
	styleUrls: ["./live-chat-button.component.scss"]
})
export class LiveChatButtonComponent {

	constructor(private store: Store<IAppState>) { }

	toggleChat(): void {
		this.store.dispatch(new LiveChatToggle());
	}
}
