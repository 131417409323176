import { Component, Input, OnInit } from "@angular/core";
import { IUser, SecurityRoles, userHasSecurityFlag } from "@app/account/account.models";
import { UserFullNamePipe } from "@app/pipes/fullname.pipe";
import { KbService } from "@app/services/kb.service";
import { IAppState } from "@app/store";
import { ErrorToast, SuccessToast } from "@app/store/toast/toast.actions";
import { selectActiveUser } from "@app/store/user";
import { IContentItemComment } from "@app/support/support.models";
import { select, Store } from "@ngrx/store";

@Component({
	selector: "c4-comment",
	templateUrl: "./comment.component.html",
	styleUrls: ["./comment.component.scss"]
})
export class CommentComponent implements OnInit {
	@Input() comment: IContentItemComment;
	@Input() replyComment: IContentItemComment;
	@Input() contentItemId: number;

	user: IUser;
	canEditSupportContent: boolean;

	constructor(private store: Store<IAppState>, readonly kbService: KbService) { }

	ngOnInit() {
		this.store.pipe(select(selectActiveUser)).subscribe((user) => {
			this.user = user;
			this.canEditSupportContent = userHasSecurityFlag(user, SecurityRoles.InternalSupportModerator | SecurityRoles.InternalSupportAdmin);
		});
	}

	hideComment() {
		this.kbService.hideComment(this.comment.id).subscribe((result) => {
			if (result) {
				this.comment = null;
				this.store.dispatch(new SuccessToast({ message: "Comment removed." }));
			} else {
				this.store.dispatch(new ErrorToast({ message: "There was a problem removing the comment." }));
			}
		});
	}

	showReply() { this.comment.replying = true; }

	cancelReplyToComment() {
		this.comment.replying = false;
		this.comment.replyComment = null;
	}

	replyToComment() {
		this.kbService.replyToComment(this.comment.id, this.contentItemId, this.comment.replyComment).subscribe((result) => {
			this.cancelReplyToComment();

			if (result) {
				result.createdByUser = new UserFullNamePipe().transform(this.user);

				if (!this.comment.replies) {
					this.comment.replies = [];
				}

				this.comment.replies.push(result);
			} else {
				this.store.dispatch(new ErrorToast({ message: "There was a problem posting your reply." }));
			}
		});
	}

	upVoteComment() {
		if (this.comment.userUpVoted || this.comment.createdByUserId === this.user.userId) {
			return;
		}

		this.kbService.upVoteComment(this.comment.id).subscribe((result) => {
			if (result) {
				this.comment.votes += this.comment.userDownVoted ? 2 : 1;
				this.comment.userUpVoted = true;
				this.comment.userDownVoted = false;
			}
		});
	}

	downVoteComment() {
		if (this.comment.userDownVoted || this.comment.createdByUserId === this.user.userId) {
			return;
		}

		this.kbService.downVoteComment(this.comment.id).subscribe((result) => {
			if (result) {
				this.comment.votes -= this.comment.userUpVoted ? 2 : 1;
				this.comment.userDownVoted = true;
				this.comment.userUpVoted = false;
			}
		});
	}
}
