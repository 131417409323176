import { Component, Input, OnInit } from "@angular/core";
import { LatestProductFirmware } from "@app/products/products.models";

@Component({
	selector: "c4-product-firmware",
	templateUrl: "./product-firmware.component.html",
	styleUrls: ["./product-firmware.component.scss"]
})
export class ProductFirmwareComponent implements OnInit {
	@Input() firmware: LatestProductFirmware[];
	@Input() sku: string;

	constructor() { }

	ngOnInit() {
	}

}
