import { ngStoreCacheItem, objectUpdate } from "@app/shared/helpers";
import { IAccountBalanceLineItem, IErrorInfo, IValueCache, SubmitStateType } from "@app/shared/shared.models";
import { AccountBalanceActions, AccountBalanceActionTypes } from "@app/store/account-balance/account-balance.actions";
import { accountBalanceAdapter } from "@app/store/account-balance/account-balance.adapter";
import { EntityState, Update } from "@ngrx/entity";

export interface IAccountBalanceState extends EntityState<IAccountBalanceLineItem> {
	submitState: SubmitStateType;
	errors: IErrorInfo[];
	loadedAccounts: IValueCache<string>[];
}

export const initialState: IAccountBalanceState = accountBalanceAdapter.getInitialState({ submitState: SubmitStateType.Ready, errors: undefined, loadedAccounts: [] });

export function accountBalanceReducer(
	state = initialState,
	action: AccountBalanceActions
): IAccountBalanceState {
	switch (action.type) {
		case AccountBalanceActionTypes.LoadAccountBalanceSuccess: {
			const cached = ngStoreCacheItem({ item: action.payload.accountId } as IValueCache<string>, 10, "minutes");
			const newState = objectUpdate(state, {
				submitState: SubmitStateType.Ready,
				errors: undefined,
				loadedAccounts: [...state.loadedAccounts, cached]
			});
			action.payload.details.forEach(item => item.accountId = action.payload.accountId);
			return accountBalanceAdapter.upsertMany(action.payload.details, newState);
		}
		case AccountBalanceActionTypes.UpdateSubmitState:
			return objectUpdate(state, { submitState: action.payload, errors: undefined });
		case AccountBalanceActionTypes.PayInvoiceAttempt:
			return objectUpdate(state, { submitState: SubmitStateType.Submitting, errors: undefined });
		case AccountBalanceActionTypes.PayInvoiceError:
			return objectUpdate(state, { submitState: SubmitStateType.Error, errors: action.payload.errors });
		case AccountBalanceActionTypes.PayInvoiceSuccess: {
			const udpates = action.payload.selectedInvoices.map(item => <Update<IAccountBalanceLineItem>>{
				id: item.referenceNumber,
				changes: {
					isPaid: true,
					agingBucket: "Paid",
					isSelected: false
				}
			});
			const newState = objectUpdate(state, { submitState: SubmitStateType.Success, errors: undefined });
			return accountBalanceAdapter.updateMany(udpates, newState);
		}
		default:
			return state;
	}
}