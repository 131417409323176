import { Component, Input, OnInit, Output } from "@angular/core";
import { IAppState } from "@app/store";
import { isFeatureFlagEnabled, isFeatureFlagToggleVisible } from "@app/store/account";
import { select, Store } from "@ngrx/store";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";

@Component({
	selector: "c4-feature-toggle",
	templateUrl: "./feature-toggle.component.html",
	styleUrls: ["./feature-toggle.component.scss"],
})
export class FeatureToggleComponent implements OnInit {
	@Input() toggle: string;
	@Input() label: string;
	@Output() showChange = new BehaviorSubject<boolean>(false);

	vm$: Observable<{ enabled: boolean, visible: boolean }>;

	constructor(readonly store: Store<IAppState>) { }

	ngOnInit() {
		this.store.pipe(select(isFeatureFlagEnabled(this.toggle))).subscribe(enabled => {
			this.showChange.next(enabled);
		});

		this.vm$ = combineLatest([
			this.store.pipe(select(isFeatureFlagToggleVisible(this.toggle))),
			this.showChange,
		]).pipe(
			map(([visible, enabled]) => ({ visible, enabled: visible && enabled })),
		);
	}
}
