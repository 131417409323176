import { CheckoutBillingInfo, CheckoutShippingInfo, IAddToCartModel, ICheckoutModel, IOrderLifecycleEvent, IOrderTotalsModel } from "@app/checkout/checkout.models";
import { IError } from "@app/shared/shared.models";
import { Action } from "@ngrx/store";

export enum CartActionType {
	LoadCartAttempt = "[Cart] Load Attempt",
	LoadCartSuccess = "[Cart] Load Success",
	LoadCartError = "[Cart] Load Error",
	LoadCurrentCartAttempt = "[Cart] Load Current Attempt",
	LoadCurrentCartSuccess = "[Cart] Load Current Success",
	LoadCurrentCartError = "[Cart] Load Current Error",
	CheckoutPageLoadedSuccess = "[Cart] Load Checkout Page Success",
	LoadPcnaCartAttempt = "[Cart] Load PCNA Attempt",
	LoadPcnaCartSuccess = "[Cart] Load PCNA Success",
	LoadPcnaCartError = "[Cart] Load PCNA Error",
	EditCartItemAttempt = "[Cart] Edit Item Attempt",
	EditCartItemSuccess = "[Cart] Add Item Success",
	EditCartItemError = "[Cart] Add Item Error",
	AddCartItemAttempt = "[Cart] Add Item Attempt",
	AddCartItemSuccess = "[Cart] Add Item Success",
	AddCartItemError = "[Cart] Add Item Error",
	AddCartPromoAttempt = "[Cart] Add Promo Attempt",
	AddCartPromoSuccess = "[Cart] Add Promo Success",
	AddCartPromoError = "[Cart] Add Promo Error",
	ClearCartSuccess = "[Cart] Clear Success",
	CartUpdateSuccess = "[Cart] Cart Update Success",
	OrderPlacedSuccess = "[Cart] Order Placed Success",
	AddOrderLifecycleEventAttempt = "[Cart] Add Lifecycle Event",
	ChangeActiveCartAttempt = "[Cart] Change Active Cart Attempt",
	ChangeActiveCartSuccess = "[Cart] Change Active Cart Success",
	ChangeActiveCartError = "[Cart] Change Active Cart Error",
	UpdateBilling = "[Cart] Update Billing",
	UpdateShipping = "[Cart] Update Shipping",
	UpdateModifyingCart = "[Cart] Update Modifying",
}

export class CheckoutPageLoadedSuccess implements Action {
	readonly type = CartActionType.CheckoutPageLoadedSuccess;
	constructor(public cart: ICheckoutModel) { }
}

export class LoadCartAttempt implements Action {
	readonly type = CartActionType.LoadCartAttempt;
	constructor(public payload: { orderId: number }) { }
}

export class LoadCartSuccess implements Action {
	readonly type = CartActionType.LoadCartSuccess;
	constructor(public payload: IOrderTotalsModel) { }
}

export class LoadCartError implements Action {
	readonly type = CartActionType.LoadCartError;
	constructor(public payload: IError) { }
}

export class LoadCurrentCartAttempt implements Action {
	readonly type = CartActionType.LoadCurrentCartAttempt;
	constructor(public payload: { orderId: number, redirect?: boolean } = { orderId: 0 }) { }
}

export class LoadCurrentCartSuccess implements Action {
	readonly type = CartActionType.LoadCurrentCartSuccess;
	constructor(public payload: IOrderTotalsModel) { }
}

export class LoadCurrentCartError implements Action {
	readonly type = CartActionType.LoadCurrentCartError;
	constructor(public payload: IError) { }
}

export class ChangeActiveCartAttempt implements Action {
	readonly type = CartActionType.ChangeActiveCartAttempt;
	constructor(public payload: { orderId: number, redirect?: boolean } = { orderId: 0 }) { }
}

export class ChangeActiveCartSuccess implements Action {
	readonly type = CartActionType.ChangeActiveCartSuccess;
	constructor(public payload: IOrderTotalsModel) { }
}

export class ChangeActiveCartError implements Action {
	readonly type = CartActionType.ChangeActiveCartError;
	constructor(public payload: IError) { }
}

export class LoadPcnaCartAttempt implements Action {
	readonly type = CartActionType.LoadPcnaCartAttempt;
	constructor() { }
}

export class LoadPcnaCartSuccess implements Action {
	readonly type = CartActionType.LoadPcnaCartSuccess;
	constructor(public payload: IOrderTotalsModel) { }
}

export class LoadPcnaCartError implements Action {
	readonly type = CartActionType.LoadPcnaCartError;
	constructor(public payload: IError) { }
}

export class EditCartItemAttempt implements Action {
	readonly type = CartActionType.EditCartItemAttempt;
	constructor(public payload: IEditCartItemPayload) { }
}

export class EditCartItemSuccess implements Action {
	readonly type = CartActionType.EditCartItemSuccess;
	constructor(public payload: IAddItemToCartPayload, public totals: IOrderTotalsModel) { }
}

export class EditCartItemError implements Action {
	readonly type = CartActionType.EditCartItemError;
	constructor(public payload: IError) { }
}

export class AddCartItemAttempt implements Action {
	readonly type = CartActionType.AddCartItemAttempt;
	constructor(public payload: IAddItemToCartPayload) { }
}

export class AddCartItemSuccess implements Action {
	readonly type = CartActionType.AddCartItemSuccess;
	constructor(public payload: IAddItemToCartPayload, public totals: IOrderTotalsModel) { }
}

export class AddCartItemError implements Action {
	readonly type = CartActionType.AddCartItemError;
	constructor(public payload: IError) { }
}

export class AddCartPromoAttempt implements Action {
	readonly type = CartActionType.AddCartPromoAttempt;
	constructor(public payload: IApplyPromoPayload) { }
}

export class AddCartPromoSuccess implements Action {
	readonly type = CartActionType.AddCartPromoSuccess;
	constructor(public payload: IApplyPromoPayload, public totals: IOrderTotalsModel) { }
}

export class AddCartPromoError implements Action {
	readonly type = CartActionType.AddCartPromoError;
	constructor(public error: IError) { }
}

export class ClearCartSuccess implements Action {
	readonly type = CartActionType.ClearCartSuccess;
	constructor(public payload: { orderId: number }) { }
}

export class OrderPlacedSuccess implements Action {
	readonly type = CartActionType.OrderPlacedSuccess;
	constructor(public payload: { orderId: number, newOrderId: number, error: string }) { }
}

export class CartUpdateSuccess implements Action {
	readonly type = CartActionType.CartUpdateSuccess;
	constructor(public totals: IOrderTotalsModel) { }
}

export class AddOrderLifecycleEventAttempt implements Action {
	readonly type = CartActionType.AddOrderLifecycleEventAttempt;
	constructor(public payload: IOrderLifecycleEvent) { }
}

export class UpdateBilling implements Action {
	readonly type = CartActionType.UpdateBilling;
	constructor(public payload: { orderId: number, billing: CheckoutBillingInfo }) { }
}

export class UpdateShipping implements Action {
	readonly type = CartActionType.UpdateShipping;
	constructor(public payload: { orderId: number, shipping: CheckoutShippingInfo }) { }
}

export class UpdateModifyingCart implements Action {
	readonly type = CartActionType.UpdateModifyingCart;
	constructor(public payload: { orderId: number, modifying: boolean }) { }
}

export interface IEditCartItemPayload {
	promoCode?: string;
	item: IAddToCartModel;
	originalOrderDetailId: number;
	originalItemAddOn: IAddToCartModel;
	lifecycle?: IOrderLifecycleEvent;
	completionCallback?: (result: boolean) => void;
}

export interface IAddItemToCartPayload {
	promoCode?: string;
	item: IAddToCartModel;
	isInCart?: boolean;
	lifecycle?: IOrderLifecycleEvent;
	completionCallback?: (result: boolean) => void;
}

export interface IEditCartItemSuccessPayload {
	totals: IOrderTotalsModel;
	item: IAddToCartModel;
}

export interface IAddCartItemSuccessPayload {
	totals: IOrderTotalsModel;
	item: IAddToCartModel;
}

export interface IApplyPromoPayload {
	promoCode: string;
	orderId?: number;
	lifecycle?: IOrderLifecycleEvent;
	completionCallback?: (result: boolean) => void;
}

export type CartActions =
	LoadCurrentCartAttempt
	| LoadCurrentCartSuccess
	| LoadCurrentCartError
	| LoadCartAttempt
	| LoadCartSuccess
	| LoadCartError
	| CheckoutPageLoadedSuccess
	| LoadPcnaCartAttempt
	| LoadPcnaCartSuccess
	| LoadPcnaCartError
	| EditCartItemAttempt
	| EditCartItemSuccess
	| EditCartItemError
	| AddCartItemAttempt
	| AddCartItemSuccess
	| AddCartItemError
	| AddCartPromoAttempt
	| AddCartPromoSuccess
	| AddCartPromoError
	| ClearCartSuccess
	| CartUpdateSuccess
	| OrderPlacedSuccess
	| AddOrderLifecycleEventAttempt
	| ChangeActiveCartAttempt
	| ChangeActiveCartError
	| ChangeActiveCartSuccess
	| UpdateBilling
	| UpdateShipping
	| UpdateModifyingCart;