import { Component, Input } from "@angular/core";
import { CloudinaryService } from "@app/services/cloudinary.service";
import { IMedia, MediaType } from "@app/shared/shared.models";

@Component({
	selector: "c4-media-image",
	templateUrl: "./media-image.component.html",
	styleUrls: ["./media-image.component.scss"]
})
export class MediaImageComponent {
	@Input() transformations: string;
	@Input() type = "img";
	@Input() url: string;
	@Input() set media(value: IMedia | IMedia[]) {
		let image = value;
		if (image) {
			if (value instanceof Array) {
				image = value.find((item) => item.mediaTypeId === MediaType.Image);
				if (!image) {
					this.url = undefined;
					return;
				}
			}
			this.url = this.cloudinary.getUrl(image as IMedia, this.transformations);
		} else {
			this.url = undefined;
		}
	}

	constructor(readonly cloudinary: CloudinaryService) { }
}
