import { animate, keyframes, style, transition, trigger } from "@angular/animations";
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { accountHasAccountRoleFlag, AccountSecurityRole, IUser, SecurityRoles, userHasSecurityFlag } from "@app/account/account.models";
import { ISavedCart } from "@app/checkout/checkout.models";
import { INavMenuItem } from "@app/nav/nav.models";
import { CartService } from "@app/services/cart.service";
import { CartGroup, CartGroupNames } from "@app/shared/shared.models";
import { IAppState } from "@app/store";
import { LogoutAttempt, selectIsImpersonating, selectIsMsrp } from "@app/store/auth";
import { ChangeActiveCartAttempt } from "@app/store/cart";
import { NavBack, NavBackToTopMenu, NavForward, selectCurrentChildren, selectCurrentNav, selectCurrentProductCategoryId, selectParentLabel, selectTopMenu } from "@app/store/nav";
import { Redirect } from "@app/store/router";
import { currentIsMigrating, getActiveUserWithAccount, selectCurrentUser } from "@app/store/user";
import { select, Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

@Component({
	selector: "c4-nav",
	templateUrl: "./nav.component.html",
	styleUrls: ["./nav.component.scss"],
	animations: [
		trigger("menuSlideAnimate", [
			transition("void=>next", [
				animate(
					"100ms",
					keyframes([
						style({ transform: "translate(100%)", zIndex: 2 }),
						style({ transform: "translate(0%)", zIndex: 2 })
					])
				)
			]),
			transition("next=>void", [
				animate(
					"100ms",
					keyframes([
						style({ transform: "translate(0%)", }),
					])
				)
			]),
			transition("void=>prev", [
				animate(
					"100ms",
					keyframes([
						style({ transform: "translate(0%)", })
					])
				)
			]),
			transition("prev=>void", [
				animate(
					"100ms",
					keyframes([
						style({ transform: "translate(0%)", zIndex: 2 }),
						style({ transform: "translate(100%)", })
					])
				)
			])
		])
	]
})
export class NavComponent implements OnInit, OnDestroy {
	menu$: Observable<INavMenuItem>;
	children$: Observable<INavMenuItem[]>;
	topMenu$: Observable<INavMenuItem>;
	canImpersonate: boolean;
	canViewPricing: boolean;
	currentProductCategoryId: number;

	searchQuery: string;

	activeUser: IUser;
	userAccountDisplay: string;
	isImpersonating$: Observable<boolean>;
	isMsrp$: Observable<boolean>;
	userIsMigrating$: Observable<boolean>;
	parentLabel$: Observable<string>;

	showSavedCarts: boolean;
	savedCarts$: Observable<CartGroup[]>;

	orientation: string;

	private destroyed$ = new Subject<{}>();

	constructor(private store: Store<IAppState>, private cartService: CartService, readonly changeDetector: ChangeDetectorRef) { }

	ngOnInit() {
		this.store.pipe(
			select(getActiveUserWithAccount),
			takeUntil(this.destroyed$)
		).subscribe(user => {
			this.activeUser = user;
			if (user && user.account) {
				this.canImpersonate = userHasSecurityFlag(user, SecurityRoles.InternalSalesImpersonation);
				this.canViewPricing = userHasSecurityFlag(user, SecurityRoles.PriceViewing) && accountHasAccountRoleFlag(user.account, AccountSecurityRole.MsrpViewing);
				this.userAccountDisplay = `${this.activeUser.subDealerId || this.activeUser.accountId} ${user.account.accountName}`;
			}
		});

		this.savedCarts$ = this.cartService.getSavedCarts().pipe(
			map(carts => {
				const data = new Map<string, CartGroup>();
				carts.forEach(cart => {
					const item = data.get(cart.cartType) || { code: cart.cartType, display: true, name: CartGroupNames.get(cart.cartType), items: [] as ISavedCart[] };
					item.items.push(cart);
					data.set(cart.cartType, item);
				});
				return [...data.values()];
			})
		);

		this.store.pipe(
			select(selectCurrentUser),
			takeUntil(this.destroyed$)
		).subscribe(() => this.showSavedCarts = false);
		this.userIsMigrating$ = this.store.pipe(select(currentIsMigrating()));
		this.isImpersonating$ = this.store.pipe(select(selectIsImpersonating));
		this.isMsrp$ = this.store.pipe(select(selectIsMsrp));

		this.menu$ = this.store.pipe(select(selectCurrentNav));
		this.children$ = this.store.pipe(select(selectCurrentChildren));
		this.parentLabel$ = this.store.pipe(select(selectParentLabel));
		this.topMenu$ = this.store.pipe(select(selectTopMenu));

		this.store.pipe(
			select(selectCurrentProductCategoryId),
			takeUntil(this.destroyed$)
		).subscribe((id: number) => this.currentProductCategoryId = id);
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}

	switchCart(cart: ISavedCart) {
		this.store.dispatch(new ChangeActiveCartAttempt({ orderId: cart.id }));
		this.showSavedCarts = false;
	}

	logout() {
		this.store.dispatch(new LogoutAttempt());
	}

	forward(menu: INavMenuItem) {
		this.orientation = "next";
		this.changeDetector.detectChanges();
		this.store.dispatch(new NavForward(menu.id));
	}

	back() {
		this.orientation = "prev";
		this.changeDetector.detectChanges();
		this.store.dispatch(new NavBack());
	}

	backToTop(menu: INavMenuItem) {
		this.orientation = "prev";
		this.changeDetector.detectChanges();
		this.store.dispatch(new NavBackToTopMenu(menu.id));
		if (menu.url && !this.isActiveForUrl(menu)) {
			this.store.dispatch(new Redirect({ url: menu.url }));
		}
	}

	closeMenu() {
		document.body.classList.remove("showmenu");
	}

	isActive(item: INavMenuItem): boolean {
		return this.isActiveForProduct(item) || this.isActiveForUrl(item);
	}

	isActiveForProduct(item: INavMenuItem): boolean {
		return this.currentProductCategoryId && item.categoryId === this.currentProductCategoryId;
	}

	isActiveForUrl(item: INavMenuItem) {
		return item.url && item.url.toLowerCase() === window.location.pathname.toLowerCase();
	}

	isLink(item: INavMenuItem) {
		// If external, definitely a link
		// Internal links must be inactive, to allow passing through landing pages
		return item.url && (item.url.indexOf("/") !== 0 || !(this.isActive(item) && item.hasChildren));
	}

	search() {
		this.store.dispatch(new Redirect({ path: ["search"], query: { q: this.searchQuery } }));
		this.searchQuery = undefined;
	}
}
