import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FeatureFlags, IEditUser } from "@app/account/account.models";
import { IAuthenticatedLinkModel } from "@app/auth/auth.models";
import { AuthService } from "@app/services/auth.service";
import { UserService } from "@app/services/user.service";
import { IAppState } from "@app/store";
import { isFeatureFlagEnabled } from "@app/store/account";
import { selectActiveUser } from "@app/store/user";
import { select, Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";

@Component({
	selector: "c4-employee-registration",
	templateUrl: "./employee-registration.component.html",
	styleUrls: ["./employee-registration.component.scss"],
})
export class EmployeeRegistrationComponent implements OnInit, OnDestroy {
	model: IEditUser;
	error: string;
	infoMessage: string;
	addingUser: boolean;
	showNeedHelp: boolean;
	showSuccess: boolean;

	resetInfo: IAuthenticatedLinkModel;

	private destroyed$ = new Subject<{}>();
	authenticated$: Observable<boolean>;
	useWebhelpEmail$: Observable<boolean>;

	constructor(private store: Store<IAppState>, readonly authService: AuthService, readonly userService: UserService, readonly route: ActivatedRoute) { }

	ngOnInit() {
		this.authenticated$ = this.store.pipe(select(selectActiveUser), map(user => !!user));
		this.useWebhelpEmail$ = this.store.pipe(select(isFeatureFlagEnabled(FeatureFlags.UseWebhelpEmail)));

		this.model = {} as IEditUser;
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}

	createUser() {
		this.error = null;
		if (!this.model.loginName) {
			this.error = "Must provide username";
		} else if (!this.model.firstName) {
			this.error = "Must provide first name.";
		} else if (!this.model.lastName) {
			this.error = "Must provide last name.";
		} else if (!this.isValidUsername(this.model.loginName)) {
			this.error = "Username must contain between 6 and 32 characters and consist of letters, numbers, or dashes only; other special characters and spaces are not allowed.";
		} else if (!this.isValidEmailDomain(this.model.email)) {
			this.error = "Must provide valid a snapone.com, snapav.com, or control4.com email address.";
		} else {
			this.addingUser = true;
			this.userService.addInternalUser(this.model).subscribe(result => {
				if (!result.success) {
					this.addingUser = false;
					this.displayMessage(result.errorMessage, true);
				} else {
					this.showSuccess = true;
					this.addingUser = false;
					this.model.userId = "";
					this.model.firstName = "";
					this.model.lastName = "";
					this.model.email = "";
				}
			}, err => {
				this.displayMessage(err.statusText, true);
			});
		}
	}

	displayMessage(msg: string, isError: boolean = false) {
		if (isError) {
			this.error = msg;
			this.infoMessage = null;
		} else {
			this.infoMessage = msg;
			this.error = null;
		}
	}

	isValidEmailDomain(email: string) {
		const emailRegex = /^(\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*)$/;
		if (emailRegex.test(email)) {
			if (email.includes("snapav.com") || email.includes("control4.com") || email.includes("snapone.com")) {
				return true;
			}
		}

		return false;
	}

	isValidUsername(username: string) {
		const usernameRegex = /^[A-Za-z\d\-]{6,32}$/;
		if (usernameRegex.test(username)) {
			return true;
		}

		return false;
	}
}
