import { Injectable } from "@angular/core";
import { AddressService } from "@app/services/address.service";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { CountryCodeActionTypes, LoadCountryCodesError, LoadCountryCodesSuccess } from "./country-code.actions";

@Injectable()
export class CountryCodeEffects {
	constructor(
		private actions$: Actions,
		private addressService: AddressService
	) { }

	@Effect()
	loadCountryCodes$: Observable<Action> = this.actions$.pipe(
		ofType(CountryCodeActionTypes.LoadCountryCodesAttempt),
		mergeMap(() => {
			return this.addressService
				.getCountryCodes()
				.pipe(
					map((info) => new LoadCountryCodesSuccess(info)),
					catchError((err) => of(new LoadCountryCodesError({ error: err.message || err.statusText })))
				);
		})
	);
}
