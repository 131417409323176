import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MediaService } from "@app/services/media.service";
import { IMedia } from "@app/shared/shared.models";
import { IAppState } from "@app/store";
import { getCurrentAccount } from "@app/store/account";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: "c4-marketing-images",
	templateUrl: "./marketing-images.component.html",
	styleUrls: ["./marketing-images.component.scss"]
})
export class MarketingImagesComponent implements OnInit, OnDestroy {

	media: IMedia[];
	showCategories = true;
	categoryHeader: string;
	statusLevel: string;
	private _mediaType: string;
	@Input() set mediaType(val: string) {
		this._mediaType = val;
		if (val) {
			this.populate();
		}
	}
	get mediaType() {
		return this._mediaType;
	}

	private readonly destroyed$ = new Subject<{}>();

	constructor(readonly store: Store<IAppState>, private readonly mediaService: MediaService) { }

	ngOnInit() {
		this.store.pipe(select(getCurrentAccount), takeUntil(this.destroyed$)).subscribe((account) => {
			if (account) {
				this.statusLevel = !account.premierStatus || account.premierStatus === "None" ? "authorized" : account.premierStatus.toLowerCase();
				this.populate();
			}
		});
	}

	populate() {
		if (!this.statusLevel || !this.mediaType) {
			return;
		}

		if (this.mediaType === "Statuses") {
			this.getStatuses();
		} else {
			this.getMarketingMedia();
		}
	}

	getStatuses() {
		this.mediaService.getStatuses().subscribe((result) => {
			this.media = result;
		});
	}

	getMarketingMedia() {
		this.mediaService.getMediaByQuery(this.mediaType).subscribe((result) => {
			this.media = result;
		});
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}
}