import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { IProductView, IStockStatus } from "@app/products/products.models";
import { ProductService } from "@app/services/product.service";
import { IAppState } from "@app/store";
import { showStockStatus } from "@app/store/product-details/product-details.selectors";
import { LoadProductStockStatusAttempt } from "@app/store/product-stock-status/product-stock-status.actions";
import { getProductStockStatus } from "@app/store/product-stock-status/product-stock-status.selectors";
import { select, Store } from "@ngrx/store";
import { differenceInCalendarDays } from "date-fns";
import { Observable, Subject } from "rxjs";

@Component({
	selector: "c4-stock-status",
	templateUrl: "./stock-status.component.html",
	styleUrls: ["./stock-status.component.scss"],
})
export class StockStatusComponent implements OnInit, OnDestroy {
	@Input() margin = true;
	@Input() sku: string;
	private _product: IProductView;
	@Input() set product(val: IProductView) {
		this._product = val;
		if (val) {
			this.populate();
		}
	}
	get product() {
		return this._product;
	}

	showWarehouses = false;
	@Output() showWarehousesChange = new EventEmitter<boolean>();

	stockStatus$: Observable<IStockStatus[]>;
	showStockStatus$: Observable<boolean>;

	showBackorder: boolean;
	showDisplayOnly: boolean;
	stockMessage: string;
	overrideActive = false;

	private destroyed$ = new Subject<{}>();

	constructor(private readonly productService: ProductService, private readonly store: Store<IAppState>) { }

	ngOnInit() {
		this.showStockStatus$ = this.store.pipe(select(showStockStatus));
		this.product.sku = this.product.sku ? this.product.sku : this.sku;
		this.stockStatus$ = this.store.pipe(select(getProductStockStatus(this.product.sku)));
	}

	populate() {
		this.overrideActive = (this.product.showBackorderOverride || this.product.shippingEstimateDescOverride) && (!this.product.overrideEndDate || differenceInCalendarDays(this.product.overrideEndDate, new Date()) >= 0);
		this.stockMessage = this.productService.getStockStatusMessage(this.product, this.overrideActive);
		this.showDisplayOnly = this.product.isDisplayOnly || this.product.dealerPrice <= 0;
		this.showBackorder = (this.product.isOnBackorder || (this.product.showBackorderOverride && this.overrideActive)) && !this.showDisplayOnly && !this.product.isKeycap && !this.product.isPartner;
	}

	toggleWarehouses(): void {
		this.showWarehouses = !this.showWarehouses;
		this.showWarehousesChange.next(this.showWarehouses);
		this.store.dispatch(new LoadProductStockStatusAttempt({ sku: this.product.sku }));
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}
}
