import { Component, Input } from "@angular/core";
import { IVideoModel, VideoType } from "@app/marketing/marketing.models";

@Component({
	selector: "c4-product-video",
	templateUrl: "./product-video.component.html",
	styleUrls: ["./product-video.component.scss"]
})
export class ProductVideoComponent {

	@Input() video: IVideoModel;
	@Input() VideoType = VideoType;

}
