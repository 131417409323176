import { Injectable } from "@angular/core";
import { ConsumerService } from "@app/services/consumer.service";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action, select, Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, debounceTime, filter, map, mergeMap, switchMap } from "rxjs/operators";
import { IAppState } from "../app.reducer";
import { CustomerSummaryActionTypes, LoadCustomerSummariesError, LoadCustomerSummariesSuccess } from "./customer-summary.actions";
import { getCurrentCustomerSummaries } from "./customer-summary.selectors";

@Injectable()
export class CustomerSummaryEffects {
	constructor(
		private actions$: Actions,
		private store: Store<IAppState>,
		private consumerService: ConsumerService
	) { }

	@Effect()
	loadCustomerSummariesByDealerId$: Observable<Action> = this.actions$.pipe(
		ofType(CustomerSummaryActionTypes.LoadCustomerSummariesAttempt),
		debounceTime(100),
		switchMap(() => this.store.pipe(select(getCurrentCustomerSummaries))),
		filter((summaries) => !summaries),
		mergeMap(() => {
			return this.consumerService
				.getCustomerSummaries()
				.pipe(
					map((info) => new LoadCustomerSummariesSuccess(info)),
					catchError((err) => of(new LoadCustomerSummariesError({ error: err.message || err.statusText })))
				);
		})
	);
}