import { Component, Input } from "@angular/core";

@Component({
	selector: "c4-tooltip",
	templateUrl: "./tooltip.component.html",
	styleUrls: ["./tooltip.component.scss"]
})
export class TooltipComponent {
	@Input() title: string;
}
