import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PropagandaService } from "@app/services/propaganda.service";
import { ControlTypes, IControlColor } from "@app/shared/shared.models";

@Component({
	selector: "c4-control-color",
	templateUrl: "./control-color.component.html",
	styleUrls: ["./control-color.component.scss"]
})
export class ControlColorComponent implements OnInit {
	@Input() controlType: ControlTypes;
	@Input() get color() {
		return this.colorValue;
	}
	colorValue: string;
	isShown = false;
	controlColors = <IControlColor[]>[];
	@Output() colorChange = new EventEmitter();

	set color(val: string) {
		this.colorValue = val;
		this.colorChange.emit(this.colorValue);
	}

	constructor(private readonly propagandaService: PropagandaService) { }

	ngOnInit(): void {
		this.propagandaService.getControlColors(this.controlType).subscribe((result) => {
			if (result) {
				this.controlColors = result.map(color => {
					color.name = color.name.replace("Primary", "");
					return color;
				});
				if (!this.color) {
					this.colorValue = this.controlColors[0].cssClass;
				}
			}
		});
	}

	setSelectColor(cssClass: string): void {
		this.isShown = false;
		this.color = cssClass;
		this.colorValue = cssClass;
	}
}