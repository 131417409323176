import { ISecurityRoleDetail } from "@app/account/account.models";
import { IError } from "@app/shared/shared.models";
import { Action } from "@ngrx/store";

export class LoadSecurityRolesAttempt implements Action {
	readonly type = SecurityRolesActionTypes.LoadAttempt;
	constructor() { }
}

export class LoadSecurityRolesSuccess implements Action {
	readonly type = SecurityRolesActionTypes.LoadSuccess;
	constructor(public payload: ISecurityRoleDetail[]) { }
}

export class LoadSecurityRolesError implements Action {
	readonly type = SecurityRolesActionTypes.LoadError;
	constructor(public payload: IError) { }
}

export type SecurityRolesActions =
	LoadSecurityRolesAttempt
	| LoadSecurityRolesSuccess
	| LoadSecurityRolesError;
export enum SecurityRolesActionTypes {
	LoadAttempt = "[Security Roles Load] Attempt",
	LoadSuccess = "[Security Roles Load] Success",
	LoadError = "[Security Roles Load] Error",
}
