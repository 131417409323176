import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Settings } from "@app/shared/shared.models";

@Injectable({ providedIn: "root" })
export class SettingsService {
	constructor(private http: HttpClient) { }

	load() {
		return this.http.get<Settings>("api/settings");
	}
}