import { Action } from "@ngrx/store";

export class Redirect implements Action {
	readonly type = RouterActionTypes.Redirect;
	constructor(public payload: RedirectInfo) { }
}

export class ExternalRedirect implements Action {
	readonly type = RouterActionTypes.ExternalRedirect;
	constructor(public url: string) { }
}

export type RouterActions = Redirect | ExternalRedirect;
export enum RouterActionTypes {
	Redirect = "[Router] Redirect",
	ExternalRedirect = "[Router] External Redirect",
}

export class RedirectInfo {
	url?: string;
	path?: string[];
	routeParams?: any;
	query?: any;
	skipLocationChange?: boolean;
}