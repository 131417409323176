import { IUpdateDealerRoleModel, IUser } from "@app/account/account.models";
import { IError } from "@app/shared/shared.models";
import { ISalesforceInfo } from "@app/support/support.models";
import { Action } from "@ngrx/store";

export class LoadUserAttempt implements Action {
	readonly type = UserActionTypes.LoadAttempt;
	constructor(public payload: ILoadUserAttemptPayload) { }
}

export class LoadUserSuccess implements Action {
	readonly type = UserActionTypes.LoadSuccess;
	constructor(public payload: IUser) { }
}

export class LoadUserError implements Action {
	readonly type = UserActionTypes.LoadError;
	constructor(public payload: IError) { }
}

export class SaveUserProfileAttempt implements Action {
	readonly type = UserActionTypes.SaveUserProfileAttempt;
	constructor(public payload: ISaveUserProfileAttemptPayload) { }
}

export class SaveUserProfileSuccess implements Action {
	readonly type = UserActionTypes.SaveUserProfileSuccess;
	constructor(public user: IUser, public sfInfo: ISalesforceInfo) { }
}

export class SaveUserProfileError implements Action {
	readonly type = UserActionTypes.SaveUserProfileError;
	constructor(public payload: IError) { }
}

export class SaveUserSuccess implements Action {
	readonly type = UserActionTypes.SaveUserSuccess;
	constructor(public payload: IUser) { }
}

export class SaveUserError implements Action {
	readonly type = UserActionTypes.SaveUserError;
	constructor(public payload: IError) { }
}

export class RemoveUserSuccess implements Action {
	readonly type = UserActionTypes.RemoveSuccess;
	constructor(public payload: string) { }
}

export class RemoveUserError implements Action {
	readonly type = UserActionTypes.RemoveError;
	constructor(public payload: IError) { }
}

export interface ILoadUserAttemptPayload {
	userId: string;
	forceReload?: boolean;
}

export class SaveBusinessRoleSuccess implements Action {
	readonly type = UserActionTypes.SaveBusinessRoleSuccess;
	constructor(public payload: IUpdateDealerRoleModel) { }
}

export class SaveBusinessRoleError implements Action {
	readonly type = UserActionTypes.SaveBusinessRoleError;
	constructor(public payload: IError) { }
}

export interface ISaveUserProfileAttemptPayload {
	user: IUser;
	sfInfo: ISalesforceInfo;
	autoClose?: boolean;
	displayMessage?: boolean;
	redirectToTechnicianPortal?: boolean;
}

export type UserActions = LoadUserAttempt
	| LoadUserSuccess
	| LoadUserError
	| SaveUserSuccess
	| SaveUserError
	| SaveUserProfileAttempt
	| SaveUserProfileSuccess
	| SaveUserProfileError
	| RemoveUserSuccess
	| RemoveUserError
	| SaveBusinessRoleSuccess
	| SaveBusinessRoleError;

export enum UserActionTypes {
	LoadAttempt = "[User Load] Attempt",
	LoadSuccess = "[User Load] Success",
	LoadError = "[User Load] Error",
	SaveUserProfileAttempt = "[User Profile Save] Attempt",
	SaveUserProfileSuccess = "[User Profile Save] Success",
	SaveUserProfileError = "[User Profile Save] Error",
	SaveUserSuccess = "[User Save] Success",
	SaveUserError = "[User Save] Error",
	RemoveAttempt = "[User Remove] Attempt",
	RemoveSuccess = "[User Remove] Success",
	RemoveError = "[User Remove] Error",
	SaveBusinessRoleSuccess = "[Business Role Save] Success",
	SaveBusinessRoleError = "[Business Role Save] Error"
}