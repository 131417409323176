import { Pipe, PipeTransform } from "@angular/core";
import { IDiscountProgramBenefit } from "@app/cms/cms.models";

@Pipe({
	name: "isVolumeDiscount",
})
export class IsVolumeDiscountPipe implements PipeTransform {
	transform(benefits: IDiscountProgramBenefit[]): boolean {
		return benefits && benefits.length && (benefits.length > 1 || benefits[0].itemQuantity > 1);
	}
}
