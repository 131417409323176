import { ITriadDiscountInfo } from "@app/shared-widgets/shared-widgets.models";
import { IError } from "@app/shared/shared.models";
import { Action } from "@ngrx/store";

export class LoadTriadDiscountAttempt implements Action {
	readonly type = TriadDiscountActionTypes.LoadTriadDiscountAttempt;
	constructor() { }
}

export class LoadTriadDiscountSuccess implements Action {
	readonly type = TriadDiscountActionTypes.LoadTriadDiscountSuccess;
	constructor(public payload: ITriadDiscountInfo) { }
}

export class LoadTriadDiscountError implements Action {
	readonly type = TriadDiscountActionTypes.LoadTriadDiscountError;
	constructor(public payload: IError) { }
}

export type TriadDiscountActions =
	LoadTriadDiscountAttempt
	| LoadTriadDiscountSuccess
	| LoadTriadDiscountError;

export enum TriadDiscountActionTypes {
	LoadTriadDiscountAttempt = "[Triad Discount Load] Attempt",
	LoadTriadDiscountSuccess = "[Triad Discount Load] Success",
	LoadTriadDiscountError = "[Triad Discount Load] Error",
}
