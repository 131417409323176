import { Injectable } from "@angular/core";
import { CartService } from "@app/services/cart.service";
import { LoadShippingMethodAttempt, LoadShippingMethodError, LoadShippingMethodSuccess, ShippingMethodActionTypes } from "@app/store/shipping-method";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";

@Injectable()
export class ShippingMethodEffects {
	constructor(
		private actions$: Actions,
		private cartService: CartService
	) { }

	@Effect()
	loadAddress$: Observable<Action> = this.actions$.pipe(
		ofType<LoadShippingMethodAttempt>(ShippingMethodActionTypes.LoadShippingMethodAttempt),
		mergeMap((action) => {
			return this.cartService
				.getShippingMethod(action.payload)
				.pipe(
					map((info) => new LoadShippingMethodSuccess(info)),
					catchError((err) => of(new LoadShippingMethodError({ error: err.message || err.statusText })))
				);
		})
	);
}
