import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "@app/services/auth.service";
import { Portals } from "@app/shared/shared.models";
import { IAppState } from "@app/store";
import { getCurrentPortal } from "@app/store/app-info/app-info.selectors";
import { isAuthenticated, LogoutAttempt } from "@app/store/auth";
import { Redirect } from "@app/store/router";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";

@Component({
	selector: "c4-employee-registration-layout",
	templateUrl: "./employee-registration-layout.component.html",
	styleUrls: ["./employee-registration-layout.component.scss"],
})

export class EmployeeRegistrationLayoutComponent implements OnInit, OnDestroy {
	year: number;
	currentPortal: Portals;
	loginImage: string;
	isAuthenticated: boolean;

	private destroyed$ = new Subject<{}>();

	constructor(private store: Store<IAppState>, private authService: AuthService, private route: ActivatedRoute) { }

	ngOnInit() {
		this.year = new Date().getFullYear();

		this.store.pipe(
			select(isAuthenticated),
			take(1),
		).subscribe((auth: boolean) => {
			const hasActiveJwt = this.authService.getActiveJwt();
			this.isAuthenticated = auth && !!hasActiveJwt;
			document.body.classList.remove("showmenu");
			if (auth) {
				if (hasActiveJwt) {
					this.store.dispatch(new Redirect({ url: this.route.snapshot.queryParamMap.get("redirect") || "" }));
				} else {
					this.store.dispatch(new LogoutAttempt());
				}
			}
		});

		this.store.pipe(
			select(getCurrentPortal),
			takeUntil(this.destroyed$),
		).subscribe((portal: Portals) => {
			this.currentPortal = portal;
			this.loginImage = this.getLoginImage(portal);
		});
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}

	private getLoginImage(portal: Portals) {
		return "url(https://res.cloudinary.com/control4/image/upload/c_scale,w_2000/v1579124397/WWW_Ctrl4_App_4Sight_v2.jpg";
	}
}
