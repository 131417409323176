import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ICustomerLicenseSummaryData, ILeadPreferences, ILicensePurchase, Lead, UpdateLeadStatusPayload } from "@app/dashboard/dashboard.models";
import { IDesignServicesLeadModel } from "@app/marketing/marketing.models";
import { ILeadWidgetModel } from "@app/shared-widgets/shared-widgets.models";
import { LeadsPayload } from "@app/store/leads";

@Injectable({
	providedIn: "root",
})
export class DashboardService {

	constructor(readonly http: HttpClient) { }

	getLeadWidget() {
		return this.http.get<ILeadWidgetModel>("api/dashboard/GetLeadWidget");
	}

	getLicensePurchaseHistory(consumer: string) {
		return this.http.get<ILicensePurchase[]>("api/dashboard/GetLicensePurchaseHistory", { params: { consumer } });
	}

	getLicenseSummaries() {
		return this.http.get<ICustomerLicenseSummaryData>("api/dashboard/GetConsumerLicenseSummaries");
	}

	exportLicenseSummaries() {
		return this.http.get("api/dashboard/exportLicenseSummaries", { responseType: "blob" });
	}

	getLeadPreferences() {
		return this.http.get<ILeadPreferences>("api/dashboard/GetLeadPreferences");
	}

	updateLeadPreferences(preferences: ILeadPreferences) {
		return this.http.put<boolean>("api/dashboard/UpdateLeadPreferences", preferences);
	}

	getLeads() {
		return this.http.get<LeadsPayload>("api/dashboard/getLeads");
	}

	updateLeadStatus(update: UpdateLeadStatusPayload) {
		return this.http.put<Lead>("api/dashboard/UpdateLeadStatus", update);
	}

	createLead(lead: IDesignServicesLeadModel) {
		return this.http.post<boolean>("api/dashboard/CreateDesignServicesLead", lead);
	}

	getSubDealerAllLabel(accountId: string) {
		return accountId === "MAG903" || accountId === "BES902" ? "All Stores" : "All Dealers";
	}
}

