import { Component, ElementRef, Input, OnInit } from "@angular/core";

@Component({
	selector: "c4-wistia-player",
	templateUrl: "./wistia-player.component.html",
	styleUrls: ["./wistia-player.component.scss"]
})
export class WistiaPlayerComponent implements OnInit {

	@Input() wistiaId: string;
	@Input() wistiaInfo: string;

	wistiaClasses = ["wistia_embed"];

	constructor(private element: ElementRef<Element>) { }

	ngOnInit() {
		this.wistiaClasses.push(`wistia_async_${this.wistiaId}`);
		this.wistiaClasses.push(this.wistiaInfo);

		const scriptEl = document.createElement("script");
		scriptEl.type = "text/javascript";
		scriptEl.src = `https://fast.wistia.com/embed/medias/${this.wistiaId}.jsonp`;
		scriptEl.async = true;

		this.element.nativeElement.appendChild(scriptEl);
	}

}
