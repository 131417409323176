import { ICacheable } from "@app/shared/shared.models";
import { Dictionary } from "@ngrx/entity";

export const enum ChartColor {
	Unknown,
	Error,
	Warn,
	Success,
}

export interface ChartInfo {
	labels: string[];
	colors?: ChartColor[];
	data: number[];
}

export interface ICustomerSummary {
	consumerAccountName: string;
	consumerName: string;
	consumerPhone: string;
	consumerEmail: string;
	dealerId: string;
	dealerName: string;
	dealerPhone: string;
	dealerEmail: string;
	distributorAccountName: string;
	controllerUuid: string;
	macAddress: string;
	controllerOs: string;
	controllerOsGroup: string;
	osGroupColor: ChartColor;
	controllerType: string;
	controllerTypeGroup: string;
	typeGroupColor: ChartColor;
	action: ControllerActionType;
	upToDateController: boolean;
	lastCheckedInAt: string | Date;
	orderId?: number;
	previousOrderIds: number[];
	lastOrderDate?: string | Date;
}

export enum ControllerActionType {
	UpToDate = "Up to Date",
	Outdated = "Upgrade Controller",
	Requested = "Upgrade Requested",
	OrderPending = "Upgrade in Cart",
	InstallationPending = "Pending Installation",
	Upgraded = "Upgraded",
}

export interface ICustomerSummaryData extends ICacheable {
	dataRefreshDate: Date;
	consumers: ICustomerSummary[];
	accountId: string;
}

export interface ICustomerUpgradeRequest {
	requestId: number;
	orderId: number;
	requestDate: Date;
	modifiedDate: Date;
	accountId: string;
	userId: string;
	customerId: string;
	deviceUuid: string;
	macAddress: string;
	status: string;
	sku: string;
	upgradeType: string;
}

export interface ChartSegment {
	id: number;
	name: string;
}

export interface ControllerOsGroup extends ChartSegment {
	color: ChartColor;
	controllerOs: ControllerOs[];
}

export interface ControllerOs extends ChartSegment {
	groupId: number;
}

export interface ControllerTypeGroup extends ChartSegment {
	color: ChartColor;
	controllerTypes: ControllerType[];
}

export interface ControllerType extends ChartSegment {
	consumerTypeCode: string;
	groupId: number;
}

export interface IConsumerFilter {
	searchTerm: string;
	upgradeType: ControllerUpgradeType;
	controllerTypeIds: number[];
	controllerTypeGroupIds: number[];
	controllerOsIds: number[];
	controllerOsGroupIds: number[];
}

export interface IPresetConsumerFilter extends IConsumerFilter {
	label: string;
	slug: string;
	description: string;
}

export enum ControllerUpgradeType {
	All,
	UpToDate,
	Outdated,
}

export const ControllerUpgradeTypeLabels = new Map([
	[ControllerUpgradeType.All, "All Controllers"],
	[ControllerUpgradeType.UpToDate, "Up to Date Controllers"],
	[ControllerUpgradeType.Outdated, "Outdated Controllers"],
]);

export interface ICustomerLicenseSummary {
	consumerId: string;
	consumerName: string;
	consumerPhone: string;
	consumerEmail: string;
	consumerPurchase: boolean;
	renewingLicense: boolean;
	fourSightActivationDate: string;
	fourSightExpirationDate: string;
	composerActivationDate: string;
	dealerId: string;
	dealerName: string;
	dealerPhone: string;
	dealerEmail: string;
	distributorAccountName: string;
	licenseHistory: ILicensePurchase[];

	// Front-end only
	daysUntilExpiration?: number;
	iconClass: string;
	displayDetails: boolean;
}

export interface ICustomerLicenseSummaryData {
	accountId: string;
	licenses: ICustomerLicenseSummary[];
	refreshDate: Date;
}

export interface ILicensePurchase {
	consumerPurchase: boolean;
	renewingLicense: boolean;
	purchaseDate: string;
}

export interface ICustomerSummaryFilter {
	searchTerm: string;
	expiryType: string;
	purchaseType: string;
	startDate: Date;
	endDate: Date;
}

export interface ILicenseAdoptionSummary extends ICacheable {
	totalConsumers: number;
	total4Sight: number;
	totalComposerHe: number;
	total4SightDealer: number;
	total4SightConsumer: number;
	total4SightRenewal: number;
	dataRefreshDate: string;

	accountId: string;
}

export class LicenseExpirationFilterType {
	static all = "all";
	static expired = "expired";
	static expiredIn30 = "expiring30";
	static expiredIn60 = "expiring60";
	static expiredIn90 = "expiring90";
	static noLicense = "none";
}

export const licenseExpirationMap = new Map([
	[LicenseExpirationFilterType.all, "All 4Sight"],
	[LicenseExpirationFilterType.expired, "Expired 4Sight"],
	[LicenseExpirationFilterType.expiredIn30, "Expires 0-30 days"],
	[LicenseExpirationFilterType.expiredIn60, "Expires 30-60 days"],
	[LicenseExpirationFilterType.expiredIn90, "Expires 60-90 days"],
	[LicenseExpirationFilterType.noLicense, "No 4Sight"],
]);

export class LicensePurchaseType {
	static all = "all";
	static dealer = "dealer";
	static consumer = "consumer";
	static autoRenew = "renewal";
	static noLicense = "none";
}

export const licensePurchaseMap = new Map([
	[LicensePurchaseType.all, "All Purchases"],
	[LicensePurchaseType.dealer, "Dealer Purchased"],
	[LicensePurchaseType.consumer, "Consumer Purchased"],
	[LicensePurchaseType.autoRenew, "Auto-renew"],
	[LicensePurchaseType.noLicense, "No 4Sight"],
]);

export interface ILeadPreferences extends ICacheable {
	accountId: string;
	primaryContactName: string;
	primaryContactEmail: string;
	primaryContactPhone: string;
	dealerPhoneForLeads: string;
	dealerEmailForLeads: string;
	dealerContactForLeads: string;
	preferredTravel: string;
	control4Solution: string[];
	timeToInstall: string;
	budgetPreference: string;
	projectSize: string[];
	wantsLeads: boolean;
	installType: string[];
	leadContact: ILeadContact;
	availableLeadContacts: ILeadContact[];
}

export interface ILeadContact {
	name: string;
	phone: string;
	email: string;
	fax: string;
	company: string;
	contactId: string;
}

export interface Lead {
	opportunityId: string;
	name: string;
	email: string;
	phone: string;
	consumerAccount: string;

	status: LeadStatusType;
	notes: string;
	leadType: LeadType;
	leadGenNotes: string;
	details: Dictionary<string>;
	lastModifiedAt: string;

	formattedAddress: string;
	streetAddress: string;
	city: string;
	state: string;
	postalCode: string;
	country: string;

	formattedDealerAddress: string;
	dealerAddress: string;
	dealerCity: string;
	dealerState: string;
	dealerZip: string;
	partySizeCount: string;

	updates: LeadStatusUpdateDetail[];
}

export class UpdateLeadStatusPayload {
	opportunityId: string;
	status: LeadStatusType;
	rejectionReason?: string;
	rejectionExplanation?: string;
	notes?: string;
	installDate?: Date;
	projectName?: string;
}

export interface LeadStatusUpdateDetail {
	opportunityId: string;
	createdAt: Date;
	createdBy: string;
	status: string;
	dealerNotes: string;
}

export enum LeadType {
	Balihoo = "Balihoo",
	Telesales = "Telesales",
	SentToDealer = "Sent to Dealer",
	Showroom = "Showroom Event",
	Upgrade = "Upgrade",
	CustomerRequestedUpgrade = "Customer Requested Upgrade",
	Microsite = "Microsite",
	LightingRequest = "Lighting Request",
}

export enum LeadDisplayType {
	Control4Qualified = "control4",
	Showroom = "showroom",
	DealerUpgrade = "dealerUpgrade",
	CustomerUpgrade = "customerUpgrade",
	Microsite = "microsite",
	LightingRequest = "lightingRequest",
}

export enum LeadStatusType {
	SentToDealer = "Sent to Dealer",
	DealerContacting = "Dealer Contacting",
	LeadContacted = "Lead Contacted by Dealer",
	Walkthrough = "Walkthrough",
	DealerBid = "Dealer Bid",
	BidAccepted = "Bid Accepted",
	Complete = "Closed Won",
	Closed = "Closed Lost",
}

export interface DealerLeadIds extends ICacheable {
	ids: string[];
}

export interface ILeadCounts {
	newTotal: number;
	openTotal: number;
	openLeads: number;
	openUpgrades: number;
	openShowrooms: number;
}

export enum LeadStatusType_New {
	New = "New!",
	NoContact = "No Contact",
	Contacted = "Contacted",
	ProposalAccepted = "Proposal Accepted",
	Completed = "Installed",
	Closed = "Rejected",
}

export const oldToNewLeadsStatusLabelMap = new Map<LeadStatusType, LeadStatusType_New>([
	[LeadStatusType.SentToDealer, LeadStatusType_New.New],
	[LeadStatusType.DealerContacting, LeadStatusType_New.NoContact],
	[LeadStatusType.LeadContacted, LeadStatusType_New.Contacted],
	[LeadStatusType.Walkthrough, LeadStatusType_New.Contacted],
	[LeadStatusType.DealerBid, LeadStatusType_New.Contacted],
	[LeadStatusType.BidAccepted, LeadStatusType_New.ProposalAccepted],
	[LeadStatusType.Complete, LeadStatusType_New.Completed],
	[LeadStatusType.Closed, LeadStatusType_New.Closed],
]);

export const leadTypeDisplayMap = new Map<LeadType, LeadDisplayType>([
	[LeadType.CustomerRequestedUpgrade, LeadDisplayType.CustomerUpgrade],
	[LeadType.Microsite, LeadDisplayType.Microsite],
	[LeadType.Balihoo, LeadDisplayType.Microsite],
	[LeadType.Telesales, LeadDisplayType.Control4Qualified],
	[LeadType.Upgrade, LeadDisplayType.DealerUpgrade],
	[LeadType.Showroom, LeadDisplayType.Showroom],
	[LeadType.LightingRequest, LeadDisplayType.LightingRequest],
]);