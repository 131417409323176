import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { QuickViewPopupComponent } from "@app/popups/quick-view-popup/quick-view-popup.component";
import { IProductLightModel } from "@app/products/products.models";
import { CartService } from "@app/services/cart.service";
import { CloudinaryService } from "@app/services/cloudinary.service";
import { PopupService } from "@app/services/popup.service";
import { ProductService } from "@app/services/product.service";
import { TrackService } from "@app/services/track.service";
import { IAppState } from "@app/store";
import { getCurrentCurrencySymbol } from "@app/store/account";
import { getIsMobile } from "@app/store/app-info/app-info.selectors";
import { selectIsMsrp } from "@app/store/auth";
import { modifyingCurrentCart } from "@app/store/cart";
import { BulkLoadProductsLightAttempt, selectProductLightBySkuList } from "@app/store/product-light";
import { currentCanPurchase, currentCanViewPricing } from "@app/store/user";
import { select, Store } from "@ngrx/store";
import { combineLatest, Observable, Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

@Component({
	selector: "c4-product-card",
	templateUrl: "./product-card.component.html",
	styleUrls: ["./product-card.component.scss"],
})
export class ProductCardComponent implements OnInit, OnDestroy {

	@Input() skus: string[];
	@Input() sku: string;

	@Input() section: string;
	@Input() ignoreSuggested: boolean;
	@Input() ignoreCustom: boolean;
	@Input() ignorePromo: boolean;

	@Input() product: IProductLightModel;
	@Input() category: number;
	@Input() isInCart = false;

	get allSkus() {
		return [...(this.skus || []), this.sku].filter(x => !!x);
	}

	vm$: Observable<{
		currency: string;
		isMsrp: boolean;
		isMobile: boolean;
		userCanViewPricing: boolean;
		userCanPurchase: boolean;
		modifyingCart: boolean;
	}>;

	productViewPricing: boolean;

	private destroyed$ = new Subject<{}>();

	constructor(
		readonly store: Store<IAppState>,
		readonly popupService: PopupService,
		readonly cloudinary: CloudinaryService,
		readonly cartService: CartService,
		readonly productService: ProductService,
		readonly tracking: TrackService) { }

	ngOnInit() {
		this.vm$ = combineLatest([
			this.store.pipe(select(selectIsMsrp)),
			this.store.pipe(select(getIsMobile)),
			this.store.pipe(select(getCurrentCurrencySymbol)),
			this.store.pipe(select(currentCanViewPricing)),
			this.store.pipe(select(currentCanPurchase)),
			this.store.pipe(select(modifyingCurrentCart)),
		]).pipe(map(([isMsrp, isMobile, currency, userCanViewPricing, userCanPurchase, modifyingCart]) =>
			({ isMsrp, isMobile, currency, userCanViewPricing, userCanPurchase, modifyingCart }),
		));

		if (!this.product) {
			if (!this.skus && !this.sku) {
				console.error("Cannot load product-card without SKUs");
			} else {
				this.store.pipe(
					select(selectProductLightBySkuList(this.allSkus)),
					takeUntil(this.destroyed$),
				).subscribe(product => {
					this.product = product;
					if (!this.product) {
						this.store.dispatch(new BulkLoadProductsLightAttempt(this.allSkus));
					} else {
						this.load();
					}
				});
			}
		} else {
			this.load();
		}
	}

	private load() {
		this.productViewPricing = !this.product.isDisplayOnly && this.product.dealerPrice > 0;
		this.tracking.trackImpression(this.product, this.section, this.category || this.product.categoryId);
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}

	openQuickView() {
		this.popupService.create(QuickViewPopupComponent, { sku: this.product.sku, category: this.category });
	}

}