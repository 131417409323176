import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IProductLookupResult, IReturnLabel, IReturnLabelRequest, IReturnLabelResult, IReturnRequestLabelInfo, IRmaHistoryItem, IRmaHistoryRequest, IRmaHistoryResult, ISubmitRmaInfo, ISubmitRmaRequest, ISubmitRmaResult } from "@app/rma/rma.models";
import { Portals } from "@app/shared/shared.models";

@Injectable({
	providedIn: "root"
})
export class RmaService {

	constructor(private http: HttpClient) { }

	getRecentSalesforceCases() {
		return this.http.get<IRmaHistoryItem[]>("api/rma/GetRecentSalesforceCases");
	}

	createSupportContactCase(briefQuestion: string, detailedQuestion: string, brand: Portals) {
		return this.http.post("api/rma/CreateSupportContactCase", { briefQuestion, detailedQuestion, brand }, { responseType: "text" });
	}

	getRmaHistory(args: IRmaHistoryRequest, pageSize: number) {
		let params = new HttpParams().append("itemsPerPage", pageSize.toString()).append("page", args.page.toString());
		if (args.caseNumber) {
			params = params.append("caseNumber", args.caseNumber);
		}
		if (args.subject) {
			params = params.append("subject", args.subject);
		}
		if (args.startDate) {
			params = params.append("startDate", args.startDate);
		}
		if (args.endDate) {
			params = params.append("endDate", args.endDate);
		}
		return this.http.get<IRmaHistoryResult>("api/rma/GetRmaHistory", { params });
	}

	getReturnLabels(caseNumber: string) {
		return this.http.get<IReturnLabel[]>("api/rma/GetReturnLabels", { params: { caseNumber } });
	}

	getSubmitRmaInfo() {
		return this.http.get<ISubmitRmaInfo>("api/rma/GetSubmitRmaInfo");
	}

	lookupProduct(serialOrMac: string) {
		return this.http.get<IProductLookupResult>("api/rma/LookupProduct", { params: { serialOrMac } });
	}

	submitRma(args: ISubmitRmaRequest) {
		return this.http.post<ISubmitRmaResult>("api/rma/SubmitRma", args);
	}

	getRequestReturnLabelInfo(caseId: string) {
		return this.http.get<IReturnRequestLabelInfo>("api/rma/GetRequestReturnLabelInfo", { params: { caseId } });
	}

	requestReturnLabels(args: IReturnLabelRequest) {
		return this.http.post<IReturnLabelResult>("api/rma/RequestReturnLabels", args);
	}
}
