import { addHours, addMinutes, addSeconds, differenceInMinutes, isAfter } from "date-fns";
import { ICacheable, SiteFeatureFlag, SiteFeatureFlagType } from "./shared.models";

export function deepcopy<T>(object: T): T {
	return object != null ? JSON.parse(JSON.stringify(object)) as T : object;
}

export function startsWithIgnoreCase(val: string, match: string) {
	return val && match && val.toLowerCase().indexOf(match.toLowerCase()) === 0;
}

export function endsWithIgnoreCase(val: string, match: string) {
	return val && match && val.toLowerCase().slice(val.length - match.length) === match.toLowerCase();
}

export function contains(val: string, match: string) {
	return val && match && val.toLowerCase().includes(match.toLowerCase());
}

export function equalsIgnoreCase(val: string, match: string) {
	return (val == null && match == null) || (val && match && val.toLowerCase() === match.toLowerCase());
}

export function isNumber(val: any, includeHex?: boolean) {
	if (typeof val === "number") {
		return true;
	} else if (val.includes("x") && !includeHex) {
		return false;
	}
	const convert = Number(val);

	return !Number.isNaN(convert) && (convert !== 0 || val.match(/(.0+)|(0+(\.0*)?)/));
}

export function objectSort<T extends Object, U extends keyof T>(a: T, b: T, keys?: (U | string)[]) {
	if (!keys) {
		return valueSort(a, b);
	} else {
		for (let i = 0; i < keys.length; i++) {
			const key = getKeyInfo(<string><unknown>keys[i]);
			const comparer: (a: any, b: any, desc?: boolean) => number = typeof a[key.key] === "string" ? stringCompareIgnoreCase : valueSort;
			const check = comparer(a[key.key], b[key.key], key.desc);
			if (check !== 0) {
				return check;
			}
		}

		return 0;
	}
}

export function dateSort(a: Date | string, b: Date | string, desc?: boolean) {
	return isAfter(a, b) ? 1 : -1 * (desc ? -1 : 1);
}

function getKeyInfo<T extends Object, U extends keyof T>(key: string) {
	const fChar = key[0];
	if (fChar === "+" || fChar === "-") {
		key = key.substring(1);
	}

	return { key: (key as U), desc: fChar === "-" };
}

export function valueSort<T>(a: T, b: T, desc?: boolean) {
	if (a === b) {
		return 0;
	}

	return (a > b ? 1 : -1) * (desc ? -1 : 1);
}

export function replace<T>(items: T[], old: T, replacement: T) {
	const idx = items.findIndex(x => x === old);
	const r = [...items];
	if (idx >= 0) {
		r[idx] = replacement;
	}

	return r;
}

export function removeRange<T>(items: T[], removed: T[]) {
	if (!removed || !removed.length) {
		return items;
	}
	const mine = [...items];
	removed.forEach(item => {
		const idx = mine.indexOf(item);
		mine.slice(idx, 1);
	});

	return mine;
}

export function remove<T>(items: T[], item: T) {
	return removeAt(items, items.indexOf(item));
}

export function removeAt<T>(items: T[], idx: number): T[] {
	if (idx < 0 || idx >= items.length) {
		return [...items];
	}
	const before = items.slice(0, idx);
	const after = items.slice(idx + 1);

	return [...before, ...after];
}

export function replaceAt<T>(items: T[], idx: number, item: T): T[] {
	if (idx < 0 || idx >= items.length) {
		return [...items];
	}
	const before = items.slice(0, idx);
	const after = items.slice(idx + 1);

	return [...before, item, ...after];
}

export function addFirst<T>(items: T[], item: T): T[] {
	return [item, ...items];
}

export function addLast<T>(items: T[], item: T): T[] {
	return [...items, item];
}

export function addAt<T>(items: T[], item: T, idx: number): T[] {
	if (idx >= items.length) {
		addLast(items, item);
	} else if (idx <= 0) {
		return addFirst(items, item);
	}
	const before = items.slice(0, idx);
	const after = items.slice(idx);

	return [...before, item, ...after];
}

export function isEmptyObject(obj: Object) {
	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			return false;
		}
	}

	return true;
}

export function convertToMap<T, K extends keyof T, V extends keyof T>(items: T[], key: K, value: V) {
	const map = new Map<T[K], T[V]>();
	items.forEach(item => map.set(item[key], item[value]));

	return map;
}

export function objectUpdate<T extends object>(old: T, update: Partial<T>) {
	return Object.assign({}, old, update);
}

export function stringCompareIgnoreCase(a: string, b: string, desc?: boolean) {
	return valueSort(a.toLowerCase(), b.toLowerCase(), desc);
}

export function checkDisplayToggle(info: SiteFeatureFlag, isInternal = false) {
	return info && (info.flag === SiteFeatureFlagType.On || (info.flag === SiteFeatureFlagType.Internal && isInternal));
}

export function isDisplayToggleInternal(info: SiteFeatureFlag) {
	return info && info.flag === SiteFeatureFlagType.Internal;
}

export function getRandomInt(max: number) {
	return Math.floor(Math.random() * Math.floor(max));
}

export function arraySwap(arr: any[], a: number, b: number) {
	const temp = arr[a];
	arr[a] = arr[b];
	arr[b] = temp;
}

export function removeFromArray<T>(arr: T[], item: T) {
	const idx = arr.findIndex(x => x === item);

	return idx >= 0 ? removeFromArrayByIndex(arr, idx) : arr;
}

export function removeFromArrayByIndex<T>(arr: T[], idx: number) {
	return [...arr.slice(0, idx), ...arr.slice(idx + 1)];
}

export function ngStoreCacheItem<T extends ICacheable>(item: T, amount: number, unit: "hours" | "minutes" | "seconds") {
	switch (unit) {
		case "hours":
			return Object.assign({}, item, { expiration: addHours(new Date(), amount) });
		case "minutes":
			return Object.assign({}, item, { expiration: addMinutes(new Date(), amount) });
		case "seconds":
			return Object.assign({}, item, { expiration: addSeconds(new Date(), amount) });
	}
}

export function getNgStoreCacheItem<T extends ICacheable>(item: T) {
	return !(item && differenceInMinutes(item.expiration, new Date()) < 0) ? item : undefined;
}

export function randomSelect<T>(items: T[], max: number) {
	if (items.length <= max || max <= 0) {
		return items;
	}
	for (let i = 0; i < max; i++) {
		const rand = getRandomInt(items.length);
		arraySwap(items, rand, i);
	}

	return items.slice(0, max);
}

export function range(start: number, end: number) {
	return [...Array(end - start).keys()].map(x => x + start);
}

export function isNullOrWhitespace(value: string) {
	return !value || !value.trim();
}

export function bitwiseAnd(val1: number, val2: number) {
	const highBit = Math.pow(2, 31);
	return (val1 >= highBit || val2 >= highBit) ? bitwiseAndLarge(val1, val2) : val1 & val2;
}

function bitwiseAndLarge(val1: number, val2: number) {
	let shift = 0;
	let result = 0;
	const mask = ~((~0) << 30);
	const divisor = 1 << 30;
	while ((val1 != 0) && (val2 != 0)) {
		let rs = (mask & val1) & (mask & val2);
		val1 = Math.floor(val1 / divisor);
		val2 = Math.floor(val2 / divisor);
		for (let i = shift++; i--;) {
			rs *= divisor;
		}
		result += rs;
	}
	return result;
}