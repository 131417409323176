import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { IAddToCartModel } from "@app/checkout/checkout.models";
import { IGrillCustomizations, IPartCustomizationsModel, IPartFinishOption } from "@app/products/products.models";
import { ProductCustomizationService } from "@app/services/product-customization.service";
import { equalsIgnoreCase, objectUpdate } from "@app/shared/helpers";
import { Subject } from "rxjs";

@Component({
	selector: "c4-product-customization-grill",
	templateUrl: "./customization-grill.component.html",
	styleUrls: ["./customization-grill.component.scss"],
})
export class CustomizationGrillComponent implements OnInit, OnDestroy, OnChanges {
	@Input() options: IPartCustomizationsModel;

	@Input() customizations: IGrillCustomizations;
	@Output() customizationsChange = new EventEmitter<IGrillCustomizations>();

	grillColor: IPartFinishOption;
	paintManufacturer = "";
	paintCode: string;
	showPaintCode: boolean;
	grillSwatchStyle = "";
	editedItemAddOn: IAddToCartModel;

	private readonly destroyed$ = new Subject<{}>();

	constructor(readonly customizationService: ProductCustomizationService) { }

	ngOnInit() {
		this.populateItem();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.options && changes.options.previousValue !== changes.options.currentValue) {
			this.populateItem();
			this.customizationsChange.emit(this.customizations);
		}
	}

	populateItem() {
		const cartItemInfo = { isCustomized: true, isValid: !this.options.canCustomizeGrillShape } as IGrillCustomizations;
		let initialColor = "White";

		if (this.customizations.grillType) {
			cartItemInfo.grillType = this.customizations.grillType;
		} else if (this.options.customGrillOptions.length === 1) {
			cartItemInfo.grillType = this.options.customGrillOptions[0].grillShape;
		} else {
			cartItemInfo.grillType = "round";
		}

		if (this.customizations.grillPaintManufacturer) {
			initialColor = "Custom Color†";
			this.paintManufacturer = this.customizations.grillPaintManufacturer;
			this.paintCode = this.customizations.grillPaintCode;
			this.showPaintCode = this.paintManufacturer.indexOf("Matching") < 0;
		} else if (this.customizations.color) {
			initialColor = this.customizations.color;
		}

		this.grillColor = this.customizationService.paintFinishes.find(x => x.value === initialColor);
		cartItemInfo.color = this.getGrillPaint();

		cartItemInfo.grillOption = this.getGrillOption(cartItemInfo.grillType);
		if (cartItemInfo.grillOption) {
			this.editedItemAddOn = this.getAddOnItem(cartItemInfo);
			if (this.editedItemAddOn) {
				this.editedItemAddOn.grillPaintCode = cartItemInfo.color;
				this.editedItemAddOn.quantity = this.customizations.quantity;
			}
		}

		setTimeout(e => this.updateCustomizations({ color: cartItemInfo.color, grillType: cartItemInfo.grillType }), 0);
	}

	ngOnDestroy() {
		this.destroyed$.next();
		this.customizationsChange.emit({ isCustomized: false } as IGrillCustomizations);
	}

	changeGrillShape(grillType: string) {
		this.updateCustomizations({ grillType });
	}

	onGrillColorChange(color: IPartFinishOption) {
		this.updateGrillPaint();
		this.grillSwatchStyle = color && color.image ? `url(https://res.cloudinary.com/control4/image/upload/triadveneer-${color.image})` : "";
	}

	onPaintManufacturerChange(manufacturer: string) {
		this.showPaintCode = manufacturer && manufacturer.indexOf("Matching") < 0;
		this.updateGrillPaint();
	}

	updateGrillPaint() {
		this.updateCustomizations({ color: this.getGrillPaint() });
	}

	private getGrillPaint(): string {
		let paint = "";
		if (this.grillColor && !this.customizationService.isCustomColor(this.grillColor.value)) {
			paint = this.grillColor.value;
		} else if (this.paintManufacturer === "Color Matching Service††") {
			paint = this.paintManufacturer;
		} else if (this.paintManufacturer && this.paintCode) {
			paint = `Custom: ${this.paintManufacturer}, ${this.paintCode}`;
		}

		return paint;
	}

	updateCustomizations(changes: Partial<IGrillCustomizations>) {
		const updated = objectUpdate(this.customizations, changes);

		updated.isCustomColor = this.customizationService.isCustomColor(this.grillColor.value);
		updated.isCustomized = true;

		updated.grillOption = this.getGrillOption(updated.grillType);
		if (updated.grillOption) {
			updated.addOnItem = this.getAddOnItem(updated);
		}

		if (!updated.isCustomColor) {
			this.showPaintCode = false;
			this.paintCode = null;
			this.paintManufacturer = "";
		}

		updated.grillPaintCode = this.paintCode;
		updated.grillPaintManufacturer = this.paintManufacturer;
		updated.editedItemAddOn = this.editedItemAddOn;
		updated.isValid = !updated.isCustomColor || (updated.grillPaintManufacturer && !!updated.grillPaintCode) || this.customizationService.customColorMatch(this.paintManufacturer);

		this.customizationsChange.emit(updated);
	}

	getGrillOption(grillType: string) {
		return grillType ? this.options.customGrillOptions.find(option => equalsIgnoreCase(option.grillShape, grillType)) : undefined;
	}

	getAddOnItem(customizations: IGrillCustomizations): IAddToCartModel {
		const addOnSku = this.getGrillAddonSku(customizations);
		if (addOnSku) {
			const grillOption = customizations.grillOption;
			const addQuantity = grillOption.addQuantity > 0 ? grillOption.addQuantity : 1;
			return { sku: addOnSku, quantity: addQuantity, grillType: grillOption.grillShape, grillColor: this.customizations.color };
		}
		return undefined;
	}

	getGrillAddonSku(customizations: IGrillCustomizations): string {
		const isRound = customizations.grillType === "round";
		const colorCustomized = customizations.color !== "White";

		if ((!isRound && this.options.canCustomizeGrillShape) || colorCustomized) {
			return colorCustomized ? customizations.grillOption.addPartNumber : customizations.grillOption.nonCustomizedAddPartNumber;
		}

		return null;
	}
}
