import { CartType, IOrderTotalsModel } from "@app/checkout/checkout.models";
import { ICustomerSummary } from "@app/dashboard/dashboard.models";
import { objectUpdate } from "@app/shared/helpers";
import { AuthActionType, ImpersonateSuccess, UnimpersonateAttempt } from "@app/store/auth";
import { CartActions, CartActionType } from "@app/store/cart";
import { cartAdapter } from "@app/store/cart/cart.adapter";
import { EntityState } from "@ngrx/entity";

export interface ICartState extends EntityState<IOrderTotalsModel> {
	pcnaCartId: number;
	currentCartId: number;
	currentLoading: boolean;
	currentCustomer: ICustomerSummary;
}

export const initialState: ICartState = cartAdapter.getInitialState({
	pcnaCartId: 0,
	currentCartId: 0,
	currentLoading: false,
	currentCustomer: undefined,
});

export function cartReducer(
	state = initialState,
	action: CartActions | ImpersonateSuccess | UnimpersonateAttempt,
): ICartState {
	switch (action.type) {
		case CartActionType.ChangeActiveCartAttempt:
		case CartActionType.ChangeActiveCartError:
		case CartActionType.LoadCurrentCartAttempt:
		case CartActionType.LoadCurrentCartError: {
			return { ...state, currentLoading: true };
		}
		case CartActionType.ChangeActiveCartSuccess:
		case CartActionType.LoadCurrentCartSuccess: {
			let currentCustomer = action.payload.customer;
			if (action.type === CartActionType.LoadCurrentCartSuccess) {
				currentCustomer = action.payload.cartType === CartType.Upgrade ? currentCustomer || state.currentCustomer : undefined;
			}
			const updatedState = objectUpdate(state, { currentCartId: action.payload.orderId, currentLoading: false, currentCustomer });
			return cartAdapter.upsertOne(action.payload, updatedState);
		}
		case CartActionType.LoadCartSuccess: {
			return cartAdapter.upsertOne(action.payload, state);
		}
		case CartActionType.LoadPcnaCartSuccess: {
			const updatedState = objectUpdate(state, { pcnaCartId: action.payload.orderId });
			return cartAdapter.upsertOne(action.payload, updatedState);
		}
		case CartActionType.AddCartItemSuccess:
		case CartActionType.AddCartPromoSuccess:
		case CartActionType.CartUpdateSuccess: {
			let updatedState = state;
			if (action.totals.cartType === CartType.Default) {
				updatedState = objectUpdate(state, { currentCartId: action.totals.orderId });
			} else if (action.totals.cartType === CartType.Pcna && action.totals.cartName === "PCNA") {
				updatedState = objectUpdate(state, { pcnaCartId: action.totals.orderId });
			}
			return cartAdapter.upsertOne(action.totals, updatedState);
		}
		case CartActionType.CheckoutPageLoadedSuccess: {
			const updatedState = objectUpdate(state, { currentCartId: action.cart.order.id });
			return cartAdapter.upsertOne(action.cart.orderTotals, updatedState);
		}
		case CartActionType.ClearCartSuccess: {
			const updatedCart = {
				orderId: action.payload.orderId,
				lineItemSum: 0,
				subtotal: 0,
				discountTotal: 0,
				discountedSnapSubtotal: 0,
				grandTotal: 0,
				taxTotal: 0,
				shippingTotal: 0,
				discountedSubtotal: 0,
				partnerShippingTotals: [],
				msrpSubtotal: 0,
				oversizedItems: [],
				shippingAlertItems: [],
				lineItemCount: 0,
			} as IOrderTotalsModel;
			return cartAdapter.upsertOne(updatedCart, state);
		}
		case CartActionType.OrderPlacedSuccess: {
			const updatedState = objectUpdate(state, {
				currentCartId: action.payload.orderId === state.currentCartId ? action.payload.newOrderId : state.currentCartId,
				pcnaCartId: action.payload.orderId === state.pcnaCartId ? action.payload.newOrderId : state.pcnaCartId,
			});
			return cartAdapter.removeOne(action.payload.orderId, updatedState);
		}
		case CartActionType.UpdateBilling:
		case CartActionType.UpdateShipping:
		case CartActionType.UpdateModifyingCart: {
			return cartAdapter.updateOne({ id: action.payload.orderId, changes: { modifying: false, ...action.payload } }, state);
		}
		case AuthActionType.ImpersonateSuccess: {
			// All carts besides the current cart have unique IDs
			const currentCartId = state.currentCartId;
			const updatedState = objectUpdate(state, { currentCartId: action.payload.user.activeCartId, pcnaCartId: 0 });
			return cartAdapter.removeMany([0, currentCartId], updatedState);
		}
		case AuthActionType.UnimpersonateAttempt: {
			// All carts besides the current cart have unique IDs
			const currentCartId = state.currentCartId;
			const updatedState = objectUpdate(state, { currentCartId: 0, pcnaCartId: 0 });
			return cartAdapter.removeMany([0, currentCartId], updatedState);
		}
		default:
			return state;
	}
}
