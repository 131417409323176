import { Pipe, PipeTransform } from "@angular/core";
import { Portals } from "@app/shared/shared.models";
import { PcnaHelper } from "@app/training/training.models";

@Pipe({ name: "pcnaCourse" })
export class PcnaCoursePipe implements PipeTransform {
	transform(courseId: number, portal: Portals, wrap: boolean = false): string {
		const courseIds = PcnaHelper.PcnaCourseIds.get(portal);
		// Zero index, plus we want the next course
		const course = courseIds.indexOf(courseId) + 2;

		return course > courseIds.length || !courseId ? "" : (wrap ? ` (Course ${course})` : `Course ${course}`);
	}
}
