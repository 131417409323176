import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { AuthService } from "@app/services/auth.service";
import { checkDisplayToggle, equalsIgnoreCase } from "@app/shared/helpers";
import { Observable } from "rxjs";

/**
 * Prevent unauthorized activating and loading of routes
 * @class FeatureFlagAuthGuard
 */
@Injectable()
export class FeatureFlagAuthGuard implements CanActivate {
	constructor(readonly router: Router, private authService: AuthService) { }

	canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		return new Promise(res => {
			this.authService.getSiteFeatureFlags().subscribe(flags => {
				const selectedFlag = flags.find(x => equalsIgnoreCase(route.data.hideFlag, x.key));
				if (checkDisplayToggle(selectedFlag)) {
					this.router.navigate(["/404"]);
					res(false);
				} else {
					res(true);
				}
			}, error => {
				res(true);
			},
			);
		});
	}
}
