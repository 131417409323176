import { ngStoreCacheItem, objectUpdate } from "@app/shared/helpers";
import { ICategoryModel } from "@app/shared/shared.models";
import { EntityState } from "@ngrx/entity";
import { AuthActions, AuthActionType } from "../auth";
import { CategoryActions, CategoryActionType } from "./product-category.actions";
import { categoryAdapter } from "./product-category.adapter";

export interface ICategoryState extends EntityState<ICategoryModel> {
	selectedId: number;
	listView: boolean;
}
export const categoryState: ICategoryState = categoryAdapter.getInitialState({
	selectedId: 0,
	listView: false,
});
export function categoryReducer(
	state = categoryState,
	action: CategoryActions | AuthActions,
): ICategoryState {
	switch (action.type) {
		case CategoryActionType.LoadCategorySuccess:
			return categoryAdapter.upsertOne(ngStoreCacheItem(action.payload, 20, "minutes"), state);
		case CategoryActionType.SelectCategory:
			return objectUpdate(state, { selectedId: action.payload });
		case CategoryActionType.UpdateListView:
			return objectUpdate(state, { listView: action.payload });
		case AuthActionType.ImpersonateSuccess:
		case AuthActionType.UnimpersonateSuccess:
			return categoryAdapter.removeAll(state);
		default:
			return state;
	}
}