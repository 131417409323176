import { IOrderHistoryItemDetailInfo } from "@app/account/account.models";
import { ngStoreCacheItem } from "@app/shared/helpers";
import { OrderHistoryDetailActions, OrderHistoryDetailActionTypes } from "@app/store/order-history-details/order-history-details.actions";
import { orderHistoryDetailsAdapter } from "@app/store/order-history-details/order-history-details.adapter";
import { EntityState } from "@ngrx/entity";

export interface IOrderHistoryDetailsState extends EntityState<IOrderHistoryItemDetailInfo> { }

export const initialState: IOrderHistoryDetailsState = orderHistoryDetailsAdapter.getInitialState();

export function orderHistoryDetailsReducer(
	state = initialState,
	action: OrderHistoryDetailActions
): IOrderHistoryDetailsState {
	switch (action.type) {
		case OrderHistoryDetailActionTypes.LoadOrderHistoryItemDetailsSuccess:
			return orderHistoryDetailsAdapter.upsertOne(ngStoreCacheItem(action.payload, 6, "hours"), state);
		default: {
			return state;
		}
	}
}