import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
	selector: "c4-product-customization-mount",
	templateUrl: "./customization-mount.component.html",
	styleUrls: ["./customization-mount.component.scss"]
})
export class CustomizationMountComponent implements OnInit {
	@Input() mount: "mount" | "floor";
	@Output() mountChange = new EventEmitter<string>();

	constructor() { }

	ngOnInit() {
	}

	changeMountType(mountType: string) {
		this.mountChange.next(mountType);
	}
}
