import { accountHasAccountRoleFlag, AccountSecurityRole, IUser, SecurityRoles, userHasSecurityFlag } from "@app/account/account.models";
import { objectUpdate } from "@app/shared/helpers";
import { Portals } from "@app/shared/shared.models";
import { userAdapter } from "@app/store/user/user.adapter";
import { UserState } from "@app/store/user/user.reducer";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { isBefore } from "date-fns";
import { getActiveUserAccount, getCurrentAccount, getImpersonatedAccount } from "../account";
import { getCurrentPortal } from "../app-info/app-info.selectors";

export const getUserState = createFeatureSelector<UserState>("users");
export const {
	selectIds: userSelectIds,
	selectEntities: userSelectEntities,
	selectAll: userSelectAll,
	selectTotal: userSelectTotal,
} = userAdapter.getSelectors(getUserState);

export const selectCurrentUserId = createSelector(
	getUserState, state => state.currentId,
);

export const selectCurrentUser = createSelector(
	userSelectEntities,
	selectCurrentUserId,
	(entities, id) => entities[id],
);

export const getCurrentUserWithAccount = createSelector(
	selectCurrentUser,
	getCurrentAccount,
	(user, account) => objectUpdate(user || {}, { account }) as IUser,
);

export const getCurrentUserIsDistributor = createSelector(
	getCurrentUserWithAccount, user => user && user.account && user.account.isDistributor && !user.subDealerId,
);

export const getCurrentUserIsUserUnderSubDealerShip = createSelector(
	selectCurrentUser, user => user.parentAccountId && user.parentAccountId !== user.accountId && !user.subDealerId,
);

export const selectCurrentUserSecurityRoles = createSelector(
	selectCurrentUser, user => user ? user.securityRoles : 0 as SecurityRoles,
);

export const selectActiveUserId = createSelector(
	getUserState, state => state.activeId,
);

export const selectActiveUser = createSelector(
	userSelectEntities,
	selectActiveUserId,
	(entities, id) => entities[id],
);

export const getActiveUserWithAccount = createSelector(
	selectActiveUser,
	getActiveUserAccount,
	(user, account) => objectUpdate(user || {}, { account }) as IUser,
);

export const selectActiveUserSecurityRoles = createSelector(
	selectActiveUser, user => user ? user.securityRoles : 0 as SecurityRoles,
);

export const selectImpersonatedUserId = createSelector(
	getUserState, state => state.impersonatedId,
);

export const selectImpersonatedUser = createSelector(
	userSelectEntities,
	selectImpersonatedUserId,
	(entities, id) => id && entities[id],
);

export const getImpersonatedUserWithAccount = createSelector(
	selectImpersonatedUser,
	getImpersonatedAccount,
	(user, account) => user && account && objectUpdate(user, { account }) as IUser,
);

export const currentCanViewPricing = createSelector(
	getCurrentUserWithAccount, user => user && userHasSecurityFlag(user, SecurityRoles.PriceViewing | SecurityRoles.PurchasingUser),
);

export const currentCanPurchase = createSelector(
	selectCurrentUser, user => user && userHasSecurityFlag(user, SecurityRoles.PurchasingUser),
);

export const canPurchaseOrOnBehalfOf = createSelector(
	selectCurrentUser,
	selectActiveUser,
	(current, active) => userHasSecurityFlag(current, SecurityRoles.PurchasingUser) || userHasSecurityFlag(active, SecurityRoles.InternalSuperUser),
);

export const currentIsSuper = createSelector(
	selectCurrentUser, user => user && userHasSecurityFlag(user, SecurityRoles.InternalSuperUser),
);

export const activeHasImpersonation = createSelector(
	selectActiveUser, user => user && userHasSecurityFlag(user, SecurityRoles.InternalSalesImpersonation),
);

export const currentCanSeeAccountStatus = createSelector(
	selectCurrentUser,
	getCurrentPortal,
	(user, portal) =>
		userHasSecurityFlag(user, SecurityRoles.ViewCompInfo | SecurityRoles.ViewOrderInfo)
		&& ([Portals.DealerUs, Portals.DealerEmea, Portals.Pakedge].includes(portal)),
);

export const currentUserIsSubdealer = createSelector(
	selectCurrentUser, user => user && !!user.subDealerId,
);

export const currentHasRole = (flag: SecurityRoles) => createSelector(
	selectCurrentUser, user => user && userHasSecurityFlag(user, flag),
);

export const activeHasRole = (flag: SecurityRoles) => createSelector(
	selectActiveUser, user => user && userHasSecurityFlag(user, flag),
);

export const currentHasAccountRole = (flag: AccountSecurityRole) => createSelector(
	getCurrentAccount, account => account && accountHasAccountRoleFlag(account, flag),
);

export const activeHasAccountRole = (flag: AccountSecurityRole) => createSelector(
	getActiveUserAccount, account => account && accountHasAccountRoleFlag(account, flag),
);

export const currentIsMigrating = () => createSelector(
	getCurrentAccount, account => {
		return account && account.migrationStartedDate != null && isBefore(account.migrationStartedDate, new Date())
	},
);

export const currentHasCompletedMigration = () => createSelector(
	getCurrentAccount, account => {
		return account && account.migrationCompletedDate != null && isBefore(account.migrationCompletedDate, new Date())
	},
);

export const currentHasMigrationScheduled = () => createSelector(
	getCurrentAccount, account => {
		return account && account.migrationScheduledDate != null && (account.migrationStartedDate == null || isBefore(new Date(), account.migrationStartedDate));
	},
);