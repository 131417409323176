import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AccountService } from "@app/services/account.service";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action, select, Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, debounceTime, filter, map, mergeMap, switchMap } from "rxjs/operators";
import { IAppState } from "../app.reducer";
import { LoadStatusProgressError, LoadStatusProgressSuccess, StatusProgressActionTypes } from "./status-progress.actions";
import { getCurrentStatusProgress } from "./status-progress.selectors";

@Injectable()
export class StatusProgessEffects {
	constructor(
		private actions$: Actions,
		private accountService: AccountService,
		private store: Store<IAppState>
	) { }

	@Effect()
	loadStatusProgress$: Observable<Action> = this.actions$.pipe(
		ofType(StatusProgressActionTypes.LoadStatusProgressAttempt),
		debounceTime(100),
		switchMap(() => this.store.pipe(select(getCurrentStatusProgress))),
		filter((data) => !data),
		mergeMap(() => {
			return this.accountService
				.getStatusProgress()
				.pipe(
					map((info) => new LoadStatusProgressSuccess(info)),
					catchError((err: HttpErrorResponse) => of(new LoadStatusProgressError({ error: err.message || err.statusText })))
				);
		})
	);
}
