import { Pipe, PipeTransform } from "@angular/core";
import { Portals } from "@app/shared/shared.models";
import { IAppState } from "@app/store";
import { getCurrentPortal } from "@app/store/app-info/app-info.selectors";
import { select, Store } from "@ngrx/store";

@Pipe({
	name: "statusBadgeType"
})
export class StatusBadgeTypePipe implements PipeTransform {

	currentPortal: Portals;

	constructor(private store: Store<IAppState>) {
		this.store.pipe(
			select(getCurrentPortal)
		).subscribe((portal: Portals) => {
			this.currentPortal = portal;
		});
	}

	transform(premierStatus: string): string {
		let statusLevel = premierStatus ? premierStatus.toLowerCase() : "authorized";
		if (statusLevel === "none") {
			statusLevel = "authorized";
		}
		switch (this.currentPortal) {
			case Portals.DealerEmea:
			case Portals.DealerUs:
				return statusLevel + "-status";
			case Portals.Pakedge:
				return `pakedge-dealer-${statusLevel}-status`;
			case Portals.Triad:
				return `triad-dealer-${statusLevel}-status`;
			default:
				return "";
		}
	}

}
