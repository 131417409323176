export interface IBrochureGroupModel {
	hasBrochures: boolean;

	dealerLiterature: IBrochureModel[];
	triadLiterature: IBrochureModel[];
	pakedgeLiterature: IBrochureModel[];
}

export interface IBrochureModel {
	id: number;
	sku: string;
	category: string;
	title: string;
	displayGroup: string;
	link: string;
	thumbnail: string;
	languages: ILanguageModel[];
}

export interface IMagazineModel {
	id: number;
	ordering_count: number;
	hsh_magazine_image: string;
	description: string;
	sub_title: string;
	hsh_pdf: string;
	hsh_pdf_preview: string;
	publish_date: string;
}

export interface ILanguageModel {
	language: string;
	link: string;
}

export interface ISignModel {
	id: number;
	hidden: boolean;
	category: string;
	title: string;
	link: string;
	thumbnail: string;
	languages: ILanguageModel[];
}

export interface IDesignServicesLeadModel {
	designServiceType: number;
	email: string;
	firstName: string;
	lastName: string;
	phone: string;
	dealerName: string;
	dealerID: string;
	projectName: string;
	cloudinaryFloorplanUrl: string;

	projectCompletionDate: string;
	projectConstructionType: number;
	theaterChallenges: string;
	theaterType: number;
	theaterDimensions: string;
	theaterSeating: string;
	theaterDisplayType: string;
	theaterDisplay: string;
	theaterSpeakerLayout: string;
	theaterMaxVolume: number;
	theaterSpeakerLine: number;
	theaterCustomerBudget: number;
	theaterSpeakerPlacement: string;
	theaterElectronics: string;
	theaterFloorplanAvailable: boolean;
	theaterFloorplan: string;
	theaterWallDepth: string;
	theaterCeilingDepth: string;

	audioWithTv: number;
	audioOutdoor: number;
	audioZonesQty: number;
	audioSpeakerPairQty: number;
	audioSurroundSound: number;
	audioSpeakerLayout: string;
	audioSpeakerLine: string;
	audioMaxVolume: number;
	audioCustomerBudget: number;
	audioSpeakerPlacement: string;
	audioElectronics: string;
	audioFloorPlanAvailable: boolean;
	audioFloorPlan: string;
	audioRoomDetail: string;
	audioRoomName: string;
	audioChallenges: string;
	audioStereoSound: number;
	audioOnScreenNavigator: number;
	audioDoorStationStyle: string;
	audioDoorStations: number;
	audioThermostats: number;
	audioCameras: number;
	audioTenInchInWall: number;
	audioSevenInchInWall: number;
	audioTenInchPortable: number;
	audioSevenInchPortable: number;
	audioTouchscreenColor: string;
	audioConfigurableKeypad: number;
	audioTvInput: string;
	audioHomeTheater: boolean;

	lightingDesignType: string;
	centralDesignType: string;
	lightingProjectSize: string;
	centralDesignSpecialRequest: string;
	lightingDesignOverview: string;
	dimmingType: string;
	totalLightingZones: number;
	totalLightingLoads: number;
	total3WayLightLoads: number;
	totalDimmable: number;
	lightingFloorplanAvailable: boolean;
	lightingFloorplan: string;
	lightingChallenges: string;

	networkHomeSize: number;
	networkHomeSizeUnit: string;
	networkInteriorWallMaterial: string;
	networkCeilingMaterial: string;
	networkEnvironment: string;
	networkFloors: number;
	networkOutdoor: boolean;
	networkSpeed: number;
	networkSpeedUnit: string;
	networkPeakUsage: string;
	networkWirelessHighThroughputDevices: number;
	networkWirelessLowThroughputDevices: number;
	networkWiredHighThroughputDevices: number;
	networkWiredLowThroughputDevices: number;
	networkPoE: number;
	networkPoEPlus: number;
	networkChallenges: string;
	networkAdvancedManagement: boolean;
	networkSecondarySwitch: boolean;
	networkSecondaryManagedSwitch: boolean;
	networkSecondarySwitchPoE: boolean;
	networkSecondarySwitchQty: number;
	networkEstimatedPoEWattage: number;
	networkFloorplanAvailable: boolean;
	networkFloorplan: string;
	networkAesthetic: string;

	winnieThePooh: string;

	// front end only
	displayHeight: number;
	displayWidth: number;
	displayUnits: string;
	aspectRatio: string;

	theaterHeight: number;
	theaterWidth: number;
	theaterLength: number;
	theaterUnits: string;

	constructionType: string;
	audioWallDepth: number;
	audioWallUnit: string;
	audioCeilingDepth: number;
	audioCeilingUnit: string;
	audioFloorplanAvailable: boolean;
	audioFloorplan: string;
}

export interface IAVZoneCreated {
	roomName: string;
	speakerPairQty: number;
	selectedPair: any;
	hasTv: boolean;
	hasSurroundSound: boolean;
	hasStereoSound: number;
	hasOutdoor: number;
	outdoor: boolean;
	audioZonesWithSurroundSound: number;
	hasOnscreenNavigator: boolean;
}

export interface ILightingZoneCreated {
	lightingLoads: number;
	isDimmable: boolean;
	roomName: string;
	threeWayLightingLoads: number;
	selected3wayLoads: any;
	selectedLoads: any;
}

export interface ILightingZoneTotals {
	totalZones: number;
	totalLightingLoads: number;
	totalDimmable: number;
	totalThreeWayLightingLoads: number;
}

export interface IAVZoneTotals {
	totalZones: number;
	totalSpeakerPair: number;
	totalWithTv: number;
	totalOutdoor: number;
	totalSurroundSound: number;
	totalStereoSound: number;
	zonesWithSurroundSound: number;
	totalOnscreenNav: number;
}

export interface IMarketingVideo {
	name: string;
	wistia_video_id: string;
	hd_download_link: string;
	wistia_data: any;
}

export interface IVideoModel {
	id: number;
	sku: string;
	displayInfo: string;
	name: string;
	description: string;
	videoType: VideoType;
}

export enum VideoType {
	Embedded = 1,
	Linked = 2
}

export interface ISaveVideoData {
	video: IVideoModel;
	saveType: "add" | "edit";
}