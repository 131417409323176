import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AccountingPipe } from "@app/pipes/accounting.pipe";
import { CamelizePipe } from "@app/pipes/camelize.pipe";
import { FromNowPipe } from "@app/pipes/fromnow.pipe";
import { UserFullNamePipe } from "@app/pipes/fullname.pipe";
import { KeyValueSelectedPipe } from "@app/pipes/key-value-selected.pipe";
import { OrderByPipe } from "@app/pipes/orderby.pipe";
import { PagingPipe } from "@app/pipes/paging.pipe";
import { PhonePipe } from "@app/pipes/phone.pipe";
import { ToPlainTextPipe } from "@app/pipes/plaintext.pipe";
import { PortalDescriptionPipe } from "@app/pipes/portal.pipe";
import { SafeHtmlPipe } from "@app/pipes/safehtml.pipe";
import { ShortCurrencyPipe } from "@app/pipes/short-currency.pipe";
import { ShortestDatePipe } from "@app/pipes/shortest-date.pipe";
import { CartIconPipe } from "./cart-icon.pipe";
import { CertStatusNamePipe } from "./cert-status-name.pipe";
import { CertStatusPipe } from "./cert-status.pipe";
import { CertTypeIconPipe } from "./cert-type-badge.pipe";
import { CertTypeNamePipe } from "./cert-type-name.pipe";
import { ChartColorPipe } from "./chart-color.pipe";
import { ChartMapPipe } from "./chart-map.pipe";
import { CloudinaryUrlPipe } from "./cloudinary-url.pipe";
import { Control4VisiblePipe } from "./control4-visible.pipe";
import { CvcLengthPipe } from "./cvc-length.pipe";
import { DistanceFromNowPipe } from "./distance-from-now.pipe";
import { EducationSessionPipe } from "./education-session.pipe";
import { EnumDescriptionPipe } from "./enum-description.pipe";
import { GroupByPipe } from "./groupby.pipe";
import { HtmlAsTextPipe } from "./html-as-text.pipe";
import { IsHtmlPipe } from "./is-html.pipe";
import { IsVolumeDiscountPipe } from "./is-volume-discount.pipe";
import { KeycapTextPipe } from "./keycap-text.pipe";
import { LatestCertPipe } from "./latest-cert.pipe";
import { LinkTransformPipe } from "./link-transform.pipe";
import { MapPipe } from "./map.pipe";
import { PakedgeVisiblePipe } from "./pakedge-visible.pipe";
import { PaymentMethodPipe } from "./payment-method.pipe";
import { PcnaCoursePipe } from "./pcna-course.pipe";
import { PortalMatchPipe } from "./portal-match.pipe";
import { PrimaryImagePipe } from "./primary-image.pipe";
import { ProductUrlPipe } from "./product-url.pipe";
import { ProgressPercentPipe } from "./progress-percent.pipe";
import { PropagandaStatusIconPipe } from "./propaganda-status-icon.pipe";
import { RebateDaysPipe } from "./rebate-days.pipe";
import { RemoveEncodingPipe } from "./remove-encoding.pipe";
import { ScheduleIconPipe } from "./schedule-icon.pipe";
import { StatusBadgeDownloadUrlPipe } from "./status-badge-download-url.pipe";
import { StatusBadgeImageUrlPipe } from "./status-badge-image-url.pipe";
import { StatusBadgeTypePipe } from "./status-badge-type.pipe";
import { StockStatusPipe } from "./stock-status.pipe";
import { TriadVisiblePipe } from "./triad-visible.pipe";

@NgModule({
	imports: [CommonModule],
	declarations: [
		PortalDescriptionPipe,
		UserFullNamePipe,
		SafeHtmlPipe,
		ShortCurrencyPipe,
		AccountingPipe,
		PhonePipe,
		CamelizePipe,
		PagingPipe,
		ShortestDatePipe,
		KeyValueSelectedPipe,
		MapPipe,
		CvcLengthPipe,
		ProgressPercentPipe,
		IsHtmlPipe,
		ToPlainTextPipe,
		FromNowPipe,
		OrderByPipe,
		GroupByPipe,
		KeycapTextPipe,
		RemoveEncodingPipe,
		PropagandaStatusIconPipe,
		PrimaryImagePipe,
		ProductUrlPipe,
		RebateDaysPipe,
		LinkTransformPipe,
		PortalMatchPipe,
		Control4VisiblePipe,
		PakedgeVisiblePipe,
		TriadVisiblePipe,
		CloudinaryUrlPipe,
		CertStatusNamePipe,
		PcnaCoursePipe,
		DistanceFromNowPipe,
		EnumDescriptionPipe,
		EducationSessionPipe,
		IsVolumeDiscountPipe,
		StatusBadgeImageUrlPipe,
		StatusBadgeDownloadUrlPipe,
		StatusBadgeTypePipe,
		CertTypeNamePipe,
		CertTypeIconPipe,
		CertStatusPipe,
		ChartColorPipe,
		ScheduleIconPipe,
		ChartMapPipe,
		LatestCertPipe,
		CartIconPipe,
		PaymentMethodPipe,
		StockStatusPipe,
		HtmlAsTextPipe,
	],
	exports: [
		PortalDescriptionPipe,
		UserFullNamePipe,
		SafeHtmlPipe,
		ShortCurrencyPipe,
		AccountingPipe,
		PhonePipe,
		CamelizePipe,
		PagingPipe,
		ShortestDatePipe,
		KeyValueSelectedPipe,
		MapPipe,
		CvcLengthPipe,
		ProgressPercentPipe,
		IsHtmlPipe,
		ToPlainTextPipe,
		FromNowPipe,
		OrderByPipe,
		GroupByPipe,
		KeycapTextPipe,
		RemoveEncodingPipe,
		PropagandaStatusIconPipe,
		PrimaryImagePipe,
		ProductUrlPipe,
		RebateDaysPipe,
		LinkTransformPipe,
		PortalMatchPipe,
		Control4VisiblePipe,
		PakedgeVisiblePipe,
		TriadVisiblePipe,
		CloudinaryUrlPipe,
		CertStatusNamePipe,
		PcnaCoursePipe,
		DistanceFromNowPipe,
		EnumDescriptionPipe,
		EducationSessionPipe,
		IsVolumeDiscountPipe,
		StatusBadgeImageUrlPipe,
		StatusBadgeDownloadUrlPipe,
		StatusBadgeTypePipe,
		CertTypeNamePipe,
		CertTypeIconPipe,
		CertStatusPipe,
		ChartColorPipe,
		ScheduleIconPipe,
		ChartMapPipe,
		LatestCertPipe,
		CartIconPipe,
		PaymentMethodPipe,
		StockStatusPipe,
		HtmlAsTextPipe,
	],
	providers: [
		MapPipe,
		PagingPipe,
	],
})
export class PipesModule { }