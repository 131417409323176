import { Action } from "@ngrx/store";

export class WarningToast implements Action {
	readonly type = ToastActionTypes.Warning;
	constructor(public payload: IToastInfo) { }
}

export class SuccessToast implements Action {
	readonly type = ToastActionTypes.Success;
	constructor(public payload: IToastInfo) { }
}

export class ErrorToast implements Action {
	readonly type = ToastActionTypes.Error;
	constructor(public payload: IToastInfo) { }
}

export class InfoToast implements Action {
	readonly type = ToastActionTypes.Info;
	constructor(public payload: IToastInfo) { }
}

export interface IToastInfo {
	message: string;
	ttl?: number;
}

export type ToastActions =
	WarningToast
	| ErrorToast
	| InfoToast
	| SuccessToast;

export enum ToastActionTypes {
	Warning = "[Toast] Warning",
	Success = "[Toast] Success",
	Error = "[Toast] Error",
	Info = "[Toast] Info",
}