import { getNgStoreCacheItem } from "@app/shared/helpers";
import { ContractType } from "@app/shared/shared.models";
import { contractAdapter } from "@app/store/contract/contract.adapter";
import { IContractState } from "@app/store/contract/contract.reducer";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { differenceInCalendarDays } from "date-fns";

export const getContractState = createFeatureSelector<IContractState>("contracts");
export const {
	selectIds: contractSelectIds,
	selectEntities: contractSelectEntities,
	selectAll: contractSelectAll,
	selectTotal: contractSelectTotal,
} = contractAdapter.getSelectors(getContractState);

export const getCurrentContract = (type: ContractType) => createSelector(
	contractSelectAll, contracts => contracts
		.filter(contract => contract.contractType === type)
		.map(contract => getNgStoreCacheItem(contract))
		.filter(contract => !!contract)
		.sort((a, b) => differenceInCalendarDays(a.dateAdded, b.dateAdded) > 0 ? 1 : -1)
		.shift(),
);

export const getCurrentTermsOfSales = getCurrentContract(ContractType.TermsOfSales);