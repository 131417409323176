import { getNgStoreCacheItem } from "@app/shared/helpers";
import { getCurrentAccount } from "@app/store/account";
import { dealerLocatorAdapter } from "@app/store/dealer-locator/dealer-locator.adapter";
import { IDealerLocatorState } from "@app/store/dealer-locator/dealer-locator.reducer";
import { createFeatureSelector, createSelector } from "@ngrx/store";

export const getDealerLocatorState = createFeatureSelector<IDealerLocatorState>("dealerLocators");

export const {
	selectIds: dealerLocatorsSelectIds,
	selectEntities: dealerLocatorsSelectEntities,
	selectAll: dealerLocatorsSelectAll,
	selectTotal: dealerLocatorsSelectTotal
} = dealerLocatorAdapter.getSelectors(getDealerLocatorState);

export const getCurrentDealerLocators = createSelector(
	dealerLocatorsSelectAll,
	getCurrentAccount,
	(items, account) => {
		if (!items || !account) {
			return undefined;
		}
		const stale = !getNgStoreCacheItem(items.find(item => (item.distributorId === account.id || item.distributorId === account.id)));
		if (stale) {
			return undefined;
		}
		return items.filter(item => (item.distributorId === account.id || item.distributorId === account.id));
	}
);

export const getDealerLocatorSubmitState = createSelector(
	getDealerLocatorState,
	(state) => state.submitState
);