import { ICategoryModel, IError } from "@app/shared/shared.models";
import { Action } from "@ngrx/store";

export enum CategoryActionType {
	LoadCategoryAttempt = "[Category] Load Attempt",
	LoadCategorySuccess = "[Category] Load Success",
	LoadCategoryError = "[Category] Load Error",
	SelectCategory = "[Category] Select",
	UpdateListView = "[Category] Update Is List View",
}

export class LoadCategoryAttempt implements Action {
	readonly type = CategoryActionType.LoadCategoryAttempt;
	constructor(public payload: number) { }
}

export class LoadCategorySuccess implements Action {
	readonly type = CategoryActionType.LoadCategorySuccess;
	constructor(public payload: ICategoryModel) { }
}

export class LoadCategoryError implements Action {
	readonly type = CategoryActionType.LoadCategoryError;
	constructor(public payload: IError) { }
}

export class SelectCategory implements Action {
	readonly type = CategoryActionType.SelectCategory;
	constructor(public payload: number) { }
}

export class UpdateListView implements Action {
	readonly type = CategoryActionType.UpdateListView;
	constructor(public payload: boolean) { }
}

export type CategoryActions =
	LoadCategoryAttempt
	| LoadCategorySuccess
	| LoadCategoryError
	| SelectCategory
	| UpdateListView;