import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "cvcLength"
})
export class CvcLengthPipe implements PipeTransform {

	transform(cardType: string): any {
		switch (cardType) {
			case "AMEX": return 4;
			default: return 3;
		}
	}

}
