import { ILeadPreferences } from "@app/dashboard/dashboard.models";
import { IError } from "@app/shared/shared.models";
import { Action } from "@ngrx/store";

export class LoadLeadPreferencesAttempt implements Action {
	readonly type = LeadPreferencesActionTypes.LoadLeadPreferencesAttempt;
	constructor() { }
}

export class LoadLeadPreferencesSuccess implements Action {
	readonly type = LeadPreferencesActionTypes.LoadLeadPreferencesSuccess;
	constructor(public payload: ILeadPreferences) { }
}

export class LoadLeadPreferencesError implements Action {
	readonly type = LeadPreferencesActionTypes.LoadLeadPreferencesError;
	constructor(public payload: IError) { }
}

export class SaveLeadPreferencesAttempt implements Action {
	readonly type = LeadPreferencesActionTypes.SaveLeadPreferencesAttempt;
	constructor(public payload: ILeadPreferences) { }
}

export class SaveLeadPreferencesSuccess implements Action {
	readonly type = LeadPreferencesActionTypes.SaveLeadPreferencesSuccess;
	constructor(public payload: ILeadPreferences) { }
}

export class SaveLeadPreferencesError implements Action {
	readonly type = LeadPreferencesActionTypes.SaveLeadPreferencesError;
	constructor(public payload: IError) { }
}

export type LeadPreferencesActions =
	LoadLeadPreferencesAttempt
	| LoadLeadPreferencesSuccess
	| LoadLeadPreferencesError
	| SaveLeadPreferencesAttempt
	| SaveLeadPreferencesSuccess
	| SaveLeadPreferencesError;
export enum LeadPreferencesActionTypes {
	LoadLeadPreferencesAttempt = "[Lead Preferences Load] Attempt",
	LoadLeadPreferencesSuccess = "[Lead Preferences Load] Success",
	LoadLeadPreferencesError = "[Lead Preferences Load] Error",
	SaveLeadPreferencesAttempt = "[Lead Preferences Save] Attempt",
	SaveLeadPreferencesSuccess = "[Lead Preferences Save] Success",
	SaveLeadPreferencesError = "[Lead Preferences Save] Error",
}
