import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ChartColor } from "@app/dashboard/dashboard.models";

@Component({
	selector: "c4-chart-color-selector",
	templateUrl: "./chart-color-selector.component.html",
	styleUrls: ["./chart-color-selector.component.scss"]
})
export class ChartColorSelectorComponent implements OnInit {
	@Input() color: ChartColor;
	@Output() colorChange = new EventEmitter<ChartColor>();

	colors = [ChartColor.Unknown, ChartColor.Error, ChartColor.Warn, ChartColor.Success];

	constructor() { }

	ngOnInit() {
	}

}
