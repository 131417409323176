import { Component, ComponentRef, Input } from "@angular/core";
import { ICustomerSummary } from "@app/dashboard/dashboard.models";
import { IAppState } from "@app/store";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { IPopupComponent } from "../popups.models";

@Component({
	selector: "c4-upgrade-reminder-popup",
	templateUrl: "./upgrade-reminder-popup.component.html",
	styleUrls: ["./upgrade-reminder-popup.component.scss"]
})
export class UpgradeReminderPopupComponent implements IPopupComponent {
	self: ComponentRef<UpgradeReminderPopupComponent>;

	@Input() name: string;
	@Input() customer: ICustomerSummary;

	answer = new Subject<boolean>();

	constructor(private store: Store<IAppState>) { }

	placeOrder() {
		this.answer.next(true);
		this.self.destroy();
	}

	close() {
		this.answer.next(false);
		this.self.destroy();
	}
}
