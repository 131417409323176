import { Pipe, PipeTransform } from "@angular/core";
import { PortalNames, Portals } from "@app/shared/shared.models";

@Pipe({ name: "portal" })
export class PortalDescriptionPipe implements PipeTransform {
	transform(value: Portals, separator?: string): string {
		const values: string[] = [];
		PortalNames.forEach((label, val) => {
			if ((value & val) > 0) {
				values.push(label);
			}
		});
		return values.join(separator);
	}
}
