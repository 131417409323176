import { Component, OnDestroy, OnInit } from "@angular/core";
import { AccountService } from "@app/services/account.service";
import { IAppState } from "@app/store";
import { getCurrentCurrencySymbol } from "@app/store/account";
import { getCurrentSalesInfo, LoadSalesInfoAttempt } from "@app/store/sales-info";
import { select, Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: "c4-kpi-widget-revenue",
	templateUrl: "./kpi-widget-revenue.component.html",
	styleUrls: ["./kpi-widget-revenue.component.scss"]
})
export class KpiWidgetRevenueComponent implements OnInit, OnDestroy {

	loading = true;
	isTriadDealer$: Observable<boolean>;

	growthPercent: number;
	ytdSales: number;
	currency$: Observable<string>;
	private destroyed$ = new Subject<{}>();

	constructor(readonly store: Store<IAppState>, readonly accountService: AccountService) { }

	ngOnInit() {
		this.currency$ = this.store.pipe(select(getCurrentCurrencySymbol));

		this.store.pipe(
			select(getCurrentSalesInfo),
			takeUntil(this.destroyed$),
		).subscribe((result) => {
			this.loading = !result;
			if (!this.loading) {
				this.growthPercent = result.yoyGrowthPercent;
				this.ytdSales = result.ytdRevenue;
			} else {
				this.store.dispatch(new LoadSalesInfoAttempt());
			}
		});
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}
}
