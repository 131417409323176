import { Directive, ElementRef, HostListener, Input } from "@angular/core";

@Directive({
	selector: "[c4ClickToggle]"
})
export class ClickToggleDirective {
	@Input("c4ClickToggle") selectors: string;
	@Input() hide: string;
	@Input() show: string;
	@Input() hideSelf: boolean;


	constructor(private el: ElementRef) {
	}

	@HostListener("click")
	onClick() {
		if (this.hide) {
			Array.from(this.getElements(this.hide)).forEach((item) => {
				item.classList.add("hidden");
				item.classList.remove("active");
			});
		}
		if (this.show) {
			Array.from(this.getElements(this.show)).forEach((item) => {
				item.classList.remove("hidden");
				item.classList.add("active");
			});
		}
		if (this.selectors) {
			Array.from(this.getElements(this.selectors)).forEach((item) => {
				item.classList.remove("active");
			});
		}
		if (!this.hideSelf) {
			this.el.nativeElement.classList.remove("hidden");
			this.el.nativeElement.classList.add("active");
		} else {
			this.el.nativeElement.classList.add("hidden");
			this.el.nativeElement.classList.remove("active");
		}
	}

	private getElements(info: string) {
		return info.match(/^[\.#]/) ? document.querySelectorAll(info) : document.getElementsByClassName(info);
	}
}