import { Injectable } from "@angular/core";
import { IError } from "@app/shared/shared.models";
import { DealerLocatorActionTypes } from "@app/store/dealer-locator";
import { ErrorToast, IToastInfo, ToastActions, ToastActionTypes, WarningToast } from "@app/store/toast/toast.actions";
import { UpgradeRequestFileActionTypes } from "@app/store/upgrade-request-file";
import { UserActionTypes } from "@app/store/user";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { LeadPreferencesActionTypes } from "../lead-preferences";

@Injectable()
export class ToastEffects {
	constructor(
		private actions$: Actions,
		private toastr: ToastrService
	) { }

	@Effect({ dispatch: false })
	toast$: Observable<Action> = this.actions$.pipe(
		ofType(ToastActionTypes.Error, ToastActionTypes.Info, ToastActionTypes.Success, ToastActionTypes.Warning),
		tap((action: ToastActions) => {
			switch (action.type) {
				case ToastActionTypes.Success:
					this.success(action.payload);
					break;
				case ToastActionTypes.Warning:
					this.warn(action.payload);
					break;
				case ToastActionTypes.Error:
					this.error(action.payload);
					break;
				case ToastActionTypes.Info:
					this.info(action.payload);
					break;
			}
		})
	);

	@Effect()
	handleErrors$: Observable<Action> = this.actions$.pipe(
		ofType(
			UserActionTypes.LoadError,
			UserActionTypes.RemoveError,
			UserActionTypes.SaveUserError,
			DealerLocatorActionTypes.SaveDealerLocatorError,
			UpgradeRequestFileActionTypes.SubmitUpgradeRequestError,
			UpgradeRequestFileActionTypes.LoadAccountUpgradeRequestFilesError,
			UpgradeRequestFileActionTypes.DeleteUpgradeRequestFileError,
			LeadPreferencesActionTypes.SaveLeadPreferencesError,
			LeadPreferencesActionTypes.LoadLeadPreferencesError,
		),
		map((action: { type: string, payload: IError }) => {
			if (action.payload.error) {
				return new ErrorToast({ message: action.payload.error, ttl: action.payload.ttl });
			}

			if (action.payload.warning) {
				return new WarningToast({ message: action.payload.warning, ttl: action.payload.ttl });
			}

			return new ErrorToast({ message: action.type, ttl: action.payload.ttl });
		})
	);

	private warn(toast: IToastInfo) {
		this.toastr.warning(toast.message, undefined, { timeOut: this.getTtl(toast.ttl) });
	}

	private success(toast: IToastInfo) {
		this.toastr.success(toast.message, undefined, { timeOut: this.getTtl(toast.ttl) });
	}

	private error(toast: IToastInfo) {
		this.toastr.error(toast.message, undefined, { timeOut: this.getTtl(toast.ttl) });
	}

	private info(toast: IToastInfo) {
		this.toastr.info(toast.message, undefined, { timeOut: this.getTtl(toast.ttl) });
	}

	private getTtl(ttl?: number) {
		// Checking for null, as ttl = 0 is valid
		return ttl != null ? ttl : 5000;
	}
}
