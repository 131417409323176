import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { KeyboardService } from "@app/services/keyboard.service";

@Component({
	selector: "c4-popup",
	templateUrl: "./popup.component.html",
	styleUrls: ["./popup.component.scss"],
})
export class PopupComponent implements OnInit, OnDestroy {
	show: boolean;
	@Input() size = "large";
	@Input() position = "";
	@Input() title: string;
	@Input() color: string;
	@Input() background: string;
	@Input() clickable = true;
	@Input() customHeader = false;
	@Input() forceFocus = true;

	@Output() destroy = new EventEmitter();
	@Output() addChange = new EventEmitter();
	hasDestroy: boolean;

	constructor(private keyboardService: KeyboardService) { }

	@HostListener("keydown", ["$event"])
	onKeyDownHandler(event: KeyboardEvent) {
		if (this.keyboardService.isEscKeybind(event) && this.hasDestroy) {
			this.handleClose();
		}
	}

	handleClose() {
		this.destroy.next();
	}

	ngOnInit() {
		this.hasDestroy = this.clickable && this.destroy.observers.length > 0;
		this.color = this.color || "white";
		this.background = this.background || "white";
		document.body.parentElement.style.overflow = "hidden";
	}

	ngOnDestroy() {
		document.body.parentElement.style.overflow = "auto";
	}
}
