import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { INavMenuItem } from "@app/nav/nav.models";

@Injectable({ providedIn: "root" })
export class NavService {
	constructor(private http: HttpClient) { }

	getNavigation() {
		return this.http.get<INavMenuItem[]>("api/user/GetNavigation");
	}
}
