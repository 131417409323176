import { ILeadCounts } from "@app/dashboard/dashboard.models";
import { getNgStoreCacheItem } from "@app/shared/helpers";
import { leadsAdapter } from "@app/store/leads/leads.adapter";
import { LeadsState } from "@app/store/leads/leads.reducer";
import { selectCurrentUser } from "@app/store/user";
import { createFeatureSelector, createSelector } from "@ngrx/store";

export const getLeadsState = createFeatureSelector<LeadsState>("leads");

export const {
	selectIds: leadsSelectIds,
	selectEntities: leadsSelectEntities,
	selectAll: leadsSelectAll,
	selectTotal: leadsSelectTotal,
} = leadsAdapter.getSelectors(getLeadsState);

export const getLeadIdsByDealer = createSelector(
	getLeadsState, state => state.idsByDealer,
);

export const getLeadIdsForAccount = createSelector(
	selectCurrentUser,
	getLeadIdsByDealer,
	(user, idsLookup) => {
		if (!idsLookup || !user) {
			return undefined;
		}
		const idData = getNgStoreCacheItem(idsLookup[user.accountId.toLowerCase()]);
		return idData ? idData.ids : undefined;
	},
);

export const getAllLeadsForAccount = createSelector(
	leadsSelectAll,
	getLeadIdsForAccount,
	(leads, ids) => leads && ids ? leads.filter(lead => ids.includes(lead.opportunityId)) : [],
);

export const getNewLeadsForAccount = createSelector(
	getAllLeadsForAccount, leads => leads.filter(lead => lead.status === "Sent to Dealer"),
);

export const getNewLeadsCountForAccount = createSelector(
	getNewLeadsForAccount, leads => leads.length,
);

export const getOpenLeadsForAccount = createSelector(
	getAllLeadsForAccount, leads => leads.filter(lead => lead.status !== "Sent to Dealer" && ["Balihoo", "Telesales", "Microsite"].includes(lead.leadType)),
);
export const getOpenLeadsCountForAccount = createSelector(
	getOpenLeadsForAccount, leads => leads.length,
);

export const getOpenShowroomLeadsForAccount = createSelector(
	getAllLeadsForAccount, leads => leads.filter(lead => lead.status !== "Sent to Dealer" && lead.leadType === "Showroom Event"),
);

export const getOpenShowroomLeadsCountForAccount = createSelector(
	getOpenShowroomLeadsForAccount, leads => leads.length,
);

export const getOpenUpgradeLeadsForAccount = createSelector(
	getAllLeadsForAccount, leads => leads.filter(lead => lead.status !== "Sent to Dealer" && (lead.leadType === "Upgrade" || lead.leadType === "Customer Requested Upgrade")),
);

export const getOpenUpgradeLeadsCountForAccount = createSelector(
	getOpenUpgradeLeadsForAccount, leads => leads.length,
);

export const getLeadCountsForAccount = createSelector(
	getLeadIdsForAccount, // Used to validate existence of account leads
	getNewLeadsCountForAccount,
	getOpenLeadsCountForAccount,
	getOpenUpgradeLeadsCountForAccount,
	getOpenShowroomLeadsCountForAccount,
	(ids, newTotal, openLeads, openUpgrades, openShowrooms) => ids ? (<ILeadCounts>{ newTotal, openTotal: openLeads + openUpgrades + openShowrooms, openLeads, openUpgrades, openShowrooms }) : undefined,
);
