import { Injectable } from "@angular/core";

@Injectable({
	providedIn: "root"
})
export class EnumService {

	getFlags<T extends number>(enumMap: Map<T, string>, value: T): string[] {
		return this.getFlagValues<T>(enumMap, value).map((val) => enumMap.get(val));
	}

	getFlagValues<T extends number>(enumMap: Map<T, string>, value: T): T[] {
		const flags: T[] = [];

		if (!value) {
			return flags;
		}

		enumMap.forEach((_, num) => {
			if (this.checkIfBitFlag(num) && (num & (value as number)) === num) {
				flags.push(num);
			}
		});

		return flags;
	}

	private checkIfBitFlag(n: number) {
		// Value is greater than 0 and a power of two
		return n && ((n & (n - 1)) === 0);
	}
}