import { Component, OnInit } from "@angular/core";
import { DriverService } from "@app/services/driver.service";
import { IDriversResult } from "@app/shared/shared.models";
import { Observable } from "rxjs";

@Component({
	selector: "c4-latest-drivers",
	templateUrl: "./latest-drivers.component.html"
})
export class LatestDriversComponent implements OnInit {
	result$: Observable<IDriversResult>;

	constructor(readonly driverService: DriverService) { }

	ngOnInit() {
		this.result$ = this.driverService.getMostRecentDrivers(7);
	}
}