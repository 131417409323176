import { Injectable } from "@angular/core";
import { AccountService } from "@app/services/account.service";
import { LoadSecurityRolesError, LoadSecurityRolesSuccess, SecurityRolesActionTypes } from "@app/store/security-roles/security-roles.actions";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action, select, Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, debounceTime, filter, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { IAppState } from "../app.reducer";
import { securityRolesSelectAll } from "./security-roles.selectors";

@Injectable()
export class SecurityRolesEffects {
	constructor(
		private actions$: Actions,
		private accountService: AccountService,
		private store: Store<IAppState>
	) { }

	@Effect()
	loadSecurityRoles$: Observable<Action> = this.actions$.pipe(
		ofType(SecurityRolesActionTypes.LoadAttempt),
		debounceTime(300),
		withLatestFrom(this.store.pipe(select(securityRolesSelectAll))),
		filter(([_, roles]) => !roles || !roles.length),
		mergeMap(() => {
			return this.accountService
				.getSecurityRoles()
				.pipe(
					map((info) => new LoadSecurityRolesSuccess(info)),
					catchError((err) => of(new LoadSecurityRolesError({ error: err.message || err.statusText })))
				);
		})
	);
}
