import { Component, ComponentRef, OnDestroy, OnInit } from "@angular/core";
import { FeatureFlags, IUser } from "@app/account/account.models";
import { CartType, IAddToCartModel, ISavedCart } from "@app/checkout/checkout.models";
import { ICustomerSummary } from "@app/dashboard/dashboard.models";
import { IPopupComponent } from "@app/popups/popups.models";
import { IProductLightModel, IProductUpgrade } from "@app/products/products.models";
import { CartService } from "@app/services/cart.service";
import { CategoryService } from "@app/services/category.service";
import { CheckoutService } from "@app/services/checkout.service";
import { ConsumerService } from "@app/services/consumer.service";
import { PopupService } from "@app/services/popup.service";
import { IAppState } from "@app/store";
import { getCurrentCurrencySymbol, isFeatureFlagEnabled } from "@app/store/account";
import { getControllerUpgradeCategory, getDoorStationUpgradeCategory, getTouchscreenUpgradeCategory, getTriadOneUpgradeCategory } from "@app/store/app-info/app-info.selectors";
import { AddCartItemAttempt, AddOrderLifecycleEventAttempt, ChangeActiveCartAttempt, ChangeActiveCartSuccess, LoadCartSuccess } from "@app/store/cart";
import { ClearCustomerSummaries } from "@app/store/customer-summary";
import { Redirect } from "@app/store/router";
import { ErrorToast } from "@app/store/toast/toast.actions";
import { selectCurrentUser } from "@app/store/user";
import { MemoizedSelector, select, Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";
import { ConfirmPopupComponent } from "../confirm-popup/confirm-popup.component";

@Component({
	selector: "c4-product-upgrade-popup",
	templateUrl: "./product-upgrade-popup.component.html",
	styleUrls: ["./product-upgrade-popup.component.scss"],
})
export class ProductUpgradePopupComponent implements OnInit, OnDestroy, IPopupComponent {
	new: boolean;
	customer: ICustomerSummary;
	showDiscount: boolean;
	working = true;

	currentUser: IUser;
	currency$: Observable<string>;
	itemCount: number;
	orderId = 0;
	upgradeCart: ISavedCart;
	canSwitchCart: boolean;

	selected: IProductLightModel;
	self: ComponentRef<ProductUpgradePopupComponent>;
	controllers$: Observable<IProductLightModel[]>;
	touchscreens$: Observable<IProductLightModel[]>;
	doorStations$: Observable<IProductLightModel[]>;
	triadOnes$: Observable<IProductLightModel[]>;

	private destroyed$ = new Subject<{}>();

	constructor(private store: Store<IAppState>, private consumerService: ConsumerService, private checkoutService: CheckoutService, private categoryService: CategoryService, private cartService: CartService, private popupService: PopupService) { }

	ngOnInit() {
		this.controllers$ = this.getCategory(getControllerUpgradeCategory);
		this.touchscreens$ = this.getCategory(getTouchscreenUpgradeCategory);
		this.doorStations$ = this.getCategory(getDoorStationUpgradeCategory);
		this.triadOnes$ = this.getCategory(getTriadOneUpgradeCategory);

		this.currency$ = this.store.pipe(select(getCurrentCurrencySymbol));

		this.store.pipe(
			select(selectCurrentUser),
			takeUntil(this.destroyed$),
		).subscribe(user => this.currentUser = user);

		this.store.pipe(
			select(isFeatureFlagEnabled(FeatureFlags.SwitchCart)),
			takeUntil(this.destroyed$),
		).subscribe(canChangeCart => {
			this.canSwitchCart = canChangeCart;
			if (this.canSwitchCart) {
				this.cartService.getConsumerCart(this.customer.consumerAccountName)
					.subscribe(totals => {
						if (totals) {
							this.checkoutService.changeActiveCart(this.orderId);
							this.store.dispatch(new ChangeActiveCartAttempt(totals));
							this.store.dispatch(new ClearCustomerSummaries());
							this.store.dispatch(new LoadCartSuccess(totals));
							this.orderId = totals.orderId;
							this.working = false;
							this.upgradeCart = { id: totals.orderId, name: totals.cartName, cartType: totals.cartType } as ISavedCart;
						} else {
							this.store.dispatch(new ErrorToast({ message: "Failed to load customer cart." }));
							this.self.destroy();
						}
					});
			} else {
				this.working = false;
			}
		});
	}

	private getCategory(selector: MemoizedSelector<object, number>) {
		return this.store.pipe(
			select(selector),
			switchMap(id => this.categoryService.getCategoryPartsForCartType(id, CartType.Upgrade)),
		);
	}

	goToDefaultCart() {
		this.store.dispatch(new ChangeActiveCartAttempt({ orderId: 0 }));
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}

	continueShopping() {
		this.checkoutService
			.changeActiveCart(this.orderId)
			.subscribe(totals => {
				this.store.dispatch(new Redirect({ url: "/products" }));
				this.store.dispatch(new ChangeActiveCartSuccess(totals));
				this.self.destroy();
			});
	}

	close = () => {
		this.store.dispatch(new AddOrderLifecycleEventAttempt({
			page: window.location.pathname,
			action: "CancelUpgrade",
			actionSource: "ProductUpgradeModal",
		}));
		this.self.destroy();
	}

	confirmDeleteSavedCart() {
		const confirmPopup = this.popupService.create(ConfirmPopupComponent, {
			title: `Delete: ${this.upgradeCart.name}`,
			body: "Are you sure you would like to delete this upgrade cart?",
			confirmText: "Delete Upgrade Cart",
		}) as ComponentRef<ConfirmPopupComponent>;
		confirmPopup.instance.answer.subscribe(confirmed => {
			if (confirmed) {
				this.deleteUpgradeCart(this.upgradeCart);
			}
		});
	}

	deleteUpgradeCart(upgradeCart: ISavedCart) {
		this.cartService.deleteSavedCart(upgradeCart).subscribe();
		this.goToDefaultCart();
	}

	orderUpgrade() {
		this.working = true;
		if (this.canSwitchCart) {
			this.runUpgrade();
		} else {
			this.addItem(0, this.selected.promoCode);
		}
	}

	runUpgrade() {
		this.working = true;
		this.store.dispatch(new AddOrderLifecycleEventAttempt({
			page: window.location.pathname,
			action: "OrderUpgrade",
			actionSource: "ProductUpgradeModal",
		}));
		this.checkoutService
			.changeActiveCart(this.orderId)
			.subscribe(totals => {
				this.store.dispatch(new Redirect({ url: "/checkout" }));
				this.store.dispatch(new ChangeActiveCartSuccess(totals));
				this.self.destroy();
			});
	}

	// #region No Cart Switching - REMOVE WHEN NOT NEEDED
	private addItem(orderId: number, promoCode?: string) {
		const item = <IAddToCartModel>{ name: this.selected.name, sku: this.selected.sku, quantity: 1, orderId };
		this.store.dispatch(new AddCartItemAttempt({
			item,
			promoCode,
			lifecycle: { actionSource: "ProductUpgradeModal" },
			completionCallback: this.complete,
		}));
	}

	complete = (result: boolean) => {
		if (result) {
			this.goToCart(this.selected.sku);
		}
	}

	private goToCart(sku: string) {
		const uuid = this.customer.controllerUuid || "control4_hc300";
		const data = <IProductUpgrade>{
			customerId: this.customer.consumerAccountName,
			deviceUuid: uuid,
			macAddress: this.customer.macAddress,
			sku,
			accountId: this.currentUser.accountId,
			userId: this.currentUser.userId,
			orderId: this.orderId,
		};
		this.store.dispatch(new AddOrderLifecycleEventAttempt({
			page: window.location.pathname,
			action: "OrderUpgrade",
			actionSource: "ProductUpgradeModal",
		}));
		this.consumerService.upgradeOrdered(data).subscribe(() => {
			this.store.dispatch(new Redirect({ url: "/checkout" }));
			this.self.destroy();
		});
	}
	// #endregion
}
