import { Component, OnInit } from "@angular/core";

@Component({
	selector: "c4-support-question",
	template: `<div class="widget hidden">
					<div class="widget-content padding">
						<p class="no-margin-bottom">Can’t find what you’re looking for? Ask a <a href="//forums.control4.com"><strong>technical question</strong></a>.</p>
					</div>
				</div>`
})
export class SupportQuestionComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
