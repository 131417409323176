import { Injectable } from "@angular/core";
import { DashboardService } from "@app/services/dashboard.service";
import { } from "@app/store/customer-license-summary";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { CustomerLicenseSummaryActionTypes, LoadCustomerLicenseSummariesError, LoadCustomerLicenseSummariesSuccess } from "./customer-license-summary.actions";

@Injectable()
export class CustomerLicenseSummaryEffects {
	constructor(
		private actions$: Actions,
		private dashboardService: DashboardService
	) { }

	@Effect()
	loadCustomerLicenseSummariesByAccountId$: Observable<Action> = this.actions$.pipe(
		ofType(CustomerLicenseSummaryActionTypes.LoadCustomerLicenseSummariesAttempt),
		mergeMap(() => {
			return this.dashboardService
				.getLicenseSummaries()
				.pipe(
					map((info) => new LoadCustomerLicenseSummariesSuccess(info)),
					catchError((err) => of(new LoadCustomerLicenseSummariesError({ error: err.message || err.statusText })))
				);
		})
	);
}
