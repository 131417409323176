import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "accounting"
})
export class AccountingPipe implements PipeTransform {

	transform(value: number, symbol: string): any {
		if (value == null) { return null; }
		const p = value.toFixed(2).replace("-", "");
		const currency = this.toCurrency(p, symbol);
		return value > 0 ? `(${currency})` : currency;
	}

	private toCurrency(num: string, symbol: string): string {
		const p = num.split(".");
		return symbol + this.addCommas(p[0]) + "." + p[1];
	}

	private addCommas(num: string): string {
		return num.split("").reverse().reduce((acc, current, i) => {
			const needSeparator = i && !(i % 3); // first i ignores adding comma at beginning, second adds the comma every 3 characters
			return current + (needSeparator ? "," : "") + acc;
		}, "");
	}
}
