import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { RouterNavigationAction, ROUTER_NAVIGATION } from "@ngrx/router-store";
import { tap } from "rxjs/operators";
import { IRouterStateUrl } from "./app.reducer";

@Injectable()
export class AppEffects {

	constructor(private actions: Actions, private titleService: Title) { }

	@Effect({ dispatch: false })
	updateTitle$ = this.actions.pipe(
		ofType(ROUTER_NAVIGATION),
		tap((action: RouterNavigationAction<IRouterStateUrl>) => {
			if (action.payload.routerState.title) {
				this.titleService.setTitle(action.payload.routerState.title);
			}
		})
	);
}