import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "cartIcon"
})
export class CartIconPipe implements PipeTransform {
	private map = new Map([
		["K", "fa-server fa-rotate-180"],
		["D", "fa-home"],
		["U", "fa-chart-bar"],
		["N", "fa-shopping-cart"],
	]);

	transform(value: "K" | "D" | "U" | "N"): any {
		return this.map.get(value) || "";
	}
}
