import { Injectable } from "@angular/core";
import { UserService } from "@app/services/user.service";
import { LoadUserSalesforceInfoAttempt, LoadUserSalesforceInfoError, LoadUserSalesforceInfoSuccess, UserSalesforceContactActionTypes } from "@app/store/sf-contact/sf-contact.actions";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action, select, Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, debounceTime, filter, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { IAppState } from "../app.reducer";
import { getSalesforceInfo } from "./sf-contact.selectors";

@Injectable()
export class SalesforceContactEffects {
	constructor(
		private store: Store<IAppState>,
		private actions$: Actions,
		private userService: UserService
	) { }

	@Effect()
	loadSalesforceInfo$: Observable<Action> = this.actions$.pipe(
		ofType<LoadUserSalesforceInfoAttempt>(UserSalesforceContactActionTypes.LoadUserSalesforceInfoAttempt),
		debounceTime(200),
		withLatestFrom(this.store.pipe(select(getSalesforceInfo))),
		filter(([_, sfInfo]) => !sfInfo),
		mergeMap(() => this.userService
			.getSalesforceInfo()
			.pipe(
				map((contact) => new LoadUserSalesforceInfoSuccess(contact)),
				catchError((err) => of(new LoadUserSalesforceInfoError({ error: err.message || err.statusText })))
			))
	);
}
