import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatExpansionModule } from "@angular/material";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { RouterModule } from "@angular/router";
import { PipesModule } from "@app/pipes/pipes.module";
import { SharedElementsModule } from "@app/shared-elements/shared-elements.module";
import { AddToCartWidgetComponent } from "./add-to-cart-widget/add-to-cart-widget.component";
import { CategoryCardComponent } from "./category-card/category-card.component";
import { CustomizationAddonComponent } from "./customization-addon/customization-addon.component";
import { CustomizationAgreementsComponent } from "./customization-agreements/customization-agreements.component";
import { CustomizationDimensionComponent } from "./customization-dimension/customization-dimension.component";
import { CustomizationFinishComponent } from "./customization-finish/customization-finish.component";
import { CustomizationGrillComponent } from "./customization-grill/customization-grill.component";
import { CustomizationMountComponent } from "./customization-mount/customization-mount.component";
import { CustomizationVariantComponent } from "./customization-variant/customization-variant.component";
import { ProductAddToCartComponent } from "./product-add-to-cart/product-add-to-cart.component";
import { ProductCardComponent } from "./product-card/product-card.component";
import { ProductFirmwareItemComponent } from "./product-firmware-item/product-firmware-item.component";
import { ProductFirmwareComponent } from "./product-firmware/product-firmware.component";
import { ProductGridComponent } from "./product-grid/product-grid.component";
import { ProductListItemComponent } from "./product-list-item/product-list-item.component";
import { ProductListComponent } from "./product-list/product-list.component";
import { ProductSearchComponent } from "./product-search/product-search.component";
import { StockStatusComponent } from "./stock-status/stock-status.component";

@NgModule({
	imports: [
		CommonModule,
		PipesModule,
		SharedElementsModule,
		RouterModule,
		FormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatExpansionModule,
	],
	declarations: [
		ProductAddToCartComponent,
		ProductCardComponent,
		ProductGridComponent,
		ProductListComponent,
		ProductListItemComponent,
		StockStatusComponent,
		AddToCartWidgetComponent,
		CustomizationAddonComponent,
		CustomizationAgreementsComponent,
		CustomizationFinishComponent,
		CustomizationGrillComponent,
		CustomizationMountComponent,
		CustomizationVariantComponent,
		CustomizationDimensionComponent,
		CategoryCardComponent,
		ProductSearchComponent,
		ProductFirmwareComponent,
		ProductFirmwareItemComponent,
	],
	exports: [
		ProductAddToCartComponent,
		ProductCardComponent,
		ProductGridComponent,
		ProductListComponent,
		ProductListItemComponent,
		StockStatusComponent,
		AddToCartWidgetComponent,
		CategoryCardComponent,
		ProductSearchComponent,
		ProductFirmwareComponent,
	],
})
export class ProductsSharedModule { }
