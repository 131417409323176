import { Injectable } from "@angular/core";
import { ICloudinaryCategorization, ICloudinaryUploadResult } from "@app/shared-elements/shared-elements.models";
import { IMedia, MediaType } from "@app/shared/shared.models";

@Injectable({
	providedIn: "root",
})
export class CloudinaryService {
	notFoundUrl = "https://res.cloudinary.com/control4/#transforms#/C4-ImageNotAvailable-DarkerLogo";
	realPersonTags = ["Human", "Person"];
	blockedTags = ["Child", "Baby"];
	constructor() { }

	getUrl(media: IMedia, transformations?: string, isThumbnail = false): string {
		let url: string;

		if (media) {
			url = (isThumbnail ? media.thumbnailUrl : media.url) || media.url || this.notFoundUrl;
		} else {
			url = this.notFoundUrl;
		}

		let transforms = !media || this.isImage(media.mediaTypeId) && media.primaryId !== "Logos" ? "e_trim:1/" : "";

		if (transformations) {
			transforms += transformations.split(",").map(item => item.replace("=", "_").trim()).join(",");
		}

		url = url.replace("#transforms#", transforms);

		return url;
	}

	isValidProfilePicture(image: ICloudinaryUploadResult) {
		const approved = image.moderation.length && image.moderation[0].status === "approved" && image.info.categorization;
		const hasFace = !!image.info.detection.aws_rek_face.data.unrecognized_faces.length || !!image.faces.length;
		const isPerson = this.checkTags(image.info.categorization, this.realPersonTags, 0.9);
		const hasBlockedTags = this.checkTags(image.info.categorization, this.blockedTags, 0.6);
		return approved && hasFace && isPerson && !hasBlockedTags;
	}

	checkTags(categorization: ICloudinaryCategorization, searchTags: string[], confidence: number) {
		return categorization && categorization.aws_rek_tagging.data.some(tag => searchTags.includes(tag.tag) && tag.confidence > confidence);
	}

	isImage(type: MediaType): boolean {
		switch (type) {
			case MediaType.Attachment:
			case MediaType.Downloads:
			case MediaType.Documents:
			case MediaType.Firmware:
			case MediaType.Video:
				return false;
			case MediaType.Image:
			case MediaType.SpecSheet:
			default:
				return true;
		}
	}
}
