import { getNgStoreCacheItem } from "@app/shared/helpers";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { productStockStatusAdapter } from "./product-stock-status.adapter";
import { IProductStockStatusState } from "./product-stock-status.reducer";

export const getProductStockStatusState = createFeatureSelector<IProductStockStatusState>("productStockStatus");

export const {
	selectIds: productStockStatusSelectIds,
	selectEntities: productStockStatusSelectEntities,
	selectAll: productStockStatusSelectAll,
	selectTotal: productStockStatusSelectTotal,
} = productStockStatusAdapter.getSelectors(getProductStockStatusState);

const productLoaded = (sku: string) => createSelector(
	productStockStatusSelectEntities, entities => sku && !!getNgStoreCacheItem(entities[sku.toUpperCase()]),
);

export const getProductStockStatus = (sku: string) => createSelector(
	productLoaded(sku),
	productStockStatusSelectEntities,
	(loaded, items) => loaded && sku && items[sku.toUpperCase()] ? items[sku.toUpperCase()].stockStatus : undefined,
);