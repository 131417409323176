import { IUpgradeRequestFile } from "@app/account/account.models";
import { ngStoreCacheItem, objectUpdate } from "@app/shared/helpers";
import { IValueCache, SubmitStateType } from "@app/shared/shared.models";
import { UpgradeRequestFileActions, UpgradeRequestFileActionTypes } from "@app/store/upgrade-request-file/upgrade-request-file.actions";
import { upgradeRequestFileAdapter } from "@app/store/upgrade-request-file/upgrade-request-file.adapter";
import { EntityState } from "@ngrx/entity";

export interface IUpgradeRequestFileState extends EntityState<IUpgradeRequestFile> {
	loadedAccounts: IValueCache<string>[];
	pendingDelete: IUpgradeRequestFile;
	submitState: SubmitStateType;
}

export const initialState: IUpgradeRequestFileState = upgradeRequestFileAdapter.getInitialState({
	loadedAccounts: [],
	pendingDelete: undefined,
	submitState: SubmitStateType.Ready
});

export function upgradeRequestFileReducer(
	state = initialState,
	action: UpgradeRequestFileActions
): IUpgradeRequestFileState {
	switch (action.type) {
		case UpgradeRequestFileActionTypes.LoadAccountUpgradeRequestFilesSuccess: {
			const cache = ngStoreCacheItem({ item: action.payload.accountId } as IValueCache<string>, 6, "hours");
			const newState = objectUpdate(state, { loadedAccounts: [...state.loadedAccounts, cache] });
			return upgradeRequestFileAdapter.upsertMany(action.payload.files, newState);
		}
		case UpgradeRequestFileActionTypes.DeleteUpgradeRequestFileAttempt:
			return objectUpdate(state, { pendingDelete: action.payload });
		case UpgradeRequestFileActionTypes.DeleteUpgradeRequestFileSuccess: {
			const newState = objectUpdate(state, { pendingDelete: undefined });
			return upgradeRequestFileAdapter.removeOne(action.payload.id, newState);
		}
		case UpgradeRequestFileActionTypes.UpdateSubmitState:
			return objectUpdate(state, { submitState: action.payload });
		case UpgradeRequestFileActionTypes.SubmitUpgradeRequestAttempt:
			return objectUpdate(state, { submitState: SubmitStateType.Submitting });
		case UpgradeRequestFileActionTypes.SubmitUpgradeRequestError:
			return objectUpdate(state, { submitState: SubmitStateType.Ready });
		case UpgradeRequestFileActionTypes.SubmitUpgradeRequestSuccess: {
			const newState = objectUpdate(state, { submitState: SubmitStateType.Success });
			const files = action.payload.files as IUpgradeRequestFile[];
			files.forEach(x => x.accountId = action.payload.accountId);
			return upgradeRequestFileAdapter.upsertMany(files, newState);
		}
		default:
			return state;
	}
}