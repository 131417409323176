import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { IAppState } from "@app/store";
import { getProductDetailsBySku, LoadProductDetailsSuccess } from "@app/store/product-details";
import { select, Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap, take, tap } from "rxjs/operators";
import { ProductService } from "./product.service";

@Injectable({
	providedIn: "root",
})
export class ProductDetailResolverService implements Resolve<string> {

	constructor(private router: Router, private productService: ProductService, private location: Location, private store: Store<IAppState>,
	) { }

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> | Observable<never> {
		const productSku = route.paramMap.get("sku");

		return this.store.pipe(
			select(getProductDetailsBySku(productSku)),
			mergeMap(product => !product
				? this.productService.getProductDetails(productSku).pipe(
					tap(p => this.store.dispatch(new LoadProductDetailsSuccess(p))),
					map(p => p.sku),
					catchError(() => of(null)),
				)
				: of(product.sku),
			),
			mergeMap(sku => !sku
				? this.router.navigate(["/404"]).then(() => this.location.replaceState(state.url))
				: of(sku),
			),
			take(1),
		);
	}
}
