import { Component, ComponentRef, OnDestroy, OnInit } from "@angular/core";
import { IUser } from "@app/account/account.models";
import { ImpersonationPopupComponent } from "@app/popups/impersonation-popup/impersonation-popup.component";
import { AuthService } from "@app/services/auth.service";
import { PopupService } from "@app/services/popup.service";
import { UnimpersonateAttempt } from "@app/store/auth";
import { getImpersonatedUserWithAccount, UserState } from "@app/store/user";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: "c4-impersonation-controls",
	templateUrl: "./impersonation-controls.component.html",
	styleUrls: ["./impersonation-controls.component.scss"],
})
export class ImpersonationControlsComponent implements OnInit, OnDestroy {
	impersonatedUser: IUser;
	impersonatedUserDisplay: string;
	impersonationPopup: ComponentRef<{}>;

	private destroyed$ = new Subject<{}>();

	constructor(readonly store: Store<UserState>, readonly authService: AuthService, readonly popupService: PopupService) { }

	ngOnInit() {
		this.store.pipe(
			select(getImpersonatedUserWithAccount),
			takeUntil(this.destroyed$),
		).subscribe(user => {
			this.removePopup();
			this.impersonatedUser = user;
			if (user && user.account) {
				const subdealer = user.subDealerId ? `${user.subDealerId} | ` : "";
				this.impersonatedUserDisplay = `${subdealer}${user.account.id} ${user.account.accountName}`;
			}
		});
	}

	ngOnDestroy() {
		this.removePopup();
		this.destroyed$.next();
	}

	impersonate() {
		this.impersonationPopup = this.popupService.create(ImpersonationPopupComponent);
	}

	unimpersonate() {
		this.store.dispatch(new UnimpersonateAttempt());
	}

	private removePopup() {
		if (this.impersonationPopup) {
			this.impersonationPopup.destroy();
			this.impersonationPopup = null;
		}
	}
}