import { IRebateProgressData } from "@app/account/account.models";
import { IError } from "@app/shared/shared.models";
import { Action } from "@ngrx/store";

export class LoadRebateProgressAttempt implements Action {
	readonly type = RebateProgressActionTypes.LoadRebateProgressAttempt;
	constructor() { }
}

export class LoadRebateProgressSuccess implements Action {
	readonly type = RebateProgressActionTypes.LoadRebateProgressSuccess;
	constructor(public payload: IRebateProgressData) { }
}

export class LoadRebateProgressError implements Action {
	readonly type = RebateProgressActionTypes.LoadRebateProgressError;
	constructor(public payload: IError) { }
}

export type RebateProgressActions =
	LoadRebateProgressAttempt
	| LoadRebateProgressSuccess
	| LoadRebateProgressError;

export enum RebateProgressActionTypes {
	LoadRebateProgressAttempt = "[Rebate Progress Load] Attempt",
	LoadRebateProgressSuccess = "[Rebate Progress Load] Success",
	LoadRebateProgressError = "[Rebate Progress Load] Error",
}
