import { IProductLightModel } from "@app/products/products.models";
import { deepcopy, ngStoreCacheItem, objectUpdate } from "@app/shared/helpers";
import { IValueCache } from "@app/shared/shared.models";
import { ProductLightActions, ProductLightActionType } from "@app/store/product-light";
import { lightAdapter } from "@app/store/product-light/product-light.adapter";
import { Dictionary, EntityState } from "@ngrx/entity";
import { AuthActions, AuthActionType } from "../auth";
import { CartActions, CartActionType } from "../cart";
import { ProductDetailsActions, ProductDetailsActionType } from "../product-details";

export interface IProductLightState extends EntityState<IProductLightModel> {
	recentlyViewed: string[];
	failedSkus: Dictionary<IValueCache<string>>;
}
export const lightInitialState: IProductLightState = lightAdapter.getInitialState({
	recentlyViewed: [],
	failedSkus: {},
});
export function productLightReducer(
	state = lightInitialState,
	action: ProductLightActions | AuthActions | CartActions | ProductDetailsActions
): IProductLightState {
	switch (action.type) {
		case AuthActionType.ImpersonateSuccess:
		case AuthActionType.UnimpersonateSuccess:
		case CartActionType.ChangeActiveCartSuccess:
		case CartActionType.CheckoutPageLoadedSuccess: {
			const newState = objectUpdate(state, { failedSkus: {} });
			return lightAdapter.removeAll(newState);
		}
		case ProductLightActionType.BulkLoadProductsLightSuccess: {
			const failedSkus = deepcopy(state.failedSkus);
			action.payload.failedSkus.map(x => x.toUpperCase()).forEach((sku) => {
				failedSkus[sku] = ngStoreCacheItem({ item: sku } as IValueCache<string>, 20, "minutes");
			});
			const newState = objectUpdate(state, { failedSkus });
			return lightAdapter.upsertMany(action.payload.products.map(x => ngStoreCacheItem(x, 20, "minutes")), newState);
		}
		case ProductDetailsActionType.UpdateCmsProductDetailsSuccess:
			return lightAdapter.removeOne(action.payload.sku.toUpperCase(), state);
		case ProductDetailsActionType.UpdateCmsBulkProductDetailsSuccess:
			return lightAdapter.removeMany(action.payload.skus.map(sku => sku.toUpperCase()), state);
		case ProductLightActionType.PushRecentlyViewedProduct: {
			const recentlyViewed = [action.payload, ...state.recentlyViewed.filter(x => x !== action.payload).slice(0, 3)];
			return objectUpdate(state, { recentlyViewed });
		}
		default:
			return state;
	}
}