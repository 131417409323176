import { Component, Input, OnInit } from "@angular/core";
import { IVideoModel, VideoType } from "@app/marketing/marketing.models";
import { SearchResultItem } from "../search.models";

@Component({
	selector: "c4-search-video-item",
	templateUrl: "./search-video-item.component.html",
	styleUrls: ["./search-video-item.component.scss"],
})
export class SearchVideoItemComponent implements OnInit {
	@Input() item: SearchResultItem<IVideoModel>;

	video: IVideoModel;
	url: string;
	embeddedCode: string;
	linkUrl: string;

	constructor() { }

	ngOnInit() {
		this.video = this.item.data;
		this.url = this.getUrl(this.item.data);
		this.linkUrl = this.video.videoType === VideoType.Linked ? this.video.displayInfo : this.url;
		this.embeddedCode = this.getVideoEmbeddedCode(this.url);
	}

	private getUrl(video: IVideoModel) {
		return video.videoType === VideoType.Embedded ? this.getEmbeddedUrl(video) : this.getLinkedEmbedUrl(video);
	}

	private getEmbeddedUrl(video: IVideoModel) {
		const matches = video.displayInfo.match(/src=['"](.*?)['"]/);

		return matches && matches[1];
	}

	private getLinkedEmbedUrl(video: IVideoModel) {
		if (video.displayInfo && video.displayInfo.includes("control4.wistia.com")) {
			const id = video.displayInfo.split("/").slice(-1)[0];

			return `//fast.wistia.net/embed/iframe/${id}?videoFoam=true`;
		}

		return video.displayInfo;
	}

	private getVideoEmbeddedCode(url: string) {
		if (!url) {
			return undefined;
		}
		const allowFullScreen = "allowfullscreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen";
		if (url.includes("wistia.net")) {
			return `<iframe width="288" height="162" src="${url}" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" ${allowFullScreen}></iframe>`;
		}
		if (url.includes("youtube.com") || url.includes("youtu.be") || url.includes("facebook.com") || url.includes("vimeo.com")) {
			return `<iframe width="288" height="162" src='${url}' frameborder='0' ${allowFullScreen}></iframe>`;
		}

		return undefined;
	}

}
