import { Directive, ElementRef, HostListener, Input, OnInit } from "@angular/core";
import { TrackService } from "@app/services/track.service";

@Directive({
	selector: "[c4Track]",
})
export class TrackDirective implements OnInit {
	@Input("c4Track") action: string;
	@Input() trackCategory: string;
	@Input() trackLabel: string;

	constructor(private element: ElementRef, private tracking: TrackService) { }

	ngOnInit() {
	}

	@HostListener("click") trackClick() {
		const category = this.trackCategory || "Navigation";
		const text = typeof this.element.nativeElement.text === "function" ? this.element.nativeElement.text() : this.element.nativeElement.text;
		const label = this.trackLabel || (text ? text.trim() : undefined);
		this.tracking.trackEvent(this.action, category, label);
	}

}
