import { Component, OnDestroy, OnInit } from "@angular/core";
import { IUser } from "@app/account/account.models";
import { IAppState } from "@app/store";
import { getIsCurrentUserPremier } from "@app/store/account";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: "c4-static-premier-content",
	templateUrl: "./premier-content.component.html",
	styleUrls: ["./premier-content.component.scss"]
})
export class PremierContentComponent implements OnInit, OnDestroy {
	currentUser: IUser;
	isPremier = false;

	private destroyed$ = new Subject<{}>();

	constructor(private store: Store<IAppState>) { }

	ngOnInit() {
		this.store.pipe(
			select(getIsCurrentUserPremier),
			takeUntil(this.destroyed$)
		).subscribe((isPremier) => {
			this.isPremier = isPremier;
		});
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}
}
