import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AddressService } from "@app/services/address.service";
import { getNgStoreCacheItem } from "@app/shared/helpers";
import { IAddress } from "@app/shared/shared.models";
import { AddressActionTypes, LoadAddressAttempt, LoadAddressError, LoadAddressSuccess } from "@app/store/address";
import { AddressActions, LoadBillingAddressesAttempt, LoadBillingAddressesError, LoadBillingAddressesSuccess, LoadFinalDestinationAddressesAttempt, LoadFinalDestinationAddressesError, LoadFinalDestinationAddressesSuccess, LoadShippingAddressesAttempt, LoadShippingAddressesError, LoadShippingAddressesSuccess } from "@app/store/address/address.actions";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action, select, Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, filter, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { getCurrentAccountId } from "../account";
import { IAppState } from "../app.reducer";
import { addressesSelectEntities } from "./address.selectors";

@Injectable()
export class AddressEffects {
	constructor(
		private actions$: Actions<AddressActions>,
		private store: Store<IAppState>,
		private addressService: AddressService
	) { }

	@Effect()
	loadAddress$: Observable<Action> = this.actions$.pipe(
		ofType<LoadAddressAttempt>(AddressActionTypes.LoadAddressAttempt),
		withLatestFrom(this.store.pipe(select(addressesSelectEntities))),
		filter(([action, entities]) => !getNgStoreCacheItem(entities[action.payload])),
		mergeMap(([action, _]) => {
			return this.addressService
				.getAddress(action.payload)
				.pipe(
					map((info: IAddress) => new LoadAddressSuccess(info)),
					catchError((err: HttpErrorResponse) => of(new LoadAddressError({ error: err.message || err.statusText })))
				);
		})
	);

	@Effect()
	loadBillingAddresses$: Observable<Action> = this.actions$.pipe(
		ofType<LoadBillingAddressesAttempt>(AddressActionTypes.LoadBillingAddressesAttempt),
		withLatestFrom(this.store.pipe(select(getCurrentAccountId))),
		mergeMap(([_, accountId]) => {
			return this.addressService
				.getBillingAddresses()
				.pipe(
					map((addresses) => new LoadBillingAddressesSuccess({ accountId, addresses })),
					catchError((err: HttpErrorResponse) => of(new LoadBillingAddressesError({ error: err.message || err.statusText })))
				);
		})
	);

	@Effect()
	loadShippingAddresses$: Observable<Action> = this.actions$.pipe(
		ofType<LoadShippingAddressesAttempt>(AddressActionTypes.LoadShippingAddressesAttempt),
		withLatestFrom(this.store.pipe(select(getCurrentAccountId))),
		mergeMap(([_, accountId]) => {
			return this.addressService
				.getShippingAddresses()
				.pipe(
					map((addresses) => new LoadShippingAddressesSuccess({ accountId, addresses })),
					catchError((err: HttpErrorResponse) => of(new LoadShippingAddressesError({ error: err.message || err.statusText })))
				);
		})
	);

	@Effect()
	loadFinalDestinationAddresses$: Observable<Action> = this.actions$.pipe(
		ofType<LoadFinalDestinationAddressesAttempt>(AddressActionTypes.LoadFinalDestinationAddressesAttempt),
		withLatestFrom(this.store.pipe(select(getCurrentAccountId))),
		mergeMap(([_, accountId]) => {
			return this.addressService
				.getFinalDestinationAddresses()
				.pipe(
					map((addresses) => new LoadFinalDestinationAddressesSuccess({ accountId, addresses })),
					catchError((err: HttpErrorResponse) => of(new LoadFinalDestinationAddressesError({ error: err.message || err.statusText })))
				);
		})
	);
}
