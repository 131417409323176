import { getNgStoreCacheItem } from "@app/shared/helpers";
import { c4ContactsAdapter } from "@app/store/c4-contacts/c4-contacts.adapter";
import { IC4ContactsState } from "@app/store/c4-contacts/c4-contacts.reducer";
import { selectCurrentUser } from "@app/store/user";
import { createFeatureSelector, createSelector } from "@ngrx/store";

export const getC4ContactsState = createFeatureSelector<IC4ContactsState>("c4Contacts");

export const {
	selectIds: c4ContactsSelectIds,
	selectEntities: c4ContactsSelectEntities,
	selectAll: c4ContactsSelectAll,
	selectTotal: c4ContactsSelectTotal
} = c4ContactsAdapter.getSelectors(getC4ContactsState);

export const getCurrentControl4Contacts = createSelector(
	c4ContactsSelectEntities,
	selectCurrentUser,
	(entities, user) => user ? getNgStoreCacheItem(entities[user.accountId]) : undefined
);