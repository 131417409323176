import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { IUser } from "@app/account/account.models";
import { AuthService } from "@app/services/auth.service";
import { IAppState } from "@app/store";
import { getCurrentCartLoading } from "@app/store/cart";
import { selectCurrentNav } from "@app/store/nav";
import { ExternalRedirect } from "@app/store/router";
import { currentCanPurchase, selectCurrentUser } from "@app/store/user";
import { environment } from "@env/environment";
import { select, Store } from "@ngrx/store";
import { combineLatest, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: "c4-saml-handler",
	templateUrl: "./saml-handler.component.html",
	styleUrls: ["./saml-handler.component.scss"],
})
export class SamlHandlerComponent implements OnInit, OnDestroy {
	action: string;
	source: string;
	queryParams: Params;
	user: IUser;

	private destroyed$ = new Subject<{}>();

	constructor(readonly store: Store<IAppState>, readonly route: ActivatedRoute, readonly authService: AuthService) { }

	ngOnInit() {
		combineLatest([
			this.route.params,
			this.route.queryParams,
			this.store.pipe(select(selectCurrentUser)),
		]).pipe(
			takeUntil(this.destroyed$),
		).subscribe(([params, queryParams, user]) => {
			if (params.action === "training") {
				this.action = "SingleSignOnService";
				this.source = params.action;
			} else {
				this.action = params.action;
				this.source = "community";
			}

			this.queryParams = queryParams;
			this.user = user;

			if (user && queryParams) {
				this.redirect();
			}
		});
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}

	private redirect() {
		const jwt = this.authService.getCurrentJwt();
		let redirUrl = `${environment.apiUrl}saml/${this.action}?token=${jwt.token}&source=${this.source}`;

		const samlRequest = this.queryParams["SAMLRequest"];
		if (samlRequest) {
			redirUrl += `&SAMLRequest=${this.safeDecode(samlRequest)}`;
		}

		const samlRelayState = this.queryParams["RelayState"];
		if (samlRelayState) {
			redirUrl += `&RelayState=${this.safeDecode(samlRelayState)}`;
		}

		const samlSignature = this.queryParams["Signature"];
		if (samlSignature) {
			redirUrl += `&Signature=${this.safeDecode(samlSignature)}`;
		}

		const samlSigAlg = this.queryParams["SigAlg"];
		if (samlSigAlg) {
			redirUrl += `&SigAlg=${this.safeDecode(samlSigAlg)}`;
		}

		combineLatest([
			this.store.pipe(select(selectCurrentNav)),
			this.store.pipe(select(getCurrentCartLoading)),
			this.store.pipe(select(currentCanPurchase)),
		]).pipe(takeUntil(this.destroyed$)).subscribe(([navLoaded, cartIsLoading, canPurchase]) => {
			if (navLoaded && (!canPurchase || !cartIsLoading)) {
				this.store.dispatch(new ExternalRedirect(redirUrl));
			}
		});
	}

	private safeDecode(str: string): string {
		return str && (str.indexOf("/") > -1 || str.indexOf(" ") > -1 || str.indexOf("+") > -1) ? encodeURIComponent(str) : str;
	}
}
