import { Injectable } from "@angular/core";
import { DashboardService } from "@app/services/dashboard.service";
import { AcceptLeadAttempt, AcceptLeadError, AcceptLeadSuccess, CloseLeadAttempt, CloseLeadError, CloseLeadSuccess, LeadsActionTypes, LoadLeadsError, LoadLeadsSuccess, UpdateLeadStatusAttempt, UpdateLeadStatusError, UpdateLeadStatusSuccess } from "@app/store/leads";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action, select, Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, debounceTime, filter, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { IAppState } from "../app.reducer";
import { SuccessToast } from "../toast/toast.actions";
import { getLeadIdsForAccount } from "./leads.selectors";

@Injectable()
export class LeadsEffects {
	constructor(
		private actions$: Actions,
		private dashboardService: DashboardService,
		private store: Store<IAppState>,
	) { }

	@Effect()
	loadLeads$: Observable<Action> = this.actions$.pipe(
		ofType(LeadsActionTypes.LoadLeadsAttempt),
		debounceTime(300),
		withLatestFrom(this.store.pipe(select(getLeadIdsForAccount))),
		filter(([_, ids]) => !ids),
		mergeMap(() => {
			return this.dashboardService
				.getLeads()
				.pipe(
					map(info => new LoadLeadsSuccess(info)),
					catchError(err => of(new LoadLeadsError({ error: err.message || err.statusText }))),
				);
		}),
	);

	@Effect()
	updateLeadStatus$: Observable<Action> = this.actions$.pipe(
		ofType<UpdateLeadStatusAttempt>(LeadsActionTypes.UpdateLeadStatusAttempt),
		mergeMap(action => {
			return this.dashboardService
				.updateLeadStatus(action.payload)
				.pipe(
					mergeMap((result): Action[] => result
						? [new UpdateLeadStatusSuccess(result), new SuccessToast({ message: "Lead status has been updated." })]
						: [new UpdateLeadStatusError({ error: "Salesforce failed update" })]),
					catchError(err => of(new UpdateLeadStatusError({ error: err.message || err.statusText }))),
				);
		}),
	);

	@Effect()
	closeLead$: Observable<Action> = this.actions$.pipe(
		ofType<CloseLeadAttempt>(LeadsActionTypes.CloseLeadAttempt),
		mergeMap(action => {
			return this.dashboardService
				.updateLeadStatus(action.payload)
				.pipe(
					mergeMap((result): Action[] => result
						? [new CloseLeadSuccess(result), new SuccessToast({ message: "Lead has been removed." })]
						: [new CloseLeadError({ error: "Salesforce failed to close" })]),
					catchError(err => of(new CloseLeadError({ error: err.message || err.statusText }))),
				);
		}),
	);

	@Effect()
	acceptLead$: Observable<Action> = this.actions$.pipe(
		ofType<AcceptLeadAttempt>(LeadsActionTypes.AcceptLeadAttempt),
		mergeMap(action => {
			return this.dashboardService
				.updateLeadStatus(action.payload)
				.pipe(
					mergeMap((result): Action[] => result
						? [new AcceptLeadSuccess(result), new SuccessToast({ message: "Lead has been accepted" })]
						: [new AcceptLeadError({ error: "Salesforce failed to accept" })]),
					catchError(err => of(new AcceptLeadError({ error: err.message || err.statusText }))),
				);
		}),
	);
}
