import { Component, Input, OnInit } from "@angular/core";
import { StaticPageFormsService } from "@app/services/static-page-forms.service";

@Component({
	selector: "c4-portal-contact-us-form",
	templateUrl: "./portal-contact-us-form.component.html",
	styleUrls: ["./portal-contact-us-form.component.scss"]
})
export class PortalContactUsFormComponent implements OnInit {
	@Input() close: () => void;
	subject: "technical" | "feedback" | "" = "";
	sent: boolean;

	comments: string;
	submitted = false;

	constructor(readonly staticPageFormsService: StaticPageFormsService) { }

	ngOnInit() {
	}

	submit() {
		this.staticPageFormsService.postForward({
			postUrl: "https://go.pardot.com/l/63762/2015-07-21/48j821",
			comments: this.comments,
			subject: this.subject,
		}).subscribe(() => {
			if (this.close) {
				this.comments = "";
				this.subject = "";
				this.submitted = false;
				this.close();
			}
		});
	}
}
