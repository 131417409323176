import { checkDisplayToggle, getNgStoreCacheItem } from "@app/shared/helpers";
import { IProductDetailsState } from "@app/store/product-details/product-details.reducer";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { isActiveAccountInternal } from "../account";
import { getSiteFeatureFlag } from "../app-info/app-info.selectors";
import { detailsAdapter } from "./product-details.adapter";

export const getProductDetailsState = createFeatureSelector<IProductDetailsState>("productDetails");

export const {
	selectIds: detailsSelectIds,
	selectEntities: detailsSelectEntities,
	selectAll: detailsSelectAll,
	selectTotal: detailsSelectTotal,
} = detailsAdapter.getSelectors(getProductDetailsState);

export const getSelectedSku = createSelector(
	getProductDetailsState, state => state.selectedSku,
);

export const getCustomizedSku = createSelector(
	getProductDetailsState, state => state.customizedSku,
);

export const getSelectedDetails = createSelector(
	getSelectedSku,
	detailsSelectEntities,
	(sku, entities) => sku ? getNgStoreCacheItem(entities[sku]) : undefined,
);

export const getProductDetailsBySku = (sku: string) => createSelector(
	detailsSelectEntities, entities => sku ? getNgStoreCacheItem(entities[sku]) : undefined,
);

export const showStockStatus = createSelector(
	isActiveAccountInternal,
	getSiteFeatureFlag("showStockStatus"),
	(isInternal, flag) => checkDisplayToggle(flag, isInternal),
);