import { AfterViewChecked, Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from "@angular/core";
import { CloudinaryService } from "@app/services/cloudinary.service";
import { IMedia } from "@app/shared/shared.models";

@Directive({
	selector: "[c4Zoom]"
})
export class ZoomDirective implements OnInit, AfterViewChecked {

	@Input("c4Zoom") image: IMedia;
	@Input() transformations: string;
	@Input() zoomTransformations: string;
	@Input() speed: number;
	@Input() scale: number;

	private zoomEnabled: boolean;
	private offset: any;

	constructor(private cloudinary: CloudinaryService, private element: ElementRef, private renderer: Renderer2) { }

	ngOnInit(): void {
		this.updateUrl(this.transformations);
	}

	ngAfterViewChecked(): void {
		this.offset = this.element.nativeElement.parentElement.getBoundingClientRect();
	}

	@HostListener("click", ["$event"])
	onClick(event: any) {
		this.zoomEnabled = !this.zoomEnabled;

		if (this.zoomEnabled) {
			this.updateUrl(this.zoomTransformations);
			this.updateZoomPosition(event);
		} else {
			this.updateUrl(this.transformations);
			this.clearZoom();
		}
	}

	@HostListener("mousemove", ["$event"])
	onMouseMove(event: any) {
		if (this.zoomEnabled) {
			this.updateZoomPosition(event);
		}
	}

	@HostListener("mouseleave")
	onMouseLeave() {
		this.clearZoom();
	}

	private updateUrl(transformations: string) {
		this.renderer.setStyle(this.element.nativeElement, "background-image", `url("${this.cloudinary.getUrl(this.image, transformations)}")`);
	}

	private updateZoomPosition(event: any) {
		const x = -300 * ((event.x - this.offset.left) / this.offset.width) + 150;
		const y = -300 * ((event.y - this.offset.top) / this.offset.height) + 150;
		this.renderer.setStyle(this.element.nativeElement, "transform", `translate(${x}%, ${y}%) scale(3)`);
		this.renderer.setStyle(this.element.nativeElement, "transition-duration", "0s");
	}

	private clearZoom() {
		this.zoomEnabled = false;
		this.renderer.setStyle(this.element.nativeElement, "transform", "translate(0%, 0%)");
		this.renderer.setStyle(this.element.nativeElement, "transition-duration", "0.5s");
	}
}
