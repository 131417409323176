import { NgModule } from "@angular/core";
import { PreloadingStrategy, Route, RouterModule, Routes } from "@angular/router";
import { AuthenticatedGuard } from "@app/auth/auth.guard";
import { LoginHomeComponent } from "@app/auth/login-home/login-home.component";
import { MsrpGuard } from "@app/auth/msrp.guard";
import { SearchComponent } from "@app/search/search/search.component";
import { PrintLayoutComponent } from "@app/_layout/print-layout/print-layout.component";
import { SiteLayoutComponent } from "@app/_layout/site-layout/site-layout.component";
import { Observable, timer } from "rxjs";
import { flatMap } from "rxjs/operators";
import { AccountSecurityRole, SecurityRoles } from "./account/account.models";
import { EmployeeRegistrationComponent } from "./auth/employee-registration/employee-registration.component";
import { IframeAuthGuard } from "./auth/iframe-auth.guard";
import { LogoutComponent } from "./auth/logout/logout.component";
import { InternalErrorComponent } from "./error/internal-error/internal-error.component";
import { NotFoundComponent } from "./error/not-found/not-found.component";
import { UnauthorizedComponent } from "./error/unauthorized/unauthorized.component";
import { PrintInvoiceComponent } from "./print/print-invoice/print-invoice.component";
import { PrintLightingCompatibilityReportComponent } from "./print/print-lighting-compatibility-report/print-lighting-compatibility-report.component";
import { PrintProformaInvoiceComponent } from "./print/print-proforma-invoice/print-proforma-invoice.component";
import { ProductDetailResolverService } from "./services/product-detail-resolver.service";
import { caseInsensitiveMatcher } from "./shared/router-helpers";
import { SamlHandlerComponent } from "./sso/saml-handler/saml-handler.component";
import { EmployeeRegistrationLayoutComponent } from "./_layout/employee-registration-layout/employee-registration-layout.component";
import { IframeLayoutComponent } from "./_layout/iframe-layout/iframe-layout.component";
import { LoginLayoutComponent } from "./_layout/login-layout/login-layout.component";

export function handleError(error: Error): void {
	const routerError = error.toString();
	console.error(error);
	if (routerError.indexOf("Cannot match any routes") >= 0) {
		if (routerError.indexOf("dealer/") < 0) {
			this.navigate(["/404"], { skipLocationChange: true });
		} else {
			console.error("dealer/ redirect needed");
		}
	}
}

export class DelayedPreloadAllModules implements PreloadingStrategy {
	preload(router: Route, load: () => Observable<any>): Observable<any> {
		return timer(5000).pipe(flatMap(_ => load()));
	}
}

export const ROUTES: Routes = [
	{
		matcher: caseInsensitiveMatcher("dealer"),
		redirectTo: "",
	},
	{
		matcher: caseInsensitiveMatcher("iframe"),
		component: IframeLayoutComponent,
		canActivate: [IframeAuthGuard],
		children: [
			{
				matcher: caseInsensitiveMatcher("dashboard"), canActivate: [MsrpGuard], canActivateChild: [MsrpGuard], loadChildren: () => import("./dashboard/dashboard.module").then(m => m.DashboardModule),
				data: { requiredRole: SecurityRoles.Dashboard | SecurityRoles.ViewCompInfo | SecurityRoles.ViewOrderInfo },
			},
			{
				matcher: caseInsensitiveMatcher("account"), canActivate: [MsrpGuard], canActivateChild: [MsrpGuard], loadChildren: () => import("./account/account.module").then(m => m.AccountModule),
			},
			{
				matcher: caseInsensitiveMatcher("rma"), canActivate: [MsrpGuard], canActivateChild: [MsrpGuard], loadChildren: () => import("./rma/rma.module").then(m => m.RmaModule),
				data: { requiredAccountRole: AccountSecurityRole.RmaManagement },
			},
		],
	},
	{
		matcher: caseInsensitiveMatcher("login"),
		component: LoginLayoutComponent,
		children: [
			{ path: "", pathMatch: "full", component: LoginHomeComponent, data: { title: "Login" } },
			{ matcher: caseInsensitiveMatcher("passwordreset/:guid"), component: LoginHomeComponent, data: { title: "Password Reset" } },
			{ matcher: caseInsensitiveMatcher("token/:firstlogin"), component: LoginHomeComponent, data: { title: "Login" } },
		],
	},
	{
		matcher: caseInsensitiveMatcher("employee/register"),
		component: EmployeeRegistrationLayoutComponent,
		children: [
			{ path: "", pathMatch: "full", component: EmployeeRegistrationComponent, data: { title: "Employee Registration" } },
		],
	},
	{
		matcher: caseInsensitiveMatcher("print"),
		component: PrintLayoutComponent,
		canActivate: [AuthenticatedGuard],
		canActivateChild: [AuthenticatedGuard],
		children: [
			{ matcher: caseInsensitiveMatcher("invoice/:id"), component: PrintInvoiceComponent },
			{ matcher: caseInsensitiveMatcher("proforma/:id"), component: PrintProformaInvoiceComponent },
			{ matcher: caseInsensitiveMatcher("lighting-compatibility/:sku/:lightId"), component: PrintLightingCompatibilityReportComponent },
		],
	},
	{
		path: "",
		component: SiteLayoutComponent,
		canActivate: [AuthenticatedGuard],
		canActivateChild: [AuthenticatedGuard],
		children: [
			{ path: "", pathMatch: "full", loadChildren: () => import("./home/home.module").then(m => m.HomeModule) },
			{ matcher: caseInsensitiveMatcher("logout"), component: LogoutComponent },
			{
				matcher: caseInsensitiveMatcher("dashboard"), canActivate: [MsrpGuard], canActivateChild: [MsrpGuard], loadChildren: () => import("./dashboard/dashboard.module").then(m => m.DashboardModule),
				data: { requiredRole: SecurityRoles.Dashboard | SecurityRoles.ViewCompInfo | SecurityRoles.ViewOrderInfo },
			},
			{ matcher: caseInsensitiveMatcher("account"), canActivate: [MsrpGuard], canActivateChild: [MsrpGuard], loadChildren: () => import("./account/account.module").then(m => m.AccountModule) },
			{ matcher: caseInsensitiveMatcher("checkout"), loadChildren: () => import("./checkout/checkout.module").then(m => m.CheckoutModule), data: { requiredRole: SecurityRoles.PurchasingUser } },
			{ matcher: caseInsensitiveMatcher("promotions"), canActivate: [MsrpGuard], canActivateChild: [MsrpGuard], loadChildren: () => import("./promotions/promotions.module").then(m => m.PromotionsModule) },
			{ matcher: caseInsensitiveMatcher("programs"), canActivate: [MsrpGuard], canActivateChild: [MsrpGuard], loadChildren: () => import("./programs/programs.module").then(m => m.ProgramsModule), data: { requiredRole: SecurityRoles.ViewCompInfo } },
			{ matcher: caseInsensitiveMatcher("os3"), canActivate: [MsrpGuard], canActivateChild: [MsrpGuard], loadChildren: () => import("./smart-home-os/smart-home-os.module").then(m => m.SmartHomeOsModule) },
			{ matcher: caseInsensitiveMatcher("ovrc"), canActivate: [MsrpGuard], canActivateChild: [MsrpGuard], loadChildren: () => import("./smart-home-os/smart-home-os.module").then(m => m.SmartHomeOsModule) },
			{ matcher: caseInsensitiveMatcher("products"), loadChildren: () => import("./products/products.module").then(m => m.ProductsModule) },
			{ matcher: caseInsensitiveMatcher("product/:sku"), redirectTo: "/products/0/product/:sku", resolve: { sku: ProductDetailResolverService } },
			{ matcher: caseInsensitiveMatcher("rma"), canActivate: [MsrpGuard], canActivateChild: [MsrpGuard], loadChildren: () => import("./rma/rma.module").then(m => m.RmaModule), data: { requiredAccountRole: AccountSecurityRole.RmaManagement } },
			{ matcher: caseInsensitiveMatcher("search"), canActivate: [MsrpGuard], canActivateChild: [MsrpGuard], component: SearchComponent, data: { title: "Search" } },
			// tslint:disable-next-line:max-line-length
			{ matcher: caseInsensitiveMatcher("support"), canActivate: [MsrpGuard], canActivateChild: [MsrpGuard], loadChildren: () => import("./support/support.module").then(m => m.SupportModule), data: { requiredAccountRole: AccountSecurityRole.Support } },
			// tslint:disable-next-line:max-line-length
			{ matcher: caseInsensitiveMatcher("knowledgebase"), canActivate: [MsrpGuard], canActivateChild: [MsrpGuard], loadChildren: () => import("./support/kb/kb.module").then(m => m.KbModule), data: { requiredAccountRole: AccountSecurityRole.Support } },
			// tslint:disable-next-line:max-line-length
			{ matcher: caseInsensitiveMatcher("new"), canActivate: [MsrpGuard], canActivateChild: [MsrpGuard], loadChildren: () => import("./what-new/what-new.module").then(m => m.WhatNewModule), data: { requiredAccountRole: AccountSecurityRole.WhatsNew } },
			// tslint:disable-next-line:max-line-length
			{ matcher: caseInsensitiveMatcher("education"), canActivate: [MsrpGuard], canActivateChild: [MsrpGuard], loadChildren: () => import("./training/training.module").then(m => m.TrainingModule), data: { requiredAccountRole: AccountSecurityRole.Education } },
			{ matcher: caseInsensitiveMatcher("training"), redirectTo: "/education" },
			// tslint:disable-next-line: max-line-length
			{ matcher: caseInsensitiveMatcher("marketing"), canActivate: [MsrpGuard], canActivateChild: [MsrpGuard], loadChildren: () => import("./marketing/marketing.module").then(m => m.MarketingModule), data: { requiredAccountRole: AccountSecurityRole.MarketingResources } },
			// tslint:disable-next-line:max-line-length
			{ matcher: caseInsensitiveMatcher("design-services"), canActivate: [MsrpGuard], canActivateChild: [MsrpGuard], loadChildren: () => import("./design-services/design-services.module").then(m => m.DesignServicesModule), data: { requiredAccountRole: AccountSecurityRole.DesignServices } },
			{ matcher: caseInsensitiveMatcher("cms"), canActivate: [MsrpGuard], canActivateChild: [MsrpGuard], loadChildren: () => import("./cms/cms.module").then(m => m.CmsModule) },
			{ matcher: caseInsensitiveMatcher("saml/:action"), component: SamlHandlerComponent, data: { title: "Single Sign On" } },
			{ path: "500", component: InternalErrorComponent },
			{ path: "401", component: UnauthorizedComponent },
			{ path: "**", component: NotFoundComponent },
		],
	},
];

@NgModule({
	imports: [RouterModule.forRoot(ROUTES, {
		onSameUrlNavigation: "reload",
		anchorScrolling: "enabled",
		scrollPositionRestoration: "top",
		errorHandler: handleError,
		urlUpdateStrategy: "eager",
		preloadingStrategy: DelayedPreloadAllModules,
	})],
	exports: [RouterModule],
	providers: [ProductDetailResolverService, DelayedPreloadAllModules],
})
export class AppRoutingModule { }
