import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AccountService } from "@app/services/account.service";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action, select, Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, debounceTime, filter, map, mergeMap, switchMap } from "rxjs/operators";
import { IAppState } from "../app.reducer";
import { LoadSalesInfoError, LoadSalesInfoSuccess, SalesInfoActionTypes } from "./sales-info.actions";
import { getCurrentSalesInfo } from "./sales-info.selectors";

@Injectable()
export class SalesInfoEffects {
	constructor(
		private actions$: Actions,
		private accountService: AccountService,
		private store: Store<IAppState>
	) { }

	@Effect()
	loadSalesInfo$: Observable<Action> = this.actions$.pipe(
		ofType(SalesInfoActionTypes.LoadSalesInfoAttempt),
		debounceTime(100),
		switchMap(() => this.store.pipe(select(getCurrentSalesInfo))),
		filter((data) => !data),
		mergeMap(() => {
			return this.accountService
				.getAccountSalesInfo()
				.pipe(
					map((info) => new LoadSalesInfoSuccess(info)),
					catchError((err: HttpErrorResponse) => of(new LoadSalesInfoError({ error: err.message || err.statusText })))
				);
		})
	);
}
