import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { ForgotPasswordComponent } from "@app/auth/forgot-password/forgot-password.component";
import { LoginHomeComponent } from "@app/auth/login-home/login-home.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { EmployeeRegistrationComponent } from "./employee-registration/employee-registration.component";
import { LogoutComponent } from "./logout/logout.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";

@NgModule({
	imports: [CommonModule, FormsModule, MatFormFieldModule, MatInputModule],
	declarations: [LoginHomeComponent, ForgotPasswordComponent, ResetPasswordComponent, LogoutComponent, ChangePasswordComponent, EmployeeRegistrationComponent],
	exports: [ResetPasswordComponent, ChangePasswordComponent],
})
export class AuthModule { }
