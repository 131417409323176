import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UserService } from "@app/services/user.service";
import { LoadAccountAttempt } from "@app/store/account";
import { AuthActionType, ImpersonateSuccess } from "@app/store/auth";
import { ContractActionType, contractSelectAll, LoadContractAttempt, LoadContractError, LoadContractSuccess } from "@app/store/contract";
import { ExternalRedirect } from "@app/store/router";
import { LoadUserAttempt, LoadUserError, LoadUserSuccess, SaveUserProfileAttempt, SaveUserProfileError, SaveUserProfileSuccess, UserActionTypes } from "@app/store/user";
import { environment } from "@env/environment";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action, select, Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { IAppState } from "../app.reducer";
import { SuccessToast } from "../toast/toast.actions";

@Injectable()
export class UserEffects {
	constructor(private actions$: Actions, private store: Store<IAppState>, private userService: UserService) { }

	@Effect()
	getUser$: Observable<Action> = this.actions$.pipe(
		ofType<LoadUserAttempt>(UserActionTypes.LoadAttempt),
		mergeMap(action => {
			return this.userService
				.get(action.payload.userId)
				.pipe(
					mergeMap(info => [new LoadUserSuccess(info), new LoadAccountAttempt(info.accountId)]),
					catchError(err => of(new LoadUserError({ error: err.message || err.statusText })),
					),
				);
		}),
	);

	@Effect()
	saveUserProfile$: Observable<{}> = this.actions$.pipe(
		ofType<SaveUserProfileAttempt>(UserActionTypes.SaveUserProfileAttempt),
		mergeMap(action => {
			return this.userService
				.updateProfile(action.payload.user, action.payload.sfInfo)
				.pipe(
					mergeMap(user => {
						const retActions: Action[] = [new SaveUserProfileSuccess(user, action.payload.sfInfo)];
						if (action.payload.displayMessage) {
							retActions.push(new SuccessToast({ message: "Your profile has been updated." }));
						}
						if (action.payload.redirectToTechnicianPortal) {
							if (!action.payload.sfInfo.user) {
								console.log("Must provide a salesforce user for redirect");
							} else {
								retActions.push(new ExternalRedirect(`${environment.technicianPortalUrl}profile/${action.payload.sfInfo.user.id}`));
							}
						}
						if (action.payload.autoClose) {
							this.userService.displayUserProfileEdit.emit(false);
						}

						return retActions;
					}),
					catchError((err: HttpErrorResponse) => of(new SaveUserProfileError({ error: err.message || err.statusText })),
					),
				);
		}),
	);

	@Effect()
	getAuthenticatedUser$: Observable<Action> = this.actions$.pipe(
		ofType<ImpersonateSuccess>(AuthActionType.ImpersonateSuccess),
		map(action =>
			new LoadUserAttempt({
				userId: action.payload.userId,
				forceReload: true,
			}),
		),
	);

	@Effect()
	getCurrentContract$: Observable<{}> = this.actions$.pipe(
		ofType<LoadContractAttempt>(ContractActionType.LoadContract),
		withLatestFrom(this.store.pipe(select(contractSelectAll))),
		mergeMap(([action, contracts]) => {
			if (contracts && contracts.find(x => x.contractType === action.payload)) {
				return of();
			}

			return this.userService
				.getCurrentContract(action.payload)
				.pipe(
					map(info => new LoadContractSuccess(info)),
					catchError(err => of(new LoadContractError({ error: err.message || err.statusText }))),
				);
		}),
	);
}