import { Pipe, PipeTransform } from "@angular/core";
import { ICartItemModel } from "@app/checkout/checkout.models";

@Pipe({ name: "stockStatus" })
export class StockStatusPipe implements PipeTransform {

	transform(items: ICartItemModel[] = [], status: "backorder" | "shippingMessage" | "builtToOrder" | "partner" | "inStock"): ICartItemModel[] {
		switch (status) {
			case "backorder":
				return items.filter(x => x.isOnBackorder && !x.isPartner && !x.isKeycap);
			case "shippingMessage":
				return items.filter(x => !x.isOnBackorder && !x.isBuiltToOrder && !x.isPartner && x.shippingEstimateDescription);
			case "builtToOrder":
				return items.filter(x => x.isBuiltToOrder || x.isKeycap);
			case "partner":
				return items.filter(x => x.isPartner && !x.isBuiltToOrder && !x.isKeycap);
			case "inStock":
				return items.filter(x => x.isInStock && !x.isBuiltToOrder && !x.isKeycap && !x.isPartner && !!!x.shippingEstimateDescription);
		}

		return items;
	}
}