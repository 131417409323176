import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { IAppState } from "@app/store";
import { getCurrentBrandName } from "@app/store/app-info/app-info.selectors";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: "c4-brand-content",
	templateUrl: "./brand-content.component.html",
	styleUrls: ["./brand-content.component.scss"]
})
export class BrandContentComponent implements OnInit, OnDestroy {

	private _brandArr: string[] = [];
	private _brands: string;
	@Input() get brands() {
		return this._brands;
	}

	set brands(val: string) {
		this._brands = val;
		this._brandArr = val ? val.split(/,\s*/) : [];
		this.brandMatch = this.matchesBrand();
	}

	private currentBrand: string;
	private destroyed$ = new Subject<{}>();

	brandMatch: boolean;

	constructor(readonly store: Store<IAppState>) { }

	ngOnInit() {
		this.store.pipe(
			select(getCurrentBrandName),
			takeUntil(this.destroyed$)
		).subscribe(brand => {
			this.currentBrand = brand;
			this.brandMatch = this.matchesBrand();
		});
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}

	matchesBrand() {
		return this.currentBrand && this._brandArr.includes(this.currentBrand);
	}
}
