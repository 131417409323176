import { IUserCertificationsInfo } from "@app/account/account.models";
import { ngStoreCacheItem } from "@app/shared/helpers";
import { UserCertificationsActions, UserCertificationsActionTypes } from "@app/store/user-certifications/user-certifications.actions";
import { userCertificationsAdapter } from "@app/store/user-certifications/user-certifications.adapter";
import { EntityState } from "@ngrx/entity";

export interface IUserCertificationsState extends EntityState<IUserCertificationsInfo> { }

export const initialState: IUserCertificationsState = userCertificationsAdapter.getInitialState();

export function userCertificationsReducer(
	state = initialState,
	action: UserCertificationsActions
): IUserCertificationsState {
	switch (action.type) {
		case UserCertificationsActionTypes.LoadUserCertificationsSuccess:
			return userCertificationsAdapter.upsertOne(ngStoreCacheItem(action.payload, 6, "hours"), state);
		default:
			return state;
	}
}