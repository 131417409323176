import { getNgStoreCacheItem } from "@app/shared/helpers";
import { leadPreferencesAdapter } from "@app/store/lead-preferences/lead-preferences.adapter";
import { ILeadPreferencesState } from "@app/store/lead-preferences/lead-preferences.reducer";
import { selectCurrentUser } from "@app/store/user";
import { createFeatureSelector, createSelector } from "@ngrx/store";

export const getLeadPreferencesState = createFeatureSelector<ILeadPreferencesState>("leadPreferences");

export const {
	selectIds: leadPreferencesSelectIds,
	selectEntities: leadPreferencesSelectEntities,
	selectAll: leadPreferencesSelectAll,
	selectTotal: leadPreferencesSelectTotal
} = leadPreferencesAdapter.getSelectors(getLeadPreferencesState);

export const getCurrentLeadPreferences = createSelector(
	leadPreferencesSelectEntities,
	selectCurrentUser,
	(entities, user) => getNgStoreCacheItem(entities[user.accountId])
);