import { Component, OnDestroy, OnInit } from "@angular/core";
import { IAppState } from "@app/store";
import { LoadAccountBalanceAttempt } from "@app/store/account-balance";
import { getCurrentAccountBalanceTotals } from "@app/store/account-balance/account-balance.selectors";
import { getCurrentCurrencySymbol } from "@app/store/app-info";
import { select, Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: "c4-kpi-widget-balance",
	templateUrl: "./kpi-widget-balance.component.html",
	styleUrls: ["./kpi-widget-balance.component.scss"]
})
export class KpiWidgetBalanceComponent implements OnInit, OnDestroy {

	currency$: Observable<string>;
	due: number;
	isDueCredit: boolean;
	balanceDue: number;
	isBalanceCredit: boolean;
	loading = true;

	private destroyed$ = new Subject<{}>();

	constructor(private store: Store<IAppState>) { }

	ngOnInit() {
		this.currency$ = this.store.pipe(select(getCurrentCurrencySymbol));
		this.store.pipe(
			select(getCurrentAccountBalanceTotals),
			takeUntil(this.destroyed$)
		).subscribe((totals) => {
			this.loading = !totals;
			if (this.loading) {
				this.store.dispatch(new LoadAccountBalanceAttempt());
			} else {
				this.due = totals.oneThirty + totals.thirtyOneSixty + totals.sixtyOneNinety + totals.ninetyOneTwenty + totals.oneTwentyPlus;
				this.isDueCredit = this.due > 0;
				this.isBalanceCredit = totals.balanceDue > 0;
				this.balanceDue = totals.balanceDue;
			}
		});
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}
}
