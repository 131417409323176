import { Component, EventEmitter, Input, OnInit } from "@angular/core";
import { ProctoruProfilePopupComponent } from "@app/popups/proctoru-profile-popup/proctoru-profile-popup.component";
import { PopupService } from "@app/services/popup.service";
import { TrainingService } from "@app/services/training.service";
import { IProctorUStudent } from "../training.models";

@Component({
	selector: "c4-proctor-u",
	templateUrl: "./proctor-u.component.html",
	styleUrls: ["./proctor-u.component.scss"],
})
export class ProctorUComponent implements OnInit {
	@Input() buttonText: string;
	@Input() classes?: string;
	@Input() disableScheduleExam = false;

	loggingIn = true;
	student: IProctorUStudent;
	profileConfirmed = new EventEmitter<IProctorUStudent>();

	constructor(private readonly trainingService: TrainingService, private readonly popupService: PopupService) { }

	ngOnInit() {
		this.profileConfirmed.subscribe((student: IProctorUStudent) => this.student = student);
		this.populate();
	}

	populate(): void {
		this.trainingService.getStudentInformation().subscribe(result => {
			this.loggingIn = false;
			this.student = result;
		});
	}

	initiateSso(): void {
		if (!this.student.hasStudentProfile) {
			this.popupService.create(ProctoruProfilePopupComponent, { student: this.student, profileConfirmed: this.profileConfirmed });
		} else {
			const newTab = window.open("about:blank", "_blank");
			this.trainingService.initiateProctorSso(newTab);
		}
	}
}
