import { ISubDealerLocatorModel } from "@app/account/account.models";
import { IError } from "@app/shared/shared.models";
import { Action } from "@ngrx/store";

export class LoadDealerLocatorsAttempt implements Action {
	readonly type = DealerLocatorActionTypes.LoadDealerLocatorsAttempt;
	constructor() { }
}

export class LoadDealerLocatorsSuccess implements Action {
	readonly type = DealerLocatorActionTypes.LoadDealerLocatorsSuccess;
	constructor(public payload: IDealerLocatorsResult) { }
}

export class LoadDealerLocatorsError implements Action {
	readonly type = DealerLocatorActionTypes.LoadDealerLocatorsError;
	constructor(public payload: IError) { }
}

export class SaveDealerLocatorAttempt implements Action {
	readonly type = DealerLocatorActionTypes.SaveDealerLocatorAttempt;
	constructor(public payload: ISubDealerLocatorModel) { }
}

export class SaveDealerLocatorSuccess implements Action {
	readonly type = DealerLocatorActionTypes.SaveDealerLocatorSuccess;
	constructor(public payload: ISubDealerLocatorModel) { }
}

export class SaveDealerLocatorError implements Action {
	readonly type = DealerLocatorActionTypes.SaveDealerLocatorError;
	constructor(public payload: IError) { }
}

export class ResetDealerLocatorSubmitState implements Action {
	readonly type = DealerLocatorActionTypes.ResetSubmitState;
	constructor() { }
}

export interface IDealerLocatorsResult {
	accountId: string;
	dealerLocators: ISubDealerLocatorModel[];
}

export type DealerLocatorActions =
	LoadDealerLocatorsAttempt
	| LoadDealerLocatorsSuccess
	| LoadDealerLocatorsError
	| SaveDealerLocatorAttempt
	| SaveDealerLocatorSuccess
	| SaveDealerLocatorError
	| ResetDealerLocatorSubmitState;
export enum DealerLocatorActionTypes {
	LoadDealerLocatorsAttempt = "[Dealer Locator] Load All Attempt",
	LoadDealerLocatorsSuccess = "[Dealer Locator] Load All Success",
	LoadDealerLocatorsError = "[Dealer Locator] Load All Error",
	SaveDealerLocatorAttempt = "[Dealer Locator] Save Attempt",
	SaveDealerLocatorSuccess = "[Dealer Locator] Save Success",
	SaveDealerLocatorError = "[Dealer Locator] Save Error",
	ResetSubmitState = "[Dealer Locator] Reset Submit State",
}
