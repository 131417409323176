import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CartService, IGenericCartResponse } from "@app/services/cart.service";
import { CheckoutService } from "@app/services/checkout.service";
import { AuthActionType, ImpersonateSuccess, LoginSuccess, MsrpImpersonateSuccess, UnimpersonateSuccess } from "@app/store/auth";
import { AddCartItemAttempt, AddCartItemError, AddCartItemSuccess, AddCartPromoAttempt, AddCartPromoError, AddCartPromoSuccess, CartActionType, IAddCartItemSuccessPayload, LoadCurrentCartAttempt, LoadCurrentCartError, LoadCurrentCartSuccess } from "@app/store/cart";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action, select, Store } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { Observable, of } from "rxjs";
import { catchError, debounceTime, filter, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { IAppState } from "../app.reducer";
import { Redirect } from "../router";
import { SuccessToast, WarningToast } from "../toast/toast.actions";
import { selectActiveUser } from "../user";
import { ChangeActiveCartAttempt, ChangeActiveCartError, ChangeActiveCartSuccess, EditCartItemAttempt, EditCartItemSuccess, IEditCartItemSuccessPayload, LoadCartAttempt, LoadCartError, LoadCartSuccess, LoadPcnaCartError, LoadPcnaCartSuccess, UpdateModifyingCart } from "./cart.actions";
import { getCurrentCartId, selectCartEntities } from "./cart.selectors";

@Injectable()
export class CartEffects {
	constructor(private readonly store: Store<IAppState>, private readonly actions$: Actions, private readonly cartService: CartService,
		private readonly checkoutService: CheckoutService, private readonly toastr: ToastrService) { }

	@Effect()
	getCart$: Observable<Action> = this.actions$.pipe(
		ofType<LoginSuccess | MsrpImpersonateSuccess | ImpersonateSuccess>(
			AuthActionType.LoginSuccess,
			AuthActionType.MsrpImpersonateSuccess,
			AuthActionType.ImpersonateSuccess,
		),
		debounceTime(500),
		map(action => new LoadCurrentCartAttempt({ orderId: action.payload.user.activeCartId })),
	);

	@Effect()
	getCartUnimpersonate$: Observable<Action> = this.actions$.pipe(
		ofType<UnimpersonateSuccess>(
			AuthActionType.UnimpersonateSuccess,
		),
		withLatestFrom(this.store.pipe(select(selectActiveUser))),
		debounceTime(500),
		map(([_, user]) => new LoadCurrentCartAttempt({ orderId: user.activeCartId })),
	);

	@Effect()
	changeActiveCart$: Observable<Action> = this.actions$.pipe(
		ofType<ChangeActiveCartAttempt>(CartActionType.ChangeActiveCartAttempt),
		mergeMap(action => {
			return this.checkoutService
				.changeActiveCart(action && action.payload ? action.payload.orderId : 0)
				.pipe(
					mergeMap(totals => {
						const success = !!totals;
						const actions: Action[] = success ? [new ChangeActiveCartSuccess(totals)] : [new ChangeActiveCartError({ error: "Cart change failed" })];
						if (success && action.payload.redirect) {
							actions.push(new Redirect({ url: "checkout" }));
						}
						return actions;
					}),
					catchError((err: HttpErrorResponse) => of(new ChangeActiveCartError({ error: err.message || err.statusText }))),
				);
		}),
	);

	@Effect()
	loadCart$: Observable<Action> = this.actions$.pipe(
		ofType<LoadCartAttempt>(CartActionType.LoadCartAttempt),
		withLatestFrom(this.store.pipe(select(selectCartEntities))),
		filter(([action, carts]) => !carts[action.payload.orderId]),
		mergeMap(([action, _]) => {
			return this.checkoutService
				.getOrderTotals(action.payload.orderId)
				.pipe(
					map(totals => new LoadCartSuccess(totals)),
					catchError((err: HttpErrorResponse) => of(new LoadCartError({ error: err.message || err.statusText }))),
				);
		}),
	);

	@Effect()
	loadCurrentCart$: Observable<Action> = this.actions$.pipe(
		ofType<LoadCurrentCartAttempt>(CartActionType.LoadCurrentCartAttempt),
		withLatestFrom(this.store.pipe(select(selectCartEntities)), this.store.pipe(select(getCurrentCartId))),
		filter(([action, carts, id]) => !(action.payload && action.payload.orderId && carts[action.payload.orderId])
			|| !(!action.payload && carts[id])
			|| action.payload.redirect,
		),
		mergeMap(([action, _]) => {
			return this.checkoutService
				.getOrderTotals(action && action.payload ? action.payload.orderId : 0, true)
				.pipe(
					mergeMap(totals => {
						const actions: Action[] = [new LoadCurrentCartSuccess(totals)];
						if (action.payload.redirect) {
							actions.push(new Redirect({ url: "checkout" }));
						}
						return actions;
					}),
					catchError((err: HttpErrorResponse) => of(new LoadCurrentCartError({ error: err.message || err.statusText }))),
				);
		}),
	);

	@Effect()
	loadPcnaCart$: Observable<Action> = this.actions$.pipe(
		ofType(CartActionType.LoadPcnaCartAttempt),
		mergeMap(() => {
			return this.checkoutService
				.getPcnaOrderId()
				.pipe(
					mergeMap(pcnaCartId => {
						this.checkoutService
							.getOrderTotals(pcnaCartId).subscribe(totals => {
								this.store.dispatch(new LoadPcnaCartSuccess(totals));
							});
						return [];
					}),
					catchError((err: HttpErrorResponse) => of(new LoadPcnaCartError({ error: err.message || err.statusText }))),
				);
		}),
	);

	@Effect()
	editCartItem$: Observable<Action> = this.actions$.pipe(
		ofType<EditCartItemAttempt>(CartActionType.EditCartItemAttempt),
		withLatestFrom(this.store.pipe(select(getCurrentCartId))),
		mergeMap(([action, id]) => {
			action.payload.item.orderId = id;
			return this.cartService
				.editCartItem(action.payload.item, action.payload.originalOrderDetailId, action.payload.originalItemAddOn, action.payload.lifecycle)
				.pipe(
					mergeMap((info: IGenericCartResponse) => {
						const response: Action[] = [new UpdateModifyingCart({ orderId: id, modifying: false })];
						if (info.success) {
							response.push(new EditCartItemSuccess({ item: action.payload.item } as IEditCartItemSuccessPayload, info.totals));
							response.push(new SuccessToast({ message: `Saved cart item.` }));
							if (action.payload.promoCode) {
								response.push(new AddCartPromoAttempt({
									promoCode: action.payload.promoCode,
									lifecycle: action.payload.lifecycle,
									orderId: id,
									completionCallback: action.payload.completionCallback,
								}));
							}
						} else {
							response.push(new WarningToast({
								message: "There was a problem saving the cart item.",
							}));
						}

						if (action.payload.completionCallback) {
							action.payload.completionCallback(info.success);
						}

						return response;
					}),
					catchError((err: HttpErrorResponse) => {
						this.store.dispatch(new WarningToast({ message: "There was a problem saving the cart item." }));
						if (action.payload.completionCallback) {
							action.payload.completionCallback(false);
						}
						return of(new AddCartItemError({ error: err.message || err.statusText }));
					}),
				);
		}),
	);

	@Effect()
	addItemToCart$: Observable<Action> = this.actions$.pipe(
		ofType<AddCartItemAttempt>(CartActionType.AddCartItemAttempt),
		withLatestFrom(this.store.pipe(select(getCurrentCartId))),
		mergeMap(([action, id]) => {
			const singleItem = action.payload.item.sku.indexOf(",") === -1;
			action.payload.item.orderId = action.payload.item.orderId || id;
			const addingMsg = singleItem ? null : this.toastr.info(`Adding product(s) to cart...`);
			return this.cartService
				.addToCart(action.payload.item, action.payload.isInCart, action.payload.lifecycle)
				.pipe(
					mergeMap((info: IGenericCartResponse) => {
						const response: Action[] = [];
						if (info.success) {
							response.push(new AddCartItemSuccess({ item: action.payload.item } as IAddCartItemSuccessPayload, { ...info.totals, modifying: !!action.payload.promoCode }));
							if (singleItem) {
								response.push(new SuccessToast({ message: `Added ${action.payload.item.sku} to cart.` }));
							} else {
								addingMsg.toastRef.close();
								response.push(new SuccessToast({ message: "Added products to cart." }));
							}

							if (action.payload.promoCode) {
								response.push(new AddCartPromoAttempt({
									promoCode: action.payload.promoCode,
									lifecycle: action.payload.lifecycle,
									orderId: id,
									completionCallback: action.payload.completionCallback,
								}));
							}
						} else {
							response.push(new WarningToast({
								message: singleItem ? `There was a problem adding ${action.payload.item.sku} to your cart. ${info.errorMessage}` :
									`There was a problem adding the following product(s) to cart: ${info.errorMessage}`,
							}));
						}

						if (action.payload.completionCallback) {
							action.payload.completionCallback(info.success);
						}

						return response;
					}),
					catchError((err: HttpErrorResponse) => {
						this.store.dispatch(new WarningToast({ message: "There was a problem adding the product(s) to your cart." }));
						if (action.payload.completionCallback) {
							action.payload.completionCallback(false);
						}
						return of(new AddCartItemError({ error: err.message || err.statusText }));
					}),
				);
		}),
	);

	@Effect()
	addPromoToCart$: Observable<Action> = this.actions$.pipe(
		ofType(CartActionType.AddCartPromoAttempt),
		mergeMap((action: AddCartPromoAttempt) => {
			const promoMsg = this.toastr.info("Applying promotion...");
			return this.cartService
				.applyPromo(action.payload.promoCode, action.payload.orderId, action.payload.lifecycle)
				.pipe(
					mergeMap((info: IGenericCartResponse) => {
						promoMsg.toastRef.close();
						const result: Action[] = [new UpdateModifyingCart({ orderId: action.payload.orderId, modifying: false })];
						const error = info.errorMessage || info.warningMessage || "There was a problem applying the promotion.";
						if (info.success) {
							result.push(new AddCartPromoSuccess(action.payload, info.totals), new SuccessToast({ message: "Promotion applied." }));
						} else {
							result.push(new AddCartPromoError({ error }), new WarningToast({ message: error, ttl: 8000 }));
						}
						if (action.payload.completionCallback) {
							action.payload.completionCallback(info.success);
						}
						return result;
					}),
					catchError((err: HttpErrorResponse) => {
						this.store.dispatch(new WarningToast({ message: "There was a problem adding the product(s) to your cart." }));
						if (action.payload.completionCallback) {
							action.payload.completionCallback(false);
						}
						return of(new AddCartPromoError({ error: err.message }));
					}),
				);
		}),
	);
}