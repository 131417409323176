import { Component, ComponentRef, Input } from "@angular/core";
import { EngravingButtonModel, EngravingConfirmationModel, EngravingKeypadModel, EngravingRoomModel, IKeycapLinePartModel } from "@app/products/products.models";
import { OrderService } from "@app/services/order.service";
import { IAppState } from "@app/store";
import { ErrorToast, SuccessToast } from "@app/store/toast/toast.actions";
import { Store } from "@ngrx/store";
import { IPopupComponent } from "../popups.models";

@Component({
	selector: "c4-engraving-review-popup",
	templateUrl: "./engraving-review-popup.component.html",
	styleUrls: ["./engraving-review-popup.component.scss"]
})
export class EngravingReviewPopupComponent implements IPopupComponent {
	self: ComponentRef<EngravingReviewPopupComponent>;
	close: () => void;

	@Input() fileQueue: File[];
	@Input() files: EngravingConfirmationModel[];

	private _confirmationOrder: EngravingConfirmationModel;
	@Input() set confirmationOrder(val: EngravingConfirmationModel) {
		this._confirmationOrder = val;
		if (val) {
			this.populate();
		}
	}
	get confirmationOrder() {
		return this._confirmationOrder;
	}

	engravingUse: string;
	sectionRoom: EngravingRoomModel;
	invalidRooms: EngravingRoomModel[];
	creatingCart: boolean;

	constructor(readonly store: Store<IAppState>, readonly orderService: OrderService) { }

	private populate() {
		this.sectionRoom = this.confirmationOrder.rooms[0];
		this.confirmationOrder.rooms[0].hasReviewed = true;
		this.invalidRooms = this.confirmationOrder.rooms.filter(room => !room.isValid);
	}

	invalidKeypads(room: EngravingRoomModel): EngravingKeypadModel[] {
		return room.keypads.filter(keypad => !keypad.isValid);
	}

	invalidButtons(keypad: EngravingKeypadModel): EngravingButtonModel[] {
		return keypad.buttons.filter(button => !button.isValid);
	}

	textLines(line: IKeycapLinePartModel[]): IKeycapLinePartModel[] {
		return line.filter(linePart => !linePart.isIcon);
	}

	loadKeypads(room: EngravingRoomModel) {
		room.hasReviewed = true;
		this.sectionRoom = room;
		document.getElementById("keypads").scrollTop = 0;
	}

	percentComplete() {
		if (this.confirmationOrder != null) {
			const totalRooms = this.confirmationOrder.rooms.length;
			const reviewedRooms = this.confirmationOrder.rooms.filter(room => room.hasReviewed).length;
			return Math.round(reviewedRooms / totalRooms * 100);
		}

		return 0;
	}

	createOrder() {
		this.creatingCart = true;
		this.orderService.createKeypadOrder(this.confirmationOrder.id, this.engravingUse).subscribe((result) => {
			if (result) {
				this.store.dispatch(new SuccessToast({ message: "Your XML order has been added as a Saved Cart. Please go to the checkout page and press the Load Cart link.", ttl: 9000 }));
				this.fileQueue.splice(this.fileQueue.findIndex(file => file.name === this.confirmationOrder.name), 1);
				this.files.splice(this.files.indexOf(this.confirmationOrder), 1);
				this.confirmationOrder = null;
				this.engravingUse = null;
				this.close();
			} else {
				this.store.dispatch(new ErrorToast({ message: "There was a problem uploading your composer engraving." }));
				this.close();
			}
		});
	}
}
