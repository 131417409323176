import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IDiscountProgramBenefit } from "@app/cms/cms.models";
import { IBundleItem, IEngravingView, IProductLightModel, IProductModel, IProductView, IStockStatusItem, IVariantDetailsModel } from "@app/products/products.models";
import { randomSelect } from "@app/shared/helpers";
import { ILoadCompatibilityPart, IMedia, MediaType } from "@app/shared/shared.models";
import { } from "@app/store/product-details";
import { selectProductListBySkus, selectProductListWithUnloadedBySkus } from "@app/store/product-light";
import { IStockStatusResponsePayload } from "@app/store/product-stock-status";
import { IFirmwareProduct } from "@app/support/support.models";
import { select } from "@ngrx/store";

@Injectable({
	providedIn: "root",
})
export class ProductService {

	constructor(private readonly http: HttpClient) {
	}

	getLightingCompatibilityList() {
		return this.http.get<ILoadCompatibilityPart[]>("api/products/GetLoadCompatibilityPartList");
	}

	getBulkLightProducts(skus: string[]) {
		return this.http.get<IProductLightModel[]>("api/products/getBulkLightProducts", { params: { skus } });
	}

	getBulkCustomizedLightProducts(skus: string[]) {
		return this.http.get<IProductLightModel[]>("api/products/getBulkLightProducts", { params: { skus, allowCustomized: "true" } });
	}

	getPartsWithLatestFirmware() {
		return this.http.get<IFirmwareProduct[]>("api/products/GetProductsWithLatestFirmware");
	}

	getPartsWithFirmware() {
		return this.http.get<IFirmwareProduct[]>("api/products/GetProductsWithFirmware");
	}

	getProductDetails(sku: string, isQuickView = false) {
		return this.http.get<IProductView>("api/products/GetProductDetails", { params: { sku, isQuickView: isQuickView.toString() } });
	}

	getBackorderBundleItems(sku: string) {
		return this.http.get<IBundleItem[]>("api/products/GetBackorderBundleItems", { params: { sku } });
	}

	getTopSellingSkus() {
		return this.http.get<string[]>("api/products/getTopSellingSkus");
	}

	getMostPopularSkus() {
		return this.http.get<string[]>("api/products/getMostPopularSkus");
	}

	getRecentlyViewedSkus() {
		return this.http.get<string[]>("api/products/getRecentlyViewedSkus");
	}

	getFeaturedSkus() {
		return this.http.get<string[]>("api/products/getFeaturedSkus");
	}

	searchProducts = (query: string, limit: number) => {
		return this.http.get<IProductLightModel[]>("api/products/search", { params: { query, limit: limit.toString() } });
	}

	getSuggestedProductSkus(sku: string) {
		return this.http.get<string[]>("api/products/GetSuggestedProductSkus", { params: { sku } });
	}

	getEngravingView() {
		return this.http.get<IEngravingView>("api/products/GetEngravingView");
	}

	getVariantDetails(parentSku: string, categoryId: number) {
		return this.http.get<IVariantDetailsModel>("api/products/GetVariantDetails", { params: { parentSku, categoryId: categoryId.toString() } });
	}

	getProductDiscountBenefits(discountProgramId: number) {
		return this.http.get<IDiscountProgramBenefit[]>("api/discountprogram/GetProductDiscountBenefits", { params: { discountProgramId: discountProgramId.toString() } });
	}

	searchByCategories(categoryIds: string[]) {
		return this.http.get<IProductModel[]>("api/products/SearchByCategories", { params: { categoryIds } });
	}

	getLightingCompatibilityPart(sku: string, lightId: number) {
		return this.http.get<ILoadCompatibilityPart>("api/products/GetLoadCompatibilityPart", { params: { sku, lightId: lightId.toString() } });
	}

	getRandomProductSelector(skus: string[], max: number) {
		const rand = randomSelect(skus, max);

		return select(selectProductListBySkus(rand));
	}

	getRandomProductsSelectorWithUnloaded(skus: string[], max: number) {
		const rand = randomSelect(skus, max);

		return select(selectProductListWithUnloadedBySkus(rand));
	}

	getImages(media: IMedia[]): IMedia[] {
		const images = media.filter(item => item.mediaTypeId === MediaType.Image);

		return images;
	}

	getStockStatus(sku: string) {
		return this.http.get<IStockStatusResponsePayload>(`api/products/${sku}/stockstatus`);
	}

	getBackorderBundleItemsBySkus(skus: string[]) {
		return this.http.get<IBundleItem[]>("api/products/getBackorderBundleItemsBySkus", { params: { skus } });
	}

	getStockStatusMessage(product: IStockStatusItem, overrideActive = false) {
		let message = "";
		if (!product || product.isDisplayOnly) {
			return message;
		}

		if (!product.isOnBackorder || product.shippingEstimateDescOverride || product.isPartner) {
			message = overrideActive ? product.shippingEstimateDescOverride : product.shippingEstimateDescription;
		} else if (product.bundleItems && product.bundleItems.length > 0 && product.bundleItems.some(x => x.isOnBackorder) && !product.isBuiltToOrder && !product.isPartner) {
			const bundleBackorderList = [...new Set(product.bundleItems.filter(x => x.isOnBackorder).map(x => x.sku))].join(", ");
			message = "Will ship when the following item(s) become available: " + bundleBackorderList;
		} else if (product.isOnBackorder && !product.isBuiltToOrder && !product.isPartner) {
			message = "Will ship when available";
		}

		return message;
	}
}
