import { Lead, UpdateLeadStatusPayload } from "@app/dashboard/dashboard.models";
import { IError } from "@app/shared/shared.models";
import { Action } from "@ngrx/store";

export enum LeadsActionTypes {
	LoadLeadsAttempt = "[Leads Load] Attempt",
	LoadLeadsSuccess = "[Leads Load] Success",
	LoadLeadsError = "[Leads Load] Error",
	AcceptLeadAttempt = "[Accept Lead] Attempt",
	AcceptLeadSuccess = "[Accept Lead] Success",
	AcceptLeadError = "[Accept Lead] Error",
	CloseLeadAttempt = "[Close Lead] Attempt",
	CloseLeadSuccess = "[Close Lead] Success",
	CloseLeadError = "[Close Lead] Error",
	UpdateLeadStatusAttempt = "[Update Lead Status] Attempt",
	UpdateLeadStatusSuccess = "[Update Lead Status] Success",
	UpdateLeadStatusError = "[Update Lead Status] Error",
}

export class LoadLeadsAttempt implements Action {
	readonly type = LeadsActionTypes.LoadLeadsAttempt;
	constructor() { }
}

export class LoadLeadsSuccess implements Action {
	readonly type = LeadsActionTypes.LoadLeadsSuccess;
	constructor(public payload: LeadsPayload) { }
}

export class LoadLeadsError implements Action {
	readonly type = LeadsActionTypes.LoadLeadsError;
	constructor(public payload: IError) { }
}

export class AcceptLeadAttempt implements Action {
	readonly type = LeadsActionTypes.AcceptLeadAttempt;
	constructor(public payload: UpdateLeadStatusPayload) { }
}

export class AcceptLeadSuccess implements Action {
	readonly type = LeadsActionTypes.AcceptLeadSuccess;
	constructor(public payload: Lead) { }
}

export class AcceptLeadError implements Action {
	readonly type = LeadsActionTypes.AcceptLeadError;
	constructor(public payload: IError) { }
}

export class UpdateLeadStatusAttempt implements Action {
	readonly type = LeadsActionTypes.UpdateLeadStatusAttempt;
	constructor(public payload: UpdateLeadStatusPayload) { }
}

export class UpdateLeadStatusSuccess implements Action {
	readonly type = LeadsActionTypes.UpdateLeadStatusSuccess;
	constructor(public payload: Lead) { }
}

export class UpdateLeadStatusError implements Action {
	readonly type = LeadsActionTypes.UpdateLeadStatusError;
	constructor(public payload: IError) { }
}

export class CloseLeadAttempt implements Action {
	readonly type = LeadsActionTypes.CloseLeadAttempt;
	constructor(public payload: UpdateLeadStatusPayload) { }
}

export class CloseLeadSuccess implements Action {
	readonly type = LeadsActionTypes.CloseLeadSuccess;
	constructor(public payload: Lead) { }
}

export class CloseLeadError implements Action {
	readonly type = LeadsActionTypes.CloseLeadError;
	constructor(public payload: IError) { }
}

export interface LeadsPayload {
	accountId: string;
	leads: Lead[];
}

export type LeadsActions =
	LoadLeadsAttempt
	| LoadLeadsSuccess
	| LoadLeadsError
	| AcceptLeadAttempt
	| AcceptLeadSuccess
	| AcceptLeadError
	| UpdateLeadStatusAttempt
	| UpdateLeadStatusSuccess
	| UpdateLeadStatusError
	| CloseLeadAttempt
	| CloseLeadSuccess
	| CloseLeadError;