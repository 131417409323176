import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AccountService } from "@app/services/account.service";
import { CartService } from "@app/services/cart.service";
import { IAccountBalanceResult, IGenericErrorListResponse } from "@app/shared/shared.models";
import { AccountBalanceActionTypes, LoadAccountBalanceError, LoadAccountBalanceSuccess, PayInvoiceAttempt, PayInvoiceError, PayInvoiceSuccess } from "@app/store/account-balance/account-balance.actions";
import { SuccessToast } from "@app/store/toast/toast.actions";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action, select, Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, debounceTime, filter, map, mergeMap, switchMap } from "rxjs/operators";
import { IAppState } from "../app.reducer";
import { getCurrentAccountBalanceTotals } from "./account-balance.selectors";

@Injectable()
export class AccountBalanceEffects {
	constructor(
		private actions$: Actions,
		private accountService: AccountService,
		private cartService: CartService,
		private store: Store<IAppState>
	) { }

	@Effect()
	loadAccountBalance$: Observable<Action> = this.actions$.pipe(
		ofType(AccountBalanceActionTypes.LoadAccountBalanceAttempt),
		debounceTime(100),
		switchMap(() => this.store.pipe(select(getCurrentAccountBalanceTotals))),
		filter(totals => !totals),
		mergeMap(() => {
			return this.accountService
				.getAccountBalance()
				.pipe(
					map((info: IAccountBalanceResult) => new LoadAccountBalanceSuccess(info)),
					catchError((err: HttpErrorResponse) => of(new LoadAccountBalanceError({ error: err.message || err.statusText })))
				);
		})
	);

	@Effect()
	payAccountInvoice$: Observable<Action> = this.actions$.pipe(
		ofType<PayInvoiceAttempt>(AccountBalanceActionTypes.PayInvoiceAttempt),
		mergeMap((action) => {
			return this.cartService
				.chargePaymentForInvoices(action.payload)
				.pipe(
					mergeMap((result: IGenericErrorListResponse): Action[] => result.success
						? [new PayInvoiceSuccess(action.payload), new SuccessToast({ message: "Your payment has been successfully submitted." })]
						: [new PayInvoiceError(result)]),
					catchError((err) => of(new PayInvoiceError({ success: false, errors: [{ errorMessage: err.message || err.statusText }] })))
				);
		})
	);
}
