import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import { RouterLink } from "@angular/router";

@Component({
	selector: "c4-kpi-widget",
	templateUrl: "./kpi-widget.component.html",
	styleUrls: ["./kpi-widget.component.scss"]
})
export class KpiWidgetComponent implements OnInit {

	@Input() link?: RouterLink;
	@Input() color?: "red" | "green" | "indigo" | "yellow" | "blue" | "orange" | "grey";
	@Input() icon?: string;
	@Input() iconImg?: string | SafeStyle;
	@Input() metric: any;
	@Input() lineOne: string;
	@Input() lineTwo?: string;
	@Input() loading: boolean;
	@Input() error: boolean;

	constructor(private sanitizer: DomSanitizer) { }

	ngOnInit() {
		this.iconImg = this.iconImg ? this.sanitizer.bypassSecurityTrustStyle(`url('${this.iconImg}')`) : undefined;
	}
}
