import { equalsIgnoreCase } from "@app/shared/helpers";
import { BrandNames, PortalNames, Portals } from "@app/shared/shared.models";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { IAppInfoState } from "./app-info.reducer";

const MIN_SMALL = 768;
const MIN_MEDIUM = 992;
const MIN_LARGE = 1200;

export const getAppInfoState = createFeatureSelector<IAppInfoState>("appInfo");

export const getCurrentPortal = createSelector(
	getAppInfoState, state => state.currentPortal,
);

export const getCurrentPortalName = createSelector(
	getCurrentPortal, portal => PortalNames.get(portal),
);

export const getCurrentBrandName = createSelector(
	getCurrentPortal, portal => BrandNames.get(portal),
);

export const getIsControl4 = createSelector(
	getCurrentPortal, portal => portal === Portals.DealerEmea || portal === Portals.DealerUs,
);

export const getIsTriad = createSelector(
	getCurrentPortal, portal => portal === Portals.Triad,
);

export const getIsPakedge = createSelector(
	getCurrentPortal, portal => portal === Portals.Pakedge,
);

export const getIsExtraSmall = createSelector(
	getAppInfoState, state => state.screenWidth < MIN_SMALL,
);

export const getIsSmall = createSelector(
	getAppInfoState, state => state.screenWidth < MIN_MEDIUM && state.screenWidth >= MIN_SMALL,
);

export const getIsMedium = createSelector(
	getAppInfoState, state => state.screenWidth < MIN_LARGE && state.screenWidth >= MIN_MEDIUM,
);

export const getIsLarge = createSelector(
	getAppInfoState, state => state.screenWidth >= MIN_LARGE,
);

export const getIsTabletOrSmaller = createSelector(
	getAppInfoState, state => state.screenWidth < MIN_MEDIUM,
);

export const getIsMobile = createSelector(
	getAppInfoState, state => state.screenWidth < MIN_SMALL,
);

export const getAllFeatureFlags = createSelector(
	getAppInfoState, state => state.featureFlags,
);

export const getSiteFeatureFlag = (flag: string) => createSelector(
	getAllFeatureFlags, flags => flags && flag ? flags.find(x => equalsIgnoreCase(flag, x.key)) : undefined,
);

export const getSettings = createSelector(
	getAppInfoState, state => state.settings,
);

export const getUpgradeCategories = createSelector(
	getSettings, settings => settings && settings.upgradeCategories,
);

export const getSnapCategoryId = createSelector(
	getSettings, settings => settings && settings.snapCategoryId,
);

export const getUsernameRegex = createSelector(
	getSettings, settings => settings && settings.usernameRegex,
);

export const getPasswordRegex = createSelector(
	getSettings, settings => settings && settings.passwordRegex,
);

export const getControllerUpgradeCategory = createSelector(
	getUpgradeCategories, categories => categories && categories.controllers,
);

export const getTouchscreenUpgradeCategory = createSelector(
	getUpgradeCategories, categories => categories && categories.touchscreens,
);

export const getDoorStationUpgradeCategory = createSelector(
	getUpgradeCategories, categories => categories && categories.doorStations,
);

export const getTriadOneUpgradeCategory = createSelector(
	getUpgradeCategories, categories => categories && categories.triadOne,
);