import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IDealerStatusTierBenefits, IDealerStatusTierRequirementsModel, IRebateProgressData } from "@app/account/account.models";
import { IGoal } from "@app/shared-elements/shared-elements.models";
import { IStatusProgress, ITriadDiscountInfo } from "@app/shared-widgets/shared-widgets.models";

@Injectable({ providedIn: "root" })
export class RewardsService {
	constructor(private http: HttpClient) { }

	getStatusWidgetInfo() {
		return this.http.get<IStatusProgress>("api/account/GetStatusWidgetInfo");
	}

	getStatusProgramBenefits() {
		return this.http.get<IDealerStatusTierBenefits[]>("api/rewards/getDealerStatusProgramBenefits");
	}

	getStatusProgramRequirements() {
		return this.http.get<IDealerStatusTierRequirementsModel[]>("api/rewards/getDealerStatusProgramRequirements");
	}

	getBuyingGroupGoals(buyingGroup: string) {
		return this.http.get<IGoal[]>("api/rewards/getBuyingGroupGoals", { params: { buyingGroup } });
	}

	getTriadRebateGoalInfo() {
		return this.http.get<IGoal[]>("api/rewards/GetTriadRebateGoalInfo");
	}

	getTriadDiscountData() {
		return this.http.get<ITriadDiscountInfo>("api/rewards/getTriadDiscountData");
	}

	getRebateProgress() {
		return this.http.get<IRebateProgressData>("api/rewards/getRebateProgress");
	}
}
