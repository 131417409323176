import { Directive, ElementRef, HostListener, Input } from "@angular/core";

@Directive({
	selector: "[c4HoverToggle]"
})
export class HoverToggleDirective {

	@Input() hide: string;
	@Input() show: string;


	constructor(private el: ElementRef) {
	}

	@HostListener("mouseover")
	onHover() {
		if (this.hide) {
			Array.from(this.getElements(this.hide)).forEach((item) => {
				item.classList.add("hidden");
				item.classList.remove("active");
			});
		}
		if (this.show) {
			Array.from(this.getElements(this.show)).forEach((item) => {
				item.classList.remove("hidden");
				item.classList.add("active");
			});
		}
		this.el.nativeElement.classList.add("active");
	}

	@HostListener("mouseout")
	onUnhover() {
		this.el.nativeElement.classList.remove("active");
	}

	private getElements(info: string) {
		return info.match(/^[\.#]/) ? document.querySelectorAll(info) : document.getElementsByClassName(info);
	}
}
