import { getNgStoreCacheItem } from "@app/shared/helpers";
import { getCurrentAccountId } from "@app/store/account";
import { orderHistoryAdapter } from "@app/store/order-history/order-history.adapter";
import { OrderHistoryState } from "@app/store/order-history/order-history.reducer";
import { createFeatureSelector, createSelector } from "@ngrx/store";

export const getOrderHistoryState = createFeatureSelector<OrderHistoryState>("orderHistory");

export const {
	selectIds: orderHistorySelectIds,
	selectEntities: orderHistorySelectEntities,
	selectAll: orderHistorySelectAll,
	selectTotal: orderHistorySelectTotal,
} = orderHistoryAdapter.getSelectors(getOrderHistoryState);

export const getCurrentAccountOrderHistory = createSelector(
	getCurrentAccountId,
	orderHistorySelectEntities,
	(id, history) => id && getNgStoreCacheItem(history[id]) ? history[id].orders : undefined,
);

export const getSelectedOrderHistoryId = createSelector(
	getOrderHistoryState, state => state.selected,
);