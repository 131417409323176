import { ngStoreCacheItem } from "@app/shared/helpers";
import { EntityState } from "@ngrx/entity";
import { AuthActions, AuthActionType } from "../auth";
import { IStockStatusResponsePayload, StockStatusActions, StockStatusActionTypes } from "./product-stock-status.actions";
import { productStockStatusAdapter } from "./product-stock-status.adapter";

export interface IProductStockStatusState extends EntityState<IStockStatusResponsePayload> { }

export const initialState: IProductStockStatusState = productStockStatusAdapter.getInitialState({});

export function productStockStatusReducer(
	state = initialState,
	action: StockStatusActions | AuthActions,
): IProductStockStatusState {
	switch (action.type) {
		case StockStatusActionTypes.LoadProductStockStatusSuccess:
			const cache = ngStoreCacheItem(action.payload, 10, "minutes");

			return productStockStatusAdapter.upsertOne(cache, state);
		case AuthActionType.ImpersonateSuccess:
			return productStockStatusAdapter.removeAll(state);
		default: {
			return state;
		}
	}
}