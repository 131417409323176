import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { deepcopy } from "@app/shared/helpers";
import { ScheduleType, ScheduleTypeNames } from "@app/shared/shared.models";
import { SchedulerInfo } from "../../cms/cms.models";

@Component({
	selector: "c4-scheduler",
	templateUrl: "./scheduler.component.html",
	styleUrls: ["./scheduler.component.scss"],
})
export class SchedulerComponent implements OnInit {
	@Input() start: Date | string;
	@Input() end: Date | string;
	@Input() type: ScheduleType;

	@Input() schedule: SchedulerInfo;
	@Output() scheduleChange = new EventEmitter<SchedulerInfo>();

	edit: SchedulerInfo;

	private oldEnd: Date;
	private _hasEnd: boolean;
	get hasEnd() {
		return this._hasEnd;
	}

	set hasEnd(val) {
		this._hasEnd = val;
		if (val) {
			if (!this.edit.end) {
				this.edit.end = this.oldEnd;
			}
		} else if (this.edit.end) {
			this.oldEnd = new Date(this.edit.end);
		}
	}

	ScheduleType = ScheduleType;
	scheduleNames = ScheduleTypeNames;

	constructor() { }

	ngOnInit() {
		this.edit = deepcopy(this.schedule || {} as SchedulerInfo);
		this.edit.start = this.edit.start || this.start ? new Date(this.edit.start || this.start) : undefined;
		this.edit.end = this.edit.end || this.end ? new Date(this.edit.end || this.end) : undefined;
		this.edit.type = this.edit.type || this.type || ScheduleType.Draft;
		this.hasEnd = !!this.edit.end;
		this.update();
	}

	update() {
		setTimeout(() => {
			this.edit.error = undefined;
			if (this.edit.type === undefined) {
				this.edit.error = "You must select a status";
			} else if (![ScheduleType.Archive, ScheduleType.Draft, ScheduleType.Live].includes(this.edit.type)) {
				if (!this.edit.start) {
					this.edit.error = "You must provide a start date.";
				} else if (this.hasEnd && this.edit.end <= this.edit.start) {
					this.edit.error = "Start date must come before end date.";
				}
			}
			this.edit.end = this.hasEnd ? this.edit.end : null;
			this.edit.valid = !this.edit.error;
			this.scheduleChange.emit(this.edit);
		});
	}
}
