import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthActionType } from "@app/store/auth";
import { Actions, ofType } from "@ngrx/effects";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: "c4-unauthorized",
	templateUrl: "./unauthorized.component.html",
	styleUrls: ["./unauthorized.component.scss"],
})
export class UnauthorizedComponent implements OnInit, OnDestroy {

	private readonly destroyed$ = new Subject<{}>();

	constructor(private actions$: Actions, private router: Router) { }

	ngOnInit() {
		this.actions$.pipe(
			ofType(AuthActionType.ImpersonateSuccess, AuthActionType.UnimpersonateSuccess),
			takeUntil(this.destroyed$),
		).subscribe(() => this.router.navigateByUrl(location.pathname + location.search));
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}
}
