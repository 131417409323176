import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterStateSnapshot } from "@angular/router";
import { AppRoutingModule } from "@app/app-routing.module";
import { AppComponent } from "@app/app.component";
import { AuthenticatedGuard } from "@app/auth/auth.guard";
import { AuthModule } from "@app/auth/auth.module";
import { BaseUrlInterceptor } from "@app/interceptors/base-url.interceptor";
import { NavModule } from "@app/nav/nav.module";
import { ConfirmPopupComponent } from "@app/popups/confirm-popup/confirm-popup.component";
import { ContractPopupComponent } from "@app/popups/contract-popup/contract-popup.component";
import { ImpersonationPopupComponent } from "@app/popups/impersonation-popup/impersonation-popup.component";
import { MobilePhoneUsagePopupComponent } from "@app/popups/mobile-phone-usage-popup/mobile-phone-usage-popup.component";
import { ProductUpgradePopupComponent } from "@app/popups/product-upgrade-popup/product-upgrade-popup.component";
import { SharedModule } from "@app/shared/shared.module";
import { IRouterStateUrl, metaReducers, reducers } from "@app/store";
import { AccountBalanceEffects } from "@app/store/account-balance/account-balance.effects";
import { AccountEffects } from "@app/store/account/account.effects";
import { AddressEffects } from "@app/store/address/address.effects";
import { AuthEffects } from "@app/store/auth/auth.effects";
import { BakpakCodesEffects } from "@app/store/bakpak-codes/bakpak-codes.effects";
import { C4ContactsEffects } from "@app/store/c4-contacts/c4-contacts.effects";
import { CartEffects } from "@app/store/cart/cart.effects";
import { CreditCardTypeEffects } from "@app/store/credit-card-type/credit-card-type.effects";
import { CustomerSummaryEffects } from "@app/store/customer-summary/customer-summary.effects";
import { DealerLocatorEffects } from "@app/store/dealer-locator/dealer-locator.effects";
import { LeadPreferencesEffects } from "@app/store/lead-preferences/lead-preferences.effects";
import { LeadsEffects } from "@app/store/leads/leads.effects";
import { NavEffects } from "@app/store/nav/nav.effects";
import { OrderHistoryDetailsEffects } from "@app/store/order-history-details/order-history-details.effects";
import { OrderHistoryEffects } from "@app/store/order-history/order-history.effects";
import { RouterEffects } from "@app/store/router/router.effects";
import { SalesContactsEffects } from "@app/store/sales-contacts/sales-contacts.effects";
import { SecurityRolesEffects } from "@app/store/security-roles/security-roles.effects";
import { SalesforceContactEffects } from "@app/store/sf-contact/sf-contact.effects";
import { ShippingMethodEffects } from "@app/store/shipping-method/shipping-method.effects";
import { ToastEffects } from "@app/store/toast/toast.effects";
import { UpgradeRequestFileEffects } from "@app/store/upgrade-request-file/upgrade-request-file.effects";
import { UserCertificationsEffects } from "@app/store/user-certifications/user-certifications.effects";
import { UserEffects } from "@app/store/user/user.effects";
import { EffectsModule } from "@ngrx/effects";
import { RouterStateSerializer, StoreRouterConnectingModule } from "@ngrx/router-store";
import { StoreModule } from "@ngrx/store";
import { ClipboardModule } from "ngx-clipboard";
import { ToastrModule } from "ngx-toastr";
import { FeatureFlagAuthGuard } from "./auth/feature-flag-auth.guard";
import { IframeAuthGuard } from "./auth/iframe-auth.guard";
import { MsrpGuard } from "./auth/msrp.guard";
import { TrainingRedirectGuard } from "./auth/training-redirect.guard";
import { CmsPageEditButtonComponent } from "./edit-tooling/cms-page-edit-button/cms-page-edit-button.component";
import { EditToolingModule } from "./edit-tooling/edit-tooling.module";
import { ErrorModule } from "./error/error.module";
import { PipesModule } from "./pipes/pipes.module";
import { AddFilterGroupPopupComponent } from "./popups/add-filter-group-popup/add-filter-group-popup.component";
import { ControllerOsGroupEditPopupComponent } from "./popups/controller-os-group-edit-popup/controller-os-group-edit-popup.component";
import { ControllerPresetFilterEditPopupComponent } from "./popups/controller-preset-filter-edit-popup/controller-preset-filter-edit-popup.component";
import { ControllerTypeGroupEditPopupComponent } from "./popups/controller-type-group-edit-popup/controller-type-group-edit-popup.component";
import { DealerRolePopupComponent } from "./popups/dealer-role-popup/dealer-role-popup.component";
import { EngravingReviewPopupComponent } from "./popups/engraving-review-popup/engraving-review-popup.component";
import { FirmwarePopupComponent } from "./popups/firmware-popup/firmware-popup.component";
import { FirmwareVersionEditPopupComponent } from "./popups/firmware-version-edit-popup/firmware-version-edit-popup.component";
import { MigrationScheduledPopupComponent } from "./popups/migration-scheduled-popup/migration-scheduled-popup.component";
import { PopupsModule } from "./popups/popups.module";
import { ProctoruProfilePopupComponent } from "./popups/proctoru-profile-popup/proctoru-profile-popup.component";
import { PromotionBenefitEditPopupComponent } from "./popups/promotion-benefit-edit-popup/promotion-benefit-edit-popup.component";
import { PromotionConditionEditPopupComponent } from "./popups/promotion-condition-edit-popup/promotion-condition-edit-popup.component";
import { QuickViewPopupComponent } from "./popups/quick-view-popup/quick-view-popup.component";
import { SalesQuoteExportPopupComponent } from "./popups/sales-quote-export-popup/sales-quote-export-popup.component";
import { SuggestedProductsPopupComponent } from "./popups/suggested-products-popup/suggested-products-popup.component";
import { UpgradeReminderPopupComponent } from "./popups/upgrade-reminder-popup/upgrade-reminder-popup.component";
import { PrintModule } from "./print/print.module";
import { SearchModule } from "./search/search.module";
import { SharedElementsModule } from "./shared-elements/shared-elements.module";
import { SamlHandlerComponent } from "./sso/saml-handler/saml-handler.component";
import { StaticContentModule } from "./static-content/static-content.module";
import { AppInfoEffects } from "./store/app-info/app-info.effects";
import { AppEffects } from "./store/app.effects";
import { CountryCodeEffects } from "./store/country-code/country-code.effects";
import { CustomerLicenseSummaryEffects } from "./store/customer-license-summary/customer-license-summary.effects";
import { CategoryEffects } from "./store/product-category/product-category.effects";
import { ProductDetailsEffects } from "./store/product-details/product-details.effects";
import { ProductLightEffects } from "./store/product-light/product-light.effects";
import { ProductStockStatusEffects } from "./store/product-stock-status/product-stock-status.effects";
import { RebateProgressEffects } from "./store/rebate-progress/rebate-progress.effects";
import { SalesInfoEffects } from "./store/sales-info/sales-info.effects";
import { StatusProgessEffects as StatusProgressEffects } from "./store/status-progress/status-progress.effects";
import { TriadDiscountEffects } from "./store/triad-discount/triad-discount.effects";
import { TrackingModule } from "./tracking/tracking.module";
import { EmployeeRegistrationLayoutComponent } from "./_layout/employee-registration-layout/employee-registration-layout.component";
import { IframeLayoutComponent } from "./_layout/iframe-layout/iframe-layout.component";
import { LoginLayoutComponent } from "./_layout/login-layout/login-layout.component";
import { PrintLayoutComponent } from "./_layout/print-layout/print-layout.component";
import { SiteLayoutComponent } from "./_layout/site-layout/site-layout.component";

declare global { interface Window { ga: (action: string, name: string, value?: string) => void; } }

export class CustomSerializer implements RouterStateSerializer<IRouterStateUrl> {
	serialize(routerState: RouterStateSnapshot): IRouterStateUrl {
		let route = routerState.root;

		while (route.firstChild) {
			route = route.firstChild;
		}
		const {
			url,
			root: { queryParams },
		} = routerState;
		const { params } = route;

		let childRoute = routerState.root;
		while (childRoute.firstChild) {
			childRoute = childRoute.firstChild;
		}
		const title = childRoute.data["title"];

		// Only return an object including the URL, params and query params
		// instead of the entire snapshot
		return { url, params, queryParams, title };
	}
}

@NgModule({
	declarations: [AppComponent, SiteLayoutComponent, PrintLayoutComponent, LoginLayoutComponent, EmployeeRegistrationLayoutComponent, IframeLayoutComponent, SamlHandlerComponent],
	imports: [
		HttpClientModule,
		ErrorModule,
		PopupsModule,
		EditToolingModule,
		SharedModule,
		AuthModule,
		PrintModule,
		ClipboardModule,
		SearchModule,
		TrackingModule,
		StoreRouterConnectingModule.forRoot({ stateKey: "router" }),
		StoreModule.forRoot(reducers, { metaReducers }),
		EffectsModule.forRoot([
			AppEffects,
			AuthEffects,
			RouterEffects,
			UserEffects,
			ProductDetailsEffects,
			ProductLightEffects,
			NavEffects,
			CustomerSummaryEffects,
			CustomerLicenseSummaryEffects,
			CartEffects,
			LeadPreferencesEffects,
			C4ContactsEffects,
			SalesContactsEffects,
			LeadsEffects,
			AddressEffects,
			ShippingMethodEffects,
			BakpakCodesEffects,
			UserCertificationsEffects,
			SalesforceContactEffects,
			ToastEffects,
			AccountEffects,
			SecurityRolesEffects,
			OrderHistoryEffects,
			OrderHistoryDetailsEffects,
			AccountBalanceEffects,
			CreditCardTypeEffects,
			DealerLocatorEffects,
			UpgradeRequestFileEffects,
			CountryCodeEffects,
			CategoryEffects,
			TriadDiscountEffects,
			SalesInfoEffects,
			RebateProgressEffects,
			StatusProgressEffects,
			AppInfoEffects,
			ProductStockStatusEffects,
		]),
		AppRoutingModule,
		NavModule,
		StaticContentModule,
		BrowserAnimationsModule,
		SharedElementsModule,
		PipesModule,
		ToastrModule.forRoot(),
	],
	providers: [
		AuthenticatedGuard,
		IframeAuthGuard,
		MsrpGuard,
		FeatureFlagAuthGuard,
		TrainingRedirectGuard,
		{ provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },
		{ provide: RouterStateSerializer, useClass: CustomSerializer },
	],
	bootstrap: [AppComponent],
	entryComponents: [
		MigrationScheduledPopupComponent,
		ImpersonationPopupComponent,
		FirmwarePopupComponent,
		ContractPopupComponent,
		DealerRolePopupComponent,
		ProductUpgradePopupComponent,
		MobilePhoneUsagePopupComponent,
		ConfirmPopupComponent,
		SuggestedProductsPopupComponent,
		EngravingReviewPopupComponent,
		QuickViewPopupComponent,
		ProctoruProfilePopupComponent,
		PromotionConditionEditPopupComponent,
		PromotionBenefitEditPopupComponent,
		SalesQuoteExportPopupComponent,
		CmsPageEditButtonComponent,
		AddFilterGroupPopupComponent,
		ControllerOsGroupEditPopupComponent,
		ControllerTypeGroupEditPopupComponent,
		ControllerPresetFilterEditPopupComponent,
		UpgradeReminderPopupComponent,
		FirmwareVersionEditPopupComponent,
	],
})
export class AppModule {

	constructor() {
	}
}
