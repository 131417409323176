import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IPartsCustomizationModel } from "@app/products/products.models";

@Component({
	selector: "c4-product-customization-variant",
	templateUrl: "./customization-variant.component.html",
	styleUrls: ["./customization-variant.component.scss"]
})
export class CustomizationVariantComponent implements OnInit {
	@Input() options: IPartsCustomizationModel[];

	private _customizedSku: string;
	@Input() get customizedSku() {
		return this._customizedSku;
	}
	set customizedSku(val) {
		this._customizedSku = val;
		this.customizedSkuChange.emit(val);
	}
	@Output() customizedSkuChange = new EventEmitter<string>();

	constructor() { }

	ngOnInit() {
	}
}
