import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatOptionModule, MatSelectModule } from "@angular/material";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { RouterModule } from "@angular/router";
import { PipesModule } from "@app/pipes/pipes.module";
import { ProductsSharedModule } from "@app/products-shared/products-shared.module";
import { SearchComponent } from "@app/search/search/search.component";
import { SharedElementsModule } from "@app/shared-elements/shared-elements.module";
import { SearchIconPipe } from "./search-icon.pipe";
import { SearchItemComponent } from "./search-item/search-item.component";
import { SearchProductItemComponent } from "./search-product-item/search-product-item.component";
import { SearchVideoItemComponent } from "./search-video-item/search-video-item.component";

@NgModule({
	imports: [
		CommonModule,
		RouterModule.forChild([]),
		SharedElementsModule,
		ProductsSharedModule,
		FormsModule,
		PipesModule,
		MatFormFieldModule,
		MatInputModule,
		MatSelectModule,
		MatOptionModule,
	],
	declarations: [SearchComponent, SearchProductItemComponent, SearchItemComponent, SearchIconPipe, SearchVideoItemComponent],
})
export class SearchModule { }
