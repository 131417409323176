import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "map",
})
export class MapPipe implements PipeTransform {
	transform(key: string, map: Map<string, string>, defValue?: string): any {
		if (map.has(key)) {
			return map.get(key);
		} else {
			return defValue;
		}
	}
}
