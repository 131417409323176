import { getNgStoreCacheItem } from "@app/shared/helpers";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { getCurrentAccount } from "../account";
import { triadDiscountAdapter } from "./triad-discount.adapter";
import { ITriadDiscountState } from "./triad-discount.reducer";

export const getTriadDiscountState = createFeatureSelector<ITriadDiscountState>("triadDiscount");

export const {
	selectIds: triadDiscountSelectIds,
	selectEntities: triadDiscountSelectEntities,
	selectAll: triadDiscountSelectAll,
	selectTotal: triadDiscountSelectTotal
} = triadDiscountAdapter.getSelectors(getTriadDiscountState);

export const getCurrentTriadDiscount = createSelector(
	getCurrentAccount,
	triadDiscountSelectEntities,
	(account, entities) => account ? getNgStoreCacheItem(entities[account.id]) : undefined
);