import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AccountOrderHistory, IOrderHistoryItemDetailInfo } from "@app/account/account.models";
import { IInvoiceInfo, IProformaInvoiceModel } from "@app/print/print.models";
import { ICreditCardType } from "@app/shared/payment.models";

@Injectable({
	providedIn: "root",
})
export class OrderService {

	constructor(private http: HttpClient) { }

	getAccountOrderHistory() {
		return this.http.get<AccountOrderHistory>("api/order/getOrderHistory");
	}

	getOrderById(orderId: string) {
		return this.http.get<AccountOrderHistory>("api/order/getOrderHistory", { params: { orderId } });
	}

	getOrderDetails(orderId: number) {
		return this.http.get<IOrderHistoryItemDetailInfo>("api/order/getOrderDetails", { params: { orderId: orderId.toString() } });
	}

	getInvoiceInfo(orderId: string) {
		return this.http.get<IInvoiceInfo>("api/invoice/GetInvoiceDetails", { params: { orderId } });
	}

	getProformaInvoiceInfo(orderId: string) {
		return this.http.get<IProformaInvoiceModel>("api/invoice/GetProformaInvoiceDetails", { params: { orderId } });
	}

	getCreditCardTypes() {
		return this.http.get<ICreditCardType[]>("api/order/getAllCreditCardTypes");
	}

	createKeypadOrder(keypadOrderId: number, engravingUse: string) {
		return this.http.post<boolean>("api/cart/CreateEngravingSavedCart", { keypadOrderId, engravingUse });
	}
}
