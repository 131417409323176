import { IAddress, IError } from "@app/shared/shared.models";
import { Action } from "@ngrx/store";

export enum AddressActionTypes {
	LoadAddressAttempt = "[Address] Load Attempt",
	LoadAddressSuccess = "[Address] Load Success",
	LoadAddressError = "[Address] Load Error",
	LoadBillingAddressesAttempt = "[Billing Addresses] Load Attempt",
	LoadBillingAddressesSuccess = "[Billing Addresses] Load Success",
	LoadBillingAddressesError = "[Billing Addresses]  LoadError",
	LoadShippingAddressesAttempt = "[Shipping Addresses]  LoadAttempt",
	LoadShippingAddressesSuccess = "[Shipping Addresses] Load Success",
	LoadShippingAddressesError = "[Shipping Addresses] Load Error",
	AddShippingAddressSuccess = "[Shipping Addresses] Add Success",
	DeleteShippingAddressSuccess = "[Shipping Addresses] Delete Success",
	LoadFinalDestinationAddressesAttempt = "[Final Destination Addresses] Load Attempt",
	LoadFinalDestinationAddressesSuccess = "[Final Destination Addresses] Load Success",
	LoadFinalDestinationAddressesError = "[Final Destination Addresses] Load Error",
}

export class LoadAddressAttempt implements Action {
	readonly type = AddressActionTypes.LoadAddressAttempt;
	constructor(public payload: number) { }
}

export class LoadAddressSuccess implements Action {
	readonly type = AddressActionTypes.LoadAddressSuccess;
	constructor(public payload: IAddress) { }
}

export class LoadAddressError implements Action {
	readonly type = AddressActionTypes.LoadAddressError;
	constructor(public payload: IError) { }
}

export class LoadBillingAddressesAttempt implements Action {
	readonly type = AddressActionTypes.LoadBillingAddressesAttempt;
	constructor() { }
}

export class LoadBillingAddressesSuccess implements Action {
	readonly type = AddressActionTypes.LoadBillingAddressesSuccess;
	constructor(public payload: { accountId: string, addresses: IAddress[] }) { }
}

export class LoadBillingAddressesError implements Action {
	readonly type = AddressActionTypes.LoadBillingAddressesError;
	constructor(public payload: IError) { }
}

export class LoadShippingAddressesAttempt implements Action {
	readonly type = AddressActionTypes.LoadShippingAddressesAttempt;
	constructor() { }
}

export class LoadShippingAddressesSuccess implements Action {
	readonly type = AddressActionTypes.LoadShippingAddressesSuccess;
	constructor(public payload: { accountId: string, addresses: IAddress[] }) { }
}

export class LoadShippingAddressesError implements Action {
	readonly type = AddressActionTypes.LoadShippingAddressesError;
	constructor(public payload: IError) { }
}

export class AddShippingAddressSuccess implements Action {
	readonly type = AddressActionTypes.AddShippingAddressSuccess;
	constructor(public payload: { accountId: string, address: IAddress }) { }
}

export class DeleteShippingAddressSuccess implements Action {
	readonly type = AddressActionTypes.DeleteShippingAddressSuccess;
	constructor(public payload: { accountId: string, id: number }) { }
}

export class LoadFinalDestinationAddressesAttempt implements Action {
	readonly type = AddressActionTypes.LoadFinalDestinationAddressesAttempt;
	constructor() { }
}

export class LoadFinalDestinationAddressesSuccess implements Action {
	readonly type = AddressActionTypes.LoadFinalDestinationAddressesSuccess;
	constructor(public payload: { accountId: string, addresses: IAddress[] }) { }
}

export class LoadFinalDestinationAddressesError implements Action {
	readonly type = AddressActionTypes.LoadFinalDestinationAddressesError;
	constructor(public payload: IError) { }
}

export type AddressActions =
	| LoadAddressAttempt
	| LoadAddressSuccess
	| LoadAddressError
	| LoadBillingAddressesAttempt
	| LoadBillingAddressesSuccess
	| LoadBillingAddressesError
	| LoadShippingAddressesAttempt
	| LoadShippingAddressesSuccess
	| LoadShippingAddressesError
	| AddShippingAddressSuccess
	| DeleteShippingAddressSuccess
	| LoadFinalDestinationAddressesAttempt
	| LoadFinalDestinationAddressesSuccess
	| LoadFinalDestinationAddressesError;
