import { IAuthState } from "@app/store/auth";
import { createFeatureSelector, createSelector } from "@ngrx/store";


export const getAuthState = createFeatureSelector<IAuthState>("auth");
export const isAuthenticated = createSelector(
	getAuthState,
	(state) => state.authenticated
);

export const selectIsImpersonating = createSelector(
	getAuthState,
	(state) => state.isImpersonating
);

export const currentIsImpersonating = () => createSelector(
	getAuthState,
	(state) => state.isImpersonating
);

export const selectIsMsrp = createSelector(
	getAuthState,
	(state) => state.isMsrp
);

export const selectIsNotMsrp = createSelector(
	getAuthState,
	(state) => !state.isMsrp
);