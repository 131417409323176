import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PagingInfo } from "@app/shared-elements/shared-elements.models";

@Component({
	selector: "c4-pager",
	templateUrl: "./pager.component.html",
	styleUrls: ["./pager.component.scss"]
})
export class PagerComponent implements OnInit {

	private _info: PagingInfo;
	@Input() get info() {
		return this._info;
	}

	set info(value: PagingInfo) {
		const updated = this.populatePager(value);
		if (this._info && updated !== this._info) {
			if (updated.pageCount !== this._info.pageCount) {
				this.pageChange.emit(updated.pageCount);
			}
			this._info = updated;
			this.infoChange.emit(this._info);
		} else if (!this._info) {
			this._info = updated;
		}
	}

	@Output() infoChange = new EventEmitter();
	@Output() pageChange = new EventEmitter();

	displayNumbers: number[];
	pageCount: number;

	constructor() { }

	ngOnInit() {
	}

	populatePager(info: PagingInfo) {
		const pageSize = info.pageSize || 10;
		let current = info.current || 1;
		const total = info.total;

		if (total === 0 || pageSize >= total) {
			this.displayNumbers = [];
		}

		const pageCount = Math.ceil(total / pageSize);
		this.pageCount = pageCount;

		if (current > pageCount) {
			current = pageCount;
		}

		const displayCount = Math.min(9, pageCount);
		const median = Math.ceil(displayCount / 2);
		let first: number;
		if (current <= median) {
			first = 1;
		} else if (pageCount - median < current) {
			first = pageCount - displayCount + 1;
		} else {
			first = current - median + 1;
		}
		this.displayNumbers = Array.from(new Array(displayCount), (_info, idx) => first + idx);
		return PagingInfo.update(info, { pageSize, current });
	}

	updateCurrentPage(page: number) {
		this.info = PagingInfo.update(this.info, { current: page });
		this.pageChange.emit(page);
	}
}
