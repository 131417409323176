import { Pipe, PipeTransform } from "@angular/core";
import { IUser } from "@app/account/account.models";

@Pipe({ name: "fullname" })
export class UserFullNamePipe implements PipeTransform {
	transform(value: IUser): string {
		return value
			? `${value.firstName || ""} ${value.lastName || ""}`.trim()
			: "";
	}
}
