import { getNgStoreCacheItem } from "@app/shared/helpers";
import { lightAdapter } from "@app/store/product-light/product-light.adapter";
import { IProductLightState } from "@app/store/product-light/product-light.reducer";
import { createFeatureSelector, createSelector } from "@ngrx/store";

export const getProductLightState = createFeatureSelector<IProductLightState>("productsLight");

export const {
	selectIds: lightSelectIds,
	selectEntities: lightSelectEntities,
	selectAll: lightSelectAll,
	selectTotal: lightSelectTotal
} = lightAdapter.getSelectors(getProductLightState);

export const selectFailedSkus = createSelector(
	getProductLightState,
	(state) => state.failedSkus
);

export const selectProductLight = (sku: string) => createSelector(
	lightSelectEntities,
	(entities) => getNgStoreCacheItem(entities[sku])
);

export const selectProductLightBySkuList = (skus: string[]) => createSelector(
	lightSelectEntities,
	(entities) => skus ? skus.map(sku => getNgStoreCacheItem(entities[sku.toUpperCase()])).find(x => !!x) : undefined
);

export const selectProductListBySkus = (skus: string[]) => createSelector(
	lightSelectEntities,
	(entities) => skus ? skus.map(sku => getNgStoreCacheItem(entities[sku.toUpperCase()])).filter(x => !!x) : undefined
);

export const selectUnloadedSkus = (skus: string[]) => createSelector(
	lightSelectEntities,
	selectFailedSkus,
	(entities, failed) => skus.map(x => x.toUpperCase()).filter(sku => !getNgStoreCacheItem(entities[sku.toUpperCase()]) && !getNgStoreCacheItem(failed[sku.toUpperCase()]))
);

export const selectProductListWithUnloadedBySkus = (skus: string[]) => createSelector(
	selectProductListBySkus(skus),
	selectUnloadedSkus(skus),
	(products, unloaded) => products && unloaded ? { products, unloaded } : undefined
);

export const selectRecentlyViewedSkus = createSelector(
	getProductLightState,
	(state) => state.recentlyViewed
);

export const selectRecentlyViewedProducts = createSelector(
	selectRecentlyViewedSkus,
	(skus) => selectProductListBySkus(skus)
);