import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IAuthenticatedLinkModel, IAuthResponse, IImpersonateRequest, IJwtResponse, ILogin, IPasswordResetRequest, ITokenResponse } from "@app/auth/auth.models";
import { getNgStoreCacheItem, ngStoreCacheItem } from "@app/shared/helpers";
import { IGenericResponse, SiteFeatureFlag } from "@app/shared/shared.models";
import { IAppState } from "@app/store";
import { Store } from "@ngrx/store";

@Injectable({ providedIn: "root" })
export class AuthService {

	public passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_+=\[\]\{\}'"?<>\\\/\.;:~`])[A-Za-z\d!@#$%^&*()\-_+=\[\]\{\}'"?<>\\\/\.;:~`]{8,20}$/;

	constructor(private http: HttpClient, private route: ActivatedRoute, private store: Store<IAppState>) {

	}

	login(request: ILogin) {
		return this.http.post<IAuthResponse>("api/authentication/login", request);
	}

	loginWithToken(request: ILogin) {
		return this.http.post<IAuthResponse>("api/authentication/LoginWithToken", request);
	}

	getSiteFeatureFlags() {
		return this.http.get<SiteFeatureFlag[]>("api/authentication/getFeatureFlags");
	}

	getUpdatedToken(token: string) {
		return this.http.get<ITokenResponse>("api/authentication/GetUpdatedToken", { params: { token } });
	}

	impersonate(request: IImpersonateRequest) {
		return this.http.post<IAuthResponse>("api/authentication/impersonate", request);
	}

	impersonateMsrp() {
		return this.http.post<IAuthResponse>("api/authentication/impersonateMsrp", {});
	}

	changePassword(resetRequest: IPasswordResetRequest) {
		return this.http.post<IGenericResponse>("api/user/changePassword", resetRequest);
	}

	getFirstTimeUserData(id: string) {
		return this.http.get<IAuthenticatedLinkModel>("api/user/GetFirstTimeUserData", { params: { id } });
	}

	getResetData(id: string) {
		return this.http.get<IAuthenticatedLinkModel>("api/user/GetResetData", { params: { id } });
	}

	clearImpersonatedJwt() {
		this.saveCurrentJwt(this.getActiveJwt());
	}

	saveActiveJwt(token: IJwtResponse) {
		token = ngStoreCacheItem(token, token.expires, "seconds");
		localStorage.setItem("activeUser", JSON.stringify(token));
	}

	getActiveJwt(): IJwtResponse {
		const val = localStorage.getItem("activeUser");

		return val ? getNgStoreCacheItem(JSON.parse(val) as IJwtResponse) : undefined;
	}

	clearJwts() {
		localStorage.removeItem("activeUser");
		localStorage.removeItem("currentUser");
	}

	saveCurrentJwt(token: IJwtResponse) {
		token = ngStoreCacheItem(token, token.expires, "seconds");
		localStorage.setItem("currentUser", JSON.stringify(token));
	}

	getCurrentJwt(): IJwtResponse {
		const val = localStorage.getItem("currentUser");
		if (val) {
			return getNgStoreCacheItem(JSON.parse(val) as IJwtResponse);
		}

		return undefined;
	}

	isAuthenticated() {
		const auth = localStorage.getItem("activeUser");

		return !!auth;
	}

	setIsExternal() {
		localStorage.setItem("isExternalLogin", "1");
	}

	clearIsExternal() {
		localStorage.removeItem("isExternalLogin");
	}

	getIsExternal() {
		return localStorage.getItem("isExternalLogin");
	}
}
