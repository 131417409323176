import { IAccountSalesInfo } from "@app/account/account.models";
import { ngStoreCacheItem } from "@app/shared/helpers";
import { EntityState } from "@ngrx/entity";
import { SalesInfoActions, SalesInfoActionTypes } from "./sales-info.actions";
import { salesInfoAdapter } from "./sales-info.adapter";

export interface ISalesInfoState extends EntityState<IAccountSalesInfo> { }

export const initialState: ISalesInfoState = salesInfoAdapter.getInitialState();

export function salesInfoReducer(
	state = initialState,
	action: SalesInfoActions
): ISalesInfoState {
	switch (action.type) {
		case SalesInfoActionTypes.LoadSalesInfoSuccess:
			return salesInfoAdapter.upsertOne(ngStoreCacheItem(action.payload, 20, "minutes"), state);
		default:
			return state;
	}
}