import { Directive, HostListener, Input } from "@angular/core";
import { startsWithIgnoreCase } from "@app/shared/helpers";

@Directive({
	selector: "[c4Toggle]"
})
export class ToggleDirective {
	@Input("c4Toggle") selector: string;

	@HostListener("click") onClick() {
		this.toggle();
	}

	constructor() {
	}

	toggle() {
		this.selector = !(startsWithIgnoreCase(this.selector, ".") || startsWithIgnoreCase(this.selector, "#")) ? `.${this.selector}` : this.selector;
		this.selector.split(" ").forEach(s => {
			const items = document.querySelectorAll(s);
			for (let i = 0; i < items.length; i++) {
				this.toggleElement(items[i]);
			}
		});
	}

	private toggleElement(el: Element) {
		if (el.classList.contains("hidden")) {
			el.classList.remove("hidden");
			el.classList.add("toggle-show");
		} else {
			el.classList.remove("toggle-show");
			el.classList.add("hidden");
		}
	}
}