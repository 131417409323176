import { Pipe, PipeTransform } from "@angular/core";
import { IAppState } from "@app/store";
import { getCurrentIsEmea } from "@app/store/account";
import { select, Store } from "@ngrx/store";
import { format as dateFnsFormat } from "date-fns";
import { take } from "rxjs/operators";

@Pipe({
	name: "shortestDate"
})
export class ShortestDatePipe implements PipeTransform {
	isEmea: boolean;
	constructor(store: Store<IAppState>) {
		store.pipe(
			take(1),
			select(getCurrentIsEmea)
		).subscribe((isEmea: boolean) => this.isEmea = isEmea);
	}

	transform(value: string | Date): any {
		const d = new Date(value);
		const format = !this.isEmea ? "MM-DD-YYYY" : "DD-MM-YYYY";
		return dateFnsFormat(d, format);
	}
}