import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { RewardsService } from "@app/services/rewards.service";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action, select, Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, debounceTime, filter, map, mergeMap, switchMap } from "rxjs/operators";
import { IAppState } from "../app.reducer";
import { LoadTriadDiscountError, LoadTriadDiscountSuccess, TriadDiscountActionTypes } from "./triad-discount.actions";
import { getCurrentTriadDiscount } from "./triad-discount.selectors";

@Injectable()
export class TriadDiscountEffects {
	constructor(
		private actions$: Actions,
		private rewardsService: RewardsService,
		private store: Store<IAppState>
	) { }

	@Effect()
	loadTriadDiscount$: Observable<Action> = this.actions$.pipe(
		ofType(TriadDiscountActionTypes.LoadTriadDiscountAttempt),
		debounceTime(100),
		switchMap(() => this.store.pipe(select(getCurrentTriadDiscount))),
		filter((data) => !data),
		mergeMap(() => {
			return this.rewardsService
				.getTriadDiscountData()
				.pipe(
					map((info) => new LoadTriadDiscountSuccess(info)),
					catchError((err: HttpErrorResponse) => of(new LoadTriadDiscountError({ error: err.message || err.statusText })))
				);
		})
	);
}
