import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CmsPageEditButtonComponent } from "./cms-page-edit-button/cms-page-edit-button.component";

@NgModule({
	declarations: [CmsPageEditButtonComponent],
	imports: [
		CommonModule
	]
})
export class EditToolingModule { }
