import { IOrderHistoryItemDetailInfo } from "@app/account/account.models";
import { getNgStoreCacheItem } from "@app/shared/helpers";
import { orderHistoryDetailsAdapter } from "@app/store/order-history-details/order-history-details.adapter";
import { IOrderHistoryDetailsState } from "@app/store/order-history-details/order-history-details.reducer";
import { getSelectedOrderHistoryId } from "@app/store/order-history/order-history.selectors";
import { createFeatureSelector, createSelector } from "@ngrx/store";

export const getOrderHistoryDetailsState = createFeatureSelector<IOrderHistoryDetailsState>("orderHistoryDetails");

export const {
	selectIds: orderHistoryDetailsSelectIds,
	selectEntities: orderHistoryDetailsSelectEntities,
	selectAll: orderHistoryDetailsSelectAll,
	selectTotal: orderHistoryDetailsSelectTotal,
} = orderHistoryDetailsAdapter.getSelectors(getOrderHistoryDetailsState);

export const getSelectedOrderHistoryDetails = createSelector(
	getSelectedOrderHistoryId,
	orderHistoryDetailsSelectEntities,
	(id, entities) => (id ? getNgStoreCacheItem(entities[id]) : undefined) || { webOrderId: id } as IOrderHistoryItemDetailInfo,
);