import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { IProductLightModel } from "@app/products/products.models";
import { ProductService } from "@app/services/product.service";
import { PagingInfo } from "@app/shared-elements/shared-elements.models";
import { randomSelect } from "@app/shared/helpers";
import { IAppState } from "@app/store";
import { selectIsMsrp } from "@app/store/auth";
import { getCategory, LoadCategoryAttempt } from "@app/store/product-category";
import { BulkLoadProductsLightAttempt } from "@app/store/product-light";
import { selectCurrentUserId } from "@app/store/user";
import { select, Store } from "@ngrx/store";
import { BehaviorSubject, combineLatest, Subject } from "rxjs";
import { map, switchMap, takeUntil } from "rxjs/operators";

@Component({
	selector: "c4-product-grid",
	templateUrl: "./product-grid.component.html",
	styleUrls: ["./product-grid.component.scss"],
})
export class ProductGridComponent implements OnInit, OnDestroy {
	displayProducts: IProductLightModel[];
	private _products: IProductLightModel[];
	@Input() get products() {
		return this._products;
	}

	set products(val) {
		this._products = val;
		this.productsChanged.next(val.sort((a, b) => a.available ? 1 : (b.available ? 0 : -1)));
	}

	productsChanged = new BehaviorSubject<IProductLightModel[]>([]);

	@Input() category: number;
	@Input() filters: string[]; // TODO: Wire in filters

	private _skus: string[];
	@Input() get skus() {
		return this._skus;
	}

	set skus(val) {
		this.skusChanged.next(val);
	}

	skusChanged = new BehaviorSubject<string[]>([]);

	@Input() header: string;
	@Input() cols: string;
	@Input() paging: PagingInfo;

	private _limit: number;
	@Input() get limit() {
		return this._limit;
	}

	set limit(val) {
		this._limit = val;
		this.limitChanged.next(val);
	}

	limitChanged = new BehaviorSubject<number>(0);

	@Input() ignoreSuggested: boolean;
	@Input() ignoreCustom: boolean;
	@Input() ignorePromo: boolean;
	@Input() section: string;
	@Input() isInCart = false;

	@Output() empty = new EventEmitter<boolean>();

	private destroyed$ = new Subject<{}>();

	loading = true;

	// Catches any instances of whitespace
	// or any special characters that aren't - or _
	public idRegex = new RegExp(/(\s*[^\-\_a-zA-Z\d]\s*|\s+)/g);

	constructor(readonly store: Store<IAppState>, readonly productService: ProductService) { }

	ngOnInit() {
		this.cols = this.cols || "col-sm-3";
		this.loading = !this.products;

		combineLatest([
			this.productsChanged,
			this.limitChanged,
		]).pipe(
			takeUntil(this.destroyed$),
		).subscribe(([products, limit]) => {
			if (limit) {
				this.displayProducts = randomSelect(products, limit);
			} else {
				this.displayProducts = products;
			}
		});

		if (this.loading) {
			this.skusChanged.pipe(
				map(val => (val || []).map(x => x.toUpperCase())),
				switchMap(skus => {
					this._skus = skus;
					this.loading = true;
					this.empty.emit(false);
					this.store.dispatch(new BulkLoadProductsLightAttempt(skus));

					return this.store.pipe(this.productService.getRandomProductsSelectorWithUnloaded(skus, this.limit));
				}),
				takeUntil(this.destroyed$),
			).subscribe(result => {
				this.products = result.products || [];
				this.loading = result.unloaded && result.unloaded.length > 0;
				if (!this.loading) {
					this.empty.emit(this.products.length === 0);
				}
			});

			combineLatest([
				this.store.pipe(select(getCategory(this.category))),
				this.store.pipe(select(selectCurrentUserId)),
				this.store.pipe(select(selectIsMsrp)),
			]).pipe(
				takeUntil(this.destroyed$),
			).subscribe(([category]) => {
				if (this.category) {
					this.store.dispatch(new LoadCategoryAttempt(this.category));
				}
				this.skusChanged.next(category ? category.skus : this.skus);
			});
		}
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}

	generateId(index: number) {
		if (this.section) {
			return `${this.section.replace(this.idRegex, "-")}-${index}`;
		} else {
			return `grid-name-${index}`;
		}
	}
}
