import { Injectable } from "@angular/core";
import { UserService } from "@app/services/user.service";
import { LoadUserCertificationsAttempt, LoadUserCertificationsError, LoadUserCertificationsSuccess, UserCertificationsActionTypes } from "@app/store/user-certifications/user-certifications.actions";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";

@Injectable()
export class UserCertificationsEffects {
	constructor(
		private actions$: Actions,
		private userService: UserService
	) { }

	@Effect()
	loadUserCertifications$: Observable<Action> = this.actions$.pipe(
		ofType<LoadUserCertificationsAttempt>(UserCertificationsActionTypes.LoadUserCertificationsAttempt),
		mergeMap((payload) => {
			return this.userService
				.getUserCertifications()
				.pipe(
					map((certifications) => new LoadUserCertificationsSuccess({ userId: payload.userId, certifications })),
					catchError((err) => of(new LoadUserCertificationsError({ error: err.message || err.statusText })))
				);
		})
	);
}
