import { formatCurrency } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { AccountService } from "@app/services/account.service";
import { IAppState } from "@app/store";
import { getCurrentCurrencySymbol } from "@app/store/app-info";
import { getCurrentStatusProgress, LoadStatusProgressAttempt } from "@app/store/status-progress";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil, withLatestFrom } from "rxjs/operators";

@Component({
	selector: "c4-kpi-widget-status",
	templateUrl: "./kpi-widget-status.component.html",
	styleUrls: ["./kpi-widget-status.component.scss"]
})
export class KpiWidgetStatusComponent implements OnInit, OnDestroy {
	loading = true;
	currentStatus: string;
	nextStatusLine: string;
	error: boolean;
	private destroyed$ = new Subject<{}>();

	constructor(readonly store: Store<IAppState>, readonly accountService: AccountService) { }

	ngOnInit() {
		this.store.pipe(
			select(getCurrentStatusProgress),
			withLatestFrom(this.store.pipe(select(getCurrentCurrencySymbol))),
			takeUntil(this.destroyed$)
		).subscribe(([result, symbol]) => {
			this.loading = !result;
			this.nextStatusLine = null;
			if (!this.loading) {
				this.error = !result.valid;
				this.currentStatus = result.nextYearCurrentStatus;
				if (!result.atMax) {
					const needed = [];
					if (result.nextStatusSalesNeeded) {
						needed.push(formatCurrency(result.nextStatusSalesNeeded, "en-US", symbol));
					}
					if (result.nextStatusControllersNeeded) {
						needed.push(`${result.nextStatusControllersNeeded} controller${result.nextStatusControllersNeeded > 1 ? "s" : ""}`);
					}
					this.nextStatusLine = `${needed.join(", ")} until ${result.nextYearNextStatus}`;
				}
			} else {
				this.store.dispatch(new LoadStatusProgressAttempt());
			}
		});
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}
}
