import { Component, Input, OnInit } from "@angular/core";
import { IAppState } from "@app/store";
import { SuccessToast } from "@app/store/toast/toast.actions";
import { Store } from "@ngrx/store";
import { ClipboardService } from "ngx-clipboard";

@Component({
	selector: "c4-wistia-thumbnail-player",
	templateUrl: "./wistia-thumbnail-player.component.html",
	styleUrls: ["./wistia-thumbnail-player.component.scss"]
})
export class WistiaThumbnailPlayerComponent implements OnInit {
	@Input() wistiaId: string;
	@Input() thumbnail: string;
	@Input() title: string;
	@Input() download: string;
	@Input() showButtons: boolean;
	@Input() aspect = "video";
	@Input() embedCode: string;

	displayVideo: boolean;

	constructor(readonly store: Store<IAppState>, private readonly clipboard: ClipboardService) { }

	ngOnInit() {
	}

	copyEmbedCode() {
		this.embedCode = `<div class="wistia_responsive_padding" style="padding:62.5% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><iframe src="https://fast.wistia.net/embed/iframe/${this.wistiaId}?videoFoam=true" title="${this.title}" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" allowfullscreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen width="100%" height="100%"></iframe></div></div><script src="https://fast.wistia.net/assets/external/E-v1.js" async></script>`;
		this.clipboard.copyFromContent(this.embedCode);
		this.store.dispatch(new SuccessToast({ message: "Video embed code copied to clipboard!" }));
	}

}
