import { Component, OnDestroy, OnInit } from "@angular/core";
import { KbService } from "@app/services/kb.service";
import { RmaService } from "@app/services/rma.service";
import { Portals } from "@app/shared/shared.models";
import { getCurrentPortal } from "@app/store/app-info/app-info.selectors";
import { IAppState } from "@app/store/app.reducer";
import { SuccessToast, WarningToast } from "@app/store/toast/toast.actions";
import { IKbArticleSearchModel } from "@app/support/support.models";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";

@Component({
	selector: "c4-support-email",
	templateUrl: "./support-email.component.html",
	styleUrls: ["./support-email.component.scss"]
})
export class SupportEmailComponent implements OnInit, OnDestroy {
	briefQuestion: string;
	detailedQuestion: string;
	portal: Portals;
	supportBrand: Portals;
	articlesLoaded: boolean;
	relatedArticles: IKbArticleSearchModel[];
	selectablePortals: Portals[];
	showForm1 = false;
	showForm2 = false;
	submitting: boolean;

	private destroyed$ = new Subject<{}>();

	constructor(readonly kbService: KbService, readonly rmaService: RmaService, readonly store: Store<IAppState>) { }

	ngOnInit() {
		this.store.pipe(select(getCurrentPortal)).subscribe((currentPortal) => {
			this.portal = currentPortal;
			this.supportBrand = currentPortal;
			this.populate();
		});
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}

	populate(): void {
		this.selectablePortals = this.getSelectablePortals(this.portal);
	}

	getPrettyName(portal: Portals) {
		switch (portal) {
			case Portals.Pakedge:
				return "Pakedge";
			case Portals.Triad:
				return "Triad Audio";
			default:
				return "Control4";
		}
	}

	private getSelectablePortals(portal: Portals): Portals[] {
		switch (portal) {
			case Portals.DealerEmea:
				return [Portals.DealerEmea, Portals.Pakedge, Portals.Triad];
			case Portals.Pakedge:
			case Portals.Triad:
				return [Portals.Pakedge, Portals.Triad];
			default:
				return [Portals.DealerUs, Portals.Pakedge, Portals.Triad];
		}
	}

	getRelatedArticles(): void {
		this.kbService.searchKbArticles(this.briefQuestion, 5).subscribe((result) => {
			this.relatedArticles = result || [];
			this.articlesLoaded = true;
			this.showForm1 = false;
			this.showForm2 = true;
		});
	}

	submitRequest(): void {
		this.submitting = true;
		this.rmaService.createSupportContactCase(this.briefQuestion, this.detailedQuestion, this.supportBrand).subscribe((result) => {
			if (result) {
				this.store.dispatch(new SuccessToast({ message: `Thank you for contacting ${this.getPrettyName(this.supportBrand)} support. Please allow 1 business day for us to contact you.` }));
			} else {
				this.store.dispatch(new WarningToast({ message: "There was a problem contacting support. Please try again later." }));
			}
			this.clearFields();
		});
	}

	clearFields(): void {
		this.briefQuestion = null;
		this.detailedQuestion = null;
		this.supportBrand = this.portal;
		this.articlesLoaded = false;
		this.relatedArticles = null;
		this.showForm1 = false;
		this.showForm2 = false;
		this.submitting = false;
	}
}
