import { Component, ComponentRef, HostListener, OnInit } from "@angular/core";
import { ControllerType, ControllerTypeGroup } from "@app/dashboard/dashboard.models";
import { CmsCustomerService } from "@app/services/cms-customer.service";
import { KeyboardService } from "@app/services/keyboard.service";
import { deepcopy, remove } from "@app/shared/helpers";
import { IAppState } from "@app/store";
import { ErrorToast } from "@app/store/toast/toast.actions";
import { Store } from "@ngrx/store";
import { map } from "rxjs/operators";
import { IPopupComponent } from "../popups.models";

@Component({
	selector: "c4-controller-type-group-edit-popup",
	templateUrl: "./controller-type-group-edit-popup.component.html",
	styleUrls: ["./controller-type-group-edit-popup.component.scss"],
})
export class ControllerTypeGroupEditPopupComponent implements OnInit, IPopupComponent {
	close: () => void;
	self: ComponentRef<ControllerTypeGroupEditPopupComponent>;
	group: ControllerTypeGroup;

	onSave: (group: ControllerTypeGroup) => void;

	constructor(readonly store: Store<IAppState>, readonly cmsCustomerService: CmsCustomerService, readonly keyboardService: KeyboardService) { }

	ngOnInit() {
		if (!this.group) {
			this.group = <ControllerTypeGroup>{
				color: 0,
				controllerTypes: [],
				id: 0,
			};
		} else {
			this.group = deepcopy(this.group);
		}
	}

	@HostListener("keydown", ["$event"])
	onKeyDownHandler(event: KeyboardEvent) {
		if (this.keyboardService.isSaveKeybind(event)) {
			this.save();
		}
	}

	addType() {
		this.group.controllerTypes.push(<ControllerType>{ groupId: this.group.id });
	}

	removeType(type: ControllerType) {
		this.group.controllerTypes = remove(this.group.controllerTypes, type);
	}

	save() {
		this.group.controllerTypes = this.group.controllerTypes.filter(x => x.consumerTypeCode);
		this.group.controllerTypes.forEach(x => x.name = x.name || x.consumerTypeCode);;
		this.cmsCustomerService.saveControllerTypeGroup(this.group).subscribe(result => {
			if (this.onSave) {
				this.onSave(result);
				this.close();
			}
		}, () => this.store.dispatch(new ErrorToast({ message: "Failed to save controller type segment." })));
	}

	searchTypes(type: ControllerType) {
		return (val: string) => {
			return this.cmsCustomerService.searchControllerTypes(val)
				.pipe(map(results => {
					const versions = this.group.controllerTypes.filter(x => x !== type).map(x => x.name);

					return results.filter(x => !versions.includes(x));
				}));
		};
	}

}
