import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IPromoBenefitModel, IPromoConditionModel, IPromoListModel, IPromoModel, IPromotionDisplayModel } from "@app/promotions/promotion.models";

@Injectable({
	providedIn: "root"
})
export class PromoService {

	constructor(private http: HttpClient) { }

	getActiveDisplayPromotions(sku: string = null) {
		sku = sku || undefined;
		let params = new HttpParams();
		if (sku) {
			params = params.set("sku", sku);
		}
		return this.http.get<IPromotionDisplayModel[]>("api/promo/getActiveDisplayPromotions", { params });
	}

	getPromos() {
		return this.http.get<IPromoListModel[]>("api/promo/GetPromos");
	}

	getPromo(id: number) {
		const params = new HttpParams().set("id", id.toString());
		return this.http.get<IPromoModel>("api/promo/GetPromo", { params });
	}

	deletePromo(promoId: number) {
		const params = new HttpParams().set("promoId", promoId.toString());
		return this.http.delete<boolean>("api/promo/DeletePromo", { params });
	}

	savePromo(promo: IPromoModel) {
		return this.http.post<boolean>("api/promo/SavePromo", promo);
	}

	removePromoCondition(id: number) {
		const params = new HttpParams().set("id", id.toString());
		return this.http.delete<boolean>("api/promo/RemovePromoCondition", { params });
	}

	savePromoCondition(condition: IPromoConditionModel) {
		return this.http.post<number>("api/promo/SavePromoCondition", condition);
	}

	savePromoBenefit(benefit: IPromoBenefitModel) {
		return this.http.post<number>("api/promo/SavePromoBenefit", benefit);
	}

	removePromoBenefit(id: number) {
		const params = new HttpParams().set("id", id.toString());
		return this.http.delete<boolean>("api/promo/RemovePromoBenefit", { params });
	}

}
