import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "htmlAsText",
})
export class HtmlAsTextPipe implements PipeTransform {

	transform(html: string): any {
		const tag = document.createElement("div");
		tag.innerHTML = html;

		return tag.innerText;
	}

}
