import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { IFinishCustomizations, IPartCustomColorOptionModel, IPartCustomizationsModel, IPartFinishOption } from "@app/products/products.models";
import { ProductCustomizationService } from "@app/services/product-customization.service";
import { contains, objectUpdate } from "@app/shared/helpers";
import { Subject } from "rxjs";

@Component({
	selector: "c4-product-customization-finish",
	templateUrl: "./customization-finish.component.html",
	styleUrls: ["./customization-finish.component.scss"],
})
export class CustomizationFinishComponent implements OnInit, OnDestroy, OnChanges {
	@Input() options: IPartCustomizationsModel;

	@Input() customizations: IFinishCustomizations;
	@Output() customizationsChange = new EventEmitter<IFinishCustomizations>();

	supportsFinishes: boolean;
	hasColorOptions: boolean;

	showManufacturer: boolean;
	showPaintCode: boolean;
	finishOptions: IPartFinishOption[];

	colorSwatchStyle: { "background-image": string };
	cabinetSwatchStyle: { "background-image": string };

	private readonly destroyed$ = new Subject<{}>();

	constructor(readonly customizationService: ProductCustomizationService) { }

	ngOnInit() {
		this.customizationsChange.emit({ isCustomized: true } as IFinishCustomizations);
		if (this.options) {
			this.hasColorOptions = this.options.customColorOptions && this.options.customColorOptions.length > 1;
			this.selectDefaultColorOption();
			this.populateFinishOptions();

			if (this.finishOptions) {
				const inititalColor = this.finishOptions.find(x => x.value === "Black");
				this.updateFinishCustomizations({ finish: inititalColor });
			}

			const initialCustomFinish = this.customizations.paintCode || (this.customizations.finish ? this.customizations.finish.value : null);
			if (initialCustomFinish) {
				this.populateInitialItem(initialCustomFinish);
			}
		}
	}

	populateInitialItem(initialCustomFinish: string) {
		if (this.customizations.paintManufacturer) {
			this.customizations.finish = this.finishOptions.find(x => x.value === "Custom Color†");
			this.updateFinishCustomizations({ paintManufacturer: this.customizations.paintManufacturer, paintCode: initialCustomFinish });
		} else {
			const finish = this.finishOptions.find(x => x.value === initialCustomFinish);
			this.onFinishChange(finish);
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		this.showManufacturer = this.customizations.finish && this.customizationService.isCustomColor(this.customizations.finish.value)
			|| (this.customizations.cabinetColor && this.customizationService.isCustomColor(this.customizations.cabinetColor.displayValue));
		this.showPaintCode = this.showManufacturer && this.customizations.paintManufacturer && this.customizations.paintManufacturer.indexOf("Matching") < 0;
		this.colorSwatchStyle = { "background-image": `url('https://res.cloudinary.com/control4/image/upload/${this.customizations.cabinetColor ? this.customizations.cabinetColor.swatch : "white.png"}')` };
		this.cabinetSwatchStyle = { "background-image": `url('https://res.cloudinary.com/control4/image/upload/triadveneer-${this.customizations.finish ? this.customizations.finish.image : "black.png"}')` };

		if (changes.options && changes.options.previousValue !== changes.options.currentValue) {
			this.populateFinishOptions();
			this.customizationsChange.emit(this.customizations);
		}
	}

	ngOnDestroy() {
		this.resetCustomizations();
		this.destroyed$.next();
	}

	private populateFinishOptions() {
		this.finishOptions = [];
		if (this.options.canCustomizePaint) {
			this.finishOptions = this.finishOptions.concat(this.customizationService.paintFinishes);
		}
		if (this.options.canCustomizeVeneer) {
			this.finishOptions = this.finishOptions.concat(this.customizationService.veneerOptions);
		}
	}

	selectDefaultColorOption() {
		if (this.options.customColorOptions) {
			this.customizations.cabinetColor = this.options.customColorOptions.find(o => o.partNumber === o.finalPartNumber);
		} else {
			this.customizations.cabinetColor = null;
		}
	}

	onFinishChange(finish: IPartFinishOption) {
		if (finish) {
			this.updateFinishCustomizations({ finish, isCustomized: true });
		} else {
			this.resetCustomizations();
		}
	}

	onPaintManufacturerChange(paintManufacturer: string) {
		this.updateFinishCustomizations({ paintManufacturer });
	}

	onPaintCodeChange(paintCode: string) {
		this.updateFinishCustomizations({ paintCode });
	}

	onColorChange(cabinetColor: IPartCustomColorOptionModel) {
		this.updateFinishCustomizations({ cabinetColor });
	}

	updateFinishCustomizations(changes: Partial<IFinishCustomizations>) {
		const updated = objectUpdate(this.customizations, changes);
		this.populateCustomizationChecks(updated);
		if (updated.isCustomized && !updated.isCustomColor) {
			delete updated.paintCode;
			delete updated.paintManufacturer;
		}
		this.customizationsChange.emit(updated);
	}

	populateCustomizationChecks(data: IFinishCustomizations) {
		if (data.isCustomized) {
			const hasManufacturer = !!data.paintManufacturer;
			const cabinetCustomColor = this.customizationService.cabinetColorIsCustom(data.cabinetColor);
			const finishCustomColor = this.customizationService.finishIsCustomColor(data.finish);

			const vaidateColor = !data.cabinetColor || !cabinetCustomColor || hasManufacturer;
			const validateVaneer = !data.finish || !finishCustomColor || hasManufacturer;

			data.isValid = vaidateColor && validateVaneer && (!hasManufacturer || !!data.paintCode || contains(data.paintManufacturer, "Matching"));
			data.isCustomColor = cabinetCustomColor || finishCustomColor;
		} else {
			data.isValid = false;
			data.isCustomColor = false;
		}
	}

	resetCustomizations() {
		this.customizationsChange.emit({ isCustomized: false } as IFinishCustomizations);
	}
}
