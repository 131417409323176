import { Component, OnDestroy, OnInit } from "@angular/core";
import { ILeadCounts } from "@app/dashboard/dashboard.models";
import { IAppState } from "@app/store";
import { getLeadCountsForAccount, LoadLeadsAttempt } from "@app/store/leads";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: "c4-kpi-widget-leads",
	templateUrl: "./kpi-widget-leads.component.html",
	styleUrls: ["./kpi-widget-leads.component.scss"]
})
export class KpiWidgetLeadsComponent implements OnInit, OnDestroy {
	loading: boolean;
	leadCounts: ILeadCounts;
	hasLeads: boolean;

	private destroyed$ = new Subject<{}>();

	constructor(private store: Store<IAppState>) {

	}

	ngOnInit(): void {
		this.store.pipe(
			select(getLeadCountsForAccount),
			takeUntil(this.destroyed$)
		).subscribe((counts) => {
			this.leadCounts = counts;
			this.loading = !this.leadCounts;
			if (this.loading) {
				this.store.dispatch(new LoadLeadsAttempt());
			} else {
				this.hasLeads = this.leadCounts.newTotal + this.leadCounts.openTotal > 0;
			}
		});
	}
	ngOnDestroy(): void {
		this.destroyed$.next();
	}

}