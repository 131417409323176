import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { RewardsService } from "@app/services/rewards.service";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action, select, Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, debounceTime, filter, map, mergeMap, switchMap } from "rxjs/operators";
import { IAppState } from "../app.reducer";
import { LoadRebateProgressError, LoadRebateProgressSuccess, RebateProgressActionTypes } from "./rebate-progress.actions";
import { getCurrentRebateProgress } from "./rebate-progress.selectors";

@Injectable()
export class RebateProgressEffects {
	constructor(
		private actions$: Actions,
		private rewardsService: RewardsService,
		private store: Store<IAppState>
	) { }

	@Effect()
	loadRebateProgress$: Observable<Action> = this.actions$.pipe(
		ofType(RebateProgressActionTypes.LoadRebateProgressAttempt),
		debounceTime(100),
		switchMap(() => this.store.pipe(select(getCurrentRebateProgress))),
		filter((data) => !data),
		mergeMap(() => {
			return this.rewardsService
				.getRebateProgress()
				.pipe(
					map((info) => new LoadRebateProgressSuccess(info)),
					catchError((err: HttpErrorResponse) => of(new LoadRebateProgressError({ error: err.message || err.statusText })))
				);
		})
	);
}
