import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class CkEditorService {
	ckeditorLoaded = new Subject<boolean>();

	private loading: boolean;


	defaultConfig: any = {
		// Define changes to default configuration here.
		// For complete reference see:
		// http://docs.ckeditor.com/#!/api/CKEDITOR.config

		// The toolbar groups arrangement, optimized for two toolbar rows.
		language: "en",
		toolbar: [
			{ name: "basicstyles", items: ["Bold", "Italic", "Strike"] },
			{ name: "formatting", items: ["NumberedList", "BulletedList", "Blockquote"] },
			{ name: "links", items: ["Link", "Unlink", "Anchor"] },
			{ name: "tools", items: ["Maximize"] },
			"/",
			{ name: "styles", items: ["Format"] },
			{ name: "colors", items: ["TextColor", "PasteText", "PasteFromWord", "CopyFormatting", "RemoveFormat"] },
			{ name: "insert", items: ["Image", "Table", "SpecialChar"] },
			{ name: "indentation", items: ["Outdent", "Indent"] },
			{ name: "clipboard", items: ["Undo", "Redo"] },
			{ name: "document", items: ["Source"] }
		],

		font_defaultLabel: "Montserrat",
		font_names: "Montserrat;Gotham SSm A;Gotham SSm B;Helvetica Neue;Calibri;Arial",

		// Remove some buttons provided by the standard plugins, which are
		// not needed in the Standard(s) toolbar.
		removeButtons: "Underline,Subscript,Superscript",

		// Set the most common block elements.
		format_tags: "p;h1;h2;h3;pre",

		// Simplify the dialog windows.
		removeDialogTabs: "image:advanced;link:advanced",

		extraPlugins: "uploadimage",

		imageUploadUrl: "this-is-set-below",

		// Disable input filtering
		allowedContent: true,
		height: 400,
		disableNativeSpellChecker: false
	};

	constructor() {
	}

	loadCkEditor() {
		if (!this.loading && !(window as any)["CKEDITOR"]) {
			this.loading = true;
			const url = "https://cdn.ckeditor.com/4.11.1/full/ckeditor.js";
			const script = document.createElement("script");
			script.onload = () => {
				this.loading = false;
				this.ckeditorLoaded.next(true);
			};
			script.type = "text/javascript";
			script.src = url;
			document.body.appendChild(script);
		}
	}
}
