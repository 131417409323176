import { ICustomerSummaryData } from "@app/dashboard/dashboard.models";
import { IError } from "@app/shared/shared.models";
import { Action } from "@ngrx/store";

export class LoadCustomerSummariesAttempt implements Action {
	readonly type = CustomerSummaryActionTypes.LoadCustomerSummariesAttempt;
	constructor() { }
}

export class LoadCustomerSummariesSuccess implements Action {
	readonly type = CustomerSummaryActionTypes.LoadCustomerSummariesSuccess;
	constructor(public payload: ICustomerSummaryData) { }
}

export class LoadCustomerSummariesError implements Action {
	readonly type = CustomerSummaryActionTypes.LoadCustomerSummariesError;
	constructor(public payload: IError) { }
}

export class UpdateCustomerSummarySubdealerFilter implements Action {
	readonly type = CustomerSummaryActionTypes.UpdateSubdealerFilter;
	constructor(public payload: string) { }
}

export class ClearCustomerSummaries implements Action {
	readonly type = CustomerSummaryActionTypes.ClearCustomerSummaries;
	constructor(public payload?: { accountId: string }) { }
}

export type CustomerSummaryActions =
	LoadCustomerSummariesAttempt
	| LoadCustomerSummariesSuccess
	| LoadCustomerSummariesError
	| UpdateCustomerSummarySubdealerFilter
	| ClearCustomerSummaries;
export enum CustomerSummaryActionTypes {
	LoadCustomerSummariesAttempt = "[Customer Summaries] Load Attempt",
	LoadCustomerSummariesSuccess = "[Customer Summaries] Load Success",
	LoadCustomerSummariesError = "[Customer Summaries] Load Error",
	UpdateSubdealerFilter = "[Customer Summaries] Update Filter",
	ClearCustomerSummaries = "[Customer Summaries] Clear",
}
