import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { IProductLightModel } from "@app/products/products.models";
import { ProductService } from "@app/services/product.service";
import { PagingInfo } from "@app/shared-elements/shared-elements.models";
import { Subject } from "rxjs";

@Component({
	selector: "c4-product-search",
	templateUrl: "./product-search.component.html",
	styleUrls: ["./product-search.component.scss"],
})
export class ProductSearchComponent implements OnInit, OnDestroy {
	@Input() title: string;
	@Input() isInCart = false;

	products: IProductLightModel[] = null;
	query: string = null;
	searching = false;
	pagingInfo: PagingInfo;

	private readonly destroyed$ = new Subject<{}>();

	constructor(private readonly router: Router, readonly productService: ProductService) { }

	ngOnInit() {
		this.pagingInfo = new PagingInfo(1, 0, 8);
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}

	searchProducts() {
		this.products = [];
		this.searching = true;

		this.productService.searchProducts(this.query, 50).subscribe(result => {
			this.searching = false;
			this.products = result || [];

			this.pagingInfo = PagingInfo.update(this.pagingInfo, { current: 1, total: this.products.length });
		});
	}

	getTypeaheadResults = (input: string) => {
		return this.productService.searchProducts(input, 10);
	}

	redirect(input: IProductLightModel) {
		this.router.navigate([input.url]);
	}

	itemSelected(input: IProductLightModel) {
		if (this.isInCart) {
			this.query = input.sku;
			this.searchProducts();
		} else {
			this.query = "";
			this.redirect(input);
		}
	}

	clear() {
		this.products = null;
		this.query = null;
	}
}
