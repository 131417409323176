import { IOrderHistoryItemDetailInfo } from "@app/account/account.models";
import { Action } from "@ngrx/store";

export class LoadOrderHistoryItemDetailsAttempt implements Action {
	readonly type = OrderHistoryDetailActionTypes.LoadOrderHistoryItemDetailsAttempt;
	constructor(public webOrderId: number) { }
}

export class LoadOrderHistoryItemDetailsSuccess implements Action {
	readonly type = OrderHistoryDetailActionTypes.LoadOrderHistoryItemDetailsSuccess;
	constructor(public payload: IOrderHistoryItemDetailInfo) { }
}

export class LoadOrderHistoryItemDetailsError implements Action {
	readonly type = OrderHistoryDetailActionTypes.LoadOrderHistoryItemDetailsError;
	constructor(public payload: string) { }
}

export type OrderHistoryDetailActions =
	LoadOrderHistoryItemDetailsAttempt
	| LoadOrderHistoryItemDetailsSuccess
	| LoadOrderHistoryItemDetailsError;
export enum OrderHistoryDetailActionTypes {
	LoadOrderHistoryItemDetailsAttempt = "[Order History Item Details Load] Attempt",
	LoadOrderHistoryItemDetailsSuccess = "[Order History Item Details Load] Success",
	LoadOrderHistoryItemDetailsError = "[Order History Item Details Load] Failure",
}
