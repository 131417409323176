import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { AuthService } from "@app/services/auth.service";
import { IAppState } from "@app/store";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";

/**
 * Handle legacy training sso redirects, preserving query params
 * @class TrainingRedirectGuard
 */
@Injectable()
export class TrainingRedirectGuard implements CanActivate, CanActivateChild {
	constructor(private store: Store<IAppState>, private authService: AuthService, private router: Router) { }

	canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree> {
		return this.isAuthenticated(route, state);
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree> {
		return this.isAuthenticated(route, state);
	}

	private isAuthenticated(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		return of(this.router.createUrlTree(["/training/sso"], { queryParams: route.queryParams }));
	}
}
