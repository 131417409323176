import { Component, ComponentRef, Input } from "@angular/core";
import { IPopupComponent } from "@app/popups/popups.models";
import { CloudinaryService } from "@app/services/cloudinary.service";
import { IMedia } from "@app/shared/shared.models";

@Component({
	selector: "c4-firmware-popup",
	templateUrl: "./firmware-popup.component.html",
	styleUrls: ["./firmware-popup.component.scss"],
})
export class FirmwarePopupComponent implements IPopupComponent {
	close: () => void;
	@Input() releaseNotes: string;
	@Input() firmware: IMedia[];
	@Input() hideHeader: boolean;
	self: ComponentRef<FirmwarePopupComponent>;

	constructor(private readonly cloudinaryService: CloudinaryService) { }

	getUrl(media: IMedia, transformations?: string) {
		return this.cloudinaryService.getUrl(media, transformations);
	}
}