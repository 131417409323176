import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "keycapText"
})
export class KeycapTextPipe implements PipeTransform {
	transform(value: string, max: number): string {
		const keycapSymbols = /[ÆÈÅÇ]/ig;
		const keycapCharacters = /[^A-z\d!@#$%^&*(){}|_+-=<>;~`ÆÈÅÇ\?\'\/\\ \u3400-\u9FBF]/ig;

		if (!value) { return value; }

		value = value.replace(keycapCharacters, "");
		let count = 0;
		let retVal = "";
		for (let i = 0; i < value.length; i++) {
			const ch = value.charAt(i);
			count += ch.match(keycapSymbols) ? 2 : 1;
			if (count <= max) {
				retVal += ch;
			}
			if (count >= max) {
				break;
			}
		}

		return retVal;
	}
}
