import { IUpgradeRequest, IUpgradeRequestFile } from "@app/account/account.models";
import { IError, SubmitStateType } from "@app/shared/shared.models";
import { Action } from "@ngrx/store";

export class LoadAccountUpgradeRequestFilesAttempt implements Action {
	readonly type = UpgradeRequestFileActionTypes.LoadAccountUpgradeRequestFilesAttempt;
	constructor(public accountId: string) { }
}

export class LoadAccountUpgradeRequestFilesSuccess implements Action {
	readonly type = UpgradeRequestFileActionTypes.LoadAccountUpgradeRequestFilesSuccess;
	constructor(public payload: LoadAccountUpgradeRequestFileResult) { }
}

export class LoadAccountUpgradeRequestFilesError implements Action {
	readonly type = UpgradeRequestFileActionTypes.LoadAccountUpgradeRequestFilesError;
	constructor(public payload: IError) { }
}

export class DeleteUpgradeRequestFileAttempt implements Action {
	readonly type = UpgradeRequestFileActionTypes.DeleteUpgradeRequestFileAttempt;
	constructor(public payload: IUpgradeRequestFile) { }
}

export class DeleteUpgradeRequestFileSuccess implements Action {
	readonly type = UpgradeRequestFileActionTypes.DeleteUpgradeRequestFileSuccess;
	constructor(public payload: IUpgradeRequestFile) { }
}

export class DeleteUpgradeRequestFileError implements Action {
	readonly type = UpgradeRequestFileActionTypes.DeleteUpgradeRequestFileError;
	constructor(public payload: IError) { }
}

export class SubmitUpgradeRequestAttempt implements Action {
	readonly type = UpgradeRequestFileActionTypes.SubmitUpgradeRequestAttempt;
	constructor(public payload: IUpgradeRequest) { }
}

export class SubmitUpgradeRequestSuccess implements Action {
	readonly type = UpgradeRequestFileActionTypes.SubmitUpgradeRequestSuccess;
	constructor(public payload: IUpgradeRequest) { }
}

export class SubmitUpgradeRequestError implements Action {
	readonly type = UpgradeRequestFileActionTypes.SubmitUpgradeRequestError;
	constructor(public payload: IError) { }
}

export class UpdateUpgradeRequestSubmitState implements Action {
	readonly type = UpgradeRequestFileActionTypes.UpdateSubmitState;
	constructor(public payload: SubmitStateType) { }
}


export interface LoadAccountUpgradeRequestFileResult {
	accountId: string;
	files: IUpgradeRequestFile[];
}

export type UpgradeRequestFileActions =
	LoadAccountUpgradeRequestFilesAttempt
	| LoadAccountUpgradeRequestFilesSuccess
	| LoadAccountUpgradeRequestFilesError
	| DeleteUpgradeRequestFileAttempt
	| DeleteUpgradeRequestFileSuccess
	| DeleteUpgradeRequestFileError
	| SubmitUpgradeRequestAttempt
	| SubmitUpgradeRequestSuccess
	| SubmitUpgradeRequestError
	| UpdateUpgradeRequestSubmitState;
export enum UpgradeRequestFileActionTypes {
	LoadAccountUpgradeRequestFilesAttempt = "[Account Upgrade Request Files Load] Attempt",
	LoadAccountUpgradeRequestFilesSuccess = "[Account Upgrade Request Files Load] Success",
	LoadAccountUpgradeRequestFilesError = "[Account Upgrade Request Files Load] Error",
	DeleteUpgradeRequestFileAttempt = "[Upgrade Request File Delete] Attempt",
	DeleteUpgradeRequestFileSuccess = "[Upgrade Request File Delete] Success",
	DeleteUpgradeRequestFileError = "[Upgrade Request File Delete] Error",
	SubmitUpgradeRequestAttempt = "[Upgrade Request Submit] Attempt",
	SubmitUpgradeRequestSuccess = "[Upgrade Request Submit] Success",
	SubmitUpgradeRequestError = "[Upgrade Request Submit] Error",
	UpdateSubmitState = "[Upgrade Request Submit State] Update",
}
