import { ICreditCardType } from "@app/shared/payment.models";
import { IError } from "@app/shared/shared.models";
import { Action } from "@ngrx/store";

export class LoadAllCreditCardTypesAttempt implements Action {
	readonly type = CreditCardTypeActionTypes.LoadAllAttempt;
	constructor() { }
}

export class LoadAllCreditCardTypesSuccess implements Action {
	readonly type = CreditCardTypeActionTypes.LoadAllSuccess;
	constructor(public payload: ICreditCardType[]) { }
}

export class LoadAllCreditCardTypesError implements Action {
	readonly type = CreditCardTypeActionTypes.LoadAllError;
	constructor(public payload: IError) { }
}

export type CreditCardTypeActions =
	LoadAllCreditCardTypesAttempt
	| LoadAllCreditCardTypesSuccess
	| LoadAllCreditCardTypesError;
export enum CreditCardTypeActionTypes {
	LoadAllAttempt = "[All Credit Card Types Load] Attempt",
	LoadAllSuccess = "[All Credit Card Types Load] Success",
	LoadAllError = "[All Credit Card Types Load] Error",
}
