import { FilterGroup } from "@app/cms/cms.models";
import { Action } from "@ngrx/store/src";

export class ToggleAllProductFilters implements Action {
	readonly type = ProductFilterActionType.ToggleAllProductFilters;
	constructor(public payload: { open: boolean }) { }
}

export class SaveProductFilterGroupSuccess implements Action {
	readonly type = ProductFilterActionType.SaveProductFilterGroupSuccess;
	constructor(public payload: FilterGroup) { }
}

export type ProductFilterActions =
	ToggleAllProductFilters
	| SaveProductFilterGroupSuccess;

export enum ProductFilterActionType {
	ToggleAllProductFilters = "[Product Filter] Toggle All",
	SaveProductFilterGroupSuccess = "[Product Filter] Save Filter Group",
}