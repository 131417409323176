import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IDriversResult, IFilterGroupModel } from "@app/shared/shared.models";

@Injectable({
	providedIn: "root"
})
export class DriverService {

	constructor(readonly http: HttpClient) { }

	getMostRecentDrivers(perPage: number) {
		return this.http.get<IDriversResult>("api/driver/getLatestDrivers", { params: { perPage: perPage.toString() } });
	}

	getDrivers(filterGroups: IFilterGroupModel[], search: string, startPage: number, perPage: number) {
		return this.http.post<IDriversResult>("api/driver/getDrivers", { filterGroups, search, startPage, perPage });
	}

	getDriverFilters() {
		return this.http.get<IFilterGroupModel[]>("api/driver/getFilters");
	}
}