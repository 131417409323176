import { Component, OnDestroy, OnInit } from "@angular/core";
import { IUser, IUserCertification } from "@app/account/account.models";
import { UserService } from "@app/services/user.service";
import { IAppState } from "@app/store";
import { getIsControl4 } from "@app/store/app-info/app-info.selectors";
import { selectCurrentUser } from "@app/store/user";
import { getCurrentUserCertifications, LoadUserCertificationsAttempt } from "@app/store/user-certifications";
import { CertificationType } from "@app/training/training.models";
import { select, Store } from "@ngrx/store";
import { combineLatest, Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: "c4-user-certifications",
	templateUrl: "./user-certifications.component.html",
	styleUrls: ["./user-certifications.component.scss"],
})
export class UserCertificationsComponent implements OnInit, OnDestroy {
	user: IUser;
	loaded: boolean;
	isControl4: Observable<boolean>;
	pcnaCert: IUserCertification;
	associateInstallerCert: IUserCertification;
	automationProgrammerCert: IUserCertification;
	centralizedLightingCert2012: IUserCertification;
	centralizedLightingCert2019: IUserCertification;
	showCentralizedLightingCert2012Badge: boolean;

	destroyed$ = new Subject<{}>();

	constructor(readonly store: Store<IAppState>, readonly userService: UserService) { }

	ngOnInit() {
		this.isControl4 = this.store.pipe(select(getIsControl4));

		combineLatest([
			this.store.pipe(select(selectCurrentUser)),
			this.store.pipe(select(getCurrentUserCertifications)),
		]).pipe(
			takeUntil(this.destroyed$),
		).subscribe(([user, certs]) => {
			this.user = user;

			if (certs) {
				this.pcnaCert = this.userService.getCertificate(certs, CertificationType.Pcna);
				this.associateInstallerCert = this.userService.getCertificate(certs, CertificationType.AssociateInstaller);
				this.automationProgrammerCert = this.userService.getCertificate(certs, CertificationType.AutomationProgrammer);
				this.centralizedLightingCert2012 = this.userService.getCertificate(certs, CertificationType.CentralizedLighting2012);
				this.centralizedLightingCert2019 = this.userService.getCertificate(certs, CertificationType.CentralizedLighting2019);
				this.showCentralizedLightingCert2012Badge = (this.centralizedLightingCert2012
					&& this.centralizedLightingCert2012.completionDate
					&& (!this.centralizedLightingCert2019 || !this.centralizedLightingCert2019.completionDate));
				this.loaded = true;
			} else if (this.user) {
				this.store.dispatch(new LoadUserCertificationsAttempt(this.user.userId));
			}
		});
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}
}
