import { ITriadDiscountInfo } from "@app/shared-widgets/shared-widgets.models";
import { ngStoreCacheItem } from "@app/shared/helpers";
import { EntityState } from "@ngrx/entity";
import { addDays, differenceInHours, differenceInMinutes } from "date-fns";
import { TriadDiscountActions, TriadDiscountActionTypes } from "./triad-discount.actions";
import { triadDiscountAdapter } from "./triad-discount.adapter";

export interface ITriadDiscountState extends EntityState<ITriadDiscountInfo> { }

export const initialState: ITriadDiscountState = triadDiscountAdapter.getInitialState();

export function triadDiscountReducer(
	state = initialState,
	action: TriadDiscountActions
): ITriadDiscountState {
	switch (action.type) {
		case TriadDiscountActionTypes.LoadTriadDiscountSuccess:
			let item: ITriadDiscountInfo;
			const staleDate = addDays(action.payload.lastUpdatedDate, 1);
			if (differenceInHours(staleDate, new Date()) > 6) {
				item = ngStoreCacheItem(action.payload, 6, "hours");
			} else {
				// If we are closing in on the 24 hour refresh period reduce the cache window
				const minutes = Math.max(differenceInMinutes(staleDate, new Date()), 20);
				item = ngStoreCacheItem(action.payload, minutes, "minutes");
			}
			return triadDiscountAdapter.upsertOne(item, state);
		default:
			return state;
	}
}