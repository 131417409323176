import { Component, ComponentRef, OnInit } from "@angular/core";
import { IPopupComponent } from "@app/popups/popups.models";

@Component({
	selector: "c4-mobile-phone-usage-popup",
	templateUrl: "./mobile-phone-usage-popup.component.html",
	styleUrls: ["./mobile-phone-usage-popup.component.scss"]
})
export class MobilePhoneUsagePopupComponent implements OnInit, IPopupComponent {
	close: () => void;
	self: ComponentRef<MobilePhoneUsagePopupComponent>;

	constructor() { }

	ngOnInit() {
	}
}
