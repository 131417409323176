import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AccountService } from "@app/services/account.service";
import { getNgStoreCacheItem } from "@app/shared/helpers";
import { AccountActionTypes, LoadAccountAttempt, LoadAccountError, LoadAccountSuccess } from "@app/store/account/account.actions";
import { AuthActionType, ImpersonateSuccess } from "@app/store/auth";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action, select, Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, debounceTime, filter, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { accountsSelectEntities } from "../app-info";
import { IAppState } from "../app.reducer";

@Injectable()
export class AccountEffects {
	constructor(
		private actions$: Actions,
		private accountService: AccountService,
		private store: Store<IAppState>
	) { }

	@Effect()
	loadAccount$: Observable<Action> = this.actions$.pipe(
		ofType<LoadAccountAttempt>(AccountActionTypes.LoadAccountAttempt),
		debounceTime(300),
		withLatestFrom(this.store.pipe(select(accountsSelectEntities))),
		filter(([action, entities]) => !getNgStoreCacheItem(entities[action.accountId])),
		mergeMap(([action, _]) => {
			return this.accountService
				.get(action.accountId)
				.pipe(
					map((info) => new LoadAccountSuccess(info)),
					catchError((err: HttpErrorResponse) => of(new LoadAccountError({ error: err.message || err.statusText })))
				);
		})
	);

	@Effect()
	getAuthenticatedAccount$: Observable<Action> = this.actions$.pipe(
		ofType<ImpersonateSuccess>(AuthActionType.ImpersonateSuccess),
		map((action) => new LoadAccountAttempt(action.payload.accountId))
	);
}
