import { Pipe, PipeTransform } from "@angular/core";
import { CertificationType, ITechnicianCertfication } from "@app/training/training.models";

@Pipe({
	name: "latestCert"
})
export class LatestCertPipe implements PipeTransform {
	transform(certs: ITechnicianCertfication[], type: CertificationType): ITechnicianCertfication {
		return certs.find(cert => cert.certificationType === type);
	}
}
