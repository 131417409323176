import { Pipe, PipeTransform } from "@angular/core";
import { IMedia, MediaType } from "@app/shared/shared.models";

@Pipe({
	name: "primaryImage"
})
export class PrimaryImagePipe implements PipeTransform {

	transform(medias: IMedia[], defaultUrl?: string): IMedia {
		let primary: IMedia;
		if (medias) {
			primary = medias.find(m => m.mediaTypeId === MediaType.Image);
		}

		return primary || { url: defaultUrl, mediaTypeId: MediaType.Image } as IMedia; // Default to an empty object so that it will be treated like a non-existent media object
	}

}
