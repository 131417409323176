import { INavMenuItem } from "@app/nav/nav.models";
import { IError } from "@app/shared/shared.models";
import { Action } from "@ngrx/store";

export class NavLoadSuccess implements Action {
	readonly type = NavActionTypes.LoadSuccess;
	constructor(public payload: INavMenuItem[]) { }
}

export class NavLoadError implements Action {
	readonly type = NavActionTypes.LoadError;
	constructor(public payload: IError) { }
}

export class NavForward implements Action {
	readonly type = NavActionTypes.Forward;
	constructor(public menuId: number) { }
}

export class NavBack implements Action {
	readonly type = NavActionTypes.Back;
}
export class NavBackToTopMenu implements Action {
	readonly type = NavActionTypes.BackToTopMenu;
	constructor(public menuId: number) { }
}

export type NavActions =
	| NavLoadSuccess
	| NavLoadError
	| NavForward
	| NavBack
	| NavBackToTopMenu;

export enum NavActionTypes {
	LoadAttempt = "[Nav Load] Attempt",
	LoadSuccess = "[Nav Load] Success",
	LoadError = "[Nav Load] Error",
	Forward = "[Nav] Forward",
	Back = "[Nav] Back",
	BackToTopMenu = "[Nav] Back To Top Menu",
}