import { ISubDealerLocatorModel } from "@app/account/account.models";
import { ngStoreCacheItem, objectUpdate } from "@app/shared/helpers";
import { SubmitStateType } from "@app/shared/shared.models";
import { DealerLocatorActions, DealerLocatorActionTypes } from "@app/store/dealer-locator/dealer-locator.actions";
import { dealerLocatorAdapter } from "@app/store/dealer-locator/dealer-locator.adapter";
import { EntityState } from "@ngrx/entity";

export interface IDealerLocatorState extends EntityState<ISubDealerLocatorModel> {
	submitState: SubmitStateType;
}

export const initialState: IDealerLocatorState = dealerLocatorAdapter.getInitialState({
	submitState: SubmitStateType.Ready
});

export function dealerLocatorReducer(
	state = initialState,
	action: DealerLocatorActions
): IDealerLocatorState {
	switch (action.type) {
		case DealerLocatorActionTypes.LoadDealerLocatorsSuccess:
			return dealerLocatorAdapter.upsertMany(action.payload.dealerLocators.map(location => ngStoreCacheItem(location, 6, "hours")), state);
		case DealerLocatorActionTypes.ResetSubmitState:
			return objectUpdate(state, { submitState: SubmitStateType.Ready });
		case DealerLocatorActionTypes.SaveDealerLocatorAttempt:
			return objectUpdate(state, { submitState: SubmitStateType.Submitting });
		case DealerLocatorActionTypes.SaveDealerLocatorError:
			return objectUpdate(state, { submitState: SubmitStateType.Error });
		case DealerLocatorActionTypes.SaveDealerLocatorSuccess:
			const saveSuccessState = objectUpdate(state, { submitState: SubmitStateType.Success });
			return dealerLocatorAdapter.updateOne({ id: action.payload.subDealerId, changes: action.payload }, saveSuccessState);
		default: {
			return state;
		}
	}
}