import { IAccountSalesInfo } from "@app/account/account.models";
import { IError } from "@app/shared/shared.models";
import { Action } from "@ngrx/store";

export class LoadSalesInfoAttempt implements Action {
	readonly type = SalesInfoActionTypes.LoadSalesInfoAttempt;
	constructor() { }
}

export class LoadSalesInfoSuccess implements Action {
	readonly type = SalesInfoActionTypes.LoadSalesInfoSuccess;
	constructor(public payload: IAccountSalesInfo) { }
}

export class LoadSalesInfoError implements Action {
	readonly type = SalesInfoActionTypes.LoadSalesInfoError;
	constructor(public payload: IError) { }
}

export type SalesInfoActions =
	LoadSalesInfoAttempt
	| LoadSalesInfoSuccess
	| LoadSalesInfoError;

export enum SalesInfoActionTypes {
	LoadSalesInfoAttempt = "[Sales Info Load] Attempt",
	LoadSalesInfoSuccess = "[Sales Info Load] Success",
	LoadSalesInfoError = "[Sales Info Load] Error",
}
