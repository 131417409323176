import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IStaticContent } from "@app/cms/cms.models";
import { IAppState } from "@app/store";
import { Redirect } from "@app/store/router";
import { Store } from "@ngrx/store";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class StaticContentService {
	constructor(private http: HttpClient, private store: Store<IAppState>) { }

	getStaticPage(friendlyUrl: string, forceRefresh: boolean = false) {
		return this.http.get<IStaticContent>("api/content/getPageContent", { params: { friendlyUrl, v: forceRefresh ? new Date().getTime().toString() : "" } })
			.pipe(
				catchError(err => {
					this.store.dispatch(new Redirect({ path: ["/404"], skipLocationChange: true }));
					return throwError(err);
				}),
			);
	}

	getStaticContent(pageCode: string) {
		return this.http.get<IStaticContent>("api/content/GetContentSection", { params: { pageCode } });
	}
}
