export const environment = {
	production: true,
	dev: false,
	consoleLogAnalytics: false,
	isEmea: true,
	apiUrl: "https://dealer-emea-api-stage.control4.com/",
	secondaryApiUrl: "https://dealer-api-stage.control4.com/",
	secondaryPortalUrl: "https://dealer-stage.control4.com",
	technicianPortalUrl: "https://c4community.force.com/technician/s/",
	postForwarderUrl: "https://www-stage.control4.com/assets/scripts/post-forwarder.php",
	trackingCode: "UA-69905-1",
};
