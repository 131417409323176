import { Pipe, PipeTransform } from '@angular/core';
import { IPaymentInformation } from '@app/print/print.models';

@Pipe({
	name: 'paymentMethod'
})
export class PaymentMethodPipe implements PipeTransform {
	transform(payment: IPaymentInformation): any {
		const id = payment && payment.id;
		switch (id) {
			case 11:
				return "Wire Transfer";
			case 6:
				return "On Account";
			default:
				return "Credit Card";
		}
	}
}
