import { Directive, ElementRef, HostListener, OnDestroy, OnInit } from "@angular/core";
import { ConfirmPopupComponent } from "@app/popups/confirm-popup/confirm-popup.component";
import { DocumentationService } from "@app/services/documentation.service";
import { PopupService } from "@app/services/popup.service";
import { TrackService } from "@app/services/track.service";
import { TrainingService } from "@app/services/training.service";
import { contains, endsWithIgnoreCase, startsWithIgnoreCase } from "@app/shared/helpers";
import { Portals } from "@app/shared/shared.models";
import { IAppState } from "@app/store";
import { getCurrentPortal } from "@app/store/app-info/app-info.selectors";
import { InfoToast } from "@app/store/toast/toast.actions";
import { selectActiveUser } from "@app/store/user";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Directive({
	// tslint:disable-next-line:directive-selector
	selector: "a:not([routerLink])",
})
export class ExternalLinkDirective implements OnInit, OnDestroy {
	private token: string;
	private portal: Portals;

	private destroyed$ = new Subject<{}>();

	private filetypes = /\.(zip|exe|pdf|doc*|xls*|ppt*|mp3)$/i;
	private acceptedDomains = [
		".control4.com",
		".pakedge.com",
		".pkdge.co",
		".triadspeakers.com",
		"ctrl4.co",
		".4store.com",
		".learnupon.com",
		"snapav.com",
		"control4.wistia.com",
	];

	constructor(
		private element: ElementRef,
		private popupService: PopupService,
		private docService: DocumentationService,
		private trainingService: TrainingService,
		private store: Store<IAppState>,
		private tracking: TrackService) { }

	ngOnInit() {
		this.store.pipe(select(selectActiveUser), takeUntil(this.destroyed$)).subscribe(user => { if (user) { this.token = user.token; } });
		this.store.pipe(select(getCurrentPortal), takeUntil(this.destroyed$)).subscribe(portal => { if (portal) { this.portal = portal; } });
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}

	@HostListener("click")
	onClick() {
		const url: string = this.element.nativeElement.href;
		if (url && !startsWithIgnoreCase(url, "javascript")) {
			const matches = url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
			const domain = matches && matches[1];
			this.dataTracking(url);

			// WWW Redirect
			if ((url.includes("control4.com/docs") && !url.includes("internal-logo")) || url.includes("control4.com/help") || url.includes("pakedge.com/stashes") || url.includes("control4.com/stashes") || url.includes("snapav.com")) {
				this.docService.viewDocument(url, this.token);
				return false;
			}

			// Training Redirect
			if (url.includes("learnupon.com") || url.includes("training.") || url.includes("education.")) {
				if (url.includes("catalog/courses/")) {
					this.store.dispatch(new InfoToast({ message: "Please wait while we sign you into the education portal." }));
					const courseId = url.substr(url.lastIndexOf("/") + 1);
					this.trainingService.getCourseEnrollmentUrl(courseId).subscribe(result => {
						if (result) {
							window.open(this.trainingService.transformTrainingUrl(result.ssoUrl, this.portal), "_blank");
						} else {
							window.open(this.trainingService.transformTrainingUrl(url, this.portal), "_blank");
						}
					});
					return false;
				} else if (url.includes("catalog/learning-paths/")) {
					this.store.dispatch(new InfoToast({ message: "Please wait while we sign you into the education portal." }));
					const pathId = url.substr(url.lastIndexOf("/") + 1);
					this.trainingService.getLearningPathEnrollmentUrl(pathId).subscribe(result => {
						if (result) {
							window.open(this.trainingService.transformTrainingUrl(result.ssoUrl, this.portal), "_blank");
						} else {
							window.open(this.trainingService.transformTrainingUrl(url, this.portal), "_blank");
						}
					});
					return false;
				}

				window.open(this.trainingService.transformTrainingUrl(url, this.portal), "_blank");
				return false;
			}

			// External Link
			const ignore = this.element.nativeElement.classList.contains("overridelegal")
				|| this.ignoreDomain(domain)
				|| (this.isDownload(url) && !endsWithIgnoreCase(url, ".pdf"));
			if ((url.startsWith("http") || url.startsWith("//")) && !ignore) {
				if (!this.isCloudinary(url)) {
					const confirm = this.popupService.create<ConfirmPopupComponent>(ConfirmPopupComponent, {
						headerColor: "white",
						title: `External Redirect`,
						body: `This link, ${url}, will take you away from the Control4 website. Would like to continue?`,
						confirmText: "Continue",
					});
					confirm.instance.answer.subscribe(confirmed => {
						if (confirmed) {
							window.open(url, "_blank");
						}
					});
					return false;
				} else {
					this.element.nativeElement.target = "_blank";
				}
			}
		}
		return true;
	}

	private ignoreDomain(domain: string) {
		const currentDomain = window.location.host === domain;
		return currentDomain || !!this.acceptedDomains.find(x => !!domain.match(x));
	}

	private dataTracking(href: string) {
		let action = "Click";
		let category = "External Link";
		const label = href.replace(/^mailto\:/i, "");  // Remove mailto before pushing to Google
		if (this.isDownload(href)) {
			const extension = (/[.]/.exec(href)) ? /[^.]+$/.exec(href) : undefined;
			action = "Click-" + extension;
			category = "Download";
		} else if (href.match(/^mailto\:/i)) {
			category = "Email";
		} else if (href.match(/(.*)\/documentation(.*)/i)) {
			category = "Online Documentation";
		} else if (href.match(/\/docs(.*)\/(latest|revision)/i)) {
			category = "Doc Repository";
		}

		this.tracking.trackEvent(action, category, label);
	}

	private isDownload(href: string) {
		return href.match(this.filetypes);
	}

	private isCloudinary(href: string) {
		return contains(href, "res.cloudinary.com/control4") || contains(href, "api.cloudinary.com/v1_1/control4");
	}
}
