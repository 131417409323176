import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CmsFirmware, CmsFirmwareSku, CmsFirmwareVersion } from "@app/cms/cms-firmware/cms-firmware.models";
import { ProductFirmware } from "@app/products/products.models";
import { IGenericResponse } from "@app/shared/shared.models";
import { IFirmwareProduct } from "@app/support/support.models";

@Injectable({
	providedIn: "root",
})
export class FirmwareService {

	constructor(private readonly http: HttpClient) {
	}

	getPartsWithLatestFirmware() {
		return this.http.get<IFirmwareProduct[]>("api/products/GetProductsWithLatestFirmware");
	}

	getPartsWithFirmware() {
		return this.http.get<IFirmwareProduct[]>("api/products/GetProductsWithFirmware");
	}

	getAllFirmware() {
		return this.http.get<ProductFirmware[]>("api/products/firmware");
	}

	getLatestFirmware(numberOfResults: number) {
		return this.http.get<ProductFirmware[]>(`api/products/firmware/latest/${numberOfResults}`);
	}

	getCmsFirmware() {
		return this.http.get<CmsFirmware[]>("api/cms/firmware");
	}

	saveFirmware(firmware: CmsFirmware) {
		return this.http.post<IGenericResponse<CmsFirmware>>("api/cms/firmware", firmware);
	}

	removeFirmware(id: number) {
		return this.http.delete<IGenericResponse>(`api/cms/firmware/${id}`);
	}

	saveFirmwareVersion(version: CmsFirmwareVersion) {
		return this.http.post<IGenericResponse<CmsFirmwareVersion>>(`api/cms/firmware/${version.firmwareId}/versions`, version);
	}

	removeFirmwareVersion(version: CmsFirmwareVersion) {
		return this.http.delete<IGenericResponse>(`api/cms/firmware/${version.firmwareId}/versions/${version.id}`);
	}

	addFirmwareProduct(firmwareId: number, sku: string) {
		return this.http.post<IGenericResponse<CmsFirmwareSku>>(`api/cms/firmware/${firmwareId}/products`, { sku });
	}

	removeFirmwareProduct(product: CmsFirmwareSku) {
		return this.http.delete<IGenericResponse>(`api/cms/firmware/${product.firmwareId}/products/${product.id}`);
	}
}
