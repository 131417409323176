import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "c4-goal-progress",
	templateUrl: "./goal-progress.component.html",
	styleUrls: ["./goal-progress.component.scss"],
})
export class GoalProgressComponent implements OnInit {
	@Input() earned: string;
	@Input() nextMilestone: string;
	@Input() currency: string;
	@Input() precision: number;

	@Input() min: number;
	@Input() minLabel: string;
	@Input() max: number;
	@Input() maxLabel: string;
	@Input() atMax: boolean;
	@Input() percent: number;
	@Input() label: string;
	@Input() color: string;

	headerColor: string;
	progressBarColor: string;
	showCheck: boolean;

	constructor() { }

	ngOnInit() {
		this.populateColors();
	}

	private populateColors() {
		switch (this.color) {
			case "normal":
				this.headerColor = "text-dark";
				this.progressBarColor = "grey";
				this.showCheck = false;
				break;
			case "warning":
				this.headerColor = "text-warning";
				this.progressBarColor = "warning";
				this.showCheck = false;
				break;
			case "success":
				this.headerColor = "text-success";
				this.progressBarColor = "success";
				this.showCheck = true;
				break;
		}
	}

}
