import { Component, Input, OnInit } from "@angular/core";
import { IUser, IUserCertification } from "@app/account/account.models";
import { UserFullNamePipe } from "@app/pipes/fullname.pipe";
import { UserService } from "@app/services/user.service";
import { IAppState } from "@app/store";
import { CertificationType } from "@app/training/training.models";
import { Store } from "@ngrx/store";

@Component({
	selector: "c4-certification-badge",
	templateUrl: "./certification-badge.component.html",
	styleUrls: ["./certification-badge.component.scss"],
})
export class CertificationBadgeComponent implements OnInit {
	private _certificate: IUserCertification;
	@Input() set certificate(val: IUserCertification) {
		this._certificate = val;
		this.certified = !!(val && val.completionDate);
		if (val) {
			this.populateCertificateInfo();
		}
	}
	get certificate() {
		return this._certificate;
	}
	@Input() user: IUser;
	title: string;
	learnMore: string;
	url: string;

	certified: boolean;

	constructor(readonly store: Store<IAppState>, readonly userService: UserService) { }

	ngOnInit() {
	}

	private populateCertificateInfo() {
		switch (this.certificate.certificationType) {
			case CertificationType.Pcna:
				this.title = "Professional Certified Network Administrator";
				this.learnMore = "/education/pcna";
				break;
			case CertificationType.CentralizedLighting2012:
				this.title = "Centralized Lighting 2012";
				this.learnMore = undefined;
				break;
			case CertificationType.CentralizedLighting2019:
				this.title = "Centralized Lighting 2019";
				this.learnMore = undefined;
				break;
			case CertificationType.AutomationProgrammer:
				this.title = "Automation Programmer";
				this.learnMore = "/education/certification#course2";
				break;
			case CertificationType.AssociateInstaller:
				this.title = "Associate Installer";
				this.learnMore = "/education/certification#course1";
				break;
		}

		this.url = CertificationType.getImageUrl(this.certificate.certificationType, "c_scale,h_125", this.certified);
	}

	getCertPdf() {
		this.userService.downloadCert(this.certificate, new UserFullNamePipe().transform(this.user));
	}
}
