import { getNgStoreCacheItem } from "@app/shared/helpers";
import { IAddress } from "@app/shared/shared.models";
import { getCurrentAccount, getCurrentIsEmea, getIsNonEmeaInternational } from "@app/store/account";
import { addressAdapter } from "@app/store/address/address.adapter";
import { IAddressState } from "@app/store/address/address.reducer";
import { Dictionary } from "@ngrx/entity";
import { createFeatureSelector, createSelector } from "@ngrx/store";

export const getAddressState = createFeatureSelector<IAddressState>("addresses");

export const {
	selectIds: addressesSelectIds,
	selectEntities: addressesSelectEntities,
	selectAll: addressesSelectAll,
	selectTotal: addressesSelectTotal,
} = addressAdapter.getSelectors(getAddressState);

export const getCurrentDefaultBillingAddressId = createSelector(
	getCurrentAccount, account => account ? account.defaultBillingAddressId : undefined,
);

export const getDefaultBillingAddress = createSelector(
	addressesSelectEntities,
	getCurrentDefaultBillingAddressId,
	(entities, id) => id ? getNgStoreCacheItem(entities[id]) : undefined,
);

export const getAddress = (id: number) => createSelector(
	addressesSelectEntities, entities => id ? getNgStoreCacheItem(entities[id]) : undefined,
);

const getAccountAddresses = function (ids: number[], entities: Dictionary<IAddress>) {
	if (!ids) {
		return undefined;
	}
	const addresses: IAddress[] = [];

	for (let i = 0; i < ids.length; i++) {
		const id = ids[i];
		const address = getNgStoreCacheItem(entities[id]);
		// If we do not have all the addresses, then we need to reload
		if (!address) {
			return undefined;
		}
		addresses.push(address);
	}

	return addresses;
};

export const getCurrentBillingAddressIds = createSelector(
	getCurrentAccount, account => account && account.billingAddressIds,
);

export const getCurrentBillingAddresses = createSelector(
	addressesSelectEntities,
	getCurrentBillingAddressIds,
	(entities, ids) => getAccountAddresses(ids, entities),
);

export const getCurrentShippingAddressIds = createSelector(
	getCurrentAccount, account => account && account.shippingAddressIds,
);

export const getCurrentShippingAddresses = createSelector(
	addressesSelectEntities,
	getCurrentShippingAddressIds,
	(entities, ids) => getAccountAddresses(ids, entities),
);

export const getCurrentFinalDestinationAddressIds = createSelector(
	getCurrentAccount, account => account && account.finalDestinationAddressIds,
);

export const getCurrentFinalDestinationAddresses = createSelector(
	addressesSelectEntities,
	getCurrentFinalDestinationAddressIds,
	(entities, ids) => getAccountAddresses(ids, entities),
);

export const getDefaultShippingAddress = createSelector(
	addressesSelectEntities,
	getCurrentAccount,
	(entities, account) => account ? getNgStoreCacheItem(entities[account.defaultShippingAddressId]) : undefined,
);

export const getIsEmeaInternational = createSelector(
	getCurrentIsEmea,
	getDefaultBillingAddress,
	(emea, billing) => emea && billing && (billing.countryAbbr || billing.country) !== "GB",
);

export const getIsGermanDealer = createSelector(
	getDefaultBillingAddress, billing => billing && (billing.countryAbbr || billing.country) === "DE",
);

export const getShowFinalDestination = createSelector(
	getIsNonEmeaInternational,
	getDefaultBillingAddress,
	(nonEmeaIntl, billing) => nonEmeaIntl && billing && billing.stateProvince !== "PR",
);