import { Pipe, PipeTransform } from "@angular/core";
import { distanceInWordsToNow } from "date-fns";

@Pipe({
	name: "fromNow"
})
export class FromNowPipe implements PipeTransform {
	transform(date: string): string {
		return distanceInWordsToNow(date) + " ago";
	}
}
