import { IError, Settings, SiteFeatureFlag } from "@app/shared/shared.models";
import { Action } from "@ngrx/store";

export enum AppInfoActionTypes {
	SetScreenWidth = "[App Info] Set Screen Width",
	LoadFeatureFlagsAttempt = "[App Info] Load Feature Flags Attempt",
	LoadFeatureFlagsSuccess = "[App Info] Load Feature Flags Success",
	LoadFeatureFlagsError = "[App Info] Load Feature Flags Error",
	LoadSettingsSuccess = "[App Info] Load Settings Success",
}


export class SetScreenWidth implements Action {
	readonly type = AppInfoActionTypes.SetScreenWidth;
	constructor(public width: number) { }
}

export class LoadFeatureFlagsAttempt implements Action {
	readonly type = AppInfoActionTypes.LoadFeatureFlagsAttempt;
	constructor() { }
}

export class LoadFeatureFlagsSuccess implements Action {
	readonly type = AppInfoActionTypes.LoadFeatureFlagsSuccess;
	constructor(public payload: SiteFeatureFlag[]) { }
}

export class LoadFeatureFlagsError implements Action {
	readonly type = AppInfoActionTypes.LoadFeatureFlagsError;
	constructor(public payload: IError) { }
}

export class LoadSettingsSuccess implements Action {
	readonly type = AppInfoActionTypes.LoadSettingsSuccess;
	constructor(public payload: Settings) { }
}

export type AppInfoActions =
	SetScreenWidth
	| LoadFeatureFlagsAttempt
	| LoadFeatureFlagsSuccess
	| LoadFeatureFlagsError
	| LoadSettingsSuccess;