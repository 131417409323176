import { ICustomerLicenseSummary } from "@app/dashboard/dashboard.models";
import { deepcopy, ngStoreCacheItem, objectUpdate } from "@app/shared/helpers";
import { IValueCache } from "@app/shared/shared.models";
import { Dictionary, EntityState } from "@ngrx/entity";
import { CustomerLicenseSummaryActions, CustomerLicenseSummaryActionTypes } from "./customer-license-summary.actions";
import { customerLicenseSummariesAdapter } from "./customer-license-summary.adapter";

export interface ICustomerLicenseSummariesState extends EntityState<ICustomerLicenseSummary> {
	refreshDates: Dictionary<IValueCache<Date>>;
	subdealerFilter: string;
}

export const initialState: ICustomerLicenseSummariesState = customerLicenseSummariesAdapter.getInitialState({
	refreshDates: {},
	subdealerFilter: undefined
});

export function customerLicenseSummariesReducer(
	state = initialState,
	action: CustomerLicenseSummaryActions
): ICustomerLicenseSummariesState {
	switch (action.type) {
		case CustomerLicenseSummaryActionTypes.LoadCustomerLicenseSummariesSuccess:
			const refreshDates = deepcopy(state.refreshDates);
			refreshDates[action.payload.accountId] = ngStoreCacheItem({ item: action.payload.refreshDate } as IValueCache<Date>, 6, "hours");
			const newState = objectUpdate(state, { refreshDates });
			return customerLicenseSummariesAdapter.upsertMany(action.payload.licenses, newState);
		case CustomerLicenseSummaryActionTypes.UpdateCustomerLicenseSummarySubdealerFilter:
			return objectUpdate(state, { subdealerFilter: action.payload });
		default:
			return state;
	}
}