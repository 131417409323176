import { Component, OnInit } from "@angular/core";
import { UserService } from "@app/services/user.service";
import { IAppState } from "@app/store";
import { ErrorToast, SuccessToast } from "@app/store/toast/toast.actions";
import { Store } from "@ngrx/store";

@Component({
	selector: "c4-forgot-password",
	templateUrl: "./forgot-password.component.html",
	styleUrls: ["./forgot-password.component.scss"]
})
export class ForgotPasswordComponent implements OnInit {
	username: string = null;
	error: string = null;
	message: string = null;
	isSubmitted = false;

	constructor(readonly userService: UserService, readonly store: Store<IAppState>) { }

	ngOnInit() {
	}

	resetPassword(): void {
		if (!this.username) {
			return;
		}

		this.error = null;
		this.message = null;
		this.isSubmitted = true;

		this.userService.requestPasswordReset(this.username, true).subscribe((result) => {
			this.username = null;
			this.isSubmitted = false;

			if (!result.errorMessage) {
				this.store.dispatch(new SuccessToast({ message: "Your password reset request has been submitted. A link will be emailed to you to complete the password reset.", ttl: 7000 }));
			} else {
				this.store.dispatch(new ErrorToast({ message: result.errorMessage, ttl: 10000 }));
			}
		});
	}
}
