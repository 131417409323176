import { Injectable } from "@angular/core";
import { CanActivate, CanActivateChild, Router, UrlTree } from "@angular/router";
import { IAppState } from "@app/store";
import { selectIsNotMsrp } from "@app/store/auth";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

/**
 * Prevent unauthorized activating and loading of routes
 * @class MsrpGuard
 */
@Injectable()
export class MsrpGuard implements CanActivate, CanActivateChild {
	constructor(private store: Store<IAppState>, readonly router: Router) { }

	canActivate(): Observable<boolean | UrlTree> {
		// Only let user through if they are not msrp
		return this.handleActivation();
	}

	canActivateChild(): Observable<boolean | UrlTree> {
		// Only let user through if they are not msrp
		return this.handleActivation();
	}

	handleActivation() {
		return this.store.pipe(
			select(selectIsNotMsrp),
			map((x) => {
				if (!x) {
					return this.router.parseUrl("/401");
				}

				return true;
			})
		);
	}
}
