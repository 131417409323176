import { Account } from "@app/account/account.models";
import { ngStoreCacheItem, objectUpdate, removeFromArray } from "@app/shared/helpers";
import { AccountActions, AccountActionTypes } from "@app/store/account/account.actions";
import { accountAdapter } from "@app/store/account/account.adapter";
import { AddressActions, AddressActionTypes } from "@app/store/address";
import { EntityState } from "@ngrx/entity";
import { AuthActions, AuthActionType } from "../auth";

export interface AccountState extends EntityState<Account> {
	internalAccountIds: string[];
	current: string;
	active: string;
	impersonated: string;
}

export const initialState: AccountState = accountAdapter.getInitialState({
	internalAccountIds: ["emp500", "emp512", "pak500", "internal"],
	current: undefined,
	active: undefined,
	impersonated: undefined,
});

export function accountReducer(
	state = initialState,
	action: AccountActions | AddressActions | AuthActions,
): AccountState {
	switch (action.type) {
		case AuthActionType.ImpersonateSuccess:
			return objectUpdate(state, { current: action.payload.accountId, impersonated: action.payload.accountId });
		case AuthActionType.UnimpersonateSuccess:
			return objectUpdate(state, { current: state.active, impersonated: undefined });
		case AuthActionType.LogoutSuccess:
			return objectUpdate(state, { current: undefined, active: undefined, impersonated: undefined });
		case AccountActionTypes.LoadAccountSuccess:
			const cached = ngStoreCacheItem(action.payload, 6, "hours");

			return accountAdapter.upsertOne(cached, state);
		case AddressActionTypes.LoadBillingAddressesSuccess: {
			const billingAddressIds = action.payload.addresses.map(x => x.id);

			return accountAdapter.updateOne({ id: action.payload.accountId, changes: { billingAddressIds } }, state);
		}
		case AuthActionType.IframeLoginSuccess:
		case AuthActionType.LoginSuccess:
			const newState = objectUpdate(state, {
				current: action.payload.accountId,
				active: action.payload.accountId,
			});

			return accountAdapter.upsertOne(ngStoreCacheItem(action.payload.account, 6, "hours"), newState);
		case AddressActionTypes.AddShippingAddressSuccess: {
			const shippingAddressIds = [...state.entities[action.payload.accountId].shippingAddressIds, action.payload.address.id];

			return accountAdapter.updateOne({ id: action.payload.accountId, changes: { shippingAddressIds } }, state);
		}
		case AddressActionTypes.DeleteShippingAddressSuccess: {
			const shippingAddressIds = removeFromArray(state.entities[action.payload.accountId].shippingAddressIds, action.payload.id);

			return accountAdapter.updateOne({ id: action.payload.accountId, changes: { shippingAddressIds } }, state);
		}
		case AddressActionTypes.LoadShippingAddressesSuccess: {
			const shippingAddressIds = action.payload.addresses.map(x => x.id);

			return accountAdapter.updateOne({ id: action.payload.accountId, changes: { shippingAddressIds } }, state);
		}
		case AddressActionTypes.LoadFinalDestinationAddressesSuccess: {
			const finalDestinationAddressIds = action.payload.addresses.map(x => x.id);

			return accountAdapter.updateOne({ id: action.payload.accountId, changes: { finalDestinationAddressIds } }, state);
		}
		default: {
			return state;
		}
	}
}