import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { PropagandaService } from "@app/services/propaganda.service";
import { IPropaganda, Pages, PropagandaType } from "@app/shared/shared.models";
import { IAppState } from "@app/store";
import { showCurrentAccountPropaganda } from "@app/store/account";
import { select, Store } from "@ngrx/store";
import { Observable, of, Subject } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";

@Component({
	selector: "c4-propaganda",
	templateUrl: "./propaganda.component.html",
	styleUrls: ["./propaganda.component.scss"],
})
export class PropagandaComponent implements OnInit, OnDestroy {
	@Input() pageType: keyof typeof Pages;
	@Input() propagandaType: keyof typeof PropagandaType;
	@Input() height: number;
	@Input() classes: string;
	@Input() propaganda: IPropaganda;

	propagandas: IPropaganda[];
	load = false;
	show = true;
	internalButtonUrl: boolean;

	canSee$: Observable<boolean>;

	private destroyed$ = new Subject<{}>();

	constructor(readonly store: Store<IAppState>, readonly propagandaService: PropagandaService) { }

	ngOnInit() {
		this.canSee$ = this.store.pipe(select(showCurrentAccountPropaganda));
		this.height = this.height || 300;
		this.store.pipe(
			select(showCurrentAccountPropaganda),
			switchMap(show => {
				if (this.pageType == null || this.propagandaType == null || !show) {
					return of(this.propagandas);
				}

				this.load = false;
				const pageType = Pages[this.pageType] as Pages;
				const type: PropagandaType = PropagandaType[this.propagandaType];

				return this.propagandaService.getPropagandaForDisplay(pageType, type);
			}),
			takeUntil(this.destroyed$),
		).subscribe(result => {
			this.propagandas = result;
			this.internalButtonUrl = this.propaganda && this.propaganda.buttonUrl && this.propaganda.buttonUrl.startsWith("/") && !this.propaganda.buttonUrl.startsWith("//");
			this.load = true;
			this.show = !!(this.propaganda || (this.propagandas && this.propagandas.length));
		});
	}

	getAlignment(propaganda: IPropaganda): string {
		let classes = "block";

		if (!propaganda) {
			return classes;
		}

		if (propaganda.aligned.toLowerCase() === "right") {
			classes += " text-right";
		} else if (propaganda.aligned.toLowerCase() !== "left") {
			classes += " text-center";
		}

		if (propaganda.vAligned.toLowerCase() === "bottom") {
			classes += " absolute bottom";
		} else if (propaganda.vAligned.toLowerCase() !== "top") {
			classes += " vertically-center";
		}

		return classes;
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}