
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ITrainingSsoResponse, IUserCertification } from "@app/account/account.models";
import { Portals } from "@app/shared/shared.models";
import { IAppState } from "@app/store";
import { WarningToast } from "@app/store/toast/toast.actions";
import { IProctorUStudent, IProctorUTimeZone, ITechnicianSummaryModel, ITrainingSchedule, LmsUrls } from "@app/training/training.models";
import { environment } from "@env/environment";
import { Store } from "@ngrx/store";

@Injectable({ providedIn: "root" })
export class TrainingService {
	constructor(private http: HttpClient, private readonly store: Store<IAppState>) {

	}

	getCourseEnrollmentUrl(courseId: string) {
		return this.http.get<ITrainingSsoResponse>("api/training/GetCourseEnrollmentUrl", { params: { courseId } });
	}

	getLearningPathEnrollmentUrl(pathId: string) {
		return this.http.get<ITrainingSsoResponse>("api/training/GetLearningPathEnrollmentUrl", { params: { pathId } });
	}

	getAutomationProgrammerSchedule() {
		return this.http.get<ITrainingSchedule>("api/training/GetAutomationProgrammerSchedule");
	}

	getAccountCertifications() {
		return this.http.get<IUserCertification[]>("api/Training/GetAccountCertifications");
	}

	getTechnicianSummary() {
		return this.http.get<ITechnicianSummaryModel>("api/training/GetTechnicianSummary");
	}

	getStudentInformation() {
		return this.http.get<IProctorUStudent>("api/training/GetStudentProfile");
	}

	createStudentProfile(student: IProctorUStudent) {
		return this.http.post<boolean>("api/training/CreateStudentProfile", student);
	}

	initiateProctorSso(openedTab: Window) {
		this.http.get("api/training/GetProctorSsoUrl", { responseType: "text" }).subscribe(result => {
			if (result) {
				openedTab.location.href = result;
				return true;
			} else {
				this.store.dispatch(new WarningToast({ message: "There was a problem redirecting you to the exam scheduler. Please try again later or contact the Sales Support Team for assistance." }));
				openedTab.close();
				return false;
			}
		});
	}

	getTimeZones() {
		return this.http.get<IProctorUTimeZone[]>("api/address/GetTimeZones");
	}

	transformTrainingUrl(url: string, portal: Portals): string {
		if (environment.dev) {
			url = "/saml/training";
		} else {
			const portalLmsHost = LmsUrls.get(portal);
			url = url.replace("control4.learnupon.com", portalLmsHost)
				.replace("training.control4.com", portalLmsHost)
				.replace("education.control4.com", portalLmsHost);
		}
		return url;
	}
}
