import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AccountService } from "@app/services/account.service";
import { IControl4ContactsModel } from "@app/shared/shared.models";
import { C4ContactActionTypes, getCurrentControl4Contacts, LoadC4ContactsError, LoadC4ContactsSuccess } from "@app/store/c4-contacts";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action, select, Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, filter, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { IAppState } from "../app.reducer";

@Injectable()
export class C4ContactsEffects {
	constructor(
		private actions$: Actions,
		private accountService: AccountService,
		private store: Store<IAppState>
	) { }

	@Effect()
	loadC4Contacts$: Observable<Action> = this.actions$.pipe(
		ofType(C4ContactActionTypes.LoadC4ContactsAttempt),
		withLatestFrom(this.store.pipe(select(getCurrentControl4Contacts))),
		filter(([action, contacts]) => !contacts),
		mergeMap(() => {
			return this.accountService
				.getControl4Contacts()
				.pipe(
					map((info: IControl4ContactsModel) => new LoadC4ContactsSuccess(info)),
					catchError((err: HttpErrorResponse) => of(new LoadC4ContactsError({ error: err.message || err.statusText })))
				);
		})
	);
}
