import { ComponentRef, Injectable } from "@angular/core";
import { ConfirmPopupComponent } from "@app/popups/confirm-popup/confirm-popup.component";
import { AccountService } from "@app/services/account.service";
import { PopupService } from "@app/services/popup.service";
import { IAppState } from "@app/store/app.reducer";
import { SuccessToast } from "@app/store/toast/toast.actions";
import { DeleteUpgradeRequestFileAttempt, DeleteUpgradeRequestFileError, DeleteUpgradeRequestFileSuccess, LoadAccountUpgradeRequestFilesAttempt, LoadAccountUpgradeRequestFilesError, LoadAccountUpgradeRequestFilesSuccess, SubmitUpgradeRequestAttempt, SubmitUpgradeRequestError, SubmitUpgradeRequestSuccess, UpgradeRequestFileActionTypes } from "@app/store/upgrade-request-file/upgrade-request-file.actions";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action, select, Store } from "@ngrx/store";
import { format as dateFnsFormat } from "date-fns";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap, switchMap, take, withLatestFrom } from "rxjs/operators";
import { getCurrentIsEmea } from "../account";

@Injectable()
export class UpgradeRequestFileEffects {

	constructor(
		private actions$: Actions,
		private store: Store<IAppState>,
		private accountService: AccountService,
		private popupService: PopupService,
	) { }

	@Effect()
	loadAccountUpgradeRequestFiles$: Observable<Action> = this.actions$.pipe(
		ofType<LoadAccountUpgradeRequestFilesAttempt>(UpgradeRequestFileActionTypes.LoadAccountUpgradeRequestFilesAttempt),
		mergeMap(action => {
			return this.accountService
				.getUpgradePromotionFiles()
				.pipe(
					map(files => new LoadAccountUpgradeRequestFilesSuccess({ accountId: action.accountId, files })),
					catchError(err => of(new LoadAccountUpgradeRequestFilesError({ error: err.message || err.statusText }))),
				);
		}),
	);

	@Effect()
	submitUpgradeRequest$: Observable<Action> = this.actions$.pipe(
		ofType<SubmitUpgradeRequestAttempt>(UpgradeRequestFileActionTypes.SubmitUpgradeRequestAttempt),
		mergeMap(action => {
			return this.accountService
				.submitUpgradeRequest(action.payload)
				.pipe(
					mergeMap((info): Action[] => {
						if (info.success) {
							return [
								new SubmitUpgradeRequestSuccess(info.result),
								new SuccessToast({
									message: "Your upgrade request has been successfully submitted.",
								}),
							];
						}

						return [new SubmitUpgradeRequestError({ error: info.errorMessage || "There was a problem submitting your request. Please contact the Sales Support Team.", warning: info.warningMessage })];
					}),
					catchError(err => of(new SubmitUpgradeRequestError({ error: err.message || err.statusText }))),
				);
		}),
	);

	@Effect()
	deleteUpgradeRequestFile$: Observable<Action> = this.actions$.pipe(
		ofType<DeleteUpgradeRequestFileAttempt>(UpgradeRequestFileActionTypes.DeleteUpgradeRequestFileAttempt),
		withLatestFrom(this.store.pipe(select(getCurrentIsEmea))),
		mergeMap(([action, isEmea]) => {
			const popup = this.popupService.create(ConfirmPopupComponent, {
				title: `Delete: ${action.payload.name} (${this.normalizeDate(action.payload.date, isEmea)})`,
				body: "Are you sure you would like to delete this file?",
				confirmText: "Delete File",
			}) as ComponentRef<ConfirmPopupComponent>;

			return popup.instance.answer.pipe(
				take(1),
				switchMap((confirm): Observable<Action> => {
					if (confirm) {
						return this.accountService
							.removeUpgradeRequestFile(action.payload.id)
							.pipe(
								mergeMap((info): Action[] => {
									if (info) {
										return [new DeleteUpgradeRequestFileSuccess(action.payload), new SuccessToast({ message: "File deleted." })];
									} else {
										return [new DeleteUpgradeRequestFileError({ error: "There was a problem deleting the selected file." })];
									}
								}),
								catchError(err => of(new DeleteUpgradeRequestFileError({ error: err.message || err.statusText }))),
							);
					}

					return of();
				}),
			);
		}),
	);

	private normalizeDate(date: Date | string, isEmea: boolean) {
		const format = !isEmea ? "MMMM-DD-YYYY" : "DD-MMMM-YYYY";

		return dateFnsFormat(date, format);
	}
}
