import { CartType, IOrderTotalsModel } from "@app/checkout/checkout.models";
import { ICartState } from "@app/store/cart";
import { cartAdapter } from "@app/store/cart/cart.adapter";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { addressesSelectEntities } from "../address";
import { selectIsMsrp } from "../auth";

export const getCartState = createFeatureSelector<ICartState>("carts");

export const {
	selectIds: selectCartIds,
	selectEntities: selectCartEntities,
	selectAll: selectAllCarts,
	selectTotal: selectCartTotalCount
} = cartAdapter.getSelectors(getCartState);

export const getCurrentCartId = createSelector(
	getCartState,
	(state) => state.currentCartId
);

export const getCurrentCartCustomer = createSelector(
	getCartState,
	(state) => state.currentCustomer
);

export const getCurrentCartLoading = createSelector(
	getCartState,
	(state) => state.currentLoading
);

export const getCart = (id: number) => createSelector(
	selectCartEntities,
	(entities) => entities[id] || { orderId: id, modifying: true } as IOrderTotalsModel
);

export const getCurrentCart = createSelector(
	selectCartEntities,
	getCurrentCartId,
	(entities, id) => entities[id]
);

export const modifyingCurrentCart = createSelector(
	getCurrentCartLoading,
	getCurrentCart,
	(loading, cart) => loading || !cart || cart.modifying
);

export const getCurrentCartItemTotal = createSelector(
	getCurrentCart,
	(cart) => cart ? cart.lineItemSum : 0
);

export const getCurrentCartType = createSelector(
	getCurrentCart,
	(cart) => cart ? cart.cartType : CartType.Default
);

export const getCurrentCartName = createSelector(
	getCurrentCart,
	(cart) => cart ? cart.cartName : ""
);

export const getCurrentCartSubtotal = createSelector(
	getCurrentCart,
	selectIsMsrp,
	(cart, isMsrp) => cart ? (isMsrp ? cart.msrpSubtotal : cart.subtotal) : 0
);

export const getCurrentCartMsrpSubtotal = createSelector(
	getCurrentCart,
	(cart) => cart ? cart.msrpSubtotal : 0
);

export const getPcnaCartId = createSelector(
	getCartState,
	(state) => state.pcnaCartId
);

export const getPcnaCart = createSelector(
	selectCartEntities,
	getPcnaCartId,
	(entities, id) => entities[id]
);

export const getPcnaShippingAddress = createSelector(
	getPcnaCart,
	addressesSelectEntities,
	(cart, addresses) => cart && cart.shipping && addresses[cart.shipping.selectedShippingAddressId]
);

export const modifyingPcnaCart = createSelector(
	getPcnaCart,
	(cart) => !cart || cart.modifying
);