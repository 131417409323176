import { ngStoreCacheItem } from "@app/shared/helpers";
import { ISalesContactsModel } from "@app/shared/shared.models";
import { SalesContactActionTypes, SalesContactsActions } from "@app/store/sales-contacts";
import { salesContactsAdapter } from "@app/store/sales-contacts/sales-contacts.adapter";
import { EntityState } from "@ngrx/entity";

export interface ISalesContactsState extends EntityState<ISalesContactsModel> { }

export const initialState: ISalesContactsState = salesContactsAdapter.getInitialState();

export function salesContactsReducer(
	state = initialState,
	action: SalesContactsActions,
): ISalesContactsState {
	switch (action.type) {
		case SalesContactActionTypes.LoadSalesContactsSuccess:
			return salesContactsAdapter.upsertOne(ngStoreCacheItem(action.payload, 6, "hours"), state);
		default: {
			return state;
		}
	}
}