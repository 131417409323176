import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ControlTypes, IControlColor, IDealerLocatorLocation, IPropaganda, IPropagandaDealer, ITestimonialModel, Pages, PropagandaType } from "@app/shared/shared.models";

@Injectable({
	providedIn: "root"
})
export class PropagandaService {

	constructor(readonly http: HttpClient) { }

	getPropaganda(type: number) {
		return this.http.get<IPropaganda[]>("api/propaganda/GetPropaganda", { params: { type: type.toString() } });
	}

	getPropagandaDealers(id: number) {
		return this.http.get<IPropagandaDealer[]>("api/propaganda/GetPropagandaDealers", { params: { id: id.toString() } });
	}

	getPropagandaById(id: number) {
		return this.http.get<IPropaganda>("api/propaganda/GetPropagandaById", { params: { id: id.toString() } });
	}

	savePropaganda(prop: IPropaganda) {
		return this.http.post<IPropaganda>("api/propaganda/savePropaganda", prop);
	}

	deletePropaganda(id: number) {
		return this.http.delete<boolean>("api/propaganda/DeletePropaganda", { params: { id: id.toString() } });
	}

	updatePropagandaSortOrder(props: IPropaganda[]) {
		return this.http.post<IPropaganda[]>("api/propaganda/updatePropagandaSortOrder", props);
	}

	getPropagandaForDisplay(page: Pages, type: PropagandaType) {
		const params = new HttpParams().set("page", page.toString()).set("type", type.toString());
		return this.http.get<IPropaganda[]>("api/propaganda/GetPropagandaForDisplay", { params });
	}

	getDealerLocatorLocations(accountId: string) {
		const params = new HttpParams().set("accountId", accountId);
		return this.http.get<IDealerLocatorLocation[]>("api/propaganda/GetDealerLocatorLocations", { params });
	}

	updateDealerLocatorLocation(location: IDealerLocatorLocation) {
		return this.http.put("api/propaganda/UpdateDealerLocatorLocation", location);
	}

	getControlColors(controlType: ControlTypes) {
		return this.http.get<IControlColor[]>("api/propaganda/GetControlColors", { params: { controlType: controlType.toString() } });
	}

	getTestimonials(dealerId: string, lineNum: number) {
		return this.http.get<ITestimonialModel[]>("api/propaganda/GetTestimonials", { params: { dealerId, lineNum: lineNum.toString() } });
	}

}