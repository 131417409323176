import { Component, ComponentRef, OnDestroy, OnInit } from "@angular/core";
import { BusinessRole, IUpdateDealerRoleModel, PrimaryRoleNames, SecondaryRoleNames, SecondaryRoles } from "@app/account/account.models";
import { UserService } from "@app/services/user.service";
import { deepcopy } from "@app/shared/helpers";
import { IAppState } from "@app/store";
import { ErrorToast, SuccessToast } from "@app/store/toast/toast.actions";
import { SaveBusinessRoleSuccess, selectCurrentUserId } from "@app/store/user";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { IPopupComponent } from "../popups.models";

@Component({
	selector: "c4-dealer-role-popup",
	templateUrl: "./dealer-role-popup.component.html",
	styleUrls: ["./dealer-role-popup.component.scss"],
})
export class DealerRolePopupComponent implements OnInit, OnDestroy, IPopupComponent {
	close: () => void;
	self: ComponentRef<DealerRolePopupComponent>;

	info = {} as IUpdateDealerRoleModel;

	primaryRoles = BusinessRole;
	secondaryRoles = SecondaryRoles;

	primaryNames = PrimaryRoleNames;
	secondaryNames = SecondaryRoleNames;

	selectedPrimary: BusinessRole;
	selectedSecondary: SecondaryRoles;

	private destroyed$ = new Subject<{}>();

	constructor(readonly store: Store<IAppState>, readonly userService: UserService) { }

	ngOnInit() {
		this.store.pipe(select(selectCurrentUserId), takeUntil(this.destroyed$)).subscribe(userId => {
			this.info.userId = userId;
		});
	}

	submit() {
		const info = deepcopy(this.info);
		this.userService.updateDealerRoles(info).subscribe(result => {
			if (result) {
				this.store.dispatch(new SaveBusinessRoleSuccess(info));
				this.store.dispatch(new SuccessToast({ message: "Thank you, your role has been saved successfully!" }));
				this.self.destroy();
			} else {
				this.store.dispatch(new ErrorToast({ message: "Unfortunately, there was an error saving your selection." }));
			}
		}, e => {
			this.store.dispatch(new ErrorToast({ message: "Unfortunately, there was an error saving your selection." }));
		});
	}

	resetRoles() {
		this.info.primaryRole = 0;
		this.info.secondaryRoles = 0;
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}

}
