import { Pipe, PipeTransform } from "@angular/core";
import { CloudinaryService } from "@app/services/cloudinary.service";
import { IMedia, MediaType } from "@app/shared/shared.models";

@Pipe({
	name: "cloudinaryUrl"
})
export class CloudinaryUrlPipe implements PipeTransform {

	constructor(private cloudinaryService: CloudinaryService, ) { }

	transform(value: IMedia | string, transformations: string, thumbnail = false): any {
		const media = typeof (value) === "string" ? <IMedia>{ mediaTypeId: MediaType.Image, url: value } : value;
		return this.cloudinaryService.getUrl(media, transformations, thumbnail);
	}

}
