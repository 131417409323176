import { getNgStoreCacheItem } from "@app/shared/helpers";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { getCurrentAccount } from "../account";
import { salesInfoAdapter } from "./sales-info.adapter";
import { ISalesInfoState } from "./sales-info.reducer";

export const getSalesInfoState = createFeatureSelector<ISalesInfoState>("salesInfo");

export const {
	selectIds: salesInfoSelectIds,
	selectEntities: salesInfoSelectEntities,
	selectAll: salesInfoSelectAll,
	selectTotal: salesInfoSelectTotal
} = salesInfoAdapter.getSelectors(getSalesInfoState);

export const getCurrentSalesInfo = createSelector(
	getCurrentAccount,
	salesInfoSelectEntities,
	(account, entities) => account ? getNgStoreCacheItem(entities[account.id]) : undefined
);