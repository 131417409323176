import { Component, Input, OnInit } from "@angular/core";
import { IStaticContent } from "@app/cms/cms.models";
import { ScheduleType } from "@app/shared/shared.models";
import { format as dateFnsFormat, isBefore } from "date-fns";

@Component({
	selector: "c4-static-review-message",
	templateUrl: "./static-review-message.component.html",
	styleUrls: ["./static-review-message.component.scss"],
})
export class StaticReviewMessageComponent implements OnInit {
	loading = true;
	reviewing = false;
	releaseDate: string;
	@Input() data: IStaticContent;

	ngOnInit(): void {
		const today = new Date();
		const unreleased = this.data.releaseDate && isBefore(today, this.data.releaseDate);
		const scheduled = this.data.scheduleType === ScheduleType.Scheduled;
		this.reviewing = (scheduled && unreleased) || this.data.scheduleType === ScheduleType.Draft;
		this.releaseDate = this.data.releaseDate && scheduled ? dateFnsFormat(this.data.releaseDate, "MM[/]DD[/]YYYY [at] h[:]mmA") : null;
	}
}
