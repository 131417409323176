import { Component, OnInit } from "@angular/core";

// This is a dummy component to allow the no-br tag to continue to work.
// This is why it does not havae the traditional selector
@Component({
	// tslint:disable-next-line:component-selector
	selector: "no-br",
	templateUrl: "./no-br.component.html",
	styleUrls: ["./no-br.component.scss"]
})
export class NoBrComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
