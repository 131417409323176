import { ComponentFactoryResolver, ComponentRef, Inject, Injectable, ViewContainerRef } from "@angular/core";
import { CmsPageEditButtonComponent } from "@app/edit-tooling/cms-page-edit-button/cms-page-edit-button.component";

@Injectable({
	providedIn: "root"
})
export class EditToolingService {
	private rootViewContainer: ViewContainerRef;
	private factoryResolver: ComponentFactoryResolver;

	constructor(@Inject(ComponentFactoryResolver) factoryResolver: ComponentFactoryResolver) {
		this.factoryResolver = factoryResolver;
	}

	setDefaultViewContainer(viewContainerRef: ViewContainerRef) {
		this.rootViewContainer = viewContainerRef;
	}

	createCmsEditButton(url: string, container?: ViewContainerRef) {
		const factory = this.factoryResolver
			.resolveComponentFactory(CmsPageEditButtonComponent);
		container = (container || this.rootViewContainer);
		const componentRef = factory.create(container.parentInjector);
		Object.assign(componentRef.instance, { url });
		componentRef.changeDetectorRef.detectChanges();
		container.insert(componentRef.hostView);
		return componentRef as ComponentRef<CmsPageEditButtonComponent>;
	}
}
