import { Component, OnInit } from "@angular/core";
import { ProductFirmware } from "@app/products/products.models";
import { FirmwareService } from "@app/services/firmware.service";
import { Observable } from "rxjs";

@Component({
	selector: "c4-latest-firmware",
	templateUrl: "./latest-firmware.component.html",
	styleUrls: ["./latest-firmware.component.scss"],
})

export class LatestFirmwareComponent implements OnInit {

	firmware$: Observable<ProductFirmware[]>;

	constructor(private firmwareService: FirmwareService) { }

	ngOnInit() {
		this.firmware$ = this.firmwareService.getLatestFirmware(10);
	}

}
