import { getNgStoreCacheItem } from "@app/shared/helpers";
import { getCurrentAccount } from "@app/store/account";
import { shippingMethodAdapter } from "@app/store/shipping-method/shipping-method.adapter";
import { IShippingMethodState } from "@app/store/shipping-method/shipping-method.reducer";
import { createFeatureSelector, createSelector } from "@ngrx/store";

export const getShippingMethodState = createFeatureSelector<IShippingMethodState>("shippingMethods");

export const {
	selectIds: shippingMethodsSelectIds,
	selectEntities: shippingMethodsSelectEntities,
	selectAll: shippingMethodsSelectAll,
	selectTotal: shippingMethodsSelectTotal
} = shippingMethodAdapter.getSelectors(getShippingMethodState);

export const getDefaultShippingMethod = createSelector(
	shippingMethodsSelectEntities,
	getCurrentAccount,
	(entities, account) => entities && account ? getNgStoreCacheItem(entities[account.defaultShippingOptionId]) : undefined
);