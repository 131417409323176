import { Component, ComponentRef } from "@angular/core";
import { IPopupComponent } from "@app/popups/popups.models";
import { PopupService } from "@app/services/popup.service";
import { UserService } from "@app/services/user.service";
import { IContractModel } from "@app/shared/shared.models";
import { IAppState } from "@app/store";
import { LogoutAttempt } from "@app/store/auth";
import { SignContractSuccess } from "@app/store/contract";
import { Store } from "@ngrx/store";
import { DealerRolePopupComponent } from "../dealer-role-popup/dealer-role-popup.component";

@Component({
	selector: "c4-contract-popup",
	templateUrl: "./contract-popup.component.html",
	styleUrls: ["./contract-popup.component.scss"]
})
export class ContractPopupComponent implements IPopupComponent {
	self: ComponentRef<ContractPopupComponent>;
	contract: IContractModel;

	constructor(private store: Store<IAppState>, private service: UserService, private popupService: PopupService) { }

	async acceptContract() {
		const signed = await this.service.signContract(this.contract.id).toPromise();
		if (signed) {
			this.store.dispatch(new SignContractSuccess(this.contract.id));
			this.popupService.create(DealerRolePopupComponent);
		}
	}

	logout() {
		this.store.dispatch(new LogoutAttempt());
		this.self.destroy();
	}

	close() {
		if (this.contract.isSigned) {
			this.self.destroy();
		}
	}
}