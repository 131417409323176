import { IUser } from "@app/account/account.models";
import { createEntityAdapter } from "@ngrx/entity";


function sortByName(a: IUser, b: IUser): number {
	const firstCompare = a.firstName.localeCompare(b.firstName);

	if (firstCompare === 0) {
		return a.lastName.localeCompare(b.lastName);
	}

	return firstCompare;
}

export const userAdapter = createEntityAdapter<IUser>({
	sortComparer: sortByName,
	selectId: user => user.userId
});