import { Component, ComponentRef, HostListener, OnInit } from "@angular/core";
import { ControllerOs, ControllerOsGroup } from "@app/dashboard/dashboard.models";
import { CmsCustomerService } from "@app/services/cms-customer.service";
import { KeyboardService } from "@app/services/keyboard.service";
import { deepcopy, remove } from "@app/shared/helpers";
import { map } from "rxjs/operators";
import { IPopupComponent } from "../popups.models";

@Component({
	selector: "c4-controller-os-group-edit-popup",
	templateUrl: "./controller-os-group-edit-popup.component.html",
	styleUrls: ["./controller-os-group-edit-popup.component.scss"],
})
export class ControllerOsGroupEditPopupComponent implements OnInit, IPopupComponent {
	close: () => void;
	self: ComponentRef<ControllerOsGroupEditPopupComponent>;
	group: ControllerOsGroup;

	onSave: (group: ControllerOsGroup) => void;

	constructor(readonly cmsCustomerService: CmsCustomerService, readonly keyboardService: KeyboardService) { }

	ngOnInit() {
		if (!this.group) {
			this.group = <ControllerOsGroup>{
				color: 0,
				controllerOs: [],
				id: 0,
			};
		} else {
			this.group = deepcopy(this.group);
		}
	}

	@HostListener("keydown", ["$event"])
	onKeyDownHandler(event: KeyboardEvent) {
		if (this.keyboardService.isSaveKeybind(event)) {
			this.save();
		}
	}

	addOs() {
		this.group.controllerOs.push(<ControllerOs>{ groupId: this.group.id });
	}

	removeOs(os: ControllerOs) {
		this.group.controllerOs = remove(this.group.controllerOs, os);
	}

	save() {
		this.group.controllerOs = this.group.controllerOs.filter(x => !!x.name);
		this.cmsCustomerService.saveControllerOsGroup(this.group).subscribe(result => {
			if (this.onSave) {
				this.onSave(result);
				this.close();
			}
		});
	}

	searchOsVersions(version: ControllerOs) {
		return (val: string) => {
			return this.cmsCustomerService.searchControllerOs(val)
				.pipe(map(results => {
					const versions = this.group.controllerOs.filter(x => x !== version).map(x => x.name);
					return results.filter(x => !versions.includes(x));
				}));
		};
	}

}
