import { ILeadPreferences } from "@app/dashboard/dashboard.models";
import { ngStoreCacheItem } from "@app/shared/helpers";
import { LeadPreferencesActions, LeadPreferencesActionTypes } from "@app/store/lead-preferences";
import { leadPreferencesAdapter } from "@app/store/lead-preferences/lead-preferences.adapter";
import { EntityState } from "@ngrx/entity";

export interface ILeadPreferencesState extends EntityState<ILeadPreferences> { }

export const initialState: ILeadPreferencesState = leadPreferencesAdapter.getInitialState();

export function leadPreferencesReducer(
	state = initialState,
	action: LeadPreferencesActions
): ILeadPreferencesState {
	switch (action.type) {
		case LeadPreferencesActionTypes.LoadLeadPreferencesSuccess:
			return leadPreferencesAdapter.upsertOne(ngStoreCacheItem(action.payload, 2, "hours"), state);
		case LeadPreferencesActionTypes.SaveLeadPreferencesSuccess:
			// TODO: This needs to return a new object after the save is complete,
			// however this will break the current implementation
			return leadPreferencesAdapter.updateOne({
				id: action.payload.accountId,
				changes: action.payload
			}, state);
		default: {
			return state;
		}
	}
}