import { Pipe, PipeTransform } from "@angular/core";
import { PagingInfo } from "@app/shared-elements/shared-elements.models";
import { isObservable, Observable } from "rxjs";
import { map } from "rxjs/operators";

@Pipe({
	name: "paging",
	pure: false,
})
export class PagingPipe<T> implements PipeTransform {

	transform(value: T[] | Observable<T[]>, info: PagingInfo): T[] | Observable<T[]> {
		return isObservable(value)
			? (value as Observable<T[]>).pipe(map(items => this.convert(items, info)))
			: this.convert(value, info);
	}

	private convert(value: T[], info: PagingInfo): T[] {
		if (!value || !value.length || !info) { return value; }
		let first = (info.current - 1) * info.pageSize;

		if (first > value.length) {
			first = (info.pageCount() - 1) * info.pageSize;
		}

		return value.slice(first, first + info.pageSize);
	}

}
