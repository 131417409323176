import { ngStoreCacheItem } from "@app/shared/helpers";
import { IAddress } from "@app/shared/shared.models";
import { AddressActionTypes } from "@app/store/address";
import { AddressActions } from "@app/store/address/address.actions";
import { addressAdapter } from "@app/store/address/address.adapter";
import { EntityState } from "@ngrx/entity";
import { AccountActions, AccountActionTypes } from "../account";

export interface IAddressState extends EntityState<IAddress> { }

export const initialState: IAddressState = addressAdapter.getInitialState();

const cache = function (address: IAddress) {
	return ngStoreCacheItem(address, 2, "hours");
};

export function addressReducer(
	state = initialState,
	action: AddressActions | AccountActions
): IAddressState {
	switch (action.type) {
		case AccountActionTypes.LoadAccountSuccess: {
			const cached = [cache(action.payload.billingAddress), cache(action.payload.shippingAddress)];
			return addressAdapter.upsertMany(cached, state);
		}
		case AddressActionTypes.LoadAddressSuccess: {
			const cached = cache(action.payload);
			return addressAdapter.upsertOne(cached, state);
		}
		case AddressActionTypes.AddShippingAddressSuccess: {
			const cached = cache(action.payload.address);
			return addressAdapter.upsertOne(cached, state);
		}
		case AddressActionTypes.DeleteShippingAddressSuccess:
			return addressAdapter.removeOne(action.payload.id, state);
		case AddressActionTypes.LoadBillingAddressesSuccess:
		case AddressActionTypes.LoadShippingAddressesSuccess:
		case AddressActionTypes.LoadFinalDestinationAddressesSuccess: {
			const cached = action.payload.addresses.map(x => cache(x));
			return addressAdapter.upsertMany(cached, state);
		}
		default:
			return state;
	}
}