import { Pipe, PipeTransform } from "@angular/core";
import { IPropaganda, ScheduleType } from "@app/shared/shared.models";

@Pipe({
	name: "propagandaStatusIcon"
})
export class PropagandaStatusIconPipe implements PipeTransform {
	transform(prop: IPropaganda): string {
		const today = new Date();

		if (
			prop.scheduleType === ScheduleType.Live ||
			prop.scheduleType === ScheduleType.Scheduled && new Date(prop.startDate) <= today && (!prop.endDate || new Date(prop.endDate) >= today)
		) {
			return "fal fa-clock";
		}

		if (prop.scheduleType === ScheduleType.Scheduled) {
			return "fal fa-check";
		}

		if (prop.scheduleType === ScheduleType.Draft) {
			return "fal fa-pen-alt";
		}

		return "";
	}
}