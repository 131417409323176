import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { IBakpakCodes } from "@app/account/account.models";
import { IAppState } from "@app/store";
import { getCurrentBakpakCodes, LoadBakpakCodesAttempt } from "@app/store/bakpak-codes";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: "c4-my-bakpak",
	templateUrl: "./my-bakpak.component.html",
	styleUrls: ["./my-bakpak.component.scss"]
})
export class MyBakpakComponent implements OnInit, OnDestroy {
	@Input() hideAccount: boolean;

	bakpakCodes: IBakpakCodes;

	private destroyed$ = new Subject<{}>();

	constructor(readonly store: Store<IAppState>) { }

	ngOnInit() {
		this.store.pipe(
			select(getCurrentBakpakCodes),
			takeUntil(this.destroyed$)
		).subscribe((bakpakCodes) => {
			if (!bakpakCodes) {
				this.store.dispatch(new LoadBakpakCodesAttempt());
			} else {
				this.bakpakCodes = bakpakCodes;
			}
		});
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}
}
