import { Component, Input, OnInit } from "@angular/core";
import { IProductLightModel } from "@app/products/products.models";
import { IAppState } from "@app/store";
import { getCurrentCurrencySymbol } from "@app/store/account";
import { selectIsMsrp } from "@app/store/auth";
import { LoadLightProductBySku, selectProductLight } from "@app/store/product-light";
import { currentCanViewPricing } from "@app/store/user";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { takeWhile } from "rxjs/operators";

@Component({
	selector: "c4-product-customization-addon",
	templateUrl: "./customization-addon.component.html",
	styleUrls: ["./customization-addon.component.scss"]
})
export class CustomizationAddonComponent implements OnInit {
	private _addon: string;
	@Input() get addon() {
		return this._addon;
	}
	set addon(val) {
		this._addon = val;
		if (val) {
			this.product = null;
			this.store.dispatch(new LoadLightProductBySku(val));
			this.store.pipe(select(selectProductLight(val), takeWhile(() => this.product == null))).subscribe((result) => {
				this.product = result;
			});
		} else {
			this.product = null;
		}
	}

	isMsrp$: Observable<boolean>;
	canViewPricing$: Observable<boolean>;
	currency$: Observable<string>;
	product: IProductLightModel;

	constructor(readonly store: Store<IAppState>) { }

	ngOnInit() {
		this.currency$ = this.store.pipe(select(getCurrentCurrencySymbol));
		this.canViewPricing$ = this.store.pipe(select(currentCanViewPricing));
		this.isMsrp$ = this.store.pipe(select(selectIsMsrp));
	}
}
