import { getNgStoreCacheItem } from "@app/shared/helpers";
import { AccountBalanceTotal } from "@app/shared/shared.models";
import { getCurrentAccount } from "@app/store/account";
import { accountBalanceAdapter } from "@app/store/account-balance/account-balance.adapter";
import { IAccountBalanceState } from "@app/store/account-balance/account-balance.reducer";
import { createFeatureSelector, createSelector } from "@ngrx/store";

export const getAccountBalanceState = createFeatureSelector<IAccountBalanceState>("accountBalances");

export const {
	selectIds: accountBalancesSelectIds,
	selectEntities: accountBalancesSelectEntities,
	selectAll: accountBalancesSelectAll,
	selectTotal: accountBalancesSelectTotal
} = accountBalanceAdapter.getSelectors(getAccountBalanceState);

export const getLoadedAccountBalanceIds = createSelector(
	getAccountBalanceState,
	(state) => state.loadedAccounts
);

export const getCurrentAccountBalanceLoaded = createSelector(
	getCurrentAccount,
	getLoadedAccountBalanceIds,
	(account, loadedIds) => loadedIds && account && getNgStoreCacheItem(loadedIds.find(x => x.item === account.id))
);

export const getCurrentAccountBalanceDetails = createSelector(
	getCurrentAccount,
	getCurrentAccountBalanceLoaded,
	accountBalancesSelectAll,
	(account, hasDetails, details) => hasDetails ? details.filter(x => x.accountId === account.id) : undefined
);

export const getCurrentAccountBalanceTotals = createSelector(
	getCurrentAccountBalanceDetails,
	(details) => details ? details.filter(i => !i.isPaid).reduce((memo, item) => {
		memo.balanceDue += item.amount;
		memo.earlyPayBalanceDue += item.earlyPayAmount;
		memo.notDue += item.notDue;
		memo.oneThirty += item.oneThirty;
		memo.thirtyOneSixty += item.thirtyOneSixty;
		memo.sixtyOneNinety += item.sixtyOneNinety;
		memo.ninetyOneTwenty += item.ninetyOneTwenty;
		memo.oneTwentyPlus += item.oneTwentyPlus;
		return memo;
	}, new AccountBalanceTotal()) : undefined
);

export const getAccountBalanceErrors = createSelector(
	getAccountBalanceState,
	(state) => state.errors
);

export const getAccountBalanceSubmitState = createSelector(
	getAccountBalanceState,
	(state) => state.submitState
);