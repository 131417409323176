import { getNgStoreCacheItem } from "@app/shared/helpers";
import { IBakpakCodesState } from "@app/store/bakpak-codes";
import { bakpakCodesAdapter } from "@app/store/bakpak-codes/bakpak-codes.adapter";
import { selectCurrentUser } from "@app/store/user";
import { createFeatureSelector, createSelector } from "@ngrx/store";

export const getBakpakCodesState = createFeatureSelector<IBakpakCodesState>("bakpakCodes");

export const {
	selectIds: bakpakCodesSelectIds,
	selectEntities: bakpakCodesSelectEntities,
	selectAll: bakpakCodesSelectAll,
	selectTotal: bakpakCodesSelectTotal
} = bakpakCodesAdapter.getSelectors(getBakpakCodesState);

export const getCurrentBakpakCodes = createSelector(
	bakpakCodesSelectEntities,
	selectCurrentUser,
	(entities, user) => user ? getNgStoreCacheItem(entities[user.userId]) : undefined
);