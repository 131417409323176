import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SearchService } from "@app/services/search.service";
import { PagingInfo } from "@app/shared-elements/shared-elements.models";
import { Portals } from "@app/shared/shared.models";
import { IAppState } from "@app/store";
import { getCurrentPortal } from "@app/store/app-info/app-info.selectors";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { searchCategories, SearchResultItem } from "../search.models";

@Component({
	selector: "c4-search",
	templateUrl: "./search.component.html",
	styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit, OnDestroy {
	pagingInfo: PagingInfo;
	canGlobalSearch: boolean;
	searchInput: string;
	staticSearchInput: string;
	searching: boolean;
	results: SearchResultItem[];
	portal: Portals;
	category = "All";
	categoryName: string;
	filteredResults: SearchResultItem[];

	categories = searchCategories;

	private destroyed$ = new Subject<{}>();

	constructor(readonly searchService: SearchService, private store: Store<IAppState>, readonly route: ActivatedRoute, readonly router: Router) { }

	ngOnInit() {
		this.store.pipe(select(getCurrentPortal), takeUntil(this.destroyed$)).subscribe(currentPortal => { this.portal = currentPortal; });
		this.pagingInfo = new PagingInfo(1, 0, 20);
		this.getSearchParams();
	}

	getSearchParams() {
		this.searching = true;
		this.route.queryParams.subscribe(params => this.getResults(params["q"]));
	}

	search(page: number = 1) {
		this.router.navigate([], { relativeTo: this.route, queryParams: { q: this.searchInput }, queryParamsHandling: "merge", replaceUrl: true });
	}

	private getResults(input: string) {
		this.searchInput = input;
		this.pagingInfo = new PagingInfo(1, 0, 20);
		if (this.canGlobalSearch && !this.searchInput) {
			return;
		}
		this.searching = true;
		this.searchService.globalSearch(this.searchInput, this.portal).subscribe(results => {
			this.staticSearchInput = this.searchInput;
			this.filter(results);
			this.searching = false;
		});
	}

	filter(results: SearchResultItem[]) {
		const cat = searchCategories.find(x => x.value === this.category);
		this.categoryName = cat && cat.label;
		this.results = results || this.results;
		this.filteredResults = this.getResultsByCategory(this.results);
		this.pagingInfo = PagingInfo.update(this.pagingInfo, { total: this.filteredResults.length });
	}

	getResultsByCategory(results: SearchResultItem[]) {
		return this.category === "All" ? results : results = results.filter(result => result.category === this.category);
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}
}