import { Component, ComponentRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Account, IUser } from "@app/account/account.models";
import { IPopupComponent } from "@app/popups/popups.models";
import { AccountService } from "@app/services/account.service";
import { TypeaheadComponent } from "@app/shared-elements/typeahead/typeahead.component";
import { contains } from "@app/shared/helpers";
import { IAppState } from "@app/store";
import { ImpersonateAttempt } from "@app/store/auth";
import { WarningToast } from "@app/store/toast/toast.actions";
import { Store } from "@ngrx/store";

@Component({
	selector: "c4-impersonation-popup",
	templateUrl: "./impersonation-popup.component.html",
	styleUrls: ["./impersonation-popup.component.scss"],
})
export class ImpersonationPopupComponent implements OnInit, IPopupComponent {
	close: () => void;
	selectedAccount: Account;
	accountUsers: IUser[];
	filteredUsers: IUser[];
	self: ComponentRef<ImpersonationPopupComponent>;

	searchTechnicianID = false;
	placeholder: string;
	field: string;

	search: string;
	loading = false;

	warning: string;
	redirectUrl: string;

	@ViewChild(TypeaheadComponent, { static: false }) typeaheadComponent: TypeaheadComponent;

	constructor(readonly store: Store<IAppState>, readonly accountService: AccountService, readonly route: ActivatedRoute) { }

	ngOnInit() {
		this.setTypeaheadValues();

		if (location.pathname === "/checkout/placeorder") {
			this.warning = "Impersonating on the checkout page will redirect you back to the cart.";
			this.redirectUrl = "/checkout";
		}
	}

	makeSelection = (item: Account | IUser) => {
		return this.searchTechnicianID ? this.selectUser(item as IUser) : this.selectAccount(item as Account);
	}

	selectAccount = (account: Account) => {
		this.selectedAccount = account;
		this.accountService
			.getImpersonatingUsers(account.id)
			.subscribe(result => {
				this.accountUsers = result;
				if (this.accountUsers.length === 1) {
					this.selectUser(this.accountUsers[0]);
				} else {
					this.filteredUsers = [...result];
				}
			});
	}

	selectUser = (user: IUser) => {
		this.search = user.userId;
		if (user) {
			this.store.dispatch(new ImpersonateAttempt({ userId: user.userId, redirectUrl: this.redirectUrl }));
			this.loading = true;
		} else {
			this.store.dispatch(new WarningToast({ message: "This is not a valid user id for the selected account. Please try again." }));
		}
	}

	filter(val: string) {
		let items = this.accountUsers || [];
		if (val && typeof val === "string") {
			items = items.filter(x => contains(x.userId, val) || contains(x.firstName, val) || contains(x.lastName, val));
		}
		this.filteredUsers = [...items];
	}

	getItems() {
		return this.searchTechnicianID ? this.accountService.lookupByPartialUsernameOrTechnicianID : this.accountService.lookupByPartialName;
	}

	toggleSearch() {
		this.setTypeaheadValues();
		this.typeaheadComponent.onChange();
	}

	setTypeaheadValues() {
		if (this.searchTechnicianID) {
			this.placeholder = "Enter a user name or technician Id";
			this.field = "displayName";
		} else {
			this.placeholder = "Enter a dealer name or Id";
			this.field = "accountName";
		}
	}
}