import { Pipe, PipeTransform } from "@angular/core";
import { Schedule, ScheduleType } from "@app/shared/shared.models";
import { isAfter, isBefore, isEqual } from "date-fns";

@Pipe({
	name: "scheduleIcon"
})
export class ScheduleIconPipe implements PipeTransform {

	private live = "fal fa-check text-success";
	private pending = "fal fa-clock";
	private archive = "fal fa-archive";
	private draft = "fal fa-pencil";

	transform(value: Schedule): any {
		if (!value) {
			return "";
		}

		if (value.type === ScheduleType.Draft) {
			return this.draft;
		}

		if (value.type === ScheduleType.Archive) {
			return this.archive;
		}

		if (value.type === ScheduleType.Live) {
			return this.live;
		}

		if (value.type === ScheduleType.Scheduled) {
			const today = new Date();
			if (value.end && isAfter(today, value.end)) {
				return this.archive;
			}
			if (isBefore(today, value.start)) {
				return this.pending;
			}
			if ((isEqual(today, value.start) || isAfter(today, value.start))
				&& (isEqual(today, value.end) || isBefore(today, value.end))) {
				return this.live;
			}
		}
		return "";
	}
}
