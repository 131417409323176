import { IStatusProgress } from "@app/shared-widgets/shared-widgets.models";
import { ngStoreCacheItem } from "@app/shared/helpers";
import { EntityState } from "@ngrx/entity";
import { StatusProgressActions, StatusProgressActionTypes } from "./status-progress.actions";
import { statusProgressAdapter } from "./status-progress.adapter";

export interface IStatusProgressState extends EntityState<IStatusProgress> { }

export const initialState: IStatusProgressState = statusProgressAdapter.getInitialState();

export function statusProgressReducer(
	state = initialState,
	action: StatusProgressActions
): IStatusProgressState {
	switch (action.type) {
		case StatusProgressActionTypes.LoadStatusProgressSuccess:
			return statusProgressAdapter.upsertOne(ngStoreCacheItem(action.payload, 20, "minutes"), state);
		default:
			return state;
	}
}