import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IEnumItem } from "@app/shared-elements/shared-elements.models";

@Component({
	selector: "c4-enum-radio-button",
	templateUrl: "./enum-radio-button.component.html",
	styleUrls: ["./enum-radio-button.component.scss"]
})
export class EnumRadioButtonComponent<T extends number> implements OnInit {
	@Input() labelMap: Map<T, string>;
	@Input() name: string;
	@Input() hasSelectAll = false;
	checked = true;
	items: IEnumItem<T>[];
	allValue: number;

	@Output() valueChange = new EventEmitter();
	@Input() value: any;

	constructor() { }

	ngOnInit() {
		const items: IEnumItem<T>[] = [];
		this.labelMap.forEach((label, value) => items.push({ name: label, value: value }));
		this.items = items;
		this.allValue = this.items.reduce((memo, item) => memo + item.value, 0);
	}
}
