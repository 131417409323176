import { Component, EventEmitter, Input, OnDestroy, OnInit } from "@angular/core";
import { cartTypeLabels, IAddToCartModel, ICartItemModel } from "@app/checkout/checkout.models";
import { SuggestedProductsPopupComponent } from "@app/popups/suggested-products-popup/suggested-products-popup.component";
import { IProductView } from "@app/products/products.models";
import { CartService } from "@app/services/cart.service";
import { PopupService } from "@app/services/popup.service";
import { ProductService } from "@app/services/product.service";
import { IAppState } from "@app/store";
import { AddCartItemAttempt, getCurrentCartId, selectCartEntities } from "@app/store/cart";
import { select, Store } from "@ngrx/store";
import { combineLatest, Observable, Subject } from "rxjs";
import { map, startWith, take } from "rxjs/operators";

@Component({
	selector: "c4-product-add-to-cart",
	templateUrl: "./product-add-to-cart.component.html",
	styleUrls: ["./product-add-to-cart.component.scss"],
})
export class ProductAddToCartComponent implements OnInit, OnDestroy {
	@Input() disabled: boolean;
	@Input() skus: string;
	@Input() quantity: number;
	@Input() promo: string;
	@Input() text: string;
	@Input() actionSource: string;
	@Input() trackLabel: string;
	@Input() classes: string;
	@Input() ignoreSuggested = false;
	@Input() onComplete: (success: boolean) => void;
	@Input() onClick: () => EventEmitter<boolean>;
	@Input() product: IProductView;
	@Input() editedCartItem: ICartItemModel;

	private _orderId = 0;
	@Input() get orderId() { return this._orderId; }
	set orderId(val) {
		this._orderId = val;
		this.orderIdChanged.next(val);
	}
	@Input() isInCart = false;

	adding: boolean;
	added: boolean;
	failed: boolean;
	backorderTooltip: string;
	cartName$: Observable<string>;

	orderIdChanged = new Subject<number>();

	private destroyed$ = new Subject<{}>();

	constructor(readonly store: Store<IAppState>, readonly productService: ProductService, readonly popupService: PopupService, readonly cartService: CartService) {
	}

	ngOnInit() {
		this.backorderTooltip = this.productService.getStockStatusMessage(this.product);

		this.text = this.editedCartItem ? "Save Changes" : this.text;
		this.cartName$ = combineLatest([
			this.orderIdChanged.pipe(startWith(this.orderId)),
			this.store.pipe(select(getCurrentCartId)),
			this.store.pipe(select(selectCartEntities)),
		]).pipe(
			map(([id, currentId, entities]) => {
				id = id || currentId;
				const cart = entities[id];
				let label: string;
				if (cart) {
					label = cartTypeLabels.get(cart.cartType);
					label = label ? label + " " : "";
				}

				return label + "Cart";
			}));
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}

	addToCart() {
		this.adding = true;
		if (this.onClick) {
			this.onClick().pipe(take(1)).subscribe(success => {
				this.complete(success);
			});

			return;
		}

		const quantity = this.quantity && this.quantity > 0 ? this.quantity : 1;
		const item = <IAddToCartModel>{ sku: this.skus, quantity, orderId: this.orderId };
		this.store.dispatch(new AddCartItemAttempt({
			item,
			promoCode: this.promo,
			isInCart: this.isInCart,
			lifecycle: { actionSource: this.actionSource },
			completionCallback: this.complete,
		}));
	}

	complete = (result: boolean) => {
		if (!this.ignoreSuggested && !this.editedCartItem) {
			this.getSuggestedProducts();
		}
		if (this.onComplete) {
			this.onComplete(result);
		}
		this.adding = false;
		this.failed = !(this.added = result);

		setTimeout(() => {
			this.added = false;
			this.failed = false;
		}, 2000);
	}

	getSuggestedProducts() {
		if (!this.skus) {
			return;
		}

		const sku = this.skus.indexOf(",") === -1 ? this.skus : this.skus.split(",")[0];
		this.productService.getSuggestedProductSkus(sku).subscribe(result => {
			if (result && result.length) {
				this.popupService.create(SuggestedProductsPopupComponent, { skus: result });
			}
		});
	}
}
