import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { RouterModule } from "@angular/router";
import { HeaderComponent } from "@app/nav/header/header.component";
import { ImpersonationControlsComponent } from "@app/nav/impersonation-controls/impersonation-controls.component";
import { MsrpImpersonationComponent } from "@app/nav/msrp-impersonation/msrp-impersonation.component";
import { NavComponent } from "@app/nav/nav/nav.component";
import { PipesModule } from "@app/pipes/pipes.module";
import { SharedElementsModule } from "@app/shared-elements/shared-elements.module";
import { SharedModule } from "@app/shared/shared.module";
import { NavCartComponent } from "./nav-cart/nav-cart.component";

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		PipesModule,
		RouterModule,
		FormsModule,
		SharedElementsModule,
		MatFormFieldModule,
		MatInputModule
	],
	declarations: [ImpersonationControlsComponent, MsrpImpersonationComponent, NavComponent, HeaderComponent, NavCartComponent],
	exports: [RouterModule, NavComponent, HeaderComponent]
})
export class NavModule { }
