import { ICustomerSummaryData } from "@app/dashboard/dashboard.models";
import { ngStoreCacheItem, objectUpdate } from "@app/shared/helpers";
import { customerSummariesAdapter } from "@app/store/customer-summary/customer-summary.adapter";
import { EntityState } from "@ngrx/entity";
import { CartActions, CartActionType } from "../cart";
import { CustomerSummaryActions, CustomerSummaryActionTypes } from "./customer-summary.actions";

// #region Primary Controller State

export interface ICustomerSummariesState extends EntityState<ICustomerSummaryData> {
	subdealerFilter: string;
}

export const customerSummaryState: ICustomerSummariesState = customerSummariesAdapter.getInitialState({
	subdealerFilter: undefined
});

export function customerSummaryReducer(
	state = customerSummaryState,
	action: CustomerSummaryActions | CartActions
): ICustomerSummariesState {
	switch (action.type) {
		case CustomerSummaryActionTypes.LoadCustomerSummariesSuccess:
			const summary = ngStoreCacheItem(action.payload, 6, "hours");
			const newState = objectUpdate(state, { subdealerFilter: undefined });
			return customerSummariesAdapter.upsertOne(summary, newState);
		case CustomerSummaryActionTypes.UpdateSubdealerFilter:
			return objectUpdate(state, { subdealerFilter: action.payload });
		case CustomerSummaryActionTypes.ClearCustomerSummaries:
			if (action.payload && action.payload.accountId) {
				return customerSummariesAdapter.removeOne(action.payload.accountId, state);
			} else {
				return customerSummariesAdapter.removeAll(state);
			}
		case CartActionType.OrderPlacedSuccess:
			return customerSummariesAdapter.removeAll(state);
		default:
			return state;
	}
}