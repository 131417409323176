import { IAuthResponse, IImpersonateRequest } from "@app/auth/auth.models";
import { IError } from "@app/shared/shared.models";
import { Action } from "@ngrx/store";

export class IframeLoginSuccess implements Action {
	readonly type = AuthActionType.IframeLoginSuccess;
	constructor(public payload: IAuthResponse) { }
}

export class LoginSuccess implements Action {
	readonly type = AuthActionType.LoginSuccess;
	constructor(public payload: IAuthResponse) { }
}

export class MsrpImpersonateAttempt implements Action {
	readonly type = AuthActionType.MsrpImpersonateAttempt;
	constructor() { }
}

export class ImpersonateAttempt implements Action {
	readonly type = AuthActionType.ImpersonateAttempt;
	constructor(public payload: IImpersonateRequest) { }
}

export class ImpersonateSuccess implements Action {
	readonly type = AuthActionType.ImpersonateSuccess;
	constructor(public payload: IAuthResponse) { }
}

export class MsrpImpersonateSuccess implements Action {
	readonly type = AuthActionType.MsrpImpersonateSuccess;
	constructor(public payload: IAuthResponse) { }
}

export class ImpersonateError implements Action {
	readonly type = AuthActionType.ImpersonateError;
	constructor(public payload: IError) { }
}

export class UnimpersonateAttempt implements Action {
	readonly type = AuthActionType.UnimpersonateAttempt;
	constructor() { }
}

export class UnimpersonateSuccess implements Action {
	readonly type = AuthActionType.UnimpersonateSuccess;
	constructor() { }
}

export class LogoutAttempt implements Action {
	readonly type = AuthActionType.LogoutAttempt;
	constructor(public payload?: { loginRedirect: string }) { }
}

export class LogoutSuccess implements Action {
	readonly type = AuthActionType.LogoutSuccess;
}

export class LogoutError implements Action {
	readonly type = AuthActionType.LogoutError;
	constructor(public payload: IError) { }
}

export type AuthActions =
	LoginSuccess
	| IframeLoginSuccess
	| LogoutAttempt
	| LogoutSuccess
	| LogoutError
	| MsrpImpersonateAttempt
	| MsrpImpersonateSuccess
	| ImpersonateAttempt
	| ImpersonateSuccess
	| ImpersonateError
	| UnimpersonateAttempt
	| UnimpersonateSuccess;
export enum AuthActionType {
	LoginSuccess = "[Auth Login] Success",
	IframeLoginSuccess = "[Auth Login Iframe] Success",
	MsrpImpersonateAttempt = "[Auth MSRP Impersonate] Attempt",
	MsrpImpersonateSuccess = "[Auth MSRP Impersonate] Success",
	ImpersonateAttempt = "[Auth Impersonate] Attempt",
	ImpersonateSuccess = "[Auth Impersonate] Success",
	ImpersonateError = "[Auth Impersonate] Error",
	UnimpersonateAttempt = "[Auth] Unimpersonate Attempt",
	UnimpersonateSuccess = "[Auth] Unimpersonate Success",
	LogoutAttempt = "[Auth Logout] Attempt",
	LogoutSuccess = "[Auth Logout] Success",
	LogoutError = "[Auth Logout] Error",
}
