import { Component, OnInit } from "@angular/core";
import { IAppState } from "@app/store";
import { LogoutAttempt } from "@app/store/auth";
import { Store } from "@ngrx/store";

@Component({
	selector: "c4-logout",
	templateUrl: "./logout.component.html",
	styleUrls: ["./logout.component.scss"]
})
export class LogoutComponent implements OnInit {

	constructor(private store: Store<IAppState>) { }

	ngOnInit() {
		this.store.dispatch(new LogoutAttempt());
	}

}
