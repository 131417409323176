import { Pipe, PipeTransform } from "@angular/core";
import { searchCategories, SearchResultItem } from "./search.models";

@Pipe({
	name: "searchIcon",
})
export class SearchIconPipe implements PipeTransform {

	transform(value: SearchResultItem): any {
		return (searchCategories.find(x => x.value === value.category) || { icon: undefined }).icon;
	}

}
