import { Component, ComponentRef, Input, OnInit } from "@angular/core";
import { accountHasAccountRoleFlag, AccountSecurityRole } from "@app/account/account.models";
import { QuickViewPopupComponent } from "@app/popups/quick-view-popup/quick-view-popup.component";
import { SuggestedProductsPopupComponent } from "@app/popups/suggested-products-popup/suggested-products-popup.component";
import { IProductLightModel } from "@app/products/products.models";
import { PopupService } from "@app/services/popup.service";
import { ProductService } from "@app/services/product.service";
import { TrackService } from "@app/services/track.service";
import { IAppState } from "@app/store";
import { getCurrentAccount, getCurrentCurrencySymbol } from "@app/store/account";
import { selectIsMsrp } from "@app/store/auth";
import { BulkLoadProductsLightAttempt, selectProductLightBySkuList } from "@app/store/product-light";
import { currentCanPurchase, currentCanViewPricing } from "@app/store/user";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";

@Component({
	selector: "c4-product-list-item",
	templateUrl: "./product-list-item.component.html",
	styleUrls: ["./product-list-item.component.scss"],
})
export class ProductListItemComponent implements OnInit {
	@Input() product: IProductLightModel;
	@Input() categoryId: number;
	@Input() orderId = 0;

	@Input() sku: string;
	@Input() skus: string[];
	get allSkus() {
		return [...(this.skus || []), this.sku].filter(x => !!x);
	}

	@Input() ignorePromo: boolean;
	@Input() ignoreCustom: boolean;
	@Input() ignoreSuggested: boolean;
	@Input() hideQuickView: boolean;

	@Input() section: string;

	@Input() popupRef?: ComponentRef<SuggestedProductsPopupComponent>;

	canPurchase$: Observable<boolean>;
	canViewPricing$: Observable<boolean>;
	canViewMsrpPricing = false;
	currency$: Observable<string>;
	isMsrp$: Observable<boolean>;

	displayOnlyMessage: string;

	constructor(readonly store: Store<IAppState>, readonly popupService: PopupService, readonly productService: ProductService, readonly tracking: TrackService) { }

	ngOnInit() {
		this.canPurchase$ = this.store.pipe(select(currentCanPurchase));
		this.canViewPricing$ = this.store.pipe(select(currentCanViewPricing));
		this.currency$ = this.store.pipe(select(getCurrentCurrencySymbol));
		this.isMsrp$ = this.store.pipe(select(selectIsMsrp));

		if (!this.product) {
			this.store.pipe(
				select(selectProductLightBySkuList(this.allSkus)),
			).subscribe(product => {
				this.product = product;
				if (!this.product) {
					this.store.dispatch(new BulkLoadProductsLightAttempt(this.allSkus));
				} else {
					this.load();
				}
			});
		} else {
			this.load();
		}

		this.store.pipe(
			select(getCurrentAccount),
		).subscribe(account => {
			this.canViewMsrpPricing = accountHasAccountRoleFlag(account, AccountSecurityRole.MsrpViewing);
		});
	}

	load() {
		this.product.quantity = 1;
		this.tracking.trackImpression(this.product, this.section, this.categoryId || this.product.categoryId);
	}

	closePopup() {
		if (this.popupRef) {
			this.popupRef.destroy();
		}
	}

	openQuickView() {
		this.popupService.create(QuickViewPopupComponent, { sku: this.product.sku, category: this.categoryId });
	}
}
