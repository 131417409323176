import { Component, OnDestroy, OnInit } from "@angular/core";
import { ProductService } from "@app/services/product.service";
import { ILoadCompatibilityFilter, ILoadCompatibilityPart } from "@app/shared/shared.models";
import { Subject } from "rxjs";
import { stringCompareIgnoreCase, valueSort } from "../helpers";

@Component({
	selector: "c4-load-compatibility",
	templateUrl: "./load-compatibility.component.html",
	styleUrls: ["./load-compatibility.component.scss"],
})
export class LoadCompatibilityComponent implements OnInit, OnDestroy {
	brandTypes: string[];
	bulbFixtureTypes: string[];
	technologyTypes: string[];
	voltageTypes: string[];
	skuTypes: string[];

	hasFilter = false;
	filteredLoads: ILoadCompatibilityPart[];
	loads: ILoadCompatibilityPart[];

	private _loadFilter = new ILoadCompatibilityFilter();
	get loadFilter() {
		return this._loadFilter;
	}
	set loadFilter(val: ILoadCompatibilityFilter) {
		this._loadFilter = val;
		this.filterResults();
	}

	private destroyed$ = new Subject<{}>();

	constructor(private productService: ProductService) { }

	ngOnInit() {
		this.populate();
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

	private populate() {
		this.productService.getLightingCompatibilityList().subscribe((result: ILoadCompatibilityPart[]) => {
			if (result) {
				this.loads = this.filteredLoads = result;
				this.loadTypes(this.loads);
			}
		});
	}

	onSkuChange(sku: string) {
		this.updateFilter({ sku });
	}

	onBrandNameChange(brandName: string) {
		this.updateFilter({ brandName });
	}

	onBulbFixtureTypeChange(bulbFixtureType: string) {
		this.updateFilter({ bulbFixtureType });
	}

	onTechnologyTypeChange(technologyTypeName: string) {
		this.updateFilter({ technologyTypeName });
	}

	onVoltageChange(voltage: string) {
		this.updateFilter({ voltage });
	}

	filterResults() {
		let filtered = this.loads;

		if (this.loadFilter.sku) {
			filtered = filtered.filter(part => part.sku === this.loadFilter.sku);
		}

		if (this.loadFilter.brandName) {
			filtered = filtered.filter(part => part.light.brand.brandName === this.loadFilter.brandName);
		}

		if (this.loadFilter.bulbFixtureType) {
			filtered = filtered.filter(part => part.light.bulbType === this.loadFilter.bulbFixtureType);
		}

		if (this.loadFilter.technologyTypeName) {
			filtered = filtered.filter(part => part.light.type && part.light.type.typeName === this.loadFilter.technologyTypeName);
		}

		if (this.loadFilter.voltage) {
			filtered = filtered.filter(part => part.light.voltage && part.light.voltage.toString() === this.loadFilter.voltage);
		}

		this.filteredLoads = filtered;
	}

	updateFilter(newFilters: Partial<ILoadCompatibilityFilter>) {
		if (newFilters) {
			this.hasFilter = true;
			this.loadFilter = Object.assign({}, this.loadFilter, newFilters);
		}
	}

	clearFilter() {
		this.hasFilter = false;
		this.loadFilter = new ILoadCompatibilityFilter();
	}

	private loadTypes(loads: ILoadCompatibilityPart[]) {
		this.brandTypes = this.getDistinctFieldValues(loads, "light.brand.brandName");
		this.bulbFixtureTypes = this.getDistinctFieldValues(loads, "light.bulbType");
		this.technologyTypes = this.getDistinctFieldValues(loads, "light.type.typeName");
		this.voltageTypes = this.getDistinctFieldValues(loads, "light.voltage");
		this.skuTypes = this.getDistinctFieldValues(loads, "sku");
	}

	private getDistinctFieldValues(loads: ILoadCompatibilityPart[], field: string) {
		const nests = field.split(".") as Array<keyof ILoadCompatibilityPart>;

		let fields: any[] = loads;
		nests.forEach(nest => fields = fields.map(load => load && load[nest]));
		const r = [...new Set<string>(fields.filter(item => !!item))];
		const comparer = typeof r.find(x => !!x) === "string" ? stringCompareIgnoreCase : valueSort;

		return r.sort((x, y) => comparer(x, y));
	}
}
