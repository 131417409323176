import { Component, ElementRef, Input, OnInit } from "@angular/core";

@Component({
	selector: "c4-image-wiper",
	templateUrl: "./image-wiper.component.html",
	styleUrls: ["./image-wiper.component.scss"]
})
export class ImageWiperComponent implements OnInit {

	@Input() height: number;
	@Input() width: number;
	@Input() image1: string;
	@Input() image2: string;
	private clicked = false;
	private overlay: HTMLElement;
	private slider: HTMLElement;
	constructor(private elementRef: ElementRef) { }

	ngOnInit() {
		this.overlay = this.elementRef.nativeElement.getElementsByClassName("img-comp-overlay")[0];
		this.slider = this.elementRef.nativeElement.getElementsByClassName("img-comp-slider")[0];
		this.compareImages();
	}
	compareImages() {

		/*set the width of the img element to 50%:*/
		this.overlay.style.width = (this.width / 2) + "px";
		/*create slider:*/
		/*insert slider*/
		/*position the slider in the middle:*/
		this.slider.style.top = (this.height / 2) - (this.slider.offsetHeight / 2) + "px";
		this.slider.style.left = (this.width / 2) - (this.slider.offsetLeft / 2) + "px";
		/*execute a function when the mouse button is pressed:*/
		this.slider.addEventListener("mousedown", this.slideReady);
		/*and another function when the mouse button is released:*/
		window.addEventListener("mouseup", this.slideFinish);
		/*or touched (for touch screens:*/
		this.slider.addEventListener("touchstart", this.slideReady);
		/*and released (for touch screens:*/
		window.addEventListener("touchstop", this.slideFinish);

	}
	slideReady = (e: any) => {
		/*prevent any other actions that may occur when moving over the image:*/
		e.preventDefault();
		/*the slider is now clicked and ready to move:*/
		this.clicked = true;
		/*execute a function when the slider is moved:*/
		window.addEventListener("mousemove", this.slideMove);
		window.addEventListener("touchmove", this.slideMove);
	}
	slideFinish = () => {
		/*the slider is no longer clicked:*/
		this.clicked = false;
	}
	slideMove = (e: any) => {
		let pos;
		/*if the slider is no longer clicked, exit this function:*/
		if (!this.clicked) { return false; }
		/*get the cursor's x position:*/
		pos = this.getCursorPos(e);
		/*prevent the slider from being positioned outside the image:*/
		if (pos < 0) { pos = 0; }
		if (pos > this.width) { pos = this.width; }
		/*execute a function that will resize the overlay image according to the cursor:*/
		this.slide(pos);
	}
	getCursorPos = (e: any) => {
		let a = 0;
		let pos = 0;
		e = e || window.event;
		/*get the x positions of the image:*/
		a = this.overlay.getBoundingClientRect().left;
		/*calculate the cursor's x coordinate, relative to the image:*/
		pos = e.pageX - a;
		/*consider any page scrolling:*/
		pos = pos - window.pageXOffset;
		return pos;
	}
	slide = (px: number) => {
		/*resize the image:*/
		this.overlay.style.width = px + "px";
		this.slider.style.left = px - (this.slider.offsetWidth / 2) + "px";
	}

}
