import { IAddToCartModel } from "@app/checkout/checkout.models";
import { SelectFilter } from "@app/cms/cms.models";
import { IVideoModel } from "@app/marketing/marketing.models";
import { deepcopy } from "@app/shared/helpers";
import { ICacheable, IFilterInfo, IMedia, IPropaganda, Portals } from "@app/shared/shared.models";
import { Dictionary } from "@ngrx/entity";

export interface IStockStatusItem extends IStockInfo {
	isKeycap: boolean;
	isOnBackorder: boolean;
	isPreferredWarehouse: boolean;
	isInStock: boolean;
	shippingEstimateDescription: string;
	shipsFrom: string;
	dealerPrice: number;
	snapPrice: number;
	displayPrice: number;
	requiresLtl: boolean;

	isOversized?: boolean;
	bundleItems?: IBundleItem[];
}

export interface IStockInfo {
	isDisplayOnly: boolean;
	showBackorderOverride: boolean;
	shippingEstimateDescription: string;
	shippingEstimateDescOverride: string;
	isWarehoused: boolean;
	isPartner: boolean;
	isBuiltToOrder: boolean;
	overrideEndDate: Date | string;
}

export interface IProductLightModel extends IStockStatusItem, ICacheable {
	// id: number; This is used for suggested/recommended. We should make an extended model for these
	sku: string;
	name: string;
	msrp: number;
	rebateAmount: number;
	customizationRequiredForCustomer: boolean;
	isVariant: boolean;
	hasPromo: boolean;
	sortOrder: number;
	categoryId: number;
	media: IMedia[];
	available?: boolean;
	url: string;
	primaryImageUrl: string;
	quantity: number;
	bundleItems: IBundleItem[];

	productDiscountId: number;
	productDiscount: number;
	productDiscountPercent: number;
	productDiscountName: string;
	isFlatDiscount: boolean;
	currencyHasDiscount: boolean;

	promoCode?: string;

	productFilters: Dictionary<number>;
	description: string;
}

export interface IProductView extends IProductLightModel, ICacheable {
	description: string;
	releaseNotes: string;
	backorderEmail: string;
	partnerName: string;
	partnerShippingMinimum: number;
	partnerShippingCost: number;
	visibleInPortals: Portals;
	propaganda: IPropaganda;
	customizations: IPartCustomizationsModel;
	recommendedProducts: IProductLightModel[];
	recommendedProductSkus: string[];
	recommendedCategories: IRecommendedCategoryModel[];
	bundleItems: IBundleItem[];
	videos: IVideoModel[];
	supportInfo: ISupportInfo;
	features: string;
	displayOnly: boolean;
	latestFirmware: LatestProductFirmware[];
}

export interface IConnectedProductLightModel extends IProductLightModel {
	id: number;
}

export interface IPartsMarketingSuggestedProduct {
	id: number;
	sku: string;
	suggestedSku: string;
	sortOrder: number;
}

export interface IProductModel extends IStockInfo {
	availableDate: string;
	categories: ICategory[];
	description: string;
	expireDate: string;
	featuredVisibility: Portals;
	inheritInventorySettingsFlag: string;
	isAvailable: boolean;
	isFeaturedProduct: boolean;
	customizationRequiredForCustomer: boolean;
	isCustomPaint: boolean;
	isCustomVeneer: boolean;
	isCustomWidth: boolean;
	isCustomHeight: boolean;
	isCustomGrill: boolean;
	keywords: string;
	lastModified: string;
	lastUser: string;
	manufacturerCode: string;
	media: IMedia[];
	minimumStockQuantity: number;
	name: string;
	packagingQty: number;
	partCode: string;
	prettyPartName: string;
	releaseDate: string;
	releaseNotes: string;
	searchTerms: string;
	sku: string;
	synchId: string;
	taxCode: string;
	visibleInPortals: Portals;
	weight: number;
	supportInfo: ISupportInfo[];
	marketingDescription: IMarketingDescription;
	videos: IVideoModel[];
	filters: SelectFilter[];
	customizations: IPartCustomizationsModel;
	requiresSnapRewards: boolean;
}

export interface IBulkEditProduct {
	skus: string[];
	description: string;
	shippingEstimateDescription: string;
	visibleInPortals: Portals;
	displayOnly?: boolean;
	isAvailable?: boolean;
	requiresSnapRewards?: boolean;
	showBackorderMessage?: boolean;
	categoryIds: number[];
	filterIds: number[];
	videos: IVideoModel[];
	recommendedProducts: IProductLightModel[];
	recommendedCategories: IRecommendedCategoryModel[];
	supportInfo: ISupportInfo;
	marketingDescription: IMarketingDescription;
	isCustomPaint?: boolean;
	isCustomVeneer?: boolean;
	isCustomWidth?: boolean;
	isCustomHeight?: boolean;
	isCustomGrill?: boolean;
	isCustomizationRequiredForCustomer?: boolean;
	safeMode: boolean;
}

export class ICategory {
	id: number;
	categoryId: number;
	sku: string;
	isPrimary?: boolean;
	sortCode: number;

	catalogsCategory: ICatalogsCategory;
}

export interface ISupportInfo {
	id: number;
	sku: string;
	warrantyInfo: string;
	rmaInfo: string;
	techSupportInfo: string;
	visibleInPortals: Portals;
}

export interface IMarketingDescription {
	id: number;
	sku: string;
	dealerUseCases: string;
	testimonials: string;
	sortCode: string;
	features: string;
}

export interface IProductUpgrade {
	accountId: string;
	userId: string;
	deviceUuid: string;
	macAddress: string;
	customerId: string;
}

export interface LatestProductFirmware {
	id: number;
	label: string;
	version: string;
	downloadUrl: string;
	releaseNotes: string;
	releaseDate: Date | string;
}

export interface ProductFirmware {
	id: number;
	label: string;
	description: string;
	skus: string[];
	products: IProductLightModel[];
	versions: ProductFirmwareVersion[];
}

export interface ProductFirmwareVersion {
	label: string;
	downloadUrl: string;
	releaseNotes: string;
	releaseDate: Date | string;
}

export interface IPartCustomizationsModel {
	canCustomize: boolean;
	canCustomizeColor: boolean;
	canCustomizeFinish: boolean;
	canCustomizePaint: boolean;
	canCustomizeVeneer: boolean;
	canCustomizeWidth: boolean;
	canCustomizeHeight: boolean;
	canCustomizeGrill: boolean;
	canCustomizeGrillShape: boolean;
	canCustomizeGrillColor: boolean;
	canCustomizeMount: boolean;
	customizationRequiredForCustomer: boolean;
	customWidthOptions: IPartCustomDimensionOptionModel[];
	customHeightOptions: IPartCustomDimensionOptionModel[];
	customGrillOptions: IPartCustomGrillOptionModel[];
	customVeneerOptions: IPartsCustomizationModel[];
	customColorOptions: IPartCustomColorOptionModel[];
	customVariantOptions: IVariantOptionsModel[];
	sku: string;
}

export interface IPartsCustomizationModel {
	partNumber: string;
	customizationCode: string;
	finalPartNumber: string;
	dimension1: string;
	dimension2: string;
	dimension3: string;
	dimension4: string;
	dimension5: string;
	dimension6: string;
	dimension7: string;
	dimension8: string;
	dimension9: string;
}

export interface IPartCustomDimensionOptionModel extends IPartsCustomizationModel {
	min: number;
	max: number;
	minMm: number;
	maxMm: number;
	displayValue: string;
	displayValueMm: string;
	veneerSku: string;
}

export interface IPartCustomGrillOptionModel extends IPartsCustomizationModel {
	grillShape: string;
	addPartNumber: string;
	nonCustomizedAddPartNumber: string;
	addQuantity: number;
}

export interface IPartCustomColorOptionModel extends IPartsCustomizationModel {
	displayValue: string;
	swatch: string;
	hasImages: boolean;
}

export interface IPartFinishOption {
	value: string;
	image: string;
	type: "veneer" | "paint";
}

export interface IRecommendedCategoryModel {
	id: number;
	sortOrder: number;
	sku: string;
	categoryId: number;
	catalogsCategory?: ICatalogsCategory;
}

export interface IBundleItem {
	sku: string;
	parentSku: string;
	isOnBackorder: boolean;
}

export interface ICatalogsCategory {
	categoryId: number;
	parentId: number;
	catalogId: number;
	categoryCode: string;
	categoryPath: string;
	categoryName: string;
	description: string;
	categoryType: string;
	sortCode: number;
	visibleFlag: string;
	securityLevel: number;
	categoryOptions: string;
	defaultShippingLeadMessage: string;
	longCategoryName: string;
	categoryImageId: number;
	filtersEnabled: boolean;
	imageFilePath: string;
	categoryImage: IMedia;
	visibleInPortals: Portals;
	isPrimary?: boolean;
}

export interface IProductCategoryInfo {
	id: number;
	html: string;
}

export interface IEngravingView {
	categoryId: number;
	variantOptions: IVariantOptionsModel[];
	types: IVariantTypeModel[];
}

export interface IVariantOptionsModel {
	sku: string;
	parentSku: string;
	name: string;
	colorCode: string;
	packagingQuantity: number;
	dealerPrice: number;
	msrp: number;
	backorderEmail: string;
	shippingEstimateDescription: string;
	shippingEstimateDescOverride: string;
	showBackorderOverride: boolean;
	overrideEndDate: Date | string;
	isSubscribedToBackorder: boolean;
	isDisplayOnly: boolean;
	isOnBackorder: boolean;
	productDiscountId: number;
	media: IMedia[];
}

export interface IVariantTypeModel {
	parentSku: string;
	name: string;
	description: string;
	features: string;
	releaseNotes: string;
	parentName: string;
	packs: ISelectListItem[];
	colors: ISelectListItem[];
}

export interface ISelectListItem {
	id: string;
	displayValue: string;
	isSelected: boolean;
}

export interface IVariantDetailsModel {
	propaganda: IPropaganda;
	media: IMedia[];
	recommendedCategories: IRecommendedCategoryModel[];
	recommendedProducts: IProductLightModel[];
	recommendedProductSkus: string[];
}

export interface IKeycapPreview {
	orderDetail: number;
	sku: string;
	type: string;
	topLine: string;
	bottomLine: string;
	richTopLine: IKeycapLinePartModel[];
	richBottomLine: IKeycapLinePartModel[];
	quantity: number;
	location: string;
	textAlign: string;
	isLargeText: boolean;
	icon: number;
	position: string;
	color: ISelectListItem;
	colors: ISelectListItem[];
}

export interface IKeycapLinePartModel {
	value: string;
	isIcon: boolean;
}

export enum StyleOptions {
	None = 0,
	Traditional = 1,
	Contemporary = 2,
}

export const styleOptionNames = new Map<StyleOptions, string>([
	[StyleOptions.None, "No Style Selected"],
	[StyleOptions.Traditional, "Traditional"],
	[StyleOptions.Contemporary, "Contemporary"],
]);

export class KeycapPreview implements IKeycapPreview {
	_initialValue: KeycapPreview;
	_isDirty: boolean;
	sku: string;
	orderId: number;
	topLine: string;
	bottomLine: string;
	buttonType: string;
	showColors: boolean;
	color: ISelectListItem;
	colors: ISelectListItem[];
	quantity: number;
	location: string;
	orderDetail: number;
	textAlign: string;
	isLargeText = false;
	type: string;
	richTopLine: IKeycapLinePartModel[];
	richBottomLine: IKeycapLinePartModel[];
	editMode = false;
	icon: number;
	position: string;
	line1Error = false;
	line2Error = false;
	disableChanges = false;
	constructor(item: IKeycapPreview) {
		this.populate(item);
		this._initialValue = deepcopy(this as KeycapPreview);
	}

	startEdit = () => {
		this._initialValue = deepcopy(this as KeycapPreview);
		this.editMode = true;
	}

	cancelEdit = () => {
		this.populate(this._initialValue);
		this.editMode = false;
	}

	hasRequiredKeycapValues(): boolean {
		return ((this.topLine && this.topLine.length > 0) || this.icon !== 0) && (!this.line1Error && !this.line2Error);
	}

	isGen3(): boolean { return this.sku && this.sku.indexOf("C4-EBD") === 0; }
	isContemporary(): boolean { return this.sku && this.sku.includes("-C-"); }
	getKeycapStyle(): string { return this.isContemporary() ? styleOptionNames.get(StyleOptions.Contemporary) : styleOptionNames.get(StyleOptions.Traditional); }
	engraverEnglishFont(): string { return this.isContemporary() ? "Gotham" : "Calibri"; }
	engraverChineseFont(): string { return this.isContemporary() ? "HanSansSC" : "SimSun"; }
	isChinese(): boolean { return this.topLine && /[\u3400-\u9FBF]/.test(this.topLine) || this.bottomLine && /[\u3400-\u9FBF]/.test(this.bottomLine); }
	fontFamily(): string {
		return this.isGen3() ? this.isChinese() ? `${this.engraverChineseFont()}, ${this.engraverEnglishFont()}, Arial` : `${this.engraverEnglishFont()}, Arial` : "c4keycaps";
	}
	buttonWidth() {
		let width = "74px";
		if (this.sku && this.sku.indexOf("-3C") !== -1) {
			width = "152px";
		}
		if (this.isGen3()) {
			width = "135px";
		}

		return width;
	}
	getTypeText(sku: string) {
		const skuMatch = sku.substring(0, 5);
		let label = "";

		switch (skuMatch) {
			case "AC-3C":
				label = "3 Button";
				break;
			case "AC-6C":
				label = "6 Button";
				break;
			case "C4-EB":
				if (sku.indexOf("1") > 0) {
					label = "Single";
				} else if (sku.indexOf("2") > 0) {
					label = "Double";
				} else if (sku.indexOf("3") > 0) {
					label = "Triple";
				} else {
					label = "Rocker";
				}
				break;
		}

		return label;
	}

	private populate(item: IKeycapPreview) {
		this._isDirty = false;
		this.showColors = false;
		this.icon = 0;
		this.textAlign = "center";

		if (!item) { return; }

		this.sku = item.sku;
		this.topLine = item.topLine;
		this.bottomLine = item.bottomLine;
		this.richTopLine = item.richTopLine;
		this.location = item.location;
		this.quantity = item.quantity || 1;
		this.type = this.getTypeText(item.sku);
		this.colors = item.colors;
		this.color = item.colors.find(c => c.id === item.color.id);
		this.orderDetail = item.orderDetail;
		this.textAlign = item.textAlign;
		this.isLargeText = item.isLargeText;
		this.icon = item.icon;
		this.position = item.position;
		if (item.richTopLine && item.richTopLine.length) {
			const found = item.richTopLine.filter(part => part.isIcon);
			this.icon = found && found.length > 0 ? parseInt(found[0].value, 10) : 0;
			this.position = item.richTopLine[0].isIcon ? "left" : "right";
			item.position = this.position;
			item.icon = this.icon;
		}
	}
}

export class EngravingConfirmationModel {
	name: string;
	rooms: EngravingRoomModel[];
	xml: string;
	orderId: number;
	id: number;
	isValid: boolean;
	hasLegacyKeycaps: boolean;
}

export class EngravingRoomModel {
	name: string;
	keypads: EngravingKeypadModel[];
	hasReviewed: boolean;
	isValid: boolean;
}

export class EngravingKeypadModel {
	name: string;
	buttons: EngravingButtonModel[];
	color: string;
	isValid: boolean;
}

export class EngravingButtonModel {
	position: number;
	slotType: number;
	buttonType: string;
	line1: IKeycapLinePartModel[];
	line2: IKeycapLinePartModel[];
	sku: string;
	icon: string;
	iconPosition: string;
	text: string;
	alignment: string;
	size: string;
	language: string;
	isValid: boolean;
	previewImage: string;
}

export interface IProductListFilter {
	searchText: string;
	filters: IFilterInfo[];
}

export interface IPartsMarketingRecommendedProduct {
	id: number;
	sku: string;
	recommendedSku: string;
	sortOrder: number;
}

export interface IManufacturer {
	id: number;
	code: string;
	name: string;
	description: string;
	logo: string;
	website: string;
	synchDate: string;
	synchFlag: string;
	synchId: string;
	encoding: string;
	contractRequired: boolean;
}

export interface ICustomizations {
	customizedSku: string;
	isCustomized: boolean;
	isValid: boolean;
}

export interface IFinishCustomizations extends ICustomizations {
	paintManufacturer: string;
	paintCode: string;
	finish: IPartFinishOption;
	cabinetColor: IPartCustomColorOptionModel;
	isCustomColor: boolean;
}

export interface IDimensionCustomizations extends ICustomizations {
	option: IPartCustomDimensionOptionModel;
	customDimension: number;
}

export interface IGrillCustomizations extends ICustomizations {
	grillType: string;
	color: string;
	grillPaintCode: string;
	grillPaintManufacturer: string;
	isCustomColor: boolean;
	grillOption: IPartCustomGrillOptionModel;
	addOnItem: IAddToCartModel;
	editedItemAddOn: IAddToCartModel;
	quantity: number;
	isCustomized: boolean;
}

export interface IStockStatus {
	sku: string;
	city: string;
	state: string;
	netAvailable: number;
}

export enum StockingOptions {
	None = 0,
	Warehoused = 1,
	BuiltToOrder = 2,
	FulfilledByPartner = 3,
}

export const stockingOptionNames = new Map<StockingOptions, string>([
	[StockingOptions.Warehoused, "Warehoused"],
	[StockingOptions.BuiltToOrder, "Built to order"],
	[StockingOptions.FulfilledByPartner, "Fulfilled by partner"],
]);

export enum StockOverrideOptions {
	NoOverride = 0,
	OnBackorder = 1,
	CustomMessage = 2,
}

export const stockOverrideNames = new Map<StockOverrideOptions, string>([
	[StockOverrideOptions.NoOverride, "No override"],
	[StockOverrideOptions.OnBackorder, "On backorder"],
	[StockOverrideOptions.CustomMessage, "Custom message"],
]);

