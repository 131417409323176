import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { IAuthResponse } from "@app/auth/auth.models";
import { AuthService } from "@app/services/auth.service";
import { AuthActionType, ImpersonateAttempt, ImpersonateSuccess, UnimpersonateSuccess } from "@app/store/auth";
import { ImpersonateError, LogoutAttempt, LogoutSuccess, MsrpImpersonateSuccess } from "@app/store/auth/auth.actions";
import { Redirect } from "@app/store/router";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, debounceTime, map, mergeMap } from "rxjs/operators";

@Injectable()
export class AuthEffects {
	constructor(private actions$: Actions, private authService: AuthService, private router: Router) { }

	@Effect()
	impersonate$ = this.actions$.pipe(
		ofType(AuthActionType.ImpersonateAttempt),
		debounceTime(500),
		mergeMap((action: ImpersonateAttempt) => {
			return this.authService.impersonate(action.payload).pipe(
				map((info: IAuthResponse) => {
					this.authService.saveCurrentJwt(info.jwt);
					if (action.payload.redirectUrl) {
						this.router.navigateByUrl(action.payload.redirectUrl);
					}

					return new ImpersonateSuccess(info);
				}),
				catchError((err: HttpErrorResponse) => of(new ImpersonateError({ error: err.message || err.statusText }))),
			);
		}),
	);

	@Effect()
	msrpImpersonate$ = this.actions$.pipe(
		ofType(AuthActionType.MsrpImpersonateAttempt),
		debounceTime(500),
		mergeMap(() => {
			return this.authService.impersonateMsrp().pipe(
				mergeMap((info: IAuthResponse) => {
					this.authService.saveCurrentJwt(info.jwt);
					if (window.location.pathname.indexOf("/products") === 0) {
						return [new MsrpImpersonateSuccess(info)];
					} else {
						return [new MsrpImpersonateSuccess(info), new Redirect({ path: ["/products"] })];
					}
				}),
				catchError((err: HttpErrorResponse) => of(new ImpersonateError({ error: err.message || err.statusText }))),
			);
		}),
	);

	@Effect()
	unimpersonate$ = this.actions$.pipe(
		ofType(AuthActionType.UnimpersonateAttempt),
		map(() => {
			this.authService.clearImpersonatedJwt();

			return new UnimpersonateSuccess();
		}),
	);

	@Effect()
	logout$ = this.actions$.pipe(
		ofType(AuthActionType.LogoutAttempt),
		mergeMap((action: LogoutAttempt) => {
			this.authService.clearJwts();
			if (action.payload && action.payload.loginRedirect) {
				return [new LogoutSuccess(), new Redirect({ path: ["/login"], query: { redirect: action.payload.loginRedirect } })];
			} else {
				return [new LogoutSuccess()];
			}
		}),
	);

}
