import { ComponentFactoryResolver, ComponentRef, Inject, Injectable, ViewContainerRef } from "@angular/core";
import { IPopupComponent, IPopupInfo } from "@app/popups/popups.models";

@Injectable({
	providedIn: "root"
})
export class PopupService {
	private rootViewContainer: ViewContainerRef;
	private factoryResolver: ComponentFactoryResolver;

	private oldPopup: ComponentRef<{}>;


	constructor(@Inject(ComponentFactoryResolver) factoryResolver: ComponentFactoryResolver) {
		this.factoryResolver = factoryResolver;
	}

	setDefaultViewContainer(viewContainerRef: ViewContainerRef) {
		this.rootViewContainer = viewContainerRef;
	}

	create<T>(component: any, data?: IPopupInfo, container?: ViewContainerRef) {
		this.clear();
		const factory = this.factoryResolver
			.resolveComponentFactory(component);
		container = (container || this.rootViewContainer);
		const componentRef = factory.create(container.parentInjector);
		componentRef.location.nativeElement.className = "popup-container";
		if (data) {
			Object.assign(componentRef.instance, data);
		}
		const converted = (<IPopupComponent>componentRef.instance);
		converted.self = componentRef;
		if (!converted.close) {
			converted.close = () => {
				converted.self.destroy();
			};
		}
		componentRef.changeDetectorRef.detectChanges();
		container.insert(componentRef.hostView);
		this.oldPopup = componentRef;
		return componentRef as ComponentRef<T>;
	}

	clear() {
		if (this.oldPopup) {
			this.oldPopup.destroy();
		}
	}
}
