import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "@app/services/auth.service";
import { SiteFeatureFlag } from "@app/shared/shared.models";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, debounceTime, map, mergeMap } from "rxjs/operators";
import { AppInfoActionTypes, LoadFeatureFlagsError, LoadFeatureFlagsSuccess } from "./app-info.actions";

@Injectable()
export class AppInfoEffects {
	constructor(
		private actions$: Actions,
		private authService: AuthService
	) { }

	@Effect()
	loadFeatureFlags$: Observable<Action> = this.actions$.pipe(
		ofType(AppInfoActionTypes.LoadFeatureFlagsAttempt),
		debounceTime(100),
		mergeMap(() => {
			return this.authService
				.getSiteFeatureFlags()
				.pipe(
					map((info: SiteFeatureFlag[]) => new LoadFeatureFlagsSuccess(info)),
					catchError((err: HttpErrorResponse) => of(new LoadFeatureFlagsError({ error: err.message || err.statusText })))
				);
		})
	);
}
