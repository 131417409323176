import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DashboardService } from "@app/services/dashboard.service";
import { LeadPreferencesActionTypes, LoadLeadPreferencesError, LoadLeadPreferencesSuccess, SaveLeadPreferencesAttempt, SaveLeadPreferencesError, SaveLeadPreferencesSuccess } from "@app/store/lead-preferences";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";

@Injectable()
export class LeadPreferencesEffects {
	constructor(
		private actions$: Actions,
		private dashboardService: DashboardService
	) { }

	@Effect()
	loadLeadPreferences$: Observable<Action> = this.actions$.pipe(
		ofType(LeadPreferencesActionTypes.LoadLeadPreferencesAttempt),
		mergeMap(() => {
			return this.dashboardService
				.getLeadPreferences()
				.pipe(
					map((info) => new LoadLeadPreferencesSuccess(info)),
					catchError((err: HttpErrorResponse) => of(new LoadLeadPreferencesError({ error: err.status !== 403 ? err.message || err.statusText : "Access forbidden" })))
				);
		})
	);

	@Effect()
	saveLeadPreferences$: Observable<Action> = this.actions$.pipe(
		ofType<SaveLeadPreferencesAttempt>(LeadPreferencesActionTypes.SaveLeadPreferencesAttempt),
		mergeMap((action) => {
			return this.dashboardService
				.updateLeadPreferences(action.payload)
				.pipe(
					map((info) => info ? new SaveLeadPreferencesSuccess(action.payload) : new SaveLeadPreferencesError({ error: "Preferences failed to update" })),
					catchError((err: HttpErrorResponse) => of(new SaveLeadPreferencesError({ error: err.status !== 403 ? err.message || err.statusText : "Access forbidden" })))
				);
		})
	);
}
