import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ChartColor, ChartInfo } from "@app/dashboard/dashboard.models";
import { ChartService } from "@app/services/chart.service";
import { ChartLegendItem } from "chart.js";

@Component({
	selector: "c4-pie-chart",
	templateUrl: "./pie-chart.component.html",
	styleUrls: ["./pie-chart.component.scss"],
})
export class PieChartComponent implements OnInit {
	@ViewChild("chartCtx", { static: false }) chartCtx: ElementRef<HTMLCanvasElement>;

	@Input() chartHeight: number;
	@Input() display: boolean;
	@Input() set data(val: ChartInfo) {
		if (val) {
			setTimeout(() => this.update(val.colors, val.labels, val.data), 0);
		}
	}
	@Output() sectionClick = new EventEmitter<string>();
	chart: Chart;

	constructor(readonly chartService: ChartService) { }

	ngOnInit() {
	}

	private update(colors: ChartColor[], labels: string[], data: number[]) {
		if (!this.chart) {
			this.chart = this.chartService.getDefaultDoughnutChartOptions(this.chartCtx.nativeElement);
			this.chart.config.options.onClick = this.sectionOnClick;
			this.chart.config.options.legend.onClick = this.legendOnClick;
		}
		this.updateChartColors(this.chart, colors);
		this.chart.data.labels = labels;
		this.chart.data.datasets[0].data = data;
		this.chart.update();
	}

	sectionOnClick = (_: MouseEvent, element: any) => {
		if (element && element[0]) {
			const label = this.chart.data.labels[element[0]._index] as string;
			this.sectionClick.emit(label);
		}
	}

	legendOnClick = (_: MouseEvent, legendItem: ChartLegendItem) => {
		this.sectionClick.emit(legendItem.text);
	}

	private updateChartColors(chart: Chart, colors: ChartColor[]) {
		chart.data.datasets[0].backgroundColor = colors ? this.chartService.getInfoColors(colors) : this.chartService.newDonutChartColors;
	}
}
