import { Injectable } from "@angular/core";
import { OrderService } from "@app/services/order.service";
import { LoadAccountBalanceError } from "@app/store/account-balance/account-balance.actions";
import { CreditCardTypeActionTypes, LoadAllCreditCardTypesError, LoadAllCreditCardTypesSuccess } from "@app/store/credit-card-type/credit-card-type.actions";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";

@Injectable()
export class CreditCardTypeEffects {
	constructor(
		private actions$: Actions,
		private orderService: OrderService
	) { }

	@Effect()
	loadAllCreditCardTypes$: Observable<Action> = this.actions$.pipe(
		ofType(CreditCardTypeActionTypes.LoadAllAttempt),
		mergeMap(() => {
			return this.orderService
				.getCreditCardTypes()
				.pipe(
					map((info) => info && info.length ? new LoadAllCreditCardTypesSuccess(info) : new LoadAllCreditCardTypesError({ error: "There was an error trying to load credit card types" })),
					catchError((err) => of(new LoadAccountBalanceError({ error: err.message || err.statusText })))
				);
		})
	);
}
