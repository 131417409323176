import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ExternalRedirect, Redirect, RouterActionTypes } from "@app/store/router/router.actions";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { debounceTime, tap } from "rxjs/operators";

@Injectable()
export class RouterEffects {
	@Effect({ dispatch: false })
	navigate$ = this.actions$.pipe(
		ofType<Redirect>(RouterActionTypes.Redirect),
		debounceTime(300),
		tap(redirect => {
			if (redirect.payload.url == null) {
				this.router.navigate(redirect.payload.path, {
					queryParams: redirect.payload.query,
					skipLocationChange: redirect.payload.skipLocationChange,
				});
			} else {
				this.router.navigateByUrl(redirect.payload.url, { skipLocationChange: redirect.payload.skipLocationChange });
			}
		},
		),
	);

	@Effect({ dispatch: false })
	redirectExternal$ = this.actions$.pipe(
		ofType<ExternalRedirect>(RouterActionTypes.ExternalRedirect),
		debounceTime(300),
		tap(redirect => window.location.href = redirect.url),
	);

	constructor(
		private actions$: Actions,
		private router: Router,
	) { }
}
