import { Component, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { ContractPopupComponent } from "@app/popups/contract-popup/contract-popup.component";
import { DealerRolePopupComponent } from "@app/popups/dealer-role-popup/dealer-role-popup.component";
import { MigrationScheduledPopupComponent } from "@app/popups/migration-scheduled-popup/migration-scheduled-popup.component";
import { AuthService } from "@app/services/auth.service";
import { PopupService } from "@app/services/popup.service";
import { StaticPageFormsService } from "@app/services/static-page-forms.service";
import { TrackService } from "@app/services/track.service";
import { getNgStoreCacheItem } from "@app/shared/helpers";
import { ContractType } from "@app/shared/shared.models";
import { IAppState } from "@app/store";
import { getActiveUserAccount, getCurrentAccount, LoadAccountAttempt } from "@app/store/account";
import { isAuthenticated } from "@app/store/auth";
import { getCurrentTermsOfSales, LoadContractAttempt } from "@app/store/contract";
import { currentHasMigrationScheduled, LoadUserAttempt, selectActiveUser, selectCurrentUser } from "@app/store/user";
import { select, Store } from "@ngrx/store";
import { combineLatest, Subject } from "rxjs";
import { filter, switchMap, takeUntil } from "rxjs/operators";

@Component({
	selector: "c4-site-layout",
	templateUrl: "./site-layout.component.html",
	styleUrls: ["./site-layout.component.scss"],
})
export class SiteLayoutComponent implements OnInit, OnDestroy {

	isAuthenticated: boolean;
	year: number;

	showFeedback: boolean;

	private destroyed$ = new Subject<{}>();

	constructor(
		private popupService: PopupService,
		private store: Store<IAppState>,
		private staticPageFormsService: StaticPageFormsService,
		private router: Router,
		private tracking: TrackService,
		private authService: AuthService,
	) {

		const navChange = this.router.events.pipe(filter(item => item instanceof NavigationEnd), switchMap(() => this.store.pipe(select(getCurrentTermsOfSales))));

		combineLatest([
			navChange,
			this.store.pipe(select(selectActiveUser)),
			this.store.pipe(select(getActiveUserAccount))
		]).pipe(
			takeUntil(this.destroyed$),
		).subscribe(([contract, actUser, actAccount]) => {
			if (!contract) {
				this.store.dispatch(new LoadContractAttempt(ContractType.TermsOfSales));
			} else if (actUser && !contract.isSigned) {
				this.popupService.create(ContractPopupComponent, { contract });
			} else if (actUser && !actUser.primaryRole) {
				this.popupService.create(DealerRolePopupComponent);
			}
			if (actUser && !getNgStoreCacheItem(actUser)) {
				this.store.dispatch(new LoadUserAttempt({ userId: actUser.userId }));
			}
			if (actAccount && !getNgStoreCacheItem(actAccount)) {
				this.store.dispatch(new LoadAccountAttempt(actAccount.id));
			}
			this.tracking.updateUserTracking(actUser);
		});

		// Active and current must be kept separate to prevent conflicts with closing the dealer role popup
		combineLatest(
			this.store.pipe(select(selectCurrentUser)),
			this.store.pipe(select(getCurrentAccount)),
		).pipe(
			takeUntil(this.destroyed$),
		).subscribe(([curUser, curAccount]) => {
			if (curUser && !getNgStoreCacheItem(curUser)) {
				this.store.dispatch(new LoadUserAttempt({ userId: curUser.userId }));
			}
			if (curAccount && !getNgStoreCacheItem(curAccount)) {
				this.store.dispatch(new LoadAccountAttempt(curAccount.id));
			}
			this.tracking.updateImpersonatedUserTracking(curUser);
		});

		combineLatest([
			this.store.pipe(select(isAuthenticated)),
			this.store.pipe(select(currentHasMigrationScheduled()))
		]).pipe(
			takeUntil(this.destroyed$),
		).subscribe(([auth, currentHasMigrationScheduled]) => {
			const hasActiveJwt = this.authService.getActiveJwt();
			if (auth) {
				if (hasActiveJwt && currentHasMigrationScheduled) {
					this.popupService.create(MigrationScheduledPopupComponent);
				}
			}
		});
	}

	ngOnInit() {
		this.staticPageFormsService.commonFeedbackDisplay.subscribe((result: boolean) => this.showFeedback = result);
		this.year = new Date().getFullYear();
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}

	toggleFeedback(): void {
		this.staticPageFormsService.commonFeedbackDisplay.emit(!this.showFeedback);
	}
}
