import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "c4-widget",
	templateUrl: "./widget.component.html",
	styleUrls: ["./widget.component.scss"],
})
export class WidgetComponent implements OnInit {
	@Input() header: string;
	@Input() headerColor: string;
	@Input() expand: boolean;
	@Input() expandable: boolean;
	@Input() buttons: IWidgetButtonModel[];
	@Input() widgetClass: string;

	headerClass: string;
	initialExpand: boolean;
	cursor: string;

	constructor() { }

	ngOnInit() {
		this.widgetClass = this.widgetClass || "";
		this.initialExpand = this.expand || !this.expandable;
		this.headerClass = `widget-header-${this.headerColor || "white"}`;
		this.cursor = this.expandable ? "default" : "pointer";
	}

	toggleExpand() {
		this.expand = !this.expand;
		this.initialExpand = this.initialExpand || this.expand;
	}
}

export interface IWidgetButtonModel {
	label: string;
	click: () => void;
	class?: string;
}
