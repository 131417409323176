import { AccountOrderHistory } from "@app/account/account.models";
import { ngStoreCacheItem, objectUpdate } from "@app/shared/helpers";
import { OrderHistoryActions, OrderHistoryActionTypes } from "@app/store/order-history/order-history.actions";
import { orderHistoryAdapter } from "@app/store/order-history/order-history.adapter";
import { EntityState } from "@ngrx/entity";

export interface OrderHistoryState extends EntityState<AccountOrderHistory> {
	selected: number;
}

export const initialState: OrderHistoryState = orderHistoryAdapter.getInitialState({
	selected: 0,
});

export function orderHistoryReducer(
	state = initialState,
	action: OrderHistoryActions,
): OrderHistoryState {
	switch (action.type) {
		case OrderHistoryActionTypes.LoadAccountOrderHistorySuccess:
			return orderHistoryAdapter.upsertOne(ngStoreCacheItem(action.payload, 10, "minutes"), state);
		case OrderHistoryActionTypes.ToggleSelectOrderHistory:
			return objectUpdate(state, { selected: state.selected !== action.webOrderId ? action.webOrderId : 0 });
		default: {
			return state;
		}
	}
}