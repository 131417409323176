import { ISecurityRoleDetail } from "@app/account/account.models";
import { ngStoreCacheItem } from "@app/shared/helpers";
import { securityRolesAdapter } from "@app/store/security-roles/secuirity-roles.adapter";
import { SecurityRolesActions, SecurityRolesActionTypes } from "@app/store/security-roles/security-roles.actions";
import { EntityState } from "@ngrx/entity";

export interface ISecurityRolesState extends EntityState<ISecurityRoleDetail> { }

export const initialState: ISecurityRolesState = securityRolesAdapter.getInitialState();

export function securityRolesReducer(
	state = initialState,
	action: SecurityRolesActions
): ISecurityRolesState {
	switch (action.type) {
		case SecurityRolesActionTypes.LoadSuccess:
			return securityRolesAdapter.upsertMany(action.payload.map(x => ngStoreCacheItem(x, 6, "hours")), state);
		default:
			return state;
	}
}