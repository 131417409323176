import { IStockStatus } from "@app/products/products.models";
import { ICacheable } from "@app/shared/shared.models";
import { Action } from "@ngrx/store";

export class LoadProductStockStatusAttempt implements Action {
	readonly type = StockStatusActionTypes.LoadProductStockStatusAttempt;
	constructor(public payload: { sku: string }) { }
}

export class LoadProductStockStatusSuccess implements Action {
	readonly type = StockStatusActionTypes.LoadProductStockStatusSuccess;
	constructor(public payload: IStockStatusResponsePayload) { }
}

export class LoadProductStockStatusError implements Action {
	readonly type = StockStatusActionTypes.LoadProductStockStatusError;
	constructor(public payload: string) { }
}

export interface IStockStatusResponsePayload extends ICacheable {
	stockStatus: IStockStatus[];
	sku: string;
}

export type StockStatusActions =
	LoadProductStockStatusAttempt
	| LoadProductStockStatusSuccess
	| LoadProductStockStatusError;
export enum StockStatusActionTypes {
	LoadProductStockStatusAttempt = "[Product Stock Status Load] Attempt",
	LoadProductStockStatusSuccess = "[Product Stock Status Load] Success",
	LoadProductStockStatusError = "[Product Stock Status Load] Failure",
}
