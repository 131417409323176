import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ControllerActionType, ControllerOs, ControllerOsGroup, ControllerType, ControllerTypeGroup, ICustomerSummary, ICustomerSummaryData, IPresetConsumerFilter } from "@app/dashboard/dashboard.models";
import { IProductUpgrade } from "@app/products/products.models";
import { equalsIgnoreCase, startsWithIgnoreCase, stringCompareIgnoreCase } from "@app/shared/helpers";
import { compareAsc as dateFnsCompareAsc } from "date-fns";

@Injectable({
	providedIn: "root",
})
export class ConsumerService {

	constructor(private http: HttpClient) { }

	getCustomerChartSegments() {
		return this.http.get<{
			controllerOs: ControllerOs[],
			controllerOsGroups: ControllerOsGroup[],
			controllerTypes: ControllerType[],
			controllerTypeGroups: ControllerTypeGroup[],
		}>("api/dashboard/getCustomerChartSegments");
	}

	getCustomerPresetFilters(slug?: string) {
		return this.http.get<IPresetConsumerFilter[]>("api/dashboard/getPresetFilters", { params: { slug } });
	}

	getCustomerSummaries() {
		return this.http.get<ICustomerSummaryData>("api/dashboard/GetCustomerSummaries");
	}

	exportCustomerSummaries(consumers?: ICustomerSummary[]) {
		return this.http.post("api/dashboard/exportCustomerSummaries", consumers || [], { responseType: "blob" });
	}

	upgradeOrdered(upgrade: IProductUpgrade) {
		return this.http.post<boolean>("api/dashboard/UpgradeOrdered", upgrade);
	}

	getSortFn(sortField: keyof ICustomerSummary, desc: boolean): (a: ICustomerSummary, b: ICustomerSummary) => number {
		switch (sortField) {
			case "consumerName":
				return (a, b) => this.compareName(a, b, desc);
			case "controllerOs":
				return (a, b) => this.compareSoftware(a, b, desc);
			case "action":
				return (a, b) => this.compareAction(a, b, desc);
			case "lastCheckedInAt":
				return (a, b) => this.compareLastCheckedInAt(a, b, desc);
			case "controllerType":
				return (a, b) => this.compareController(a, b, desc);
		}
	}

	compareLastCheckedInAt(a: ICustomerSummary, b: ICustomerSummary, desc?: boolean) {
		const score = dateFnsCompareAsc(a.lastCheckedInAt, b.lastCheckedInAt);
		if (score === 0) {
			return this.compareName(a, b);
		}
		return desc ? score * -1 : score;
	}

	compareSoftware(a: ICustomerSummary, b: ICustomerSummary, desc?: boolean) {
		const first = desc ? -1 : 1;
		const second = desc ? 1 : -1;

		const aVersion = this.getSoftwareVersion(a.controllerOs);
		const bVersion = this.getSoftwareVersion(b.controllerOs);

		if (aVersion.length !== bVersion.length) {
			return aVersion.length > bVersion.length ? first : second;
		}

		for (let i = 0; i < aVersion.length; i++) {
			if (aVersion[i] !== bVersion[i]) {
				return aVersion[i] > bVersion[i] ? first : second;
			}
		}
		return stringCompareIgnoreCase(a.consumerName, b.consumerName);
	}

	getSoftwareVersion(os: string) {
		if (!os || equalsIgnoreCase(os, "unknown")) {
			return [-2];
		}
		return os.split(".").map(x => parseInt(x, 10));
	}

	private compareAction(a: ICustomerSummary, b: ICustomerSummary, desc?: boolean) {
		const aScore = this.scoreAction(a.action);
		const bScore = this.scoreAction(b.action);
		if (aScore === bScore) {
			return stringCompareIgnoreCase(a.consumerName, b.consumerName);
		}
		return (aScore > bScore ? 1 : -1) * (desc ? -1 : 1);
	}

	private scoreAction(a: ControllerActionType) {
		switch (a) {
			case ControllerActionType.Outdated:
				return 1;
			case ControllerActionType.Requested:
				return 2;
			case ControllerActionType.OrderPending:
				return 3;
			case ControllerActionType.InstallationPending:
				return 4;
			case ControllerActionType.Upgraded:
				return 5;
			case ControllerActionType.UpToDate:
				return 6;
		}
		return 0;
	}

	compareController(a: ICustomerSummary, b: ICustomerSummary, desc?: boolean) {
		const aScore = this.scoreController(a.controllerType);
		const bScore = this.scoreController(b.controllerType);
		if (aScore === bScore) {
			return stringCompareIgnoreCase(a.consumerName, b.consumerName);
		}
		return (aScore > bScore ? 1 : -1) * (desc ? -1 : 1);
	}

	private scoreController(controller: string) {
		if (!controller || equalsIgnoreCase(controller, "unknown")) {
			return 0;
		}
		if (startsWithIgnoreCase(controller, "EA")) {
			return 10000 + parseInt(controller.substring(3), 10);
		}
		if (startsWithIgnoreCase(controller, "CA")) {
			return 9900 + parseInt(controller.substring(3), 10);
		}
		if (startsWithIgnoreCase(controller, "HC")) {
			return 1000 + parseInt(controller.substring(3), 10);
		}
		return 1;
	}

	compareName(a: ICustomerSummary, b: ICustomerSummary, desc?: boolean) {
		const aName = (a.consumerName || "").trim();
		const bName = (b.consumerName || "").trim();
		const score = stringCompareIgnoreCase(aName, bName);
		return desc ? score * -1 : score;
	}
}
