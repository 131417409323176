import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Account, IAccountSalesInfo, IBakpakCodes, IDealerStatusProgramInfoModel, IDealerStatusTierRequirementsModel, ISecurityRoleDetail, ISubDealerLocatorModel, IUpgradeRequest, IUpgradeRequestFile, IUser } from "@app/account/account.models";
import { IRebateDataModel } from "@app/shared-widgets/rebate.models";
import { IStatusProgress } from "@app/shared-widgets/shared-widgets.models";
import { IAccountBalanceResult, IControl4ContactsModel, IGenericResponse, ISalesContactsModel, Portals } from "@app/shared/shared.models";
import { IAppState } from "@app/store";
import { AccountResult } from "@app/store/account";
import { getCurrentPortal } from "@app/store/app-info/app-info.selectors";
import { IDealerLocatorsResult } from "@app/store/dealer-locator/dealer-locator.actions";
import { ISupportPhone } from "@app/support/support.models";
import { select, Store } from "@ngrx/store";
import { from } from "rxjs";

@Injectable({ providedIn: "root" })
export class AccountService {
	private currentPortal: Portals;
	constructor(private http: HttpClient, private store: Store<IAppState>) {
		this.store.pipe(
			select(getCurrentPortal),
		).subscribe(portal => this.currentPortal = portal);
	}

	get(accountId: string) {
		return this.http.get<AccountResult>("api/account/getAccount", { params: { accountId } });
	}

	getSecurityRoles() {
		return this.http.get<ISecurityRoleDetail[]>("api/account/getSecurityRoles");
	}

	getControl4Contacts() {
		return this.http.get<IControl4ContactsModel>("api/account/GetControl4Contacts");
	}

	getSalesContacts() {
		return this.http.get<ISalesContactsModel>("api/account/GetSalesContacts");
	}

	getStatusProgress() {
		return this.http.get<IStatusProgress>("api/account/GetStatusWidgetInfo");
	}

	getAccountSalesInfo() {
		return this.http.get<IAccountSalesInfo>("api/account/GetYearlySales");
	}

	getRebateData() {
		return this.http.get<IRebateDataModel>("api/rewards/GetRebateSalesData");
	}

	getGoalInfo() {
		return this.http.get<IDealerStatusProgramInfoModel>("api/account/getGoalInfo");
	}

	getBakpakCodes() {
		return this.http.get<IBakpakCodes>("api/account/GetMyBakpakCodes");
	}

	getAccountBalance(forceRefresh: boolean = false) {
		const params = new HttpParams();
		if (forceRefresh) {
			params.set("v", new Date().getTime().toString());
		}

		return this.http.get<IAccountBalanceResult>("api/account/GetAccountBalance", { params });
	}

	lookupByPartialName = (name: string) => {
		if (!name) {
			return from<Account[]>([]);
		}

		return this.http.get<Account[]>("api/account/LookupByPartialName", { params: { name } });
	}

	lookupByPartialUsernameOrTechnicianID = (name: string) => {
		if (!name) {
			return from<Account[]>([]);
		}

		return this.http.get<Account[]>("api/account/LookupByPartialUsernameOrTechnicianID", { params: { name } });
	}

	submitUpgradeRequest(request: IUpgradeRequest) {
		return this.http.put<IGenericResponse<IUpgradeRequest>>("api/account/submitUpgradeRequest", request);
	}

	removeUpgradeRequestFile(id: number) {
		return this.http.delete<boolean>("api/account/deleteUpgradeRequestFile", { params: { id: id.toString() } });
	}

	getUpgradePromotionFiles() {
		return this.http.get<IUpgradeRequestFile[]>("api/account/GetUpgradePromotionFiles");
	}

	getImpersonatingUsers(accountId: string) {
		return this.http.get<IUser[]>("api/account/getImpersonatingUsers", { params: { accountId } });
	}

	exportUsers() {
		return this.http.get("api/account/exportUsers", { responseType: "blob" });
	}

	getSubDealerLocators() {
		return this.http.get<IDealerLocatorsResult>("api/distributor/GetSubDealerLocators");
	}

	modifySubDealerLocator(subDealerLocator: ISubDealerLocatorModel) {
		return this.http.put<boolean>("api/distributor/UpdateDealerLocator", subDealerLocator);
	}

	getStatusForUrl(premierStatus: string): string {
		let statusLevel = premierStatus ? premierStatus.toLowerCase() : "authorized";
		if (premierStatus === "none") {
			statusLevel = "authorized";
		}
		switch (this.currentPortal) {
			case Portals.DealerEmea:
			case Portals.DealerUs:
				return statusLevel + "-status";
			case Portals.Pakedge:
				return `pakedge-dealer-${statusLevel}-status`;
			case Portals.Triad:
				return `triad-dealer-${statusLevel}-status`;
			default:
				return "";
		}
	}

	getSupportNumbers() {
		return this.http.get<ISupportPhone[]>("api/account/GetSupportNumbers");
	}

	getTrainingGoals() {
		return this.http.get<IDealerStatusTierRequirementsModel>("api/account/GetTrainingGoals");
	}
}
