import { Pipe, PipeTransform } from "@angular/core";
import { distanceInWordsStrict } from "date-fns";

@Pipe({
	name: "distanceFromNow"
})
export class DistanceFromNowPipe implements PipeTransform {

	transform(value: string | Date, unit: "s" | "m" | "h" | "d" | "M" | "Y" = "h", rounding: "floor" | "ceil" | "round" = "floor"): any {
		return distanceInWordsStrict(new Date(), value, { unit, partialMethod: rounding, addSuffix: true });
	}

}
