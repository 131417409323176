import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { environment } from "@env/environment";
import { Angulartics2Module } from "angulartics2";

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		Angulartics2Module.forRoot({ pageTracking: { clearHash: true, clearQueryParams: true } }),
	]
})
export class TrackingModule {
	constructor() {
		window.ga("create", environment.trackingCode, "auto");
		window.ga("require", "ec");
	}
}
