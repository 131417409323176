import { Component, ComponentRef, OnInit } from "@angular/core";
import { CmsFirmwareVersion } from "@app/cms/cms-firmware/cms-firmware.models";
import { FirmwareService } from "@app/services/firmware.service";
import { MediaService } from "@app/services/media.service";
import { ICloudinaryUploadResult } from "@app/shared-elements/shared-elements.models";
import { IAppState } from "@app/store";
import { ErrorToast } from "@app/store/toast/toast.actions";
import { Store } from "@ngrx/store";
import { IPopupComponent } from "../popups.models";

@Component({
	selector: "c4-firmware-version-edit-popup",
	templateUrl: "./firmware-version-edit-popup.component.html",
	styleUrls: ["./firmware-version-edit-popup.component.scss"],
})
export class FirmwareVersionEditPopupComponent implements OnInit, IPopupComponent {
	close: () => void;
	self: ComponentRef<FirmwareVersionEditPopupComponent>;

	version: CmsFirmwareVersion;
	edit: CmsFirmwareVersion;
	firmwareName: string;
	save: (update: CmsFirmwareVersion) => void;

	constructor(readonly store: Store<IAppState>, readonly firmwareService: FirmwareService, readonly mediaService: MediaService) { }

	ngOnInit() {
		this.edit = { ...this.version };
	}

	onSave() {
		this.firmwareService.saveFirmwareVersion(this.edit).subscribe(result => {
			if (result.success) {
				this.save(result.result);
				this.close();
			} else {
				this.store.dispatch(new ErrorToast({ message: result.errorMessage }));
			}
		});
	}

	addFile(results: ICloudinaryUploadResult[]) {
		if (results && results.length) {
			this.edit.downloadUrl = results[0].secure_url;
		}
	}
}
