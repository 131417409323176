import { AfterViewInit, Directive, ElementRef, Input } from "@angular/core";

@Directive({
	selector: "[c4Focus]"
})
export class FocusDirective implements AfterViewInit {

	@Input("c4Focus") focus = true;

	constructor(private el: ElementRef) {
	}

	ngAfterViewInit() {
		this.focus = this.focus !== false;
		if (this.focus && document.activeElement !== this.el.nativeElement) {
			setTimeout(() => this.el.nativeElement.focus());
		}
	}

}
