import { CommonModule } from "@angular/common";
import { AfterViewInit, Compiler, Component, ComponentRef, Input, NgModule, OnDestroy, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { IStaticContent } from "@app/cms/cms.models";
import { PipesModule } from "@app/pipes/pipes.module";
import { ProductsSharedModule } from "@app/products-shared/products-shared.module";
import { StaticContentService } from "@app/services/static-content.service";
import { SharedElementsModule } from "@app/shared-elements/shared-elements.module";
import { SharedModule } from "@app/shared/shared.module";
import { IAppState } from "@app/store";
import { selectImpersonatedUser } from "@app/store/user";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { mergeMap, takeUntil } from "rxjs/operators";

@Component({
	selector: "c4-static-content",
	templateUrl: "./static-content.component.html",
	styleUrls: ["./static-content.component.scss"],
})
export class StaticContentComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild("container", { read: ViewContainerRef, static: false })
	vc: ViewContainerRef;

	@Input() id: string;
	@Input() template: string;

	cmpRef: ComponentRef<any>;
	initialized: boolean;
	data: IStaticContent;

	private destroyed$ = new Subject<{}>();

	constructor(private service: StaticContentService, private compiler: Compiler, private store: Store<IAppState>) { }

	ngOnInit() {
		if (this.id) {
			this.store.pipe(
				select(selectImpersonatedUser),
				takeUntil(this.destroyed$),
				mergeMap(() => this.service.getStaticContent(this.id)),
				takeUntil(this.destroyed$),
			).subscribe(content => {
				this.data = content;
				if (content) {
					this.compile(content.pageSourceCode);
				} else {
					this.clear();
				}
			});
		} else if (this.template) {
			this.compile(this.template);
		}
	}

	ngAfterViewInit() {
	}

	ngOnDestroy() {
		this.destroyed$.next();
		this.clear();
	}

	clear() {
		if (this.cmpRef) {
			this.cmpRef.destroy();
			this.cmpRef = null;
		}
	}

	private compile(template: string) {
		const pseudoSelector = `c4-${this.id}`;
		const tmpModule = this.getStaticModule(pseudoSelector, template);
		this.compiler
			.compileModuleAndAllComponentsAsync(tmpModule)
			.then(factories => {
				const f = factories.componentFactories.find(
					item => item.selector === pseudoSelector,
				);
				this.clear();
				this.cmpRef = this.vc.createComponent(f, 0);
			});
	}

	getStaticModule(selector: string, template: string) {
		const tmpCmp: any = Component({
			template,
			selector,
		})(class DynamicComponent { });
		return NgModule({
			imports: [CommonModule, SharedModule, SharedElementsModule, PipesModule, ProductsSharedModule, RouterModule, FormsModule],
			declarations: [tmpCmp],
		})(class DynamicModule { });
	}
}