import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PipesModule } from "@app/pipes/pipes.module";
import { SharedElementsModule } from "@app/shared-elements/shared-elements.module";
import { PrintInvoiceComponent } from "./print-invoice/print-invoice.component";
import { PrintProformaInvoiceComponent } from "./print-proforma-invoice/print-proforma-invoice.component";
import { PrintLightingCompatibilityReportComponent } from './print-lighting-compatibility-report/print-lighting-compatibility-report.component';

@NgModule({
	imports: [
		CommonModule,
		SharedElementsModule,
		PipesModule
	],
	declarations: [PrintInvoiceComponent, PrintProformaInvoiceComponent, PrintLightingCompatibilityReportComponent],
	exports: [PrintInvoiceComponent, PrintProformaInvoiceComponent]
})
export class PrintModule { }
