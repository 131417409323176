import { IShippingMethod } from "@app/checkout/checkout.models";
import { IError } from "@app/shared/shared.models";
import { Action } from "@ngrx/store";

export class LoadShippingMethodAttempt implements Action {
	readonly type = ShippingMethodActionTypes.LoadShippingMethodAttempt;
	constructor(public payload: number) { }
}

export class LoadShippingMethodSuccess implements Action {
	readonly type = ShippingMethodActionTypes.LoadShippingMethodSuccess;
	constructor(public payload: IShippingMethod) { }
}

export class LoadShippingMethodError implements Action {
	readonly type = ShippingMethodActionTypes.LoadShippingMethodError;
	constructor(public payload: IError) { }
}

export type ShippingMethodActions =
	LoadShippingMethodAttempt
	| LoadShippingMethodSuccess
	| LoadShippingMethodError;
export enum ShippingMethodActionTypes {
	LoadShippingMethodAttempt = "[ShippingMethod Load] Attempt",
	LoadShippingMethodSuccess = "[ShippingMethod Load] Success",
	LoadShippingMethodError = "[ShippingMethod Load] Error",
}
