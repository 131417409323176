import { Pipe, PipeTransform } from "@angular/core";
import { Portals } from "@app/shared/shared.models";

@Pipe({
	name: "triadVisible"
})
export class TriadVisiblePipe implements PipeTransform {

	transform(value: Portals): any {
		return (value & Portals.Triad) !== 0;
	}

}
