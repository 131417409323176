import { Component, ComponentRef, HostListener, OnInit } from "@angular/core";
import { ICmsPresetConsumerFilter, SchedulerInfo } from "@app/cms/cms.models";
import { ControllerOs, ControllerOsGroup, ControllerType, ControllerTypeGroup, ControllerUpgradeTypeLabels } from "@app/dashboard/dashboard.models";
import { CmsCustomerService } from "@app/services/cms-customer.service";
import { KeyboardService } from "@app/services/keyboard.service";
import { deepcopy } from "@app/shared/helpers";
import { ErrorList, Schedule, ScheduleType } from "@app/shared/shared.models";
import { IAppState } from "@app/store";
import { ErrorToast } from "@app/store/toast/toast.actions";
import { Store } from "@ngrx/store";
import { combineLatest, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { IPopupComponent } from "../popups.models";

@Component({
	selector: "c4-controller-preset-filter-edit-popup",
	templateUrl: "./controller-preset-filter-edit-popup.component.html",
	styleUrls: ["./controller-preset-filter-edit-popup.component.scss"],
})
export class ControllerPresetFilterEditPopupComponent implements OnInit, IPopupComponent {
	close: () => void;
	self: ComponentRef<ControllerPresetFilterEditPopupComponent>;
	filter: ICmsPresetConsumerFilter;

	osGroups: ControllerOsGroup[];
	osVersions: ControllerOs[];

	typeGroups: ControllerTypeGroup[];
	types: ControllerType[];

	scheduleError: string;
	scheduleValid: boolean;

	upgradeTypeLabels = ControllerUpgradeTypeLabels;

	loading = true;

	destroyed$ = new Subject<{}>();

	onSave: (filter: ICmsPresetConsumerFilter) => void;

	constructor(readonly store: Store<IAppState>, readonly cmsCustomerService: CmsCustomerService, readonly keyboardService: KeyboardService) { }

	ngOnInit() {
		this.filter = this.filter ? deepcopy(this.filter) : <ICmsPresetConsumerFilter>{};

		this.filter.visible = this.filter.visible != null ? this.filter.visible : true;
		this.filter.sortCode = this.filter.sortCode != null ? this.filter.sortCode : 999;
		this.filter.upgradeType = this.filter.upgradeType || 0;
		this.filter.controllerOsIds = this.filter.controllerOsIds || [];
		this.filter.controllerOsGroupIds = this.filter.controllerOsGroupIds || [];
		this.filter.controllerTypeIds = this.filter.controllerTypeIds || [];
		this.filter.controllerTypeGroupIds = this.filter.controllerTypeGroupIds || [];
		this.filter.scheduleId = this.filter.scheduleId || 0;
		this.filter.schedule = this.filter.schedule || <Schedule>{ type: ScheduleType.Draft, id: this.filter.scheduleId };

		combineLatest([
			this.cmsCustomerService.getControllerOsSegments(),
			this.cmsCustomerService.getControllerTypeSegments(),
		]).pipe(
			takeUntil(this.destroyed$),
		).subscribe(([osGroups, typeGroups]) => {
			this.osGroups = osGroups;
			this.osVersions = osGroups.reduce((memo, curr) => memo.concat(curr.controllerOs), []);

			this.typeGroups = typeGroups;
			this.types = typeGroups.reduce((memo, curr) => memo.concat(curr.controllerTypes), []);

			this.loading = false;
		});
	}

	@HostListener("keydown", ["$event"])
	onKeyDownHandler(event: KeyboardEvent) {
		if (this.keyboardService.isSaveKeybind(event)) {
			this.save();
		}
	}

	save() {
		if (this.scheduleValid) {
			this.cmsCustomerService.savePresetFilter(this.filter).subscribe(result => {
				const e = result as ErrorList;
				if (e && e.errors) {
					e.errors.forEach(message => this.store.dispatch(new ErrorToast({ message })));
				} else {
					if (this.onSave) {
						this.onSave(result as ICmsPresetConsumerFilter);
					}
					this.close();
				}
			});
		}
	}

	selectOsGroup() {
		this.osVersions = this.filter.controllerOsGroupIds.map(x => this.osGroups.find(y => y.id === x)).reduce((memo, curr) => memo.concat(curr.controllerOs), []);
		this.filter.controllerOsIds = this.osVersions.map(x => x.id);
	}

	selectTypeGroup() {
		this.types = this.filter.controllerTypeGroupIds.map(x => this.typeGroups.find(y => y.id === x)).reduce((memo, curr) => memo.concat(curr.controllerTypes), []);
		this.filter.controllerTypeIds = this.types.map(x => x.id);
	}

	handleScheduleError(info: SchedulerInfo) {
		this.scheduleError = info.error;
		this.scheduleValid = info.valid;
	}

	onClose() {
		this.destroyed$.next();
		this.close();
	}
}
