import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { IProductLightModel } from "@app/products/products.models";
import { ProductService } from "@app/services/product.service";
import { PagingInfo } from "@app/shared-elements/shared-elements.models";
import { randomSelect } from "@app/shared/helpers";
import { IAppState } from "@app/store";
import { selectIsMsrp } from "@app/store/auth";
import { getCategory, LoadCategoryAttempt } from "@app/store/product-category";
import { BulkLoadProductsLightAttempt } from "@app/store/product-light";
import { selectCurrentUserId } from "@app/store/user";
import { select, Store } from "@ngrx/store";
import { BehaviorSubject, combineLatest, Subject } from "rxjs";
import { map, switchMap, takeUntil } from "rxjs/operators";

@Component({
	selector: "c4-product-list",
	templateUrl: "./product-list.component.html",
	styleUrls: ["./product-list.component.scss"],
})
export class ProductListComponent implements OnInit, OnDestroy {
	@Input() products: IProductLightModel[];
	@Input() category: number;
	@Input() filters: string[]; // TODO: Wire in filters
	@Input() orderId = 0;

	private _skus: string[];
	@Input() get skus() {
		return this._skus;
	}

	set skus(val) {
		this.skusChanged.next(val);
	}

	skusChanged = new BehaviorSubject<string[]>([]);

	@Input() paging: PagingInfo;

	@Input() ignorePromo: boolean;
	@Input() ignoreCustom: boolean;
	@Input() ignoreSuggested: boolean;
	@Input() hideQuickView: boolean;
	@Input() limit: number;
	@Input() section: string;

	@Output() empty = new EventEmitter<boolean>();

	loading = true;

	private destroyed$ = new Subject<{}>();

	constructor(readonly store: Store<IAppState>, readonly productService: ProductService) { }

	ngOnInit() {
		this.loading = !this.products;
		if (this.loading) {
			this.skusChanged.pipe(
				map(val => (val || []).map(x => x.toUpperCase())),
				switchMap(skus => {
					this._skus = skus;
					this.loading = true;
					this.empty.emit(false);
					this.store.dispatch(new BulkLoadProductsLightAttempt(skus));

					return this.store.pipe(this.productService.getRandomProductsSelectorWithUnloaded(skus, this.limit));
				}),
				takeUntil(this.destroyed$),
			).subscribe(result => {
				this.products = result.products || [];
				this.loading = result.unloaded && result.unloaded.length > 0;
				this.empty.emit(!this.loading && this.products.length === 0);
			});

			combineLatest([
				this.store.pipe(select(getCategory(this.category))),
				this.store.pipe(select(selectCurrentUserId)),
				this.store.pipe(select(selectIsMsrp)),
			]).pipe(
				takeUntil(this.destroyed$),
			).subscribe(([category]) => {
				if (this.category) {
					this.store.dispatch(new LoadCategoryAttempt(this.category));
				}
				this.skusChanged.next(category ? category.skus : this.skus);
			});
		} else if (this.limit) {
			this.products = randomSelect(this.products, this.limit);
		}
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

}
