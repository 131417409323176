import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import { CloudinaryService } from "@app/services/cloudinary.service";
import { IMedia, MediaType } from "@app/shared/shared.models";

@Component({
	selector: "c4-cloudinary",
	template: `
				<div *ngIf="isImage && zoomTransformations" [class]="ngClass" [c4Zoom]="media" [transformations]="transformations" [zoomTransformations]="zoomTransformations"></div>
				<div *ngIf="isImage && !zoomTransformations" [class]="ngClass" [style.backgroundImage]="backgroundStyle"></div>
				<a *ngIf="isAttachment" [href]="media | cloudinaryUrl" target="_blank">{{media.name}}</a>
				`,
})
export class CloudinaryComponent implements OnInit {
	backgroundStyle: SafeStyle;
	isImage: boolean;
	isAttachment: boolean;

	@Input() transformations: string;
	@Input() zoomTransformations: string;
	@Input() ngClass: string;

	private _media: IMedia;
	@Input() set media(val: IMedia) {
		this._media = val;
		this.populate();
	}
	get media() {
		return this._media;
	}

	constructor(readonly cloudinaryService: CloudinaryService, private sanitizer: DomSanitizer) { }

	ngOnInit() {
		this.populate();
	}

	private populate() {
		const cloudinaryUrl = this.cloudinaryService.getUrl(this.media, this.transformations);
		this.backgroundStyle = this.sanitizer.bypassSecurityTrustStyle(`url("${cloudinaryUrl}")`);
		this.isImage = this.isImageType(this.media);
		this.isAttachment = this.isAttachmentType(this.media);
	}

	isImageType(media: IMedia) {
		return media && (media.mediaTypeId === MediaType.Image || media.mediaTypeId === MediaType.SpecSheet);
	}

	isAttachmentType(media: IMedia) {
		return media && media.mediaTypeId === MediaType.Attachment;
	}
}
