import { Component, OnInit } from "@angular/core";

@Component({
	selector: "c4-cms-page-edit-button",
	templateUrl: "./cms-page-edit-button.component.html",
	styleUrls: ["./cms-page-edit-button.component.scss"]
})
export class CmsPageEditButtonComponent implements OnInit {

	url: string;

	constructor() { }

	ngOnInit() {
	}

}
