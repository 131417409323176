import { Injectable } from "@angular/core";
import { IAccountSalesInfo, ISalesQuarterModel } from "@app/account/account.models";
import { ChartColor } from "@app/dashboard/dashboard.models";
import { shortCurrencyPipeTransform } from "@app/pipes/short-currency.pipe";
import * as Chart from "chart.js";

@Injectable({
	providedIn: "root"
})
export class ChartService {

	constructor() {
	}

	oldDonutChartColors = [
		"#6397AC", "#918433", "#D45C30", "#B08BA8", "#F29138",
		"#81A8B9", "#A59650", "#DC774A", "#BE9EB7", "#F6A459",
		"#A0BAC7", "#BAAB72", "#E5936A", "#CDB2C6", "#F9B87A",
		"#BFCFD8", "#D0C295", "#EFB28F", "#DDC9D8", "#FCCD9D",
		"#DFE5EB", "#E9E0C3", "#F9D6BF", "#EFE2E9", "#FEE5C8",
	];

	newDonutChartColors = [
		// Indigo, Green, Blue, Red, Yellow, Orange
		"#252a56", "#778628", "#3388aa", "#7f212d", "#c4901d", "#b55c32",
		"#515578", "#929e53", "#5ca0bb", "#994d57", "#d0a64a", "#c47d5b",
		"#7c7f9a", "#a0aa69", "#85b8cc", "#b27a81", "#dcbc77", "#d39d84",
		"#a8aabb", "#bbc394", "#adcfdd", "#cca6ab", "#e7d3a5", "#e1bead",
		"#1a1d3c", "#535e1c", "#245f77", "#59171f", "#896514", "#7f4023",
	];


	private successColors = [
		"#778628",
		"#929e53",
		"#a0aa69",
		"#bbc394",
		"#535e1c",
	];

	private warnColors = [
		"#c4901d",
		"#d0a64a",
		"#dcbc77",
		"#e7d3a5",
		"#896514",
	];

	private dangerColors = [
		"#7f212d",
		"#994d57",
		"#b27a81",
		"#cca6ab",
		"#59171f",
	];

	private unknownColors = [
		"#A3A4A5",
		"#B9B9BA",
		"#CFCFD0",
		"#E6E7E7",
		"#98999B",
	];

	getDefaultDoughnutChartOptions(ctx: HTMLCanvasElement): Chart {
		return new Chart(ctx, {
			type: "doughnut",
			data: {
				labels: [],
				datasets: [{
					backgroundColor: this.oldDonutChartColors,
					data: []
				}],
			},
			options: {
				legend: {
					position: "bottom"
				}
			}
		});
	}

	getInfoColors(data: ChartColor[]) {
		let currentColor: ChartColor;
		let counter: number;
		return data.map((x) => {
			counter += 1;
			if (currentColor !== x) {
				currentColor = x;
				counter = 0;
			}
			switch (x) {
				case ChartColor.Success:
					return this.successColors[counter % 5];
				case ChartColor.Warn:
					return this.warnColors[counter % 5];
				case ChartColor.Error:
					return this.dangerColors[counter % 5];
				case ChartColor.Unknown:
					return this.unknownColors[counter % 5];
			}
		});
	}

	getOrderHistoryChart(ctx: HTMLCanvasElement, result: IAccountSalesInfo, currencySymbol: string) {
		return new Chart(ctx, {
			type: "bar",
			data: {
				labels: ["Q1", "Q2", "Q3", "Q4"],
				datasets: [
					{
						data: this.getYearData(result.previousYear),
						backgroundColor: "#b72c3b",
						label: (result.previousYear && result.previousYear.length > 0 ? result.previousYear[0].year : new Date().getFullYear() - 1).toString(),
					},
					{
						data: this.getYearData(result.currentYear),
						backgroundColor: "#e6902e",
						label: (result.currentYear && result.currentYear.length > 0 ? result.currentYear[0].year : new Date().getFullYear()).toString(),
					},
				]
			},
			options: {
				responsive: true,
				maintainAspectRatio: false,
				scales: {
					yAxes: [
						{
							ticks: {
								callback: (val) => `${shortCurrencyPipeTransform(val, currencySymbol)}`,
								beginAtZero: true
							}
						}
					],
					xAxes: [{ barThickness: 20 }]
				}
			}
		});
	}

	private getYearData(quarters: ISalesQuarterModel[]): number[] {
		const data = [[1, 0], [2, 0], [3, 0], [4, 0]];
		if (quarters) {
			for (let i = 0; i < quarters.length && i < 4; i++) {
				const datum = data.find((item) => item[0] === quarters[i].quarter);
				datum[1] = quarters[i].amount;
			}
		}
		return data.map((item) => item[1]);
	}
}