import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IBakpakCodes } from "@app/account/account.models";
import { AccountService } from "@app/services/account.service";
import { BakpakCodesActionTypes, LoadBakpakCodesError, LoadBakpakCodesSuccess } from "@app/store/bakpak-codes";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";

@Injectable()
export class BakpakCodesEffects {
	constructor(
		private actions$: Actions,
		private accountService: AccountService
	) { }

	@Effect()
	loadAddress$: Observable<Action> = this.actions$.pipe(
		ofType(BakpakCodesActionTypes.LoadBakpakCodesAttempt),
		mergeMap(() => {
			return this.accountService
				.getBakpakCodes()
				.pipe(
					map((info: IBakpakCodes) => new LoadBakpakCodesSuccess(info)),
					catchError((err: HttpErrorResponse) => of(new LoadBakpakCodesError({ error: err.message || err.statusText })))
				);
		})
	);
}
