import { ngStoreCacheItem } from "@app/shared/helpers";
import { IControl4ContactsModel } from "@app/shared/shared.models";
import { C4ContactActionTypes, C4ContactsActions } from "@app/store/c4-contacts";
import { c4ContactsAdapter } from "@app/store/c4-contacts/c4-contacts.adapter";
import { EntityState } from "@ngrx/entity";

export interface IC4ContactsState extends EntityState<IControl4ContactsModel> { }

export const initialState: IC4ContactsState = c4ContactsAdapter.getInitialState();

export function c4ContactsReducer(
	state = initialState,
	action: C4ContactsActions
): IC4ContactsState {
	switch (action.type) {
		case C4ContactActionTypes.LoadC4ContactsSuccess:
			return c4ContactsAdapter.upsertOne(ngStoreCacheItem(action.payload, 6, "hours"), state);
		default: {
			return state;
		}
	}
}