import { Injectable } from "@angular/core";
import { OrderService } from "@app/services/order.service";
import { OrderHistoryDetailActionTypes } from "@app/store/order-history-details";
import { LoadOrderHistoryItemDetailsAttempt, LoadOrderHistoryItemDetailsError, LoadOrderHistoryItemDetailsSuccess } from "@app/store/order-history-details/order-history-details.actions";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";

@Injectable()
export class OrderHistoryDetailsEffects {
	constructor(
		private actions$: Actions,
		private orderService: OrderService
	) { }

	@Effect()
	loadOrderHistoryDetails$: Observable<Action> = this.actions$.pipe(
		ofType<LoadOrderHistoryItemDetailsAttempt>(OrderHistoryDetailActionTypes.LoadOrderHistoryItemDetailsAttempt),
		mergeMap((action) => {
			return this.orderService
				.getOrderDetails(action.webOrderId)
				.pipe(
					map((response) => new LoadOrderHistoryItemDetailsSuccess(response)),
					catchError((err) => of(new LoadOrderHistoryItemDetailsError(err.error)))
				);
		})
	);
}
