import { IProductLightModel } from "@app/products/products.models";
import { IError } from "@app/shared/shared.models";
import { Action } from "@ngrx/store";

export enum ProductLightActionType {
	BulkLoadProductsLightAttempt = "[Light Product] Bulk Load Attempt",
	BulkLoadProductsLightSuccess = "[Light Product] Bulk Load Success",
	BulkLoadProductsLightError = "[Light Product] Bulk Load Error",
	PushRecentlyViewedProduct = "[Light Product] Push Recently Viewed",
	LoadLightProductBySku = "[Light Product] Load By Sku",
}

export class BulkLoadProductsLightAttempt implements Action {
	readonly type = ProductLightActionType.BulkLoadProductsLightAttempt;
	constructor(public payload: string[]) { }
}

export class BulkLoadProductsLightSuccess implements Action {
	readonly type = ProductLightActionType.BulkLoadProductsLightSuccess;
	constructor(public payload: { products: IProductLightModel[], failedSkus: string[] }) { }
}

export class BulkLoadProductsLightError implements Action {
	readonly type = ProductLightActionType.BulkLoadProductsLightError;
	constructor(public payload: IError) { }
}

export class PushRecentlyViewedProduct implements Action {
	readonly type = ProductLightActionType.PushRecentlyViewedProduct;
	constructor(public payload: string) { }
}

export class LoadLightProductBySku implements Action {
	readonly type = ProductLightActionType.LoadLightProductBySku;
	constructor(public payload: string) { }
}

export type ProductLightActions =
	BulkLoadProductsLightAttempt
	| BulkLoadProductsLightSuccess
	| BulkLoadProductsLightError
	| PushRecentlyViewedProduct
	| LoadLightProductBySku;