import { Pipe, PipeTransform } from "@angular/core";
import { CertificationStatus } from "@app/shared/shared.models";
import { ITechnicianCertfication } from "@app/training/training.models";

@Pipe({
	name: "certStatus"
})
export class CertStatusPipe implements PipeTransform {
	transform(certs: ITechnicianCertfication[], status: CertificationStatus): ITechnicianCertfication[] {
		return certs.filter(cert => status > 0 ? (cert.currentStatus === status) : (cert.currentStatus < status * -1));
	}
}
