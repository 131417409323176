import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
	selector: "c4-kb-search-redirect",
	templateUrl: "./kb-search-redirect.component.html",
	styleUrls: ["./kb-search-redirect.component.scss"]
})
export class KbSearchRedirectComponent implements OnInit {
	searchText: string;

	constructor(readonly router: Router) { }

	ngOnInit() {
	}

	searchRedirect() {
		if (!this.searchText) {
			return;
		}

		this.router.navigate(["/support"], { queryParams: { q: this.searchText } });
	}
}
