import { KeyValue } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { IAppState } from "@app/store";
import { getCurrentAccount, getCurrentSubDealersMap } from "@app/store/account";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: "c4-subdealer-select",
	templateUrl: "./subdealer-select.component.html",
	styleUrls: ["./subdealer-select.component.scss"]
})
export class SubdealerSelectComponent implements OnInit, OnDestroy {

	private _value: string;
	@Input() get value() {
		return this._value;
	}
	set value(val: string) {
		this._value = val;
		this.valueChange.emit(val);
	}
	@Output() valueChange = new EventEmitter<string>();
	@Input() classes: string;

	@Input() selectAll = true;
	allLabel: string;

	subDealers = new Map<string, string>();

	private destroyed$ = new Subject<{}>();

	constructor(readonly store: Store<IAppState>) { }

	ngOnInit() {
		this.store.pipe(
			select(getCurrentSubDealersMap),
			takeUntil(this.destroyed$)
		).subscribe((subdealers) => this.subDealers = subdealers);

		this.store.pipe(
			select(getCurrentAccount),
			takeUntil(this.destroyed$)
		).subscribe((account) => {
			this.allLabel = !account || account.id === "MAG903" || account.id === "BES902" ? "All Stores" : "All Dealers";
		});
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}

	sortSubDealers(a: KeyValue<string, string>, b: KeyValue<string, string>): number {
		return a.key.toLowerCase() >= b.key.toLowerCase() ? 1 : -1;
	}
}
