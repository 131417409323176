import { Component, ComponentRef, Input, OnDestroy, OnInit } from "@angular/core";
import { IProductLightModel } from "@app/products/products.models";
import { CartService } from "@app/services/cart.service";
import { IAppState } from "@app/store";
import { getCurrentCurrencySymbol } from "@app/store/account";
import { getCurrentCartSubtotal } from "@app/store/cart";
import { BulkLoadProductsLightAttempt, selectProductListBySkus, selectUnloadedSkus } from "@app/store/product-light";
import { select, Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { IPopupComponent } from "../popups.models";

@Component({
	selector: "c4-suggested-products-popup",
	templateUrl: "./suggested-products-popup.component.html",
	styleUrls: ["./suggested-products-popup.component.scss"]
})
export class SuggestedProductsPopupComponent implements IPopupComponent, OnInit, OnDestroy {
	self: ComponentRef<SuggestedProductsPopupComponent>;
	close: () => void;

	@Input() skus: string[];

	suggestedProducts$: Observable<IProductLightModel[]>;
	cartSubtotal$: Observable<number>;
	currency$: Observable<string>;

	private destroyed$ = new Subject<{}>();

	constructor(readonly store: Store<IAppState>, readonly cartService: CartService) { }

	ngOnInit() {
		this.skus = [...new Set(this.skus)];
		this.currency$ = this.store.pipe(select(getCurrentCurrencySymbol));
		this.cartSubtotal$ = this.store.pipe(select(getCurrentCartSubtotal));
		this.suggestedProducts$ = this.store.pipe(select(selectProductListBySkus(this.skus)));
		this.store.pipe(select(selectUnloadedSkus(this.skus)), takeUntil(this.destroyed$)).subscribe((unloaded) => {
			this.store.dispatch(new BulkLoadProductsLightAttempt(unloaded || []));
		});
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}

	continueShopping() {
		this.cartService.addOrderLifeCycleEvent({ action: "ContinueShopping", actionSource: "SuggestedProductsModal" });
		this.close();
	}

	viewCart() {
		this.cartService.goToCheckout("SuggestedProductsModal");
		this.close();
	}
}
