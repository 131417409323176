import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { IDimensionCustomizations, IPartCustomDimensionOptionModel } from "@app/products/products.models";
import { contains, objectUpdate } from "@app/shared/helpers";

@Component({
	selector: "c4-product-customization-dimension",
	templateUrl: "./customization-dimension.component.html",
	styleUrls: ["./customization-dimension.component.scss"],
})
export class CustomizationDimensionComponent implements OnInit, OnDestroy {
	@Input() dimension: string;
	@Input() options: IPartCustomDimensionOptionModel[];
	@Input() dimensionChange: EventEmitter<IPartCustomDimensionOptionModel>;
	@Input() customDimensionChange: EventEmitter<number>;

	@Input() customizations: IDimensionCustomizations;
	@Output() customizationsChange = new EventEmitter<IDimensionCustomizations>();

	selectedOption: IPartCustomDimensionOptionModel;
	selectedUnit: "in" | "mm" = "in";
	isDefaultSelected = true;

	custom: number;
	step: number;
	min: number;
	max: number;

	prevValidNum: number;
	nextValidNum: number;

	isValid = true;
	isWithinRange = true;

	constructor() { }

	ngOnInit() {
		this.customizationsChange.emit({ isCustomized: true } as IDimensionCustomizations);
		if (this.customizations.customDimension) {
			const measurement = this.customizations.customDimension;
			this.selectedOption = this.options.find(x => x.min === measurement || (x.min <= measurement && x.max >= measurement));
			this.onDimensionOptionChange(this.selectedOption, measurement);
		}
	}

	ngOnDestroy() {
		this.customizationsChange.emit({ isCustomized: false } as IDimensionCustomizations);
	}

	onDimensionOptionChange(option: IPartCustomDimensionOptionModel, initialMeasurement: number) {
		if (option) {
			this.custom = initialMeasurement || option.min;
			this.isDefaultSelected = contains(option.displayValue, "Default");
			this.populateCustomOptions();
		}
		this.updateCustomizations({ option });
	}

	onCustomMeasurementTypeChange(measurement: string) {
		this.populateCustomOptions();
		this.custom = this.selectedUnit === "in" ? this.convertToInches(this.custom) : this.convertToMm(this.custom);
		this.updateCustomizations({ measurement } as Partial<IDimensionCustomizations>);
	}

	populateCustomOptions() {
		this.step = this.selectedUnit === "in" ? 0.125 : 3.175;
		if (this.selectedOption) {
			this.min = this.selectedUnit === "in" ? this.selectedOption.min : this.calcPrevValidNum(this.selectedOption.minMm);
			this.max = this.selectedUnit === "in" ? this.selectedOption.max : this.selectedOption.maxMm;
		}
	}

	onCustomMeasurementChange() {
		this.updateCustomizations();
	}

	updateCustomizations(updates: Partial<IDimensionCustomizations> = {}) {
		const updated = objectUpdate(this.customizations, updates);
		// Validate using measurements
		const isValidIncr = this.isValidIncrement(this.custom);
		this.isWithinRange = this.custom >= this.min && this.custom <= this.max;
		this.isValid = updated.option && (this.isDefaultSelected || (
			this.isWithinRange && isValidIncr
		));
		// Always send out inches
		updated.isValid = this.isValid;
		updated.customDimension = this.getCustomDimension();
		if (!isValidIncr) {
			this.prevValidNum = this.calcPrevValidNum(this.custom);
			this.nextValidNum = this.calcNextValidNum(this.prevValidNum);
		}
		this.customizationsChange.emit(updated);
	}

	calcPrevValidNum(custom: number) {
		return Math.round((custom - (custom % this.step)) * 1000) / 1000;
	}

	calcNextValidNum(prevValidNum: number) {
		return Math.round((prevValidNum + this.step) * 1000) / 1000;
	}

	isValidIncrement(custom: number) {
		const roundedMod = Math.round((custom % this.step) * 1000) / 1000;
		return roundedMod === 0 || roundedMod === this.step;
	}

	getCustomDimension() {
		return this.selectedUnit === "mm" ? this.convertToInches(this.custom) : this.custom;
	}

	convertToInches(valMm: number) {
		return Math.round(valMm / 0.125 / 25.4) * 0.125;
	}

	convertToMm(valInches: number) {
		return Math.round(valInches * 25.4 * 1000) / 1000;
	}
}
