import { Component, ComponentRef, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { IProductLightModel } from "@app/products/products.models";
import { IPromoBenefitModel } from "@app/promotions/promotion.models";
import { CmsProductService } from "@app/services/cms-product.service";
import { PromoService } from "@app/services/promo.service";
import { deepcopy } from "@app/shared/helpers";
import { IAppState } from "@app/store";
import { SuccessToast, WarningToast } from "@app/store/toast/toast.actions";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { IPopupComponent } from "../popups.models";

@Component({
	selector: "c4-promotion-benefit-edit-popup",
	templateUrl: "./promotion-benefit-edit-popup.component.html",
	styleUrls: ["./promotion-benefit-edit-popup.component.scss"],
})
export class PromotionBenefitEditPopupComponent implements IPopupComponent, OnInit, OnDestroy {
	close: () => void;

	self: ComponentRef<PromotionBenefitEditPopupComponent>;

	@Input() benefit: IPromoBenefitModel;
	@Output() benefitRemoved = new EventEmitter<number>();
	@Output() benefitSaved = new EventEmitter<IPromoBenefitModel>();
	@Input() valueMatchesList = false;

	private destroyed$ = new Subject<{}>();

	constructor(readonly promoService: PromoService, readonly store: Store<IAppState>, readonly cmsProductService: CmsProductService) { }

	ngOnInit() {
	}

	cancelEditBenefit() {
		this.benefit = null;
		this.self.destroy();
	}

	removeBenefit() {
		if (confirm("Are you sure you would like to remove this benefit?")) {
			this.promoService.removePromoBenefit(this.benefit.id).subscribe(result => {
				this.benefitRemoved.emit(this.benefit.id);
				this.store.dispatch(new SuccessToast({ message: "Promo benefit removed" }));
				this.cancelEditBenefit();
			}, () => {
				this.store.dispatch(new WarningToast({ message: "There was a problem removing the promo benefit." }));
			});
		}
	}

	saveBenefit(): void {
		this.benefit.id = this.benefit.id ? this.benefit.id : 0;
		this.benefit.factor = this.benefit.factor >= 1 ? this.benefit.factor / -100 : this.benefit.factor;
		this.benefit.increment = this.benefit.increment > 0 ? this.benefit.increment * -1 : this.benefit.increment;
		const updatedBenefit = deepcopy(this.benefit);

		if (this.benefit.discountId > 0) {
			this.promoService.savePromoBenefit(this.benefit).subscribe(result => {
				if (result > 0) {
					updatedBenefit.id = result;
					this.benefitSaved.emit(updatedBenefit);
					this.store.dispatch(new SuccessToast({ message: "Promo benefit saved successfully." }));
					this.cancelEditBenefit();
				} else {
					this.store.dispatch(new WarningToast({ message: "There was a problem saving the promo benefit." }));
				}
			}, err => {
				this.store.dispatch(new WarningToast({ message: `There was a problem saving the promo benefit. ${err}` }));
			});
		} else {
			this.benefitSaved.emit(updatedBenefit);
			this.cancelEditBenefit();
		}
	}

	selectProduct = (item: IProductLightModel) => {
		this.benefit.benefitValue = item.sku;
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}

}
