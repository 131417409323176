import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IAddress } from "@app/shared/shared.models";
import { ICountryCode, IGoogleAddressModel, IShippingCountry, IStateModel } from "@app/store/address/address.models";

@Injectable({
	providedIn: "root"
})
export class AddressService {

	constructor(readonly http: HttpClient) { }

	getAddress(id: number) {
		return this.http.get("api/address/getAddress", { params: { id: id.toString() } });
	}

	saveAddress(address: IAddress) {
		return this.http.post<IAddress>("api/address/SaveAddress", address);
	}

	deleteAddress(id: number) {
		return this.http.delete<boolean>("api/address/RemoveAddress", { params: { id: id.toString() } });
	}

	getBillingAddresses() {
		return this.http.get<IAddress[]>("api/address/getBillingAddresses");
	}

	getShippingAddresses() {
		return this.http.get<IAddress[]>("api/address/getShippingAddresses");
	}

	getFinalDestinationAddresses() {
		return this.http.get<IAddress[]>("api/address/getFinalDestinationAddresses");
	}

	geolocateAddress(address: IGoogleAddressModel) {
		const location = `${address.line1}, ${address.city}, ${address.state}, ${address.zip}, ${address.country}`;
		return this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(location)}&sensor=false&key=AIzaSyCiIpEzQOvFwgn_AYHdHIgwNa4YrKSjgNg`);
	}

	getCountryCodes() {
		return this.http.get<ICountryCode[]>("api/address/getCountryCodes");
	}

	getCountries(orderId: number) {
		return this.http.get<IShippingCountry[]>("api/order/GetCountries", { params: { orderId: orderId.toString() } });
	}

	getAvailableStates(countryAbbr: string) {
		return this.http.get<IStateModel[]>("api/address/GetAvailableStatesForCountry", { params: { countryAbbr } });
	}
}
