import { Pipe, PipeTransform } from "@angular/core";
import { Portals } from "@app/shared/shared.models";

@Pipe({
	name: "pakedgeVisible"
})
export class PakedgeVisiblePipe implements PipeTransform {

	transform(value: Portals) {
		return (value & Portals.Pakedge) !== 0;
	}

}
