import { ICustomerLicenseSummaryData } from "@app/dashboard/dashboard.models";
import { IError } from "@app/shared/shared.models";
import { Action } from "@ngrx/store";

export class LoadCustomerLicenseSummariesAttempt implements Action {
	readonly type = CustomerLicenseSummaryActionTypes.LoadCustomerLicenseSummariesAttempt;
	constructor() { }
}

export class LoadCustomerLicenseSummariesSuccess implements Action {
	readonly type = CustomerLicenseSummaryActionTypes.LoadCustomerLicenseSummariesSuccess;
	constructor(public payload: ICustomerLicenseSummaryData) { }
}

export class LoadCustomerLicenseSummariesError implements Action {
	readonly type = CustomerLicenseSummaryActionTypes.LoadCustomerLicenseSummariesError;
	constructor(public payload: IError) { }
}

export class UpdateCustomerLicenseSummarySubdealerFilter implements Action {
	readonly type = CustomerLicenseSummaryActionTypes.UpdateCustomerLicenseSummarySubdealerFilter;
	constructor(public payload: string) { }
}

export type CustomerLicenseSummaryActions =
	LoadCustomerLicenseSummariesAttempt
	| LoadCustomerLicenseSummariesSuccess
	| LoadCustomerLicenseSummariesError
	| UpdateCustomerLicenseSummarySubdealerFilter;
export enum CustomerLicenseSummaryActionTypes {
	LoadCustomerLicenseSummariesAttempt = "[Customer License Summaries] Load Attempt",
	LoadCustomerLicenseSummariesSuccess = "[Customer License Summaries] Load Success",
	LoadCustomerLicenseSummariesError = "[Customer License Summaries] Load Error",
	UpdateCustomerLicenseSummarySubdealerFilter = "[Customer License Summaries] Update Subdealer Filter",
}
