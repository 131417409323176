import { ContractType, IContractModel, IError } from "@app/shared/shared.models";
import { Action } from "@ngrx/store";

export class LoadContractAttempt implements Action {
	readonly type = ContractActionType.LoadContract;
	constructor(public payload: ContractType) { }
}

export class LoadContractSuccess implements Action {
	readonly type = ContractActionType.LoadSuccess;
	constructor(public payload: IContractModel) { }
}

export class LoadContractError implements Action {
	readonly type = ContractActionType.LoadError;
	constructor(public payload: IError) { }
}

export class SignContractSuccess implements Action {
	readonly type = ContractActionType.ContractSigned;
	constructor(public id: number) { }
}

export type ContractActions =
	| LoadContractAttempt
	| LoadContractSuccess
	| LoadContractError
	| SignContractSuccess;
export enum ContractActionType {
	LoadContract = "[Contract] Load",
	LoadSuccess = "[Contract] Load Success",
	LoadError = "[Contract] Load Error",
	ContractSigned = "[Contract] Signed",
}