import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { INavMenuItem } from "@app/nav/nav.models";
import { NavService } from "@app/services/nav.service";
import { AuthActionType } from "@app/store/auth";
import { NavLoadError, NavLoadSuccess } from "@app/store/nav";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action, select, Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, combineLatest, debounceTime, map, mergeMap } from "rxjs/operators";
import { IAppState } from "../app.reducer";
import { currentIsMigrating } from "../user";

@Injectable()
export class NavEffects {
	constructor(private actions$: Actions, private navService: NavService, private store: Store<IAppState>) { }

	@Effect()
	loadNavigation$: Observable<Action> = this.actions$.pipe(
		ofType(
			AuthActionType.LoginSuccess,
			AuthActionType.ImpersonateSuccess,
			AuthActionType.MsrpImpersonateSuccess,
			AuthActionType.UnimpersonateSuccess,
		),
		debounceTime(500),
		combineLatest(this.store.pipe(select(currentIsMigrating()))),
		mergeMap(([action, currentIsMigrating]: [Action, boolean]) => {
			return this.navService
				.getNavigation()
				.pipe(
					map((info: INavMenuItem[]) => {

						//After impersonation, we want the page to reload to reignite the authguard if the impersonated user is in the process of migrating
						if (action && action.type !== AuthActionType.LoginSuccess && (currentIsMigrating || location.pathname.includes("dealer-migration"))) {
							location.reload();
						}

						return new NavLoadSuccess(info)
					}),
					catchError((err: HttpErrorResponse) =>
						of(new NavLoadError({ error: err.message || err.statusText })),
					),
				);
		}),
	);
}
