import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IAppState } from "@app/store";
import { WarningToast } from "@app/store/toast/toast.actions";
import { Store } from "@ngrx/store";
import { ICloudinaryUploadOverview, ICloudinaryUploadResult } from "../shared-elements.models";

declare var cloudinary: any;

@Component({
	selector: "c4-file-upload",
	templateUrl: "./file-upload.component.html",
	styleUrls: ["./file-upload.component.scss"],
})
export class FileUploadComponent {
	@Input() text: string;
	@Input() folder: string;
	@Input() classes: string;
	@Input() disabled: boolean;
	@Input() allowCamera: boolean;
	@Input() fileFormats: string;
	@Input() maxFiles: number;
	@Input() preset: string;
	@Input() files: ICloudinaryUploadResult[];
	@Input() cropping = false;

	@Output() complete = new EventEmitter<ICloudinaryUploadResult[]>();

	constructor(readonly store: Store<IAppState>) { }

	promptUpload() {
		const sources = ["local"];
		if (this.allowCamera) {
			sources.push("camera");
		}

		let uploadCount = 0;
		const succeededUploads: ICloudinaryUploadResult[] = [];

		const widget = cloudinary.openUploadWidget(
			{
				cloudName: "control4",
				uploadPreset: this.preset || "nayrzjcb",
				sources,
				theme: "minimal",
				styles: {
					palette: {
						window: "#FFF",
						windowBorder: "#424548",
						tabIcon: "#424548",
						link: "#424548",
						action: "#FF620C",
						inactiveTabIcon: "#0E2F5A",
						error: "#C32032",
						inProgress: "#C32032",
						complete: "#C32032",
						sourceBg: "#f6f6f7",
					},
					fonts: {
						"'Montserrat'": "https://fonts.googleapis.com/css?family=Montserrat",
					},
				},
				multiple: this.maxFiles > 1,
				maxFiles: this.maxFiles,
				folder: this.folder,
				clientAllowedFormats: this.fileFormats ? this.fileFormats.split(",") : null,
				showPoweredBy: false,
				cropping: this.cropping,
				croppingAspectRatio: 1,
				singleUploadAutoClose: true,
				showUploadMoreButton: false,
				text: {
					en: {
						notifications: {
							limit_reached: `Only ${this.maxFiles} files can be uploaded at a time.`,
						},
					},
				},
			},
			(error: any, result: ICloudinaryUploadOverview) => {
				console.log(result);
				if (error) {
					uploadCount--;
					this.store.dispatch(new WarningToast({ message: "There was a problem uploading one or more files." }));
				} else if (result && result.event === "upload-added") {
					uploadCount++;
				} else if (result && result.event === "success") {
					succeededUploads.push(result.info);
					if (succeededUploads.length === uploadCount) {
						if (this.files) {
							this.files.push(...succeededUploads);
						} else {
							this.files = succeededUploads;
						}
						this.complete.emit(succeededUploads instanceof Array ? succeededUploads : [succeededUploads]);
						widget.close();
					}
				}
			});
	}
}
