import { Component, ComponentRef, Input, OnDestroy, OnInit } from "@angular/core";
import { FileType } from "@app/account/account.models";
import { IExportFilter, ISalesQuoteExport, ISavedCart } from "@app/checkout/checkout.models";
import { CartService } from "@app/services/cart.service";
import { IAppState } from "@app/store";
import { getCurrentPortalName } from "@app/store/app-info/app-info.selectors";
import { select, Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { IPopupComponent } from "../popups.models";

@Component({
	selector: "c4-sales-quote-export-popup",
	templateUrl: "./sales-quote-export-popup.component.html",
	styleUrls: ["./sales-quote-export-popup.component.scss"]
})
export class SalesQuoteExportPopupComponent implements IPopupComponent, OnInit, OnDestroy {
	self: ComponentRef<SalesQuoteExportPopupComponent>;
	close: () => void;

	@Input() savedCart: ISavedCart;

	portalName$: Observable<string>;
	salesQuoteExport: ISalesQuoteExport;
	exportFilter: IExportFilter = {
		includeControl4Products: false,
		includeDealerPricing: false,
		includeMsrpPricing: true,
		includePartnerProducts: false,
		includeControl4Logo: true
	};

	private destroyed$ = new Subject<{}>();

	constructor(readonly store: Store<IAppState>, private readonly cartService: CartService) { }

	ngOnInit() {
		this.portalName$ = this.store.pipe(select(getCurrentPortalName));

		this.salesQuoteExport = {
			savedCartId: this.savedCart.id,
			name: this.savedCart.name,
			exportFilter: this.exportFilter
		};
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}

	exportSalesQuote(isCSV = false) {
		const type = isCSV ? FileType.Csv : FileType.Xls;
		this.cartService.exportSalesQuotation(this.salesQuoteExport.savedCartId, this.exportFilter, this.salesQuoteExport.name, type, () => {
			this.close();
		});
	}
}
