import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { OrderService } from "@app/services/order.service";
import { IAppState } from "@app/store";
import { getCurrentCurrencySymbol, getCurrentIsEmea } from "@app/store/account";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { IProformaInvoiceModel } from "../print.models";

@Component({
	selector: "c4-print-proforma-invoice",
	templateUrl: "./print-proforma-invoice.component.html",
	styleUrls: ["./print-proforma-invoice.component.scss"]
})
export class PrintProformaInvoiceComponent implements OnInit {

	currency$: Observable<string>;
	isEmea$: Observable<boolean>;

	invoice: IProformaInvoiceModel;

	constructor(readonly store: Store<IAppState>, readonly orderService: OrderService, readonly route: ActivatedRoute, readonly title: Title) { }

	ngOnInit() {
		this.currency$ = this.store.pipe(select(getCurrentCurrencySymbol));
		this.isEmea$ = this.store.pipe(select(getCurrentIsEmea));

		const id = this.route.snapshot.paramMap.get("id");
		this.orderService.getProformaInvoiceInfo(id).subscribe((result) => {
			this.invoice = result;

			this.title.setTitle(`Proforma #${id}`);
		});
	}
}
