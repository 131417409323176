import { getNgStoreCacheItem } from "@app/shared/helpers";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { getCurrentAccount } from "../account";
import { rebateProgressAdapter } from "./rebate-progress.adapter";
import { IRebateProgressState } from "./rebate-progress.reducer";

export const getRebateProgressState = createFeatureSelector<IRebateProgressState>("rebateProgress");

export const {
	selectIds: rebateProgressSelectIds,
	selectEntities: rebateProgressSelectEntities,
	selectAll: rebateProgressSelectAll,
	selectTotal: rebateProgressSelectTotal
} = rebateProgressAdapter.getSelectors(getRebateProgressState);

export const getCurrentRebateProgress = createSelector(
	getCurrentAccount,
	rebateProgressSelectEntities,
	(account, entities) => account ? getNgStoreCacheItem(entities[account.id]) : undefined
);

export const getCurrentRebateTotal = createSelector(
	getCurrentRebateProgress,
	(rebate) => {
		if (!rebate) {
			return undefined;
		}
		if (!(rebate.triadRebate || rebate.generalRebate)) {
			return NaN;
		}
		return rebate.totalRebate;
	}
);